import {Divider, Table, Descriptions} from 'antd';
import {ColumnsType} from "antd/es/table";
import '../index.less';
import moment from "moment";
import {formatNumber, outTypeList, typeList} from "../../../utils";
import React from "react";
import '../../../components/GeneralManagement/index.less'

interface ViewItemProps {
    name: '入库' | '出库',
    dataSource: any[],
    info: any
}

const ViewItem = (props: ViewItemProps) => {
    const {name, info, dataSource} = props;
    const columns: ColumnsType<any> = [
        {
            title: '序号',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            ellipsis: true,
            render: (_: any, item: any, index) => <span>{index + 1}</span>
        },
        {
            title: '产品名称',
            dataIndex: 'product_name',
            key: 'name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '产品代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '规格型号',
            dataIndex: 'specification',
            key: 'model',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '单位',
            dataIndex: 'unit_name',
            key: 'unit',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '数量',
            dataIndex: 'count',
            key: 'number',
            align: 'center',
            ellipsis: true,
            render: (value: number) => {
                return <span>{formatNumber(value.toString())}</span>
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            ellipsis: true,
        },
    ]

    return (<div className={'viewItem'}>
        <div className={'viewTitle'} style={{marginTop: -20}}>{name}信息</div>
        <Descriptions>
            <Descriptions.Item label={`${name}单号`}>{info.code ? info.code : ''}</Descriptions.Item>
            <Descriptions.Item
                label={`${name}类型`}>
                {name === '入库' ? (info.instore_type ? typeList.map(e => {
                        if (e.id === info.instore_type) {
                            return <span key={e.id}>{e.name}</span>
                        }
                    }) : '') :
                    (info.outstore_type ? outTypeList.map(e => {
                        if (e.id === info.outstore_type) {
                            return <span key={e.id}>{e.name}</span>
                        }
                    }) : '')}
            </Descriptions.Item>
            <Descriptions.Item label="仓库名称">{info.storage_name ? info.storage_name : ''}</Descriptions.Item>
            <Descriptions.Item
                label={`${name}时间`}>{name === '入库' ? (info.instore_time ? moment(info.instore_time).format('YYYY/MM/DD HH:mm') : '') :
                (info.outstore_time ? moment(info.outstore_time).format('YYYY/MM/DD HH:mm') : '')
            }</Descriptions.Item>
            <Descriptions.Item
                label={`${name}申请人`}>{name === '入库' ? (info.instore_user ? info.instore_user : '') : (info.outstore_user ? info.outstore_user : '')}</Descriptions.Item>
            <Descriptions.Item
                label="所在部门">{name === '入库' ? (info.instore_user_organization ? info.instore_user_organization : '') :
                (info.outstore_user_organization ? info.outstore_user_organization : '')}</Descriptions.Item>
            <Descriptions.Item label="关联单号">{info.bind_no ? info.bind_no : ''}</Descriptions.Item>
            <Descriptions.Item label="对方单位">{info.trader ? info.trader : ''}</Descriptions.Item>
            <Descriptions.Item label="部门">{info.organization_name ? info.organization_name : ''}</Descriptions.Item>
            <Descriptions.Item label="备注">{info.remark ? info.remark : ''}</Descriptions.Item>
        </Descriptions>
        <Divider/>
        <div className={'viewTitle'}>{name}明细</div>
        <Table pagination={false} columns={columns} dataSource={dataSource} className={'mes-detail-tableBar'}/>
    </div>)
}

export default ViewItem
