import React from 'react'
import {Button, Input, InputNumber, Modal, Select} from "antd";
import './index.css'
import moment from "moment";
import {toastMessage} from "../../../utils";
import {getCauseInfo, workDetailRequest} from "../../../service";

/**
 * 报工对话框
 */
const {Option} = Select;

interface Type {
    goodNum: number,
    badNum: number,
    reason: undefined | number,
    badReasons: Array<any>,
    detailWorkItem: API.workDetailContent | undefined,
}

export default class ReportModal extends React.Component<{
    dataItem: API.queryItem | undefined,
    producerList?: Array<any>,
    closeClick: () => void,
    saveClick: (id: number, goodNum: number, badNum: number, reason: undefined | number) => void,
}> {

    state: Type = {
        goodNum: -1,
        badNum: -1,
        reason: undefined,
        badReasons: [],
        detailWorkItem: undefined,
    }

    componentDidMount() {
        const {dataItem} = this.props
        workDetailRequest({id: `${dataItem!.object_id}`}).then((res) => {
            if (res.code === 200) {
                this.setState({
                    detailWorkItem: res.data
                })
            }
        })
        getCauseInfo().then((res: any) => {
            this.setState({
                badReasons: res.data.data
            })
        })
    }

    render() {
        const {goodNum, badNum, reason, badReasons, detailWorkItem} = this.state
        const {closeClick, saveClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'报工'}
            footer={null}
            width={855}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>任务单号</div>
                    <Input
                        value={detailWorkItem && detailWorkItem.task.task_number}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>任务数量</div>
                    <Input
                        value={detailWorkItem && detailWorkItem.task.task_quantity === null || undefined ? '' : detailWorkItem && detailWorkItem.task.task_quantity.toLocaleString()}
                        className={'reportModalItemInputStyle'}
                        style={{textAlign: "right"}}
                        disabled
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>销售单号</div>
                    <Input
                        value={detailWorkItem && detailWorkItem.task.order_number}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>上工序</div>
                    <Input
                        value={detailWorkItem && detailWorkItem.pre_work}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>产品</div>
                    <Select
                        className={'reportModalItemSelectStyle'}
                        value={detailWorkItem && detailWorkItem.task.product_name}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>上工序合格数量</div>
                    <Input
                        value={detailWorkItem ? detailWorkItem.pre_work === '' || null ? '' : detailWorkItem.pre_qualified.toLocaleString() : ''}
                        className={'reportModalItemInputStyle'}
                        style={{textAlign: "right"}}
                        disabled
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>单位</div>
                    <Input
                        value={detailWorkItem && detailWorkItem.task.unit_name}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>当前工序</div>
                    <Select
                        className={'reportModalItemSelectStyle'}
                        value={detailWorkItem && detailWorkItem.process_name}
                        disabled
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>产线</div>
                    <Select
                        className={'reportModalItemSelectStyle'}
                        value={detailWorkItem && detailWorkItem.task.line_info}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        合格数量
                    </div>
                    <InputNumber
                        className={'reportModalItemInputStyle'}
                        min={0}
                        placeholder="请输入合格数量"
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                this.setState({
                                    goodNum: newValue
                                })
                            } else {
                                this.setState({
                                    goodNum: -1
                                })
                            }
                        }}
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>订单数量</div>
                    <Input
                        value={detailWorkItem && detailWorkItem.task.order_quantity === null || undefined ? '' : detailWorkItem && detailWorkItem.task.order_quantity.toLocaleString()}
                        className={'reportModalItemInputStyle'}
                        style={{justifyContent: "right"}}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        不良数量
                    </div>
                    <InputNumber
                        className={'reportModalItemInputStyle'}
                        min={0}
                        placeholder="请输入不良数量"
                        onChange={(newValue) => {
                            if (newValue !== null) {
                                this.setState({
                                    badNum: newValue
                                })
                            } else {
                                this.setState({
                                    badNum: -1
                                })
                            }
                        }}
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'reportModalItemTitleLeft'}>计划日期</div>
                    <div className={'reportModalItemTextStyle'} style={{width: 120, justifyContent: "center"}}>
                        {detailWorkItem && detailWorkItem.task.start_date === null ? '' : moment(detailWorkItem && detailWorkItem.task.start_date).format('YYYY/MM/DD')}
                    </div>
                    <div style={{display: "flex", width: 40, justifyContent: "center"}}>
                        ~
                    </div>
                    <div className={'reportModalItemTextStyle'}
                         style={{width: 120, justifyContent: "center", marginLeft: 0}}>
                        {detailWorkItem && detailWorkItem.task.end_date === null ? '' : moment(detailWorkItem && detailWorkItem.task.end_date).format('YYYY/MM/DD')}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'reportModalItemTitleRight'}>不良原因</div>
                    <Select
                        className={'reportModalItemSelectStyle'}
                        placeholder="请选择不良原因"
                        onChange={(value) => {
                            this.setState({
                                reason: value
                            })
                        }}
                    >
                        {
                            badReasons.length > 0 && badReasons.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.reason}
                                </Option>
                            })
                        }
                    </Select>
                </div>
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button
                    style={{width: 100, height: 35, backgroundColor: '#B8B8B8', borderRadius: 5, color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        closeClick && closeClick()
                    }}
                >
                    关闭
                </Button>
                <Button
                    style={{marginLeft: 20, width: 100, height: 35, borderRadius: 5}}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                        if (goodNum <= -1) {
                            toastMessage('请输入合格数量')
                            return;
                        }
                        if (badNum <= -1) {
                            toastMessage('请输入不良数量')
                            return;
                        }
                        saveClick && saveClick(detailWorkItem!.object_id, goodNum, badNum, reason)
                    }}
                >
                    保存
                </Button>
            </div>
        </Modal>
    }
}
