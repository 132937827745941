import React, {useContext, useEffect, useState} from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import SelectFormQuery from "../components/selectFormQuery";
import moment from "moment";
import CheckOrderModal from "../../OrderManage/components/checkOrderModal";
import {getClientDetailRequest, getOrderDetailRequest, getProductDetailRequest, queryRequest} from "../../../service";
import CheckModal from "../../Basic/Production/Modal/checkModal";
import BusinessmanCheckModal from "../../Basic/Businessman/Modal/checkModal";
import {UserContext} from "../../../contexts/userContext";
import {toastErrorMessage} from "../../../utils";

/**
 * 进度查询
 */
const Query = (): React.ReactElement => {
    const userInfo = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.queryItem[]>([]);
    const [isHasSell, setIsHasSell] = useState(false);
    const [isHasCustomer, setIsHasCustomer] = useState(false);
    const [isHasProduct, setIsHasProduct] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSellOrder, setIsShowSellOrder] = useState(false);
    const [sellOrderInfo, setSellOrderInfo] = useState<API.orderItem>();
    const [isShowClient, setIsShowClient] = useState(false);
    const [clientInfo, setClientInfo] = useState<API.Businessman_ResponseInfo>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        remark: '',
        update_time: '',
        link_name: '',
        contact: '',
        name: '',
        type: 0,
    });
    const [isShowProduct, setIsShowProduct] = useState(false);
    const [productInfo, setProductInfo] = useState<API.productItem>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        product_name: '',
        product_unit_id: 0,
        remark: '',
        specification: '',
        unit_code: '',
        unit_name: '',
        update_time: ''
    });
    const [taskStatus, setTaskStatus] = useState<undefined | Array<string>>(undefined);
    const [taskNumber, setTaskNumber] = useState('');
    const [productName, setProductName] = useState('');

    const columns: ColumnsType<API.queryItem> = [
        {title: '任务单号', dataIndex: ['task', 'task_number'], align: 'center', ellipsis: true},
        {
            title: '销售单号',
            dataIndex: ['task', 'order_number'],
            align: 'center',
            ellipsis: true,
            render: (saleNumber: string, record) => <div
                className={isHasSell ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasSell) {
                        getDetailSellOrderInfo(record.task.order_id!)
                    }
                }}
            >
                {saleNumber}
            </div>
        },
        {
            title: '客户', dataIndex: ['task', 'trader_name'], align: 'center', ellipsis: true,
            render: (customer: string, record) => <div
                className={isHasCustomer ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasCustomer) {
                        getDetailClientInfo(record.task.trader_id)
                    }
                }}
            >
                {customer}
            </div>
        },
        {
            title: '产品', dataIndex: ['task', 'product_name'], align: 'center', ellipsis: true,
            render: (product: string, record) => <div
                className={isHasProduct ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasProduct) {
                        getDetailProductInfo(record.task.product_id)
                    }
                }}
            >
                {product}
            </div>
        },
        {
            title: '订单数量',
            dataIndex: ['task', 'order_quantity'],
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '单位', dataIndex: ['task', 'unit_name'], align: 'center', ellipsis: true},
        {title: '产线', dataIndex: ['task', 'line_info'], align: 'center', ellipsis: true},
        {title: '工序', dataIndex: 'process_name', align: 'center', ellipsis: true},
        {
            title: '任务数量', dataIndex: ['task', 'task_quantity'], align: 'center', ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {
            title: '计划开始时间', dataIndex: ['task', 'start_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '计划完成时间', dataIndex: ['task', 'end_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '指派时间', dataIndex: 'issue_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '作业者', dataIndex: 'user', align: 'center', ellipsis: true},
        {
            title: '实际开始时间', dataIndex: 'start_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '实际完成时间', dataIndex: 'end_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '合格数量',
            dataIndex: 'qualified',
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {
            title: '不良数量', dataIndex: 'defective', align: 'center', ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '不良原因', dataIndex: 'defect_reason', align: 'center', ellipsis: true, width: '12%'},
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 20 ? '#EB4420' : status === 30 ? '#3E7BFA' : status === 40 ? '#06C270' : status === 50 ? '#0053FF' : '#666666'}}>
                {
                    status === 20 ? '未派工' : status === 30 ? '已派工' : status === 40 ? '处理中' : status === 50 ? '已完成' : ''
                }
            </div>
        },
    ]

    useEffect(() => {
        userInfo.useInfo.authRoutes?.map((item) => {
            if (item === '/order/management') {
                setIsHasSell(true)
            } else if (item === '/basic/businessman') {
                setIsHasCustomer(true)
            } else if (item === '/basic/production') {
                setIsHasProduct(true)
            }
        })
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        taskStatus: Array<string> | undefined,
        taskNum: string,
        productName: string
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page: page, per_page: size}
        if (data) {
            if (data.taskStatus && data.taskStatus.length > 0) {
                param = {...param, status: JSON.stringify(data.taskStatus)}
            }
            if (data.taskNum !== '') {
                param = {...param, task_number: data.taskNum}
            }
            if (data.productName !== '') {
                param = {...param, product: data.productName}
            }
        } else {
            if (taskStatus && taskStatus.length > 0) {
                param = {...param, status: JSON.stringify(taskStatus)}
            }
            if (taskNumber !== '') {
                param = {...param, task_number: taskNumber}
            }
            if (productName !== '') {
                param = {...param, product: productName}
            }
        }
        queryRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleShowTotal = (total: number) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{display: "flex", marginLeft: 0, color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    const getDetailSellOrderInfo = (objectId: number) => {
        getOrderDetailRequest({object_id: objectId}).then((res) => {
            if (res.code === 200) {
                setIsShowSellOrder(true)
                setSellOrderInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const getDetailClientInfo = (objectId: number) => {
        getClientDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowClient(true)
                setClientInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const getDetailProductInfo = (objectId: number) => {
        getProductDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowProduct(true)
                setProductInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    return (
        <div>
            <SelectFormQuery
                titles={['任务状态', '任务单号', '产品名称']}
                queryClick={(status: undefined | Array<string>, taskOrderNum: string, productionName: string) => {
                    setTaskStatus(status)
                    setTaskNumber(taskOrderNum)
                    setProductName(productionName)
                    onFinish(1, pageSize, {taskStatus: status, taskNum: taskOrderNum, productName: productionName})
                }}
            />
            <Table
                className={'commonTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                scroll={{x: 3000}}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />
            {
                isShowSellOrder && <CheckOrderModal
                    dataItem={sellOrderInfo}
                    closeClick={() => {
                        setIsShowSellOrder(false)
                    }}
                />
            }

            {
                isShowClient && <BusinessmanCheckModal
                    BusinessmanItem={clientInfo}
                    closeClick={() => {
                        setIsShowClient(false)
                    }}
                />
            }

            {
                isShowProduct && <CheckModal
                    productItem={productInfo}
                    closeClick={() => {
                        setIsShowProduct(false)
                    }}
                />
            }
        </div>
    )
}
export default Query
