import { useState, useRef, useEffect, forwardRef, useImperativeHandle} from "react";
import { Form, Input, Select, DatePicker, Checkbox, message } from "antd";
import EditOrDragTable,{TRef} from "../../../../components/EditOrDragTable";
import {ColumnsType} from "antd/es/table";
import moment,{Moment} from "moment";
import ModalContainer from "../../../../components/Modal";
import SetPlan,{SRef} from "../../components/SetPlan";
import {getDeviceInfoList, getPatrolProject,postCreatePatrolPlan,postEditPatrolPlan} from "../../../../service";
import { FormInstance } from "antd/es/form";
import { getFormData } from "../../../../utils";
import {IProps} from "../../PatrolProject/components/AddOrEditPatrolProject"
import "../../index.less";

interface IPlanProps extends IProps {
  tableForm:FormInstance
}
export interface IDeviceInfo {
  object_id:number
  device_name:string
  device_code:string
  device_specification:string
  device_type_name:string
  device_position:string
  device_organization_name:string
}

export interface IPatrolProject {
  object_id:number
  patrol_content:string
  parameter_name:string
  patrol_type:number
  upper_limit:string
  lower_limit:string
  results_options:Array<string>
  patrol_name:string
}

const AddOrEditPatrolPlan = forwardRef((props:IPlanProps,ref) => {
  const {form,tableForm,afterOnOK,selectItem,isEdit} = props;
  const [endDateChecked,setEndDateChecked] = useState<boolean>(false);
  const [detailDataSource, setDetailDataSource] = useState<any[]>([]);
  const [selectStartDate,setSelectStartDate] = useState<Moment | null>(null);
  const [open,setOpen] = useState<boolean>(false);
  const [deviceInfo,setDeviceInfo] = useState<Array<IDeviceInfo>>([]);
  const [patrolProject,setPatrolProject] = useState<Array<IPatrolProject>>([]);
  const [params,setParams] = useState<{description:string,cronExpression:string | null,device_id:number}>({
    description:'',cronExpression:null,device_id:isEdit ? selectItem.device_id : 0
  });
  const tRef = useRef<TRef>(null);
  const sRef = useRef<SRef>(null);
  
  const newItem = {
    patrol_content: undefined,
    parameter_name: undefined,
    patrol_type: undefined,
    upper_limit: undefined,
    lower_limit: undefined,
    results_options: undefined,
    patrol_name: undefined,
  };
  const defaultColumns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "index",
      width: 60,
      align: "center",
      render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
    },
    {
      title: "",
      dataIndex: "object_id",
      width: 0,
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`,"object_id"]}>
          <div></div>
        </Form.Item>
      ),
    },
    {
      title: ( <span><span className={"requiredIcon"}>*</span>巡检项目</span>),
      dataIndex: "product_name",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "patrol_name"]}  rules={[{ required: true, message: "请选择巡检项目！" }]}>
          <Select style={{textAlign:'left'}}  placeholder="请选择巡检项目" 
             getPopupContainer={trigger => {
              // 选一个跟随滚动的父元素并且设置为relative
              return document.getElementsByClassName('plan-modal-bottom')[0] as any
             }}
              onChange={(id) => {
                patrolProject.forEach((i) => {
                  if(i.object_id === id) {
                    tableForm.setFieldValue([`${index}`, "patrol_content"],i.patrol_content)
                    tableForm.setFieldValue([`${index}`, "parameter_name"],i.parameter_name)
                    tableForm.setFieldValue([`${index}`, "patrol_type"],i.patrol_type)
                    tableForm.setFieldValue([`${index}`, "upper_limit"],i.upper_limit)
                    tableForm.setFieldValue([`${index}`, "lower_limit"],i.lower_limit)
                    tableForm.setFieldValue([`${index}`, "results_options"],i.results_options)
                    tableForm.setFieldValue([`${index}`, "object_id"],i.object_id)
                  }
                })
              }}
              options={handleSelectOptions('patrol')}
            />
        </Form.Item>
      ),
    },
    {
      title: <span>巡检内容</span>,
      dataIndex: "patrol_content",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`,"patrol_content"]}>
          <Input disabled={true}></Input>
        </Form.Item>
      ),
    },
    {
      title: <span>参数名称</span>,
      dataIndex: "parameter_name",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "parameter_name"]}>
          <Input disabled={true}  style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: <span>巡检结果类型</span>,
      dataIndex: "patrol_type",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "patrol_type"]}>
          <Input disabled={true}  style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: <span>上限</span>,
      dataIndex: "upper_limit",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "upper_limit"]}>
          <Input disabled={true}  style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: <span>下限</span>,
      dataIndex: "lower_limit",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "lower_limit"]}>
          <Input disabled={true}  style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: <span>选项</span>,
      dataIndex: "results_options",
      align: "center",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "results_options"]}>
          <Input disabled={true}></Input>
        </Form.Item>
      ),
    },
    {
      title: <span>操作</span>,
      dataIndex: "options",
      width:60,
      align: "center",
      render: (_: any, __: any, index: number) => (
        <span
          className="delete-btn"
          onClick={() => {
            if (tRef.current) {
              tRef.current.handleDelete(index);
            }
          }}
        >
          删除
        </span>
      ),
    },
  ]; 

  useImperativeHandle(ref,()=>({
    handleSave
  }))

  useEffect(()=>{
    // 获取设备档案信息
    getDeviceInfoList({page_num:1,page_size:10000}).then(res => {
      if(res.code === 200 && res.data.data) {
        let deviceInfoArr:Array<IDeviceInfo> = res.data.data.map((i:any) => {
          return {
            object_id: i.object_id,
            device_name: i.device_name,
            device_code: i.code,
            device_specification: i.device_specification,
            device_type_name: i.device_type_name,
            device_position: i.position_name,
            device_organization_name: i.organization_name,
          };
        })
        setDeviceInfo(deviceInfoArr)
      }
    }).catch(err => console.log('err',err))
    // 获取未删除的巡检项目信息
    getPatrolProject({page_num:1,page_size:10000}).then(res => {
      if(res.code === 200 && res.data.data) {
        let projectInfoArr:Array<IPatrolProject> = res.data.data.map((i:any) => {
          return {
            patrol_content: i.patrol_content,
            parameter_name: i.parameter_name,
            patrol_type: i.patrol_type === 10 ? "数值" : i.patrol_type === 20 ? "单选" : "多选",
            upper_limit: i.upper_limit,
            lower_limit: i.lower_limit,
            results_options: i.results_options.join("；"),
            object_id: i.object_id,
            patrol_name:i.patrol_name
          };
        })
        setPatrolProject(projectInfoArr)
      }
    }).catch(err => console.log('err',err))
    form.resetFields()
    tableForm.resetFields()
    if(isEdit) {
      selectItem.permanently_or_not === 1 ? setEndDateChecked(true) : setEndDateChecked(false)
      setSelectStartDate(moment(selectItem.plan_start_date));
      if(selectItem.plan_end_date) {
        form.setFieldsValue({
          ...selectItem,
          plan_start_date:moment(selectItem.plan_start_date),
          plan_end_date:moment(selectItem.plan_end_date)
        })
      } else {
        delete selectItem.plan_end_date
        form.setFieldsValue({
          ...selectItem,
          plan_start_date:moment(selectItem.plan_start_date)
        })
      }
      if(selectItem.patrol_items.length) {
        let initArr:any[] = [];
        selectItem.patrol_items.forEach((i:any,index:number) => {
          initArr.push({ 
            ...i,
            index,
            patrol_type:i.patrol_type === 10 ? '数值' : i.patrol_type === 20 ? '单选' : '多选',
            results_options:i.results_options.join('；')
          })
        })
        setDetailDataSource(initArr)
        tableForm.setFieldsValue({...initArr})
      }
    }
  },[])


  const handleSelectOptions = (type:string) => {
   if(type === 'device') {
    return deviceInfo.map((i:IDeviceInfo) => {
      return { label:i.device_name, value:i.object_id }
    })
   }
   return patrolProject.map((i:IPatrolProject) => {
     return { label:i.patrol_name, value:i.object_id }
   })
  }

  const handleSave = (formData:any,tableData:any) => {
    if(!Object.keys(tableData).length) {
      return message.warning('至少添加一条巡检项目')
    }
    let obj = {...formData,...params}
    !obj.code && delete obj.code
    obj.plan_start_date = moment(obj.plan_start_date).format("YYYY-MM-DD")
    obj.plan_end_date ? obj.plan_end_date = moment(obj.plan_end_date).format("YYYY-MM-DD") : delete obj.plan_end_date
    obj.permanently_or_not = endDateChecked ? 1 : 0
    obj.patrol_items_ids = JSON.stringify(Object.values(tableData).map((i:any) => i.object_id))
    !obj.remarks && delete obj.remarks
    if(isEdit) {
      postEditPatrolPlan(getFormData({...obj,object_id:selectItem.object_id,execute_cycle:obj.cronExpression ? obj.cronExpression : selectItem.execute_cycle})).then(res => {
        res.code === 200 ? afterOnOK('编辑成功') : message.warning(res.message)
      }).catch(err => console.log('err',err))
    } else {
      postCreatePatrolPlan(getFormData({...obj,execute_cycle:obj.cronExpression})).then(res => {
        res.code === 200 ? afterOnOK('新增成功') : message.warning(res.message)
      }).catch(err => console.log('err',err))
    }
   
  }

  return (
    <div>
      <div className="patrol-point-plan-modal">
      <div className="plan-modal-top">
      <Form name="patrol-plan-modal-form" form={form} colon={false} autoComplete="off">
        <div className="plan-title">设备信息</div>
        <div className="plan-part-one">
          <Form.Item label="设备名称" name="device_name" rules={[{ required: true, message: "请选择设备名称!" }]} >
            <Select
              placeholder="请选择设备名称"
              style={{ width: 120 }}
              onChange={(id) => {
                deviceInfo.forEach(i => {
                  if(i.object_id === id) {
                    setParams({ ...params, device_id:id })
                    form.setFieldsValue({device_code:i.device_code,device_specification:i.device_specification,device_type_name:i.device_type_name,device_position:i.device_position,device_organization_name:i.device_organization_name})
                  }
                })
              }}
              getPopupContainer={trigger => trigger.parentNode}
              options={handleSelectOptions('device')}
            />
          </Form.Item>
          <Form.Item label="设备代码" name="device_code">
            <Input disabled />
          </Form.Item>
          <Form.Item label="规格型号" name="device_specification">
            <Input disabled />
          </Form.Item>
          <Form.Item label="设备类型" name="device_type_name">
            <Input disabled />
          </Form.Item>
          <Form.Item label="存放位置" name="device_position">
            <Input disabled />
          </Form.Item>
          <Form.Item label="使用部门" name="device_organization_name">
            <Input disabled />
          </Form.Item>
        </div>
        <div className="plan-line"></div>
        <div className="plan-title">巡检计划</div>
        <div className="plan-part-two">
          <Form.Item label="巡检计划代码" name="code" rules={[ { type: "string", max: 32, pattern: /^[A-Za-z0-9]+$/, message: "巡检计划代码由不大于32位的字母、数字组成!", }, ]} >
            <Input placeholder="不填写，系统将自动生成" disabled={isEdit}/>
          </Form.Item>
          <Form.Item label="巡检计划名称" name="patrol_plan_name" rules={[ { required: true, message: "请输入巡检计划名称!" }, { type: "string", max: 32, message: "巡检计划名称长度不大于32位!", }, ]} >
            <Input placeholder="请输入巡检计划名称" />
          </Form.Item>
          <Form.Item label="计划开始日期" name="plan_start_date" rules={[{ required: true, message: "请选择计划开始日期!" }]} >
            <DatePicker placeholder="请选择计划开始日期" format="YYYY/MM/DD" onChange={(date: Moment | null)=>{
              setSelectStartDate(date)
              // 重选了计划开始日期，若是此时存在计划结束日期，并且计划结束日期早于计划开始日期，那么直接清空计划结束日期
              if(form.getFieldValue('plan_end_date') && date) {
                if(form.getFieldValue('plan_end_date') < date) {
                  form.resetFields(['plan_end_date'])
                }
              }
            }}/>
          </Form.Item>
          <div className="plan-end-date">
            <Form.Item label="计划结束日期" name="plan_end_date" rules={[{
               validator: (_, value) => {
                if (!value && endDateChecked) {
                  return Promise.resolve();
                } else if(value) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error('请选择计划结束日期或者勾选永久!'))
                }
            }
            }]} >
              <DatePicker placeholder="请选择计划结束日期" allowClear={false} format="YYYY/MM/DD" suffixIcon={<span></span>} disabled={endDateChecked} disabledDate={current=>{ if(selectStartDate) { return current < selectStartDate } return false }} />
            </Form.Item>
            <Checkbox className="end-date-btn" checked={endDateChecked} onChange={(e)=>{setEndDateChecked(e.target.checked);form.resetFields(['plan_end_date'])}}>永久</Checkbox>
          </div>
          <div className="plan-part-row">
            <div className="row">
              <Form.Item label="执行周期" name="execute_cycle_describe" rules={[{ required: true, message: "请输入执行周期!" }]}>
                <Input disabled placeholder="请设置执行周期" />
              </Form.Item>
              <div className="set-btn" onClick={()=>setOpen(true)}> 设置 </div>
            </div>
            <Form.Item label="备注" name="remarks" rules={[ { type: "string", max: 200, message: "备注不大于200位!", }, ]} >
              <Input.TextArea placeholder="请输入备注" />
            </Form.Item>
            <div className="plan-line"></div>
          </div>
        </div>
        
      </Form>
      </div>
           <div className="plan-modal-bottom">
           <EditOrDragTable tableForm={tableForm} detailColumns={defaultColumns} detailDataSource={detailDataSource}
            title={`巡检项目`} newItem={newItem} ref={tRef} scroll={{x:'max-content'}} addText={<div><span className="add-btn">+</span>添加</div>}/>
           </div>
    </div>
    {
        open && <ModalContainer title="设置巡检周期" open={open} onCancel={()=>setOpen(false)} width="1090px" innerComponent={<SetPlan ref={sRef} cronStr={params.cronExpression ? params.cronExpression : isEdit ? selectItem.execute_cycle : null}/>}
        onOk={()=>{
          if(sRef.current && sRef.current.handleSetPlan()) {
            let obj:{description:string,cronExpression:string} = sRef.current.handleSetPlan()
            setParams({ ...params, ...obj })
            form.setFieldValue('execute_cycle_describe',obj.description)
            form.validateFields(['execute_cycle_describe']);
            setOpen(false)
            message.success('保存成功')
          }
        }} okText="保存"></ModalContainer>
    }
    </div>
  );
})
export default AddOrEditPatrolPlan;
