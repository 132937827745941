import React, {useRef, useState, useContext} from "react";
import {useNavigate} from 'react-router-dom';
import GeneralManagement, {QueryListItem,GRef} from "../../../components/GeneralManagement";
import {Button, Form, message, Space, Switch, Modal, Image} from "antd";
import ModalContainer from "../../../components/Modal";
import DeleteItem from "../../../components/DeleteItem";
import {ColumnsType} from "antd/es/table";
import AddOrEditPointPlan from "./components/AddOrEditPointPlan";
import ShowPlan from "./components/ShowPlan";
import showPlanImage from "../../../assets/show-plan.png";
import {getPointPlan,postEnableOrDeactivatePointPlan,getPointPlanInfo,postDeletePointPlan} from "../../../service";
import { getFormData } from "../../../utils";
import {UserContext} from "../../../contexts/userContext";
import moment from "moment";
import "../index.less";

enum ModalTitle {
    ADD = '新增点检计划',
    EDIT = '编辑点检计划',
    DELETE = '系统确认',
    SHOW = '查看点检计划',
    IMPLEMENTATION = '查看执行情况'
}

const PointPlan = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedRowKeys,setSelectedRowKeys] = useState<any[]>([]);
    const [objectId,setObjectId] = useState<number>();
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const addOrEditRef = useRef<{handleSave:Function}>(null);
    const {useInfo} = useContext(UserContext);
    const gRef = useRef<GRef>(null);
    const navigate = useNavigate();
    const formList: QueryListItem[] = [
        {
            key: 'spot_plan_name',
            name: '计划名称',
            placeholder: '请输入计划名称'
        },
        {
            key: 'device_name',
            name: '设备名称',
            placeholder: '请输入设备名称'
        },
        {
            key: 'execute_cycle_describe',
            name: '执行周期',
            placeholder: '请输入执行周期'
        }
    ]
    const columns: ColumnsType<any>  = [
        {
            title: 'No',
            dataIndex: 'index',
            align: 'center',
            ellipsis: true,
            width:100,
            render:(_:any,__:any,index:number)=>index+1
        },
        {
            title: '计划代码',
            dataIndex: 'code',
            align: 'center',
            ellipsis: true,
            render:(text:string,record:any) => <span className="plan-class" onClick={() => {
                openModal(ModalTitle.SHOW, record)
            }}>{text}</span>
        },
        {
            title: '计划名称',
            dataIndex: 'spot_plan_name',
            align: 'left',
            ellipsis: true,
            render:(text:string,record:any) => <span className="plan-class" onClick={() => {
                openModal(ModalTitle.SHOW, record)
            }}>{text}</span>
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            align: 'left',
            ellipsis: true
        },
        {
            title: '计划状态',
            dataIndex: 'plan_status',
            align: 'center',
            ellipsis: true,
            render:(value:number,record:any,__:number) => {
                // 10 启用 20 停用
                return <Switch checkedChildren="启用" unCheckedChildren="停用" checked={value === 10} onChange={(checked)=>{
                    if(checked) {
                        // checked为true表示当前从停用状态变成了启用状态
                        postEnableOrDeactivatePointPlan(getFormData({object_id:record.object_id,plan_status:10})).then(res => {
                            res.code === 200 ? afterOnOK('已启用', false) : message.warning(res.message)
                        }).catch(err => console.log('err',err))
                    } else {
                        Modal.confirm({
                          title:"停用后将不再生成点检任务!",
                          onOk: function() {
                            postEnableOrDeactivatePointPlan(getFormData({object_id:record.object_id,plan_status:20})).then(res => {
                                res.code === 200 ? afterOnOK('已停用', false) : message.warning(res.message)
                            }).catch(err => console.log('err',err))
                          },
                          okText: "停用",
                          okType: "default",
                          cancelButtonProps: {type:"primary"}
                        })
                    }
                }}/>
            }
        },
        {
            title: '计划开始日期',
            dataIndex: 'plan_start_date',
            align: 'center',
            ellipsis: true,
            render: (item:string) => <span>{item && moment(item).format('YYYY/MM/DD')}</span>

        },
        {
            title: '计划结束日期',
            dataIndex: 'plan_end_date',
            align: 'center',
            ellipsis: true,
            render: (item:string,record:Record<string,any>) => <span>{item ? moment(item).format('YYYY/MM/DD') :  record.permanently_or_not ? '永久' : ''}</span>
        },
        {
            title: '执行周期',
            dataIndex: 'execute_cycle_describe',
            align: 'left',
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace:'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight:0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },

    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className='mes-patrol-plan-add' onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
            <Button className='mes-patrol-plan-show' onClick={() => {
                openModal(ModalTitle.IMPLEMENTATION, null)
            }}>查看执行情况</Button>
        </div>
    ))


    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            setInnerComponent(<AddOrEditPointPlan ref={addOrEditRef} form={form} tableForm={tableForm} afterOnOK={afterOnOK}/>)
            setOpen(true)
        } else if (title === ModalTitle.EDIT) {
            getPointPlanInfo({object_id:item.object_id}).then(res => {
                if(res.code === 200) {
                    setInnerComponent(<AddOrEditPointPlan ref={addOrEditRef} form={form} tableForm={tableForm} afterOnOK={afterOnOK} isEdit={true} selectItem={res.data}/>)
                    setOpen(true)
                } else {
                    message.warning(res.message)
                }
            }).catch(err => console.log('err',err))
            
        } else if(title === ModalTitle.IMPLEMENTATION){
            if(!selectedRowKeys.length) {
                return message.warning('请选择要查看的计划')
            }
            if(useInfo.authRoutes?.includes('/inspection/pointTask')) {
                return navigate('/inspection/pointTask',{state:{fromPointPlan:selectedRowKeys}})
                
            } 
            message.warning('您无权限查看');
        } else if (title === ModalTitle.DELETE) {
            setObjectId(item.object_id)
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
            setOpen(true)
        } else {
            getPointPlanInfo({object_id:item.object_id}).then(res => {
                if(res.code === 200) {
                    setInnerComponent(<ShowPlan selectItem={res.data}/>) 
                    setOpen(true)
                } else {
                    message.warning(res.message)
                }
            }).catch(err => console.log('err',err))
        }
        
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.validateFields().then(formData => {
                tableForm.validateFields().then(tableData => {
                    addOrEditRef && addOrEditRef.current && addOrEditRef.current.handleSave(formData,tableData)
                })
              }).catch(err => console.log('err',err))
        } else if (title === ModalTitle.DELETE) {
            postDeletePointPlan(getFormData({object_id:objectId})).then(res => {
                res.code === 200 ? afterOnOK('删除成功') : message.warning(res.message) 
            }).catch(err => console.log('err',err))
        }
    }

    const afterOnOK = (text: string, isModal = true) => {
        message.success(text);
        isModal && setOpen(false);
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }


    const getDataAsync = (queryCondition: API.getPatrolPlanParams) => {
        return new Promise((resolve) => {
            getPointPlan(queryCondition).then(res => {
                res.code === 200 ? resolve(res.data) : message.warning(res.message)
            }).catch(err => console.log('err',err))
        })
    }


    return (
        <div className={'mes-patrol-point-plan'}>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} showSelection={true}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}/>
            {
                
                modalTitle === '查看点检计划' ?
                <ModalContainer title={<div><Image preview={false} src={showPlanImage}></Image>查看点检计划</div>} open={open}
                            onOk={() => onOK(modalTitle)} onCancel={() => setOpen(false)}
                            innerComponent={innerComponent} width={"1150px"} footer={null}/> :
                <ModalContainer title={modalTitle} open={open}
                        onOk={() => onOK(modalTitle as ModalTitle)} onCancel={() => setOpen(false)}
                        okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                        innerComponent={innerComponent} width={modalTitle === "系统确认"? "20%" : "1150px"}/>
                
            }
        </div>
    )
}
export default PointPlan;
