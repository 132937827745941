import React from 'react'
import {Button, Select} from "antd";
import Input from "antd/lib/input/Input";
import './index.css'
import {getStatus} from "../../../utils";

/**
 * 生产管理--生产派工--查询重置
 */
const {Option} = Select;

interface Type {
    taskStatus: Array<string>,
    taskClientName: string,
    productionName: string,
}

export default class SelectFormDispatch extends React.Component<{
    titles: string[],
    queryClick?: (taskStatus: Array<string>, taskClientName: string, productionName: string) => void,
    batchClick?: () => void,
}> {

    state: Type = {
        taskStatus: ['20'],
        taskClientName: '',
        productionName: '',
    }

    render() {
        const {taskStatus, taskClientName, productionName} = this.state
        const {titles} = this.props
        return <div className={'selectFormTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    {titles && titles[0]}
                </div>
                <Select
                    value={taskStatus}
                    mode={"multiple"}
                    showArrow
                    showSearch={false}
                    style={{marginLeft: 20, minWidth: 244, height: 30, borderRadius: 5}}
                    placeholder={`请选择${titles && titles[0]}`}
                    onChange={(value) => {
                        this.setState({
                            taskStatus: value,
                        })
                    }}
                >
                    {
                        getStatus('query').map((item: any, index: number) => {
                            return <Option key={index} value={item.value}>
                                {item.name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    {titles && titles[1]}
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={taskClientName}
                    placeholder={`请输入${titles && titles[1]}`}
                    onChange={(e) => {
                        this.setState({
                            taskClientName: e.target.value
                        })
                    }}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    {titles && titles[2]}
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={productionName}
                    placeholder={`请输入${titles && titles[2]}`}
                    onChange={(e) => {
                        this.setState({
                            productionName: e.target.value
                        })
                    }}
                />
            </div>

            <Button
                className={'buttonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(taskStatus, taskClientName, productionName)
                }}
            >
                查询
            </Button>

            <Button
                className={'buttonReset'}
                type={"primary"}
                onClick={() => {
                    this.setState({
                        taskStatus: ['20'],
                        taskClientName: '',
                        productionName: '',
                    }, () => {
                        this.props.queryClick && this.props.queryClick(['20'], '', '')
                    })
                }}
            >
                重置
            </Button>

            <div style={{display: "flex", flex: 1}}/>
            <Button
                className={'buttonBatch'}
                type={"primary"}
                onClick={() => {
                    this.props.batchClick && this.props.batchClick()
                }}
            >
                批量派工
            </Button>
        </div>
    }
}
