import React, {useCallback, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, Options} from "../../../components/GeneralManagement";
import {Button, Col, Form, Input, message, Row, Select, Space, Upload} from "antd";
import {
    closeExceptionReport,
    createOrUpdateExceptionReport,
    deleteExceptionReport,
    getAllProcedureInfo,
    getAllProductionLineInfo, getExceptionCodeByType, getExceptionInfo,
    getExceptionReportList,
    getUploadUrl, putForwardExceptionReport, request, withdrawExceptionReport
} from "../../../service";
import {EXCReportStatus, filterData, getCategoryList, getFormData, showError} from "../../../utils";
import DeleteItem from "../../../components/DeleteItem";
import ModalContainer from "../../../components/Modal";
import moment from "moment";
import ExceptionDetail from "../components/ExceptionDetail";
import type {RcFile} from 'antd/es/upload/interface';
import '../index.less'
import {FormInstance} from "antd/lib/form/hooks/useForm";
import type {UploadProps} from 'antd/es/upload';
import type {UploadFile} from 'antd/es/upload/interface';
import {ColumnsType} from "antd/es/table";

export enum ModalTitle {
    ADD = '新增异常事件',
    EDIT = '编辑异常事件',
    DELETE = '系统确认',
    VIEW = '异常详情',
}

interface AddOrEditReportProps {
    setFileID: (fileID: string) => void,
    form: FormInstance,
    image?: string | undefined,
    title?: ModalTitle
}

/**
 * 异常提报
 */
const EXCReport = () => {
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState<Options[]>([]);
    const [categoryIDs, setCategoryIDs] = useState<number[]>([]);
    const [productionLineIDs, setProductionLineIDss] = useState<number[]>([]);
    const [procedureIDs, setProcedureIDs] = useState<number[]>([]);
    const [productionLine, setProductionLine] = useState<any[]>([]);
    const [procedure, setProcedure] = useState<any[]>([]);
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [fileID, setFileID] = useState<string>('');

    const formList = [
        {
            key: 'exception_type_ids',
            name: '异常类别',
            placeholder: '请选择异常类别',
            options: categoryList,
            multiple: true
        },
        {
            key: 'phenomenal_description',
            name: '异常描述',
            placeholder: '请输入异常描述',
        },
        {
            key: 'date',
            name: '创建时间',
            placeholder: '请输入异常描述',
            date: true
        }
    ]

    const columns: ColumnsType<any>  = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '异常单号',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '产线',
            dataIndex: 'line_name',
            key: 'line_name',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '工序',
            dataIndex: 'work_process_name',
            key: 'work_process_name',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '异常类别',
            dataIndex: 'exception_type',
            key: 'exception_type',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '异常代码',
            dataIndex: 'exception_code',
            key: 'exception_code',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '现象描述',
            dataIndex: 'phenomenal_description',
            key: 'phenomenal_description',
            align: 'center',
            width: 330,
            ellipsis: true
        },
        {
            title: '提报人',
            dataIndex: 'reporting_user',
            key: 'reporting_user',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                } else {
                    return <span/>
                }
            }
        },
        {
            title: '提报时间',
            dataIndex: 'reporting_time',
            key: 'reporting_time',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                } else {
                    return <span/>
                }
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            ellipsis: true,
            width: 150,
            render: (value: number) => {
                return <span
                    style={value === 10 ? {color: '#F8CA16'} : (value === 20 ? {color: '#06C270'} : ((value === 30 ? {color: '#FF8800'} : ((value === 40 ? {color: '#3E7BFA'} : {})))))}>{EXCReportStatus[value]}</span>
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'left',
            ellipsis: true,
            width: 300,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.VIEW, item)
                        }}>查看</span>
                        {
                            item.status === 10 &&
                            <Space size={'large'}>
                                <span className={'mes-options'} onClick={() => {
                                    openModal(ModalTitle.EDIT, item)
                                }}>编辑</span>
                                <span className={'mes-options'} onClick={() => {
                                    openModal(ModalTitle.DELETE, item)
                                }}>删除</span>
                                <span className={'mes-options'} onClick={() => {
                                    openToast(putForwardExceptionReport, item, '提报成功')
                                }}>提报</span>
                            </Space>
                        }
                        {
                            item.status === 20 && <span className={'mes-options'} onClick={() => {
                                openToast(withdrawExceptionReport, item, '撤回成功')
                            }}>撤回</span>
                        }
                        {
                            item.status === 40 && <span className={'mes-options'} onClick={() => {
                                openToast(closeExceptionReport, item, '关闭成功')
                            }}>关闭</span>
                        }
                    </Space>
                )
            }
        },
    ]

    const openToast = (dispatchFunc: Function, item: any, successText: string) => {
        return new Promise((resolve) => {
            dispatchFunc({object_id: item.object_id}).then((res: any) => {
                if (res.code === 200) {
                    message.success(successText)
                    resolve(res.data)
                    refreshData()
                } else {
                    message.error(res.message)
                }
            })
        })
    }

    const AddOrEditReport = React.memo((props: AddOrEditReportProps): React.ReactElement => {
        const {setFileID, form, image,title} = props;
        const [codeList, setCodeList] = useState<any[]>([]);
        const [fileList, setFileList] = useState<UploadFile[]>([]);

        const layout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18},
        }

        const beforeUpload: UploadProps['beforeUpload'] = (file: RcFile) => {
            const isNeedFile = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isNeedFile) {
                message.error('只支持jpg、png格式的文件!');
            }
            return isNeedFile || Upload.LIST_IGNORE;
        };

        const customRequest: UploadProps['customRequest'] = (options: any) => {
            const formData = getFormData({name: options.file.name});
            getUploadUrl(formData).then(res => {
                if (res.code === 200) {
                    request.put(res.data.upload_url, {
                        data: options.file
                    }).then(res1 => {
                        if (res1) {
                            message.error('上传失败！')
                        } else {
                            setFileID(res.data.file.object_id)
                            options.onSuccess(res1)
                        }
                    })
                } else {
                    showError(res.message)
                }
            })
        }

        const handleChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
            setFileList(newFileList);
        }

        const handleRemove: UploadProps['onRemove'] = () => {
            setFileID('')
            setFileList([])
        }

        const handlePreview: UploadProps['onPreview'] = async (file: UploadFile) => {
            let src = file.url as string;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj as RcFile);
                    reader.onload = () => resolve(reader.result as string);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        };

        const uploadButton = (
            <div>
                <img src={require('../../../assets/upload-img.png')} alt={''}/>
            </div>
        );

        const getExceptionCode = (value: string) => {
            form.setFieldsValue({
                exception_code_id: null
            })
            getExceptionCodeByType({exception_type_id: Number(value)}).then(res => {
                if (res.code === 200) {
                    setCodeList(res.data)
                } else {
                    setCodeList([])
                    showError(res.message)
                }
            })
        }

        useEffect(() => {
            //根据异常类别获取异常代码
            if (form.getFieldValue('exception_type_id')) {
                getExceptionCodeByType({exception_type_id: form.getFieldValue('exception_type_id')}).then(res => {
                    if (res.code === 200) {
                        setCodeList(res.data)
                    } else {
                        setCodeList([])
                    }
                })
            }
            //显示图片
            if (image) {
                setFileList([
                    {
                        uid: new Date().getTime().toString(),
                        name: '图片',
                        status: 'done',
                        url: image,
                    },
                ])
            }
        }, [])

        return (
            <Form {...layout} colon={false} form={form} className={'report-detail'}>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item label={'异常单号'} name={'code'} rules={[
                            {
                                type: "string",
                                max: 32,
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '异常单号由不大于32位的字母、数字组成!'
                            }
                        ]}>
                            <Input placeholder={'不填写，系统将自动生成'}
                                   disabled={title === ModalTitle.EDIT}/>
                        </Form.Item>
                        <Form.Item label={'产线'} name={'line_id'} rules={[{required: true, message: '请选择产线'}]}>
                            <Select showSearch optionFilterProp={'children'} placeholder={'请选择产线'}>
                                {
                                    productionLine.map(e => <Select.Option key={e.object_id}
                                                                           value={e.object_id}>{e.line_name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label={'工序'} name={'work_process_id'} rules={[{required: true, message: '请选择工序'}]}>
                            <Select showSearch optionFilterProp={'children'} placeholder={'请选择工序'}>
                                {
                                    procedure.map(e => <Select.Option key={e.object_id}
                                                                      value={e.object_id}>{e.process_name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label={'异常类别'} name={'exception_type_id'}
                                   rules={[{required: true, message: '请选择异常类别'}]}>
                            <Select showSearch optionFilterProp={'children'} placeholder={'请选择异常类别'}
                                    onChange={getExceptionCode}>
                                {
                                    categoryList.map(e => <Select.Option key={e.id}
                                                                         value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label={'异常代码'} name={'exception_code_id'}
                                   rules={[{required: true, message: '请选择异常代码'}]}>
                            <Select showSearch optionFilterProp={'children'} placeholder={'请选择异常代码'}>
                                {
                                    codeList.map(e => <Select.Option key={e.object_id}
                                                                     value={e.object_id}>{e.exception_describe}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'现象描述'} name={'phenomenal_description'}
                                   rules={[{required: true, message: '请描述异常现象'}, {max: 200, message: '现象描述不得大于200位！'}]}>
                            <Input.TextArea placeholder={'请描述异常现象'} rows={4}/>
                        </Form.Item>
                        <Form.Item label={'图片'} valuePropName="fileList">
                            <Upload
                                className={'upload-image'}
                                listType="picture-card"
                                fileList={fileList}
                                showUploadList={{showPreviewIcon: true, showRemoveIcon: true, showDownloadIcon: false}}
                                beforeUpload={beforeUpload}
                                customRequest={customRequest}
                                onChange={handleChange}
                                onPreview={handlePreview}
                                onRemove={handleRemove}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditReport form={form} setFileID={setFileID}/>)
        } else if (title === ModalTitle.EDIT) {
            //获取异常提报编辑详情
            getExceptionInfo({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    let tempIDs: number[] = [];
                    //存在异常分类调用获取异常代码接口
                    if (categoryIDs.includes(res.data.exception_type_id)) {
                        getExceptionCodeByType({exception_type_id: res.data.exception_type_id}).then(res1 => {
                            if (res1.code === 200) {
                                if (res1.data.length) {
                                    res1.data.map((e: any) => {
                                        tempIDs.push(e.object_id)
                                    })
                                    form.setFieldsValue({
                                        code: res.data.code,
                                        line_id: productionLineIDs.includes(res.data.line_id) ? res.data.line_id : null,
                                        work_process_id: procedureIDs.includes(res.data.work_process_id) ? res.data.work_process_id : null,
                                        exception_type_id: categoryIDs.includes(res.data.exception_type_id) ? res.data.exception_type_id : null,
                                        exception_code_id: categoryIDs.includes(res.data.exception_type_id) ? tempIDs.includes(res.data.exception_code_id) ? res.data.exception_code_id : null : null,
                                        phenomenal_description: res.data.phenomenal_description,
                                    })
                                    if (res.data.file_id) {
                                        setFileID(res.data.file_id.toString())
                                    }
                                    setInnerComponent(<AddOrEditReport form={form} setFileID={setFileID} title={title}
                                                                       image={res.data.file ? res.data.file.url : undefined}/>);
                                }
                            }
                        })
                    } else {
                        form.setFieldsValue({
                            code: res.data.code,
                            line_id: productionLineIDs.includes(res.data.line_id) ? res.data.line_id : null,
                            work_process_id: procedureIDs.includes(res.data.work_process_id) ? res.data.work_process_id : null,
                            exception_type_id: categoryIDs.includes(res.data.exception_type_id) ? res.data.exception_type_id : null,
                            exception_code_id: categoryIDs.includes(res.data.exception_type_id) ? tempIDs.includes(res.data.exception_code_id) ? res.data.exception_code_id : null : null,
                            phenomenal_description: res.data.phenomenal_description,
                        })
                        if (res.data.file_id) {
                            setFileID(res.data.file_id.toString())
                        }
                        setInnerComponent(<AddOrEditReport form={form} setFileID={setFileID} title={title}
                                                           image={res.data.file ? res.data.file.url : undefined}/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.VIEW) {
            setInnerComponent(<ExceptionDetail objectId={item.object_id}/>)
        }
        setOpen(true);
    }

    const onOK = () => {
        const formData = getFormData(filterData({object_id: selectedItem.object_id}))
        deleteExceptionReport(formData).then(res => {
            if (res.code === 200) {
                afterOnOK('删除成功！');
            } else {
                showError(res.message);
            }
        })
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            setFileID('')
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);;
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            setFileID('')
            form.resetFields();
        }
        setSelectedItem(null);
        setOpen(false);
        setInnerComponent(undefined);;
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => (
        <div>
            <Button className={'mes-add'} style={{marginRight: 20}} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getExceptionReportList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data)
                } else {
                    showError(res.message)
                }
            })
        })
    }, [])

    const onSave = (submit_type: number) => {
        if (modalTitle === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData({...filterData({...values, file_id: fileID}), submit_type: submit_type})
                createOrUpdateExceptionReport(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (modalTitle === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData({
                    ...filterData({...values, file_id: fileID}),
                    submit_type: submit_type,
                    object_id: selectedItem.object_id
                })
                createOrUpdateExceptionReport(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        }
    }

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.VIEW) {
            //查看
            return (<div>
                <Button type={'primary'} onClick={() => onCancel(modalTitle)}>关闭</Button>
            </div>)
        } else if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            //新增、编辑
            return <div>
                <Button onClick={() => onCancel(modalTitle)}>关闭</Button>
                <Button type={'primary'} onClick={() => onSave(10)}>保存</Button>
                <Button type={'primary'} onClick={() => onSave(20)}>提报</Button>
            </div>
        }
    }

    useEffect(() => {
        //获取异常类别列表
        getCategoryList().then((res: any) => {
            const temp: any[] = [];
            res.map((e: any) => {
                temp.push({
                    id: e.object_id,
                    name: e.exception_type_describe
                })
            })
            setCategoryList(temp)
            let tempIDs: number[] = [];
            if (res.length) {
                res.map((e: any) => {
                    tempIDs.push(e.object_id)
                })
                setCategoryIDs(tempIDs)
            }
        });
        //获取产线列表
        getAllProductionLineInfo().then(res => {
            if (res.code === 200) {
                setProductionLine(res.data.data)
                let tempIDs: number[] = [];
                if (res.data.data.length) {
                    res.data.data.map((e: any) => {
                        tempIDs.push(e.object_id)
                    })
                    setProductionLineIDss(tempIDs)
                }
            } else {
                setProductionLine([])
                showError(res.message);
            }
        })
        //获取工序列表
        getAllProcedureInfo().then(res => {
            if (res.code === 200) {
                setProcedure(res.data.data)
                let tempIDs: number[] = [];
                if (res.data.data.length) {
                    res.data.data.map((e: any) => {
                        tempIDs.push(e.object_id)
                    })
                    setProcedureIDs(tempIDs)
                }
            } else {
                setProcedure([])
                showError(res.message);
            }
        })
    }, [])

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<ExtraComponent/>}
                               getDataAsync={getDataAsync} ref={gRef} scroll={true}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                width={modalTitle === ModalTitle.DELETE ? '20%' : '855px'}
                                onOk={() => onOK()} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                footer={getFooter()} innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default EXCReport
