import React from 'react'
import {Button, Modal, Select} from "antd";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {toastMessage} from "../../../utils";
import {getUserList} from "../../../service";

/**
 * 派工对话框
 */
const {Option} = Select;

interface Type {
    producer: undefined | number,
    producerList: Array<any>,
}

export default class DispatchModal extends React.Component<{
    isHas: boolean,
    navigate: NavigateFunction,
    dataItems: API.queryItem[],
    closeClick: () => void,
    saveClick: (producer: undefined | number) => void,
}> {

    state: Type = {
        producer: undefined,
        producerList: [],
    }

    componentDidMount() {
        getUserList().then(res => {
            if (res.code === 200) {
                this.setState({
                    producerList: res.data.data.filter((item: any) => item.status === 1),
                    producer: this.getProducerStatus(res.data.data)
                })
            }
        })
    }

    getProducerStatus(data: any) {
        if (this.props.dataItems.length !== 1) {
            return undefined
        }
        const newData = data.filter((item: any) => item.status === 1)
        if (newData.find((item: any) => item.object_id === this.props.dataItems[0].user_id)) {
            return this.props.dataItems[0].user_id
        }
        return undefined
    }

    render() {
        const {isHas, navigate, closeClick, saveClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'派工'}
            footer={null}
            width={458}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{color: "red", marginRight: 5}}>
                        *
                    </div>
                    <div
                        style={{
                            color: isHas ? '#3E7BFA' : '#333333',
                            cursor: isHas ? 'pointer' : 'auto',
                            textDecoration: isHas ? 'underline' : 'none',
                        }}
                        onClick={() => {
                            if (isHas) {
                                navigate('/system/user')
                            }
                        }}
                    >
                        作业者
                    </div>
                </div>
                <Select
                    style={{
                        display: 'flex',
                        borderRadius: 5,
                        marginLeft: 20,
                        width: 280,
                        height: 38,
                    }}
                    value={this.state.producer}
                    placeholder="请选择作业者"
                    onChange={(value) => {
                        this.setState({
                            producer: value,
                        })
                    }}
                >
                    {
                        this.state.producerList.length > 0 && this.state.producerList.map((item: any, index: number) => {
                            return <Option key={index} value={item.object_id}>
                                {item.name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <div style={{display: "flex", justifyContent: "center", marginTop: 200}}>
                <Button style={{width: 100, height: 35, backgroundColor: '#B8B8B8', borderRadius: 5, color: '#ffffff'}}
                        htmlType="button"
                        onClick={() => {
                            closeClick && closeClick()
                        }}
                >
                    关闭
                </Button>
                <Button style={{marginLeft: 20, width: 100, height: 35, borderRadius: 5}} type="primary"
                        htmlType="submit"
                        onClick={() => {
                            if (this.state.producer === undefined) {
                                toastMessage('请选择作业者')
                                return;
                            }
                            saveClick && saveClick(this.state.producer)
                        }}
                >
                    保存
                </Button>
            </div>
        </Modal>
    }
}
