import React, {useContext, useEffect, useState} from "react";
import {Row,Divider, Table} from 'antd';
import {ColumnsType} from "antd/lib/table";
import {useNavigate} from 'react-router-dom'
import './index.less';
import {getLineStatistics, reportListRequest} from "../../service";
import {formatNumber, reportStatus, showError} from "../../utils";
import moment from "moment";
import {UserContext} from "../../contexts/userContext";

interface TaskData {
    name: string,
    image: string,
    text: string,
    number: number | null,
    color: string
}

interface ProductionLineData {
    name: string,
    total: number,
    undone: number,
    notStart: number,
    processing: number,
    finished: number
}

interface DataSource {
    key: string,
    taskNumber: string,
    product: string,
    number: number,
    company: string,
    productionLine: string,
    procedure: string,
    plannedStartTime: string,
    plannedEndTime: string,
    startTime: string,
    endTime: string,
    status: number
}

const Dashboard = (): React.ReactElement => {
    const {useInfo} = useContext(UserContext);
    const [taskData, setTaskData] = useState<TaskData[]>([
        {
            name: 'total',
            image: require('../../assets/dashboard-task-total.png'),
            text: '总数量',
            number: null,
            color: '#3E7BFA'
        },
        {
            name: 'not_started',
            image: require('../../assets/dashboard-task-notStart.png'),
            text: '未开始',
            number: null,
            color: '#FF8800'
        },
        {
            name: 'processing',
            image: require('../../assets/dashboard-task-processing.png'),
            text: '处理中',
            number: null,
            color: '#06C270'
        },
        {
            name: 'done',
            image: require('../../assets/dashboard-task-finished.png'),
            text: '已完成',
            number: null,
            color: '#3E7BFA'
        },
        {
            name: 'overdue',
            image: require('../../assets/dashboard-task-undone.png'),
            text: '逾期未完成',
            number: null,
            color: '#FF3B3B'
        },
    ])
    const [productionLineData, setProductionLineData] = useState<ProductionLineData[]>([])
    const [dataSource, setDataSource] = useState<DataSource[]>([])
    const columns: ColumnsType<any> = [
        {
            title: '任务单号',
            dataIndex: 'taskNumber',
            key: 'taskNumber',
            align: 'center'
        },
        {
            title: '产品',
            dataIndex: 'product',
            key: 'product',
            align: 'center'
        },
        {
            title: '数量',
            dataIndex: 'number',
            key: 'number',
            align: 'center',
            render: (value: number) => {
                return <span>{formatNumber(value.toString())}</span>
            }
        },
        {
            title: '单位',
            dataIndex: 'company',
            key: 'company',
            align: 'center'
        },
        {
            title: '产线',
            dataIndex: 'productionLine',
            key: 'productionLine',
            align: 'center'
        },
        {
            title: '工序',
            dataIndex: 'procedure',
            key: 'procedure',
            align: 'center'
        },
        {
            title: '计划开始时间',
            dataIndex: 'plannedStartTime',
            key: 'plannedStartTime',
            align: 'center',
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD')}</span>
                }
            }
        },
        {
            title: '计划完成时间',
            dataIndex: 'plannedEndTime',
            key: 'plannedEndTime',
            align: 'center',
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD')}</span>
                }
            }
        },
        {
            title: '实际开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            align: 'center',
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '实际完成时间',
            dataIndex: 'endTime',
            key: 'endTime',
            align: 'center',
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value: number) => (
                Object.entries(reportStatus).map(e => {
                    if (Number(e[0]) === value) {
                        return <span style={Number(e[0]) === 30 ? {color: '#3E7BFA'} : {color: "#009669"}}>{e[1]}</span>
                    } else {
                        return <span/>
                    }
                })
            )
        },
    ]
    const navigate = useNavigate();
    useEffect(() => {
        getLineStatistics().then(res => {
            if (res.code === 200) {
                let tempTask = taskData;
                Object.entries(res.data.task).map(e => {
                    tempTask.map(t => {
                        if (t.name == e[0]) {
                            t.number = e[1]
                        }
                    })
                })
                setTaskData(tempTask);
                let tempProductionLineData: ProductionLineData[] = [];
                if (res.data.line.length) {
                    res.data.line.slice(0, 8).map(e => {
                        tempProductionLineData.push({
                            name: e.line_name,
                            total: e.task_all_count,
                            undone: e.time_out_tasked_count,
                            notStart: e.no_start_task_count,
                            processing: e.tasking_count,
                            finished: e.tasked_count
                        })
                    })
                }
                setProductionLineData(tempProductionLineData)
            } else {
                showError(res.message)
            }
        })
        reportListRequest({
            status: JSON.stringify(["30", "40"])
        }).then(res => {
            if (res.code === 200) {
                let tempDataSource: DataSource[] = []
                res.data.data.map(e => {
                    tempDataSource.push({
                        key: e.object_id.toString(),
                        taskNumber: e.task.task_number,
                        product: e.task.product_name,
                        number: e.task.order_quantity,
                        company: e.task.unit_name,
                        productionLine: e.task.line_info,
                        procedure: e.process_name,
                        plannedStartTime: e.task.start_date,
                        plannedEndTime: e.task.end_date,
                        startTime: e.start_time,
                        endTime: e.end_time,
                        status: e.status
                    })
                })
                setDataSource(tempDataSource)
            } else {
                showError(res.message)
            }
        })
    }, [])

    return (
        <div className={'mes-dashboard'}>
            <div className={'dashboard-title'}>任务统计</div>
            <div className={'dashboard-task'}>
                <div style={{display: 'flex'}}>
                    {
                        taskData.map((e, i) => {
                            return (
                                <div className={'task-item'} style={{display: 'flex', alignItems: 'center'}}
                                     key={i}>
                                    <div style={{marginRight: 10}}>
                                        <img src={e.image} alt=""/>
                                    </div>
                                    <div>
                                        <div style={{fontSize: 14, color: '#333333'}}>{e.text}</div>
                                        <div style={{
                                            fontSize: 32,
                                            color: e.color,
                                            fontWeight: 'bold'
                                        }}>{e.number ? formatNumber(e.number.toString()) : 0}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={'dashboard-productionLine'}>
                <div className={'dashboard-title'}>产线统计</div>
                <div>
                    <Row gutter={[16, 16]} style={{marginLeft: 0}}>
                        {
                            productionLineData.map((e, i) => (
                                <div className={'productionLine-item'} key={i}>
                                    <div className={'productionLine-item-row'}>
                                    <span>
                                        <span className={'productionLine-item-title'} title={e.name}>{e.name}</span>
                                        <span className={'productionLine-item-total'}>总数量：
                                            <span style={{
                                                fontWeight: 'bold',
                                                color: '#666666'
                                            }}>{formatNumber(e.total.toString())}
                                            </span>
                                        </span>
                                    </span>
                                        <span className={'productionLine-item-icon'}>
                                        逾期未完成:{formatNumber(e.undone.toString())}
                                    </span>
                                    </div>
                                    <Divider style={{margin: 0}}/>
                                    <div className={'productionLine-item-row2'}>
                                    <span style={{display: 'inline-block', width: '33.3%'}}>
                                        <img src={require('../../assets/row-icon.png')} alt=""/>
                                        <span>未开始：{formatNumber(e.notStart.toString())}</span>
                                    </span>
                                        <span style={{display: 'inline-block', width: '33.3%'}}>
                                        <img src={require('../../assets/col-icon.png')} alt=""/>
                                        <span>处理中：{formatNumber(e.processing.toString())}</span>
                                    </span>
                                        <span style={{display: 'inline-block', width: '33.3%'}}>
                                        <img src={require('../../assets/true-icon.png')} alt=""/>
                                        <span>已完成：{formatNumber(e.finished.toString())}</span>
                                    </span>
                                    </div>
                                </div>
                            ))
                        }
                    </Row>
                </div>
            </div>
            <div className={'dashboard-procedure'}>
                <div style={{marginTop: 15, marginBottom: 15}}>
                    <span className={'dashboard-title'}>工序任务</span>
                    {dataSource.length > 4 && <span className={'dashboard-procedure-more'} onClick={() => {
                        if (useInfo.authRoutes?.includes('/production/report')) {
                            navigate('/production/report')
                        } else {
                            navigate('/403')
                        }
                    }}>
                        <span style={{marginRight: 10}}>更多</span>
                        <img src={require('../../assets/dashboard-procedure-more.png')} alt={''}/>
                    </span>}
                </div>
                <Table columns={columns} dataSource={dataSource.slice(0, 4)} pagination={false}></Table>
            </div>
        </div>
    )
}
export default Dashboard
