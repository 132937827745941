import {message, Modal, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {UploadFile} from "antd/es/upload/interface";
import {getBase64, getFormData, showError} from "../../utils";
import {RcFile, UploadProps} from "antd/es/upload";
import {getUploadUrl, request} from "../../service";
import './index.less';

interface UploadPicturesProps {
    fileList: UploadFile[];
    setFileList: (fileIDList: UploadFile[]) => void;
}

//上传图片包含预览删除等功能
const UploadPictures = (props: UploadPicturesProps) => {
    const {fileList, setFileList} = props;
    //上传图片相关参数
    const [tempFileList, setTempFileList] = useState<UploadFile[]>([]);
    //图片预览相关参数
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const customRequest: UploadProps['customRequest'] = (options: any) => {
        const formData = getFormData({name: options.file.name});
        getUploadUrl(formData).then(res => {
            if (res.code === 200) {
                request.put(res.data.upload_url, {
                    data: options.file
                }).then(res1 => {
                    if (res1) {
                        message.error('上传失败！')
                    } else {
                        const temp = [...tempFileList]
                        temp.push({
                            uid: res.data.file.object_id,
                            name: res.data.file.name,
                            url: res.data.file.url,
                        })
                        setTempFileList(temp)
                        options.onSuccess(res1)
                    }
                })
            } else {
                showError(res.message)
            }
        })
    }

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleRemove = (file: UploadFile) => {
        const temp = [...tempFileList].filter(e => e.uid !== file.uid)
        setTempFileList(temp)
    }


    useEffect(() => {
        if (tempFileList.length) {
            setFileList(tempFileList)
        }
    }, [[...tempFileList]])


    useEffect(() => {
        setTempFileList(fileList)
    }, [])
    return (
        <div>
            <div className={'pictures-total'}>已上传{tempFileList.length}/{10}张图片</div>
            <div className={'pictures-upload'}>
                <Upload
                    beforeUpload={
                        file => {
                            const isImage = file.type.includes('image');
                            if (!isImage) {
                                message.warning(`${file.name} 不是图片格式，请上传图片`);
                            }
                            return isImage || Upload.LIST_IGNORE;
                        }
                    }
                    maxCount={10}
                    listType="picture-card"
                    fileList={tempFileList}
                    onPreview={handlePreview}
                    customRequest={customRequest}
                    onRemove={handleRemove}>
                    {
                        tempFileList.length < 10 ? <div className={'pictures-upload-button'}>点击或者将图片拖拽到这里上传</div> : null
                    }
                </Upload>
            </div>
            {/*预览图片*/}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="加载图片失败" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>

    )
}

export default UploadPictures
