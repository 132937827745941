import React, {useEffect, useState} from "react";
import {Form, Input, Select, Row, Col, TreeSelect} from "antd";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {getTreeData, sexes, showError} from "../../../utils";
import {getStationList, getOrganizationTree} from "../../../service";

interface AddOrEditUserProps {
    form: FormInstance;
}

const AddOrEditUser = (props: AddOrEditUserProps) => {
    const {Option} = Select;
    const {form} = props;
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const [organizationList, setOrganizationList] = useState<any>([])
    const [jobList, setJobList] = useState<any>([])

    useEffect(() => {
        getOrganizationTree().then(res => {
            if (res.code === 200) {
                let temp = getTreeData(res.data)
                setOrganizationList(temp)
            } else {
                showError(res.message)
            }
        })
        getStationList({page_num: 1}).then(res => {
            if (res.code === 200) {
                if (res.data) {
                    setJobList(res.data.data)
                }
            } else {
                showError(res.message)
            }
        })
    }, [])

    return (
        <div>
            <Form form={form} {...layout} colon={false}>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'账号'} name={'account'}
                                   rules={[
                                       {required: true, message: '请输入用户名!'},
                                       {
                                           type: "string",
                                           max: 32,
                                           pattern: /^[A-Za-z0-9]+$/,
                                           message: '账号由不大于32位的字母、数字组成!'
                                       },
                                   ]}

                        >
                            <Input placeholder={'请输入账号'} disabled={form.getFieldValue('account')}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'姓名'} name={'name'}
                                   rules={[{required: true, message: '请输入姓名!'},
                                       {
                                           type: "string",
                                           max: 32,
                                           pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                                           message: '姓名由不大于32位的汉字、字母和数字组成!'
                                       }]}
                                    >
                            <Input placeholder={'请输入姓名'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'工号'} name={'work_number'} rules={[
                            {required: true, message: '请输入工号!'},
                            {
                                type: "string",
                                max: 32,
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '工号由不大于32位的字母、数字组成!'
                            },]}  >
                            <Input placeholder={'请输入工号'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'性别'} name={'sex'} rules={[{required: true, message: '请选择性别!'}]}  >
                            <Select placeholder={'请选择性别'}>
                                {
                                    Object.entries(sexes).map(e =>
                                        <Option key={e[0]} value={Number(e[0])}>{e[1]}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'组织机构'} name={'organization_id'}  >
                            <TreeSelect placeholder={'请选择组织机构'} treeData={organizationList} treeDefaultExpandAll/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'手机号'} name={'phone'}   rules={[
                            {
                                type: 'string',
                                len: 11,
                                pattern: /^[0-9]+$/,
                                message: '请输入正确的手机号！'
                            }]}>
                            <Input placeholder={'请输入手机号'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'岗位'} name={'station_id'}  >
                            <Select placeholder={'请选择岗位'}>
                                {
                                    jobList.map((e: any, i: number) =>
                                        <Option key={i} value={e.object_id}>{e.station_name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'邮箱'} name={'email'}
                                   rules={[{type: 'email', message: '请输入正确的邮箱！'}]}>
                            <Input placeholder={'请输入邮箱'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default React.memo(AddOrEditUser);
