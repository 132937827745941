import React from 'react'
import Input from '../../../components/input'
import Button from '../../../components/button'
import Dropdown from '../../../components/dropdown'
declare type OverlayFunc = () => React.ReactElement;

export default class BusinessmanMenu extends React.Component<{
    productionNum: string,
    productionName: string,
    codeOnChange: (value: string)=>void,
    typeInfo :React.ReactElement | OverlayFunc,
    queryClick:()=>void,
    resetClick:()=>void,
    addClick:()=>void,
    deleteClick:()=>void,
    exportClick:()=>void,
}>{
    
    render() {
        const {
            productionNum,
            productionName,
            codeOnChange,
            typeInfo,
            queryClick,
            resetClick,
            addClick,
            deleteClick,
            exportClick,
        } = this.props
        return <div style={{ flex: 1, flexDirection: 'row', display: 'flex' ,backgroundColor:'#FFF',borderRadius:10 }}>
            <div style={{width:6,height:1}}/>
            <Input title='名称' style={{ width: 244, marginRight: 10 }} placeholder='请输入客商名称' onChange={(value) => {
                codeOnChange(value)
            }}
                value={productionNum}
            />
            <Dropdown title='类别'  titleStyle={{marginRight:-5}} placeholder='请选择类别' defaultValue={productionName} overlay={typeInfo} />
            
            <Button title='查询' buttonStyle={{marginLeft:20,marginTop:15,marginRight:20 }} onClick={() => {
               queryClick()
            }} />
            
           <Button title='重置' buttonStyle={{marginTop:15,backgroundColor:'#3B9FFD' }}  onClick={() => {
                resetClick()
            }} />
            <div style={{flex:1}}/>

            <Button title='新增' buttonStyle={{marginTop:15,marginRight:20,backgroundColor:'#5DA198'   }} onClick={() => {
                addClick()
            }} />
            
           <Button title='批量删除' buttonStyle={{marginTop:15,marginRight:20,width:76,backgroundColor:'#EB4420' }}  onClick={() => {
                deleteClick()
            }} />
              <Button title='导出' buttonStyle={{marginTop:15,marginRight:20 }} onClick={() => {
               exportClick()
            }} />
            
        </div>
    }
}