import React, {useState} from 'react';
import {Layout} from 'antd';
import HeaderBar from "./headerBar";
import SiderBar from './siderBar';
import BreadcrumbBar from "./breadcrumbBar";
import Router from '../../router';
import './index.less';
import {useNavigate} from "react-router-dom";


const LayoutContainer = () => {
    const {Header, Sider, Content} = Layout;
    //false展开，true收起
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const navigate = useNavigate();


    return (
        <Layout className={'mes-layout'}>
            <Sider className={'mes-sider'} width={260} collapsible collapsed={collapsed} collapsedWidth={98}
                   trigger={null}>
                {
                    collapsed ?
                        <div className="logoSmall" onClick={()=>{navigate('/')}}>
                            <img src={require('../../assets/logo.png')}
                                 alt=""/>
                        </div>:
                        <div className="logo" onClick={()=>{navigate('/')}}>
                            <img src={ require('../../assets/sider-logo.png')}
                                 alt=""/>
                        </div>
                }
                <SiderBar collapsed={collapsed}/>
            </Sider>
            <Layout>
                <Header>
                    <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed}/>
                </Header>
                <BreadcrumbBar/>
                <Content className={'mes-content'}>
                    <Router/>
                </Content>
            </Layout>
        </Layout>
    )
}

export default React.memo(LayoutContainer);
