import React, { useEffect, useState } from "react";
import CompanyMenu from "./companyMenu";
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../globalLess/global.css'
import AddModal from "./Modal/addModal";
import EditModal from "./Modal/editModal";
import { getAllCompanyInfo, postCompanyCreate_or_update, companyDelete } from "../../../service/index";
import {toastErrorMessage, toastSuccessMessage, toastMessage} from "../../../utils";
import DeleteModal from "../../Production/components/deleteModal";
import moment from "moment";

const ExportJsonExcel = require('js-export-excel')

const Company = (): React.ReactElement => {
    const columns: ColumnsType<API.allCompanyResponseItem> = [
        {
            title: '单位代码',
            dataIndex: 'code',
            ellipsis: true,
            width: 400,
        },
        {
            title: '单位名称',
            dataIndex: 'unit_name',
            ellipsis: true,
            width: 400,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            ellipsis: true,
            width: 600,
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'object_id',
            render: (code: string, data: API.addCompanyItem) =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setCompany_Item(data)
                            setShowEditModal(true)
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            data.object_id &&  setDeleteObjectString([data.object_id])
                            setIsShowDelete(true)
                        }}
                    >
                        删除
                    </div>
                </div>
        }
    ];
    let [code, setCode] = useState('');
    let [unit_name, setUnit_name] = useState('');
    let [company_Item, setCompany_Item] = useState<API.addCompanyItem>({})
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.allCompanyResponseItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowMoreDelete, setIsShowMoreDelete] = useState(false);
    const [deleteObjectString, setDeleteObjectString] = useState<number[]>([]);
   
    const queryCompany = () => {
        onFinish(1, pageSize, {
            code: code,
            unit_name: unit_name
        })
    }
 
    const BatchDelete = () => {
        const num = selectedRowKeys.length
        let objArray = []
        for (let index = num - 1; index >= 0; index--) {
            let indexItem: any = selectedRowKeys[index]
            indexItem = indexItem- (currentPage-1)* pageSize
            const item = dataSource[indexItem]
            objArray.push(item.object_id)
        }
        CompanyItemDelete(objArray)
        setSelectedRowKeys([])
    }
    const CompanyItemDelete = (object_ids: number[]) => {
        let objString: any;
        for (let index = 0; index < object_ids.length; index++) {
            if (index === 0) {
                objString = object_ids[index]
            } else {
                objString += ',' + object_ids[index]
            }
        }
        companyDelete({ object_ids: objString }).then((res) => {
            if (res.code === 200) {
                setIsShowDelete(false)
                setIsShowMoreDelete(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const addCompanyItem = (e: API.addCompanyItem, msg: string) => {
        postCompanyCreate_or_update(e).then((res) => {
            if (res.code === 200) {
                if (msg === '新增成功') {
                    setShowAddModal(false)
                } else if (msg === '编辑成功') {
                    setShowEditModal(false)
                }
                onFinish(currentPage, pageSize)
                toastSuccessMessage(msg)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const onFinish = (page: number, size: number, data?: {
        code?: string,
        unit_name?: string
    }) => {
        let dict: any = { page_num: page, page_size: size }

        if (data && data.code) {
            dict.code = data.code
        }
        if (data && data.unit_name) {
            dict.unit_name = data.unit_name
        }
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        getAllCompanyInfo(dict).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let dataCopy = []
                for (let index = 0; index < res.data.data.length; index++) {
                    let item = res.data.data[index]
                    item.key = index + (page - 1) * size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            toastErrorMessage(err.message)
        })
    }
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const exportFile = ( )=>{
        let dict: any = { page_num: 1, page_size: total }
        if (code) {
            dict.code = code
        }
        if (unit_name) {
            dict.unit_name = unit_name
        }

        setIsLoading(true)
        getAllCompanyInfo(dict).then((res: any) => {
            if (res.code === 200) {
                let dataSourceCopy: any = res.data.data
                if (dataSourceCopy.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    dataSourceCopy.forEach((item: any) => {
                        dataTable.push({
                            code: item.code,
                            unit_name: item.unit_name,
                            remark: item.remark
                        })
                    })
                    option.fileName = '单位信息' + moment(new Date()).format('YYYYMMDD')
                    option.datas = [
                        {
                            sheetData: dataTable,
                            sheetName: 'sheet',
                            sheetFilter: ['code', 'unit_name', 'remark'],
                            sheetHeader: ['单位代码', '单位名称', '备注'],
                        }
                    ];
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err: any) => {
            setIsLoading(false)
            toastErrorMessage(err.message)
        })
        
    };

    useEffect(() => {
        onFinish(1, 10);
    }, [])
    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999' }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <CompanyMenu productionNum={code} productionName={unit_name}
                    codeOnChange={(num: string) => {
                        setCode(num)
                    }}
                    nameOnChange={(name: string) => {
                        setUnit_name(name)
                    }}
                    queryClick={() => {
                        queryCompany()
                    }}
                    resetClick={() => {
                        setCode('')
                        setUnit_name('')
                        onFinish(1, pageSize);
                    }}
                    addClick={() => {
                        setShowAddModal(true)
                    }}
                    deleteClick={() => {
                        if (selectedRowKeys.length > 0) {
                            setIsShowMoreDelete(true)
                        } else {
                            toastMessage('请选择要删除的单位数据')
                        }
                    }}
                    exportClick={() => {
                        exportFile()
                    }}
                />
            </div>
            <div >
                <Table
                    className={'commonTable'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{x: 1600}}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                />
            </div>
            {
                showAddModal && <AddModal
                    closeClick={() => {
                        setShowAddModal(false)
                    }}
                    saveClick={(e) => {
                        addCompanyItem(e, '新增成功')
                    }}
                />
            }
            {
                showEditModal && <EditModal
                    companyItem={company_Item}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(e) => {
                        addCompanyItem(e, '编辑成功')
                    }}
                />
            }
             {
                isShowDelete && <DeleteModal
                closeClick={() => {
                    setIsShowDelete(false)
                }}
                confirmClick={() => {
                    CompanyItemDelete(deleteObjectString)
                }}
            />
            }
       {
                isShowMoreDelete && <DeleteModal
                closeClick={() => {
                    setIsShowMoreDelete(false)
                    setSelectedRowKeys([])
                }}
                confirmClick={() => {
                    BatchDelete()
                }}
            />
            }
        </div>
    )
}
export default Company
