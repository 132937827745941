import {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import {Form,Input,Radio,Checkbox,message} from "antd";
import EditOrDragTable from "../../../../components/EditOrDragTable";
import {ColumnsType} from "antd/es/table";
import { FormInstance } from "antd/es";
import {postPointTaskComplete} from "../../../../service";
import {getFormData} from "../../../../utils";
import UploadPictures from "../../../../components/UploadPictures";
import "../../index.less";


interface IProps {
  form: FormInstance
  selectItem: any
  tableForm: FormInstance
  afterOnOK: Function
}


export interface IPointProject {
  object_id?:number
  spot_content:string
  parameter_name:string
  spot_type:string | number
  spot_result:string | string[]
  spot_name:string
  options_exception: string
  remarks: string | null
  spot_method?:string | number
}


const TaskReport = forwardRef((props:IProps,ref) => {
  const [detailDataSource,setDetailDataSource] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const {form,tableForm,afterOnOK,selectItem} = props;
  const {code,device_name,device_code,team_groups,spot_user_name,spot_items,member_user_name_arr,object_id} = selectItem;
  const defaultColumns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: 'index',
      align:"center",
      ellipsis: true,
      fixed:true,
      render: (_:any,__:any,index:number) => <span>{index+1}</span>,
    },
    {
      title: '点检项目',
      dataIndex: 'spot_name',
      ellipsis: true,
      align:"left",
      fixed:true,
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "spot_name"]}>
          <Input disabled></Input>
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'objectId',
      width:0,
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "object_id"]}>
          <div></div>
        </Form.Item>
      ),
    },
    {
      title: '点检部位',
      dataIndex: 'spot_area',
      ellipsis: true,
      align:'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "spot_area"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '点检方法',
      dataIndex: 'spot_method',
      ellipsis: true,
      align:'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "spot_method"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '点检内容',
      dataIndex: 'spot_content',
      ellipsis: true,
      align:"left",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "spot_content"]}>
          <Input disabled></Input>
        </Form.Item>
      ),
    },
    {
      title: '参数名称',
      dataIndex: 'parameter_name',
      ellipsis: true,
      align:'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "parameter_name"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '点检结果类型',
      dataIndex: 'spot_type',
      ellipsis: true,
      align:'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "spot_type"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '点检结果',
      dataIndex: 'spot_result',
      align: "left",
      render: (_: any, record: any, index: number) => {
        if(record.spot_type === "数值") {
            return <Form.Item
            name={[`${index}`, "spot_result"]}
            rules={[{ required: true, message: "请输入点检结果！" },{
              pattern:/^[0-9][0-9]*(\.[0-9]{1,2})?$/,message:'只能输入整数或者两位小数!'
            }]}
          >
            <Input placeholder="请输入点检结果" onChange={(e)=>{
              let value = e.target.value;
              if(!value.length) {
                tableForm.resetFields([[`${index}`,'options_exception']])
                return
              }
              if((record.upper_limit || record.upper_limit === 0) && ( record.lower_limit || record.lower_limit === 0)) {
                if(record.upper_limit<parseFloat(value) || record.lower_limit>parseFloat(value)) {
                  tableForm.setFieldValue([`${index}`,'options_exception'],'异常')
                } else {
                  tableForm.setFieldValue([`${index}`,'options_exception'],'正常')
                }
              }
            }}></Input>
          </Form.Item>
        } else if(record.spot_type === "单选") {
            return (
              <Form.Item
                name={[`${index}`, "spot_result"]}
                rules={[{ required: true, message: "请选择点检结果！" }]}
              >
                <Radio.Group  onChange={(e)=>{
                  if((record.results_options.filter((i:any) => i.object_id === parseInt(e.target.value)))[0].options_exception === 1) {
                      tableForm.setFieldValue([`${index}`,'options_exception'],'异常')
                  } else {
                      tableForm.setFieldValue([`${index}`,'options_exception'],'正常')
                  }
                }
                  }>
                    {record.results_options.map((i:any)=><Radio key={i.object_id}  value={i.object_id} style={{wordBreak:'break-all'}}>{i.options_name}</Radio>)}
                </Radio.Group>
              </Form.Item>
            );
        } else if(record.spot_type === "多选") {
            return (
              <Form.Item
              name={[`${index}`, "spot_result"]}
              rules={[{ required: true, message: "请选择点检结果！" }]}>
                <Checkbox.Group
                  style={{wordBreak:'break-all'}}
                  options={record.results_options.map((i:any) => ({label:i.options_name,value:i.object_id}))}
                  onChange={(value)=>{
                    if(!value.length) {
                      tableForm.resetFields([[`${index}`,'options_exception']])
                      return
                    }
                    value.reduce((previousValue:number[],currentValue) => {
                        previousValue.push(record.results_options.find((i:any) => i.object_id === currentValue).options_exception)
                        return previousValue
                    },[]).includes(1) ?  tableForm.setFieldValue([`${index}`,'options_exception'],'异常') :
                    tableForm.setFieldValue([`${index}`,'options_exception'],'正常')
                  }
                  }
                />
              </Form.Item>
            );
        } else {
            return null
        }
      }
    },
    {
      title: '是否正常',
      dataIndex: 'options_exception',
      align: 'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "options_exception"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      align: "left",
      width: 500,
      render: (_: any, __: any, index: number) => (
          <Form.Item
            name={[`${index}`, "remarks"]}
            rules={[{type:"string",max:200,message:"备注不大于200位!"}]}
          >
            <Input placeholder="请输入备注"></Input>
          </Form.Item>
        ),
      }
  ];

  useImperativeHandle(ref,()=>({
    handleSave
  }))

  useEffect(()=>{
    form.resetFields();
    form.setFieldsValue({code,device_name,device_code,team_groups,spot_user_name,member_user_name_arr:member_user_name_arr.map((i:{name:string,object_id:number})=>i.name).join(',')});
    tableForm.resetFields();
    let initArr = spot_items.map((i:any,index:number) => {
      return {
        ...i,
        index,
        spot_type:i.spot_type === 10 ? '数值' : i.spot_type === 20 ? '单选' : '多选',
        spot_method: i.spot_method === 10 ? '目视' : i.spot_method === 20 ? '手感' : i.spot_method === 30 ? '看听' : '看/听',
        remarks: null
      }
    })
    tableForm.setFieldsValue({...initArr})
    setDetailDataSource(initArr)
  },[])


  const handleSave:{<T extends IPointProject>(res:T[]):void} = (res) => {
    
    let arr = res.map((i)=>{
      return {
        ...i,
        spot_type: i.spot_type === '数值' ? 10 : i.spot_type === '单选' ? 20 : 30,
        options_exception: i.options_exception === '正常' ? 10 : 20,
        spot_result: i.spot_type === '数值' ? i.spot_result : i.spot_type === '单选' ? spot_items.find((s:any) =>  s.object_id === i.object_id)?.results_options?.find((r:any)=>r.object_id === i.spot_result)?.options_name :
        (spot_items.find((s:any) => s.object_id === i.object_id)?.results_options as Array<any>)?.reduce((arr:any[],cur:any)=>{
          i.spot_result.includes(cur.object_id) && arr.push(cur.options_name)
          return arr
        },[]).join(','),
        spot_method: i.spot_method === '目视' ? 10 : i.spot_method === '手感' ? 20 : i.spot_method === '看听' ? 30 : 40
      }
    })
    postPointTaskComplete(getFormData({
      object_id,
      spot_task_results: JSON.stringify(arr),
      spot_task_images: JSON.stringify((fileList).map((i:any) => ({file_id:i.uid})))
    })).then(res => {
      res.code === 200 ? afterOnOK('报工成功') : message.warning(res.message)
    }).catch(err => console.log('err',err))

  }



  return (
    <div className="patrol-point-task-report">
      <div className="task-report-top">
        <div className="title">点检信息</div>
        <Form name="patrol-task-report-form" form={form} colon={false} autoComplete="off" layout="inline" >
          <Form.Item label="点检任务单号" name="code"><Input disabled /></Form.Item>
          <Form.Item label="设备名称" name="device_name"><Input disabled /></Form.Item>
          <Form.Item label="设备代码" name="device_code"><Input disabled /></Form.Item>
          <Form.Item label="点检班组" name="team_groups"><Input disabled /></Form.Item>
          <Form.Item label="点检负责人" name="spot_user_name"><Input disabled /></Form.Item>
          <div className="other-optioners"><Form.Item label="其他点检人员" name="member_user_name_arr"><Input disabled /></Form.Item></div>
        </Form>
      </div>
      <div className="task-report-line"></div>
      <div className="task-report-center">
        <EditOrDragTable title="点检结果" detailColumns={defaultColumns} detailDataSource={detailDataSource} tableForm={tableForm} isNoneAdd={true} scroll={{x:'max-content'}} ></EditOrDragTable>
      </div>
      <div className="task-report-line"></div>
      <div className="task-report-bottom">
        <div className="title">点检图片</div>
        <div className="pictures-upload">
          <UploadPictures fileList={fileList} setFileList={setFileList}></UploadPictures>
        </div>
      </div>
    </div>
  );
})
export default TaskReport;
