import React, {useEffect, useRef, useState} from "react";
import GeneralManagement, {Options, QueryListItem} from "../../components/GeneralManagement";
import {Button, Form, Input, message, Select, Space} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {createGroup, deleteGroup, getGroupById, getGroupList, getUserList, UpdateGroup} from "../../service";
import {filterData, getFormData, showError} from "../../utils";
import './index.less';
import {ColumnsType} from "antd/es/table";
import {useWatch} from "antd/es/form/Form";

enum ModalTitle {
    ADD = '新增班组',
    EDIT = '编辑班组',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

const Group = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const [personList, setPersonList] = useState<Options[]>([]);
    const formList: QueryListItem[] = [
        {
            key: 'group_name',
            name: '班组名称',
            placeholder: '请输入班组名称'
        },
        {
            key: 'head_user_id',
            name: '班组负责人',
            placeholder: '请选择班组负责人',
            options: personList
        }
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '班组代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '班组名称',
            dataIndex: 'group_name',
            key: 'group_name',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '班组负责人',
            dataIndex: 'head_user_name',
            key: 'head_user_name',
            align: 'center',
            ellipsis: true
        },
        {
            title: '班组成员',
            dataIndex: 'member_user_name_arr',
            key: 'member_user_name_arr',
            align: 'center',
            ellipsis: true,
            render: (value: string[]) => {
                if (value.length) {
                    return <div style={{textAlign: 'left'}} className={'text-show-ellipsis'}>{value.join('、')}</div>
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const AddOrEditJob = React.memo((): React.ReactElement => {
        return (
            <Form colon={false} form={form} className={'form-margin'}>
                <Form.Item label={'班组代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '班组代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'班组名称'} name={'group_name'} rules={[
                    {required: true, message: '请输入班组名称!'},
                    {type: "string", max: 32, message: '班组名称不得大于32位!'}]}>
                    <Input placeholder={'请输入班组名称'}/>
                </Form.Item>
                <Form.Item label={'负责人'} name={'head_user_id'}
                           rules={[{required: true, message: '请选择负责人!'}, (form) => ({
                               validator: (_, value) => {
                                   if (form.getFieldValue('member_user_id')) {
                                       if (form.getFieldValue('member_user_id').includes(value)) {
                                           return Promise.reject('负责人不能为班组成员!')
                                       } else {
                                           return Promise.resolve()
                                       }
                                   } else {
                                       return Promise.resolve()
                                   }
                               }
                           })]}>
                    <Select placeholder={'请选择负责人'} showSearch allowClear>
                        {
                            personList.map((e: any, i: number) =>
                                <Select.Option key={i} value={e.id}>{e.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'班组成员'} name={'member_user_id'}
                           rules={[{required: true, message: '请选择班组成员!'}, () => ({
                               validator: (_, value) => {
                                   if (form.getFieldValue('head_user_id')) {
                                       if (value.includes(form.getFieldValue('head_user_id'))) {
                                           return Promise.reject('班组成员不能为负责人!')
                                       } else {
                                           return Promise.resolve()
                                       }
                                   } else {
                                       return Promise.resolve()
                                   }
                               }
                           })]}>
                    <Select placeholder={'请选择班组成员'} showSearch allowClear mode={'multiple'}>
                        {
                            personList.map((e: any, i: number) =>
                                <Select.Option key={i} value={e.id}>{e.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'备注'} name={'remarks'} rules={[{max: 200, message: '备注不得大于200位！'}]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditJob/>)
        } else if (title === ModalTitle.EDIT) {
            getGroupById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        group_name: res.data.group_name,
                        head_user_id: res.data.head_user_id,
                        member_user_id: res.data.member_user_id,
                        remarks: res.data.remarks
                    })
                    setInnerComponent(<AddOrEditJob/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createGroup(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                UpdateGroup(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteGroup(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getGroupList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    useEffect(() => {
        getUserList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.name
                            })
                        })
                    }
                    setPersonList(temp)
                }
            } else {
                showError(res.message);
            }
        })
    }, [])

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={(modalTitle === ModalTitle.DELETE) ? '20%' : '688px'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default Group
