import {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import {Form,Input,Radio,Checkbox,message} from "antd";
import EditOrDragTable from "../../../../components/EditOrDragTable";
import {ColumnsType} from "antd/es/table";
import { FormInstance } from "antd/es";
import {postPatrolTaskCompelete} from "../../../../service";
import {getFormData} from "../../../../utils";
import UploadPictures from "../../../../components/UploadPictures";
import "../../index.less";


interface IProps {
  form: FormInstance
  selectItem: any
  tableForm: FormInstance
  afterOnOK: Function
}


interface IPatrolProject {
  object_id?:number,
  patrol_content:string
  parameter_name:string
  patrol_type:string | number
  patrol_result:string
  patrol_name:string
  options_exception: string
  remarks: string | null
}

const TaskReport = forwardRef((props:IProps,ref) => {
  const [detailDataSource,setDetailDataSource] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const {form,selectItem,tableForm,afterOnOK} = props;
  const {code,device_name,device_code,team_groups,patrol_items,patrol_user_name,member_user_name_arr,object_id} = selectItem;

  const defaultColumns: ColumnsType<any> = [
    {
      title: 'No',
      dataIndex: 'index',
      align:"center",
      ellipsis: true,
      fixed:true,
      render: (_:any,__:any,index:number) => <span>{index+1}</span>,
    },
    {
      title: '巡检项目',
      dataIndex: 'patrol_name',
      ellipsis: true,
      align:"left",
      fixed:true,
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "patrol_name"]}>
          <Input disabled></Input>
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'objectId',
      width:0,
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "object_id"]}>
          <div></div>
        </Form.Item>
      ),
    },
    {
      title: '巡检内容',
      dataIndex: 'patrol_content',
      ellipsis: true,
      align:"left",
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "patrol_content"]}>
          <Input disabled></Input>
        </Form.Item>
      ),
    },
    {
      title: '参数名称',
      dataIndex: 'parameter_name',
      ellipsis: true,
      align:'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "parameter_name"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '巡检结果类型',
      dataIndex: 'patrol_type',
      ellipsis: true,
      align:'center',
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "patrol_type"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '巡检结果',
      dataIndex: 'patrol_result',
      align: "left",
      render: (_: any, record: any, index: number) => {
        if(record.patrol_type === "数值") {
            return <Form.Item
            name={[`${index}`, "patrol_result"]}
            rules={[{ required: true, message: "请输入巡检结果！" },{
              pattern:/^[0-9][0-9]*(\.[0-9]{1,2})?$/,message:'只能输入整数或者两位小数!'
            }]}
          >
            <Input placeholder="请输入巡检结果" onChange={(e)=>{
               let value = e.target.value;
               if(!value.length) {
                tableForm.resetFields([[`${index}`,'options_exception']])
                return
               }
              if((record.upper_limit || record.upper_limit === 0) && ( record.lower_limit || record.lower_limit === 0)) {
                if(record.upper_limit<parseFloat(value) || record.lower_limit>parseFloat(value)) {
                  tableForm.setFieldValue([`${index}`,'options_exception'],'异常')
                } else {
                  tableForm.setFieldValue([`${index}`,'options_exception'],'正常')
                }
              }

            }}></Input>
          </Form.Item>
        } else if(record.patrol_type === "单选") {
            return (
              <Form.Item
                name={[`${index}`, "patrol_result"]}
                rules={[{ required: true, message: "请选择巡检结果！" }]}
              >
                <Radio.Group onChange={(e)=>{
                  if((record.results_options.filter((i:any) => i.object_id === parseInt(e.target.value)))[0].options_exception === 1) {
                    tableForm.setFieldValue([`${index}`,'options_exception'],'异常')
                  } else {
                    tableForm.setFieldValue([`${index}`,'options_exception'],'正常')
                  }
                   }
                  }>               
                      {record.results_options.map((i:any)=><Radio key={i.object_id} value={i.object_id}  style={{wordBreak:'break-all'}}>{i.options_name}</Radio>)}
                </Radio.Group>
              </Form.Item>
            );
        } else if(record.patrol_type === "多选") {
            return (
              <Form.Item
              style={{wordBreak:'break-all'}}
              name={[`${index}`, "patrol_result"]}
              rules={[{ required: true, message: "请选择巡检结果！" }]}>
                <Checkbox.Group
                  options={record.results_options.map((i:any) => ({label:i.options_name,value:i.object_id}))}
                  onChange={(value)=>{
                    if(!value.length) {
                      tableForm.resetFields([[`${index}`,'options_exception']])
                      return
                    }
                    value.reduce((previousValue:any[],currentValue) => {
                        previousValue.push(record.results_options.find((i:any) => i.object_id === currentValue).options_exception)
                        return previousValue
                    },[]).includes(1) ?  tableForm.setFieldValue([`${index}`,'options_exception'],'异常') :
                    tableForm.setFieldValue([`${index}`,'options_exception'],'正常')
                  }
                  }
                />
              </Form.Item>
            );
        } else {
            return null
        }
      }
    },
    {
      title: '是否正常',
      dataIndex: 'options_exception',
      align:'center',
      // 根据用户输入的巡检结果判断是否正常
      render: (_: any, __: any, index: number) => (
        <Form.Item name={[`${index}`, "options_exception"]}>
          <Input disabled style={{textAlign:'center'}}></Input>
        </Form.Item>
      ),
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      align: "left",
      width: 500,
      render: (value: any, item: any, index: number) => (
        <Form.Item
          name={[`${index}`, "remarks"]}
          rules={[{type:"string",max:200,message:"备注不大于200位!"}]}
        >
          <Input placeholder="请输入备注"></Input>
        </Form.Item>
      ),
    }
  ];

  useImperativeHandle(ref,()=>({
    handleSave
  }))

  useEffect(()=>{
    form.resetFields();
    form.setFieldsValue({code,device_name,device_code,team_groups,patrol_user_name,member_user_name_arr:member_user_name_arr.map((i:{name:string,object_id:number})=>i.name).join(',')});
    tableForm.resetFields();
    let initArr = patrol_items.map((i:any,index:number) => {
      return {
        ...i,
        index,
        patrol_type:i.patrol_type === 10 ? '数值' : i.patrol_type === 20 ? '单选' : '多选',
        remarks: null
      }
    })
    tableForm.setFieldsValue({...initArr})
    setDetailDataSource(initArr)
  },[])




  const handleSave:{<T extends IPatrolProject>(res:T[]):void} = (res) => {
    let arr = res.map((i)=>{
      return {
        ...i,
        patrol_type: i.patrol_type === '数值' ? 10 : i.patrol_type === '单选' ? 20 : 30,
        options_exception: i.options_exception === '正常' ? 10 : 20,
        patrol_result: i.patrol_type === '数值' ? i.patrol_result : i.patrol_type === '单选' ? patrol_items.find((s:any) =>  s.object_id === i.object_id)?.results_options?.find((r:any)=>r.object_id === i.patrol_result)?.options_name :  patrol_items.find((s:any) => s.object_id === i.object_id)?.results_options?.reduce((arr:any[],cur:any)=>{
          i.patrol_result.includes(cur.object_id) && arr.push(cur.options_name)
          return arr
        },[]).join(',')
      }
    })
    postPatrolTaskCompelete(getFormData({
      object_id,
      patrol_task_results: JSON.stringify(arr),
      patrol_task_images: JSON.stringify((fileList).map((i:any) => ({file_id:i.uid})))
    })).then(res => {
      res.code === 200 ? afterOnOK('报工成功') : message.warning(res.message)
    }).catch(err => console.log('err',err))

  }

  return (
    <div className="patrol-point-task-report">
      <div className="task-report-top">
        <div className="title">巡检信息</div>
        <Form name="patrol-task-report-form" form={form} colon={false} autoComplete="off" layout="inline" >
          <Form.Item label="巡检任务单号" name="code"><Input disabled /></Form.Item>
          <Form.Item label="设备名称" name="device_name"><Input disabled /></Form.Item>
          <Form.Item label="设备代码" name="device_code"><Input disabled /></Form.Item>
          <Form.Item label="巡检班组" name="team_groups"><Input disabled /></Form.Item>
          <Form.Item label="巡检负责人" name="patrol_user_name"><Input disabled /></Form.Item>
          <div className="other-optioners"><Form.Item label="其他巡检人员" name="member_user_name_arr"><Input disabled /></Form.Item></div>
        </Form>
      </div>
      <div className="task-report-line"></div>
      <div className="task-report-center">
        <EditOrDragTable title="巡检结果" detailColumns={defaultColumns} detailDataSource={detailDataSource} tableForm={tableForm} isNoneAdd={true} scroll={{x:'max-content'}} ></EditOrDragTable>
      </div>
      <div className="task-report-line"></div>
      <div className="task-report-bottom">
        <div className="title">巡检图片</div>
        <div className="pictures-upload">
          <UploadPictures fileList={fileList} setFileList={setFileList}></UploadPictures>
        </div>
      </div>
    </div>
  );
})
export default TaskReport;
