import React, { useContext, useEffect, useState } from "react";
import StationMenu from "./stationMenu";
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../globalLess/global.css'
import AddModal from "./Modal/addModal";
import EditModal from "./Modal/editModal";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/userContext";
import { getWork_stationInfo, Work_stationCreate, Work_stationUpdate, Work_stationDelete } from "../../../service/index";
import {toastErrorMessage, toastSuccessMessage, toastMessage} from "../../../utils";
import DeleteModal from "../../Production/components/deleteModal";
import moment from "moment";
const ExportJsonExcel = require('js-export-excel')
const Station = (): React.ReactElement => {
    const columns: ColumnsType<API.Work_stationResponesItem> = [
        {
            title: '工位代码',
            dataIndex: 'code',
            width: 250,
            ellipsis: true,
        },
        {
            title: '工位名称',
            dataIndex: 'work_station_name',
            width: 250,
            ellipsis: true,
        },
        {
            title: '所属产线',
            dataIndex: 'line_name',
            width: 250,
            ellipsis: true,
        },
        {
            title: '所属工序',
            dataIndex: 'work_process_name',
            width: 250,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 400,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'object_id',
            render: (object_id: string, data: API.Work_stationResponesItem) =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setStatioinItem(data)
                            setShowEditModal(true)
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setDeleteObjectString([data.object_id])
                            setIsShowDelete(true)
                        }}
                    >
                        删除
                    </div>
                </div>
        }
    ];
    let [StatioinItem, setStatioinItem] = useState<API.Work_stationResponesItem>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        remark: '',
        update_time: '',
        line_name: '',
        work_process_name: '',
        work_station_name: '',
        line_id: 0,
        work_process_id: 0,
    })
    let [code, setCode] = useState('');
    let [work_station_name, setWork_station_name] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [dataSource, setDataSource] = useState<API.Work_stationResponesItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const userInfo = useContext(UserContext)
    const navigate = useNavigate();
    const [isHasProcedure, setIsHasProcedure] = useState(false);
    const [isHasProductionLine, setIsHasProductionLine] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowMoreDelete, setIsShowMoreDelete] = useState(false);
    const [deleteObjectString, setDeleteObjectString] = useState<number[]>([]);

    const queryProduct = () => {
        onFinish(1, pageSize, {
            code: code,
            work_station_name: work_station_name
        })
    }
  
    const BatchDelete = () => {
        if (selectedRowKeys.length > 0) {
            const num = selectedRowKeys.length
            let objArray = []
            for (let index = num - 1; index >= 0; index--) {
                let indexItem: any = selectedRowKeys[index]
                indexItem = indexItem- (currentPage-1)* pageSize
                const item = dataSource[indexItem]
                objArray.push(item.object_id)
            }
            StationItemDelete(objArray)
            setSelectedRowKeys([])
        } else {
            return
        }
    }
    const StationItemDelete = (object_ids: number[]) => {
        let objString: any;
        for (let index = 0; index < object_ids.length; index++) {
            if (index === 0) {
                objString = object_ids[index]
            } else {
                objString += ',' + object_ids[index]
            }
        }
        Work_stationDelete({ object_ids: objString }).then((res) => {
            if (res.code === 200) {
                setIsShowDelete(false)
                setIsShowMoreDelete(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const editStationItem = (newItem: API.Work_stationUpdate) => {
        Work_stationUpdate(newItem).then((res) => {
            if (res.code === 200) {
                setShowEditModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('编辑成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const addStationItem = (newItem: API.Work_stationCreate) => {
        Work_stationCreate(newItem).then((res) => {
            if (res.code === 200) {
                setShowAddModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('添加成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }


    const exportFile = ( )=>{
        let dict: any = { page_num: 1, page_size: total }
        if (code) {
            dict.code = code
        }
        if (work_station_name) {
            dict.work_station_name = work_station_name
        }
        setIsLoading(true)
        getWork_stationInfo(dict).then((res: any) => {
            if (res.code === 200) {
                let dataSourceCopy: any = res.data.data
                if (dataSourceCopy.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    dataSourceCopy.forEach((item: any) => {
                        dataTable.push({
                            code: item.code,
                            work_station_name: item.work_station_name,
                            line_name: item.line_name,
                            work_process_name: item.work_process_name,
                            remark: item.remark
                        })
                    })
                    option.fileName = '工位信息' + moment(new Date()).format('YYYYMMDD')
                    option.datas = [
                        {
                            sheetData: dataTable,
                            sheetName: 'sheet',
                            sheetFilter: ['code', 'work_station_name', 'line_name', 'work_process_name', 'remark'],
                            sheetHeader: ['工位代码', '工位名称', '所属产线', '所属工序', '备注'],
                        }
                    ];
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err: any) => {
            toastErrorMessage(err.message)
            setIsLoading(false)
        })
    };

    const onFinish = (page: number, size: number, data?: {
        code?: string;
        work_station_name?: string;
    }) => {
        let dict: any = { page_num: page, page_size: size }
        if (data && data.code) {
            dict.code = data.code
        }
        if (data && data.work_station_name) {
            dict.work_station_name = data.work_station_name
        }
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        getWork_stationInfo(dict).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let dataCopy = []
                for (let index = 0; index < res.data.data.length; index++) {
                    let item = res.data.data[index]
                    item.key = index + (page - 1) * size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            toastErrorMessage(err.message)
        })
    }
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(() => {
        userInfo.useInfo.authRoutes?.map((item: any) => {
            console.log("item",item);
            if (item === '/basic/procedure') {
                setIsHasProcedure(true)
            } else if(item === '/basic/productionLine'){
                setIsHasProductionLine(true)
            }
        })
        onFinish(1, 10);
    }, [])
    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999' }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <StationMenu productionNum={code} productionName={work_station_name}
                    codeOnChange={(num: string) => {
                        setCode(num)
                    }}
                    nameOnChange={(name: string) => {
                        setWork_station_name(name)
                    }}
                    queryClick={() => {
                        queryProduct()
                    }}
                    resetClick={() => {
                        setCode('')
                        setWork_station_name('')
                        onFinish(1, pageSize);
                    }}
                    addClick={() => {
                        setShowAddModal(true)
                    }}
                    deleteClick={() => {
                        if (selectedRowKeys.length > 0) {
                            setIsShowMoreDelete(true)
                        } else {
                            toastMessage('请选择要删除的工位数据')
                        }
                    }}
                    exportClick={() => {
                        exportFile()
                    }}
                />
            </div>
            <div >
                <Table
                    className={'commonTable'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{x: 1600}}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                />
            </div>
            {
                showAddModal && <AddModal
                isHasProcedure={isHasProcedure}
                isHasProductionLine={isHasProductionLine}
                navigate={navigate}
                    closeClick={() => {
                        setShowAddModal(false)
                    }}
                    saveClick={(e) => {
                        addStationItem(e)
                    }}
                />
            }
            {
                showEditModal && <EditModal
                isHasProcedure={isHasProcedure}
                isHasProductionLine={isHasProductionLine}
                navigate={navigate}
                    StatioinItem={StatioinItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(e) => {
                        editStationItem(e)
                    }}
                />
            }
              {
                isShowDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowDelete(false)
                    }}
                    confirmClick={() => {
                        StationItemDelete(deleteObjectString)
                    }}
                />
            }
            {
                isShowMoreDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowMoreDelete(false)
                        setSelectedRowKeys([])
                    }}
                    confirmClick={() => {
                        BatchDelete()
                    }}
                />
            }
        </div>
    )
}
export default Station
