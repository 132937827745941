import React, {useRef, useState, useEffect} from "react";
import GeneralManagement, {QueryListItem,GRef} from "../../../components/GeneralManagement";
import {Form, message, Space, Image, Modal} from "antd";
import ModalContainer from "../../../components/Modal";
import AdjustTask from "./components/AdjustTask";
import DispatchTask from "./components/DispatchTask";
import ShowTask from "./components/ShowTask";
import {ColumnsType} from "antd/es/table";
import showTaskImage from "../../../assets/show-task.png";
import {getPatrolTaskList,getPatrolPlan,getPatrolTaskInfo,postAdjustPatrolTask,postDispatchPatrolTask, companyDelete} from "../../../service";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {getFormData,filterDateParam} from "../../../utils/index";
import "../index.less";

enum ModalTitle {
    ADJUST = "调整巡检任务",
    DISPATCH = "巡检任务派工",
    SHOW = "查看巡检任务"
}

export interface IPlans {
    id:number
    name:string
}

const PatrolTask = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [plans,setPlans] = useState<IPlans[]>([]);
    const [objectIds,setObjectIds] = useState<{adjustId:number | undefined,dispatchId:number | undefined}>({adjustId: undefined,dispatchId: undefined});
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const location = useLocation();
    const formList: QueryListItem[] = [
        {
            key: 'patrol_plan_ids',
            name: '计划名称',
            placeholder: '请选择计划名称',
            options: plans,
            multiple: true,
            defaultValue:location.state && location.state.fromPatrolPlan 
        },
        {
            key: 'date',
            name: '应执行时间',
            rangePick: true
        },
        {
            key: 'status',
            name: '任务状态',
            placeholder: '请选择任务状态',
            options: [{id:10,name:"未开始"},{id:20,name:"处理中"},{id:30,name:"已完成"},{id:40,name:"已跳过"}],
            multiple: true
        }
    ]
    const columns: ColumnsType<any>  = [
        {
            title: 'No',
            dataIndex: 'index',
            align: 'center',
            ellipsis: true,
            width:60,
            render:(_:any,__:any,index:number)=>index+1
        },
        {
            title: '巡检任务单号',
            dataIndex: 'code',
            align: 'center',
            ellipsis: true,
            width: 220,
            render:(text:string,record:any) => <span className="patrol-point-task-number" onClick={()=>{openModal(ModalTitle.SHOW,record)}}>{text}</span>
        },
        {
            title: '计划名称',
            dataIndex: 'patrol_plan_name',
            align: 'left',
            ellipsis: true
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            align: 'left',
            ellipsis: true
        },
        {
            title: '应执行时间',
            dataIndex: 'should_execution_time',
            align: 'center',
            width: 150,
            ellipsis: true,
            render:(i) => <span>{i && moment(i).format('YYYY-MM-DD HH:mm')}</span>
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render:(text:number) => {
                if(text === 10) {
                    return <span className="status-noStart">未开始</span>
                } else if(text === 20) {
                    return <span className="status-processing">处理中</span>
                } else if(text === 30) {
                    return <span className="status-have-done">已完成</span>
                } else {
                    return <span className="status-skip">已跳过</span>
                }
            }
        },
        {
            title: '巡检班组',
            dataIndex: 'team_groups',
            align: 'center',
            ellipsis: true
        },
        {
            title: '巡检负责人',
            dataIndex: 'patrol_user_name',
            align: 'center',
            ellipsis: true,
            width:120
        },
        {
            title: '实际开始时间',
            dataIndex: 'real_start_date',
            align: 'center',
            ellipsis: true,
            width: 150,
            render:(i => <span>{i && moment(i).format("YYYY-MM-DD HH:mm")}</span>)
        },
        {
            title: '实际结束时间',
            dataIndex: 'real_end_date',
            align: 'center',
            ellipsis: true,
            width: 150,
            render:(i => <span>{i && moment(i).format("YYYY-MM-DD HH:mm")}</span>)
        },
        {
            title: '执行结果',
            dataIndex: 'results_of_execution',
            align: 'center',
            ellipsis: true,
            render:((i:number) => <span>{i ? i === 10 ? '正常' : '异常' : ''}</span>)
        },
        {
            title: '操作',
            dataIndex: 'options',
            align: 'center',
            render: (_: any, record: any) => {
              if(record.status === 10) {
                return (
                <Space size={'large'} style={{whiteSpace:'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.ADJUST, record)
                    }}>调整</span>
                    <span className={'mes-options'} style={{marginRight:0}} onClick={() => {
                        openModal(ModalTitle.DISPATCH, record)
                    }}>派工</span>
                </Space>
                     )
              } else {
                    return null
              }
            }
        },

    ]

    useEffect(()=>{
        getPatrolPlan({page_num:1,page_size:10000}).then(res => {
            if(res.code === 200 && res.data.data) {
                setPlans(res.data.data.map((i:any) => {
                    return { id:i.object_id, name:i.patrol_plan_name }
                }))
            } else {
                message.warning(res.message)
            }
        }) 
    },[])


    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        if (title === ModalTitle.ADJUST) {
            setObjectIds({...objectIds,adjustId:item.object_id})
            getPatrolTaskDetail(item.object_id,'adjust')
        } else if (title === ModalTitle.DISPATCH) {
            setObjectIds({...objectIds,dispatchId: item.object_id})
            getPatrolTaskDetail(item.object_id,'dispatch')
            
        } else if (title === ModalTitle.SHOW) {
            getPatrolTaskDetail(item.object_id,'show')
        }
        
    }

    const getPatrolTaskDetail = (object_id:number,componentName:string) => {
        getPatrolTaskInfo({object_id}).then(res => {
            if(res.code === 200) {
               componentName === 'adjust' ? setInnerComponent(<AdjustTask selectItem={res.data} form={form}/>) :
               componentName === 'dispatch' ? setInnerComponent(<DispatchTask selectItem={res.data} form={form}/>) :
               setInnerComponent(<ShowTask selectItem={res.data}/>)
               setOpen(true);
            } else {
                message.warning(res.message)
            }
        }).catch(err => console.log('err',err))
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADJUST) {
            form.validateFields().then(res => {
                if(res.last_should_execution_time) {
                    res.should_execution_time = moment(res.last_should_execution_time).format("YYYY-MM-DD HH:mm:ss")
                    postAdjustPatrolTask(getFormData({...res,object_id:objectIds.adjustId})).then(res => {
                        res.code === 200 ? afterOnOK('保存成功') : message.warning(res.message)
                    }).catch(err => console.log('err',err))
                } else {
                    // 当前是选择了跳过本次巡检
                    Modal.confirm({title:'确定要跳过本次巡检？',onOk:(function(close){
                        return new Promise((resolve,reject) => {
                            postAdjustPatrolTask(getFormData({...res,object_id:objectIds.adjustId})).then(res => {
                                if(res.code === 200) {
                                    afterOnOK('保存成功')
                                    close()
                                    resolve('success')
                                } else {
                                    message.warning(res.message)
                                    reject(res.message)
                                }
                            }).catch(err => {
                                reject(err)
                                console.log('err',err)
                            })
                        })
                    })})
                }
               
            }).catch(err => {
                console.log('err',err)
            })
        } else if (title === ModalTitle.DISPATCH) {
          form.validateFields().then(res => {
            if(JSON.stringify(res) === '{}') return;
            postDispatchPatrolTask(getFormData({
                team_groups_id: res.team_groups_id,
                patrol_user_id: res.patrol_user_id,
                member_user_id: res.member_user_id,
                object_id: objectIds.dispatchId
            },true)).then(res => {
                res.code === 200 ? afterOnOK('派工成功')  : message.warning(res.message)
            }).catch(err => console.log('err',err))
          }).catch(err => console.log('err',err))
        } 
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }



    const getDataAsync = (queryCondition: API.getPatrolTaskListParams) => {
        return new Promise((resolve) => {
            getPatrolTaskList(queryCondition).then(res => {
                if(res.code === 200) {
                    resolve(res.data)
                } else {
                    message.warning(res.message)
                }
            }).catch(err => {
                console.log('err',err)
            })
        })
    }


    return (
        <div className={'mes-patrol-point-task'}>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                                ref={gRef} filterQueryData={(queryData:API.getPatrolTaskListParams) => {
                                    if(queryData.patrol_plan_ids)  {
                                        JSON.stringify(queryData.patrol_plan_ids) === '[]' ? delete queryData.patrol_plan_ids : queryData.patrol_plan_ids = JSON.stringify(queryData.patrol_plan_ids)
                                    }
                                    if(queryData.status) {
                                        JSON.stringify(queryData.status) === '[]' ? delete queryData.status : queryData.status = JSON.stringify(queryData.status)
                                    }
                                    return filterDateParam(queryData, 'date', 'start_date', 'end_date')
                                }}/>
            {
                modalTitle === "查看巡检任务" ?
                <ModalContainer title={<div><Image preview={false} src={showTaskImage}></Image>查看巡检任务</div>} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={()=>setOpen(false)}
                                innerComponent={innerComponent} width="1150px" okText="保存" 
                                footer={null}/> :
                 <ModalContainer title={modalTitle} open={open}
                            onOk={() => onOK(modalTitle as ModalTitle)} onCancel={()=>setOpen(false)}
                            innerComponent={innerComponent} width="1150px" okText="保存" />
            }
        </div>
    )
}
export default PatrolTask;
