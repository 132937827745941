import {useState, useEffect}  from "react";
import { Form, Input, Select, DatePicker } from "antd";
import { FormInstance } from "antd/es/form/Form";
import moment from "moment";
interface IProps {
  selectItem: any
  form: FormInstance
}
const AdjustTask = (props:IProps) => {
  const [adjustType,setAdjustType] = useState<number>(1);
  const {selectItem,form} = props;
  const {code,patrol_plan_name,should_execution_time,device_name,team_groups,patrol_user_name} = selectItem;
  useEffect(()=>{
    form.resetFields();
    form.setFieldsValue({
      code,
      patrol_plan_name,
      should_execution_time,
      device_name,
      team_groups,
      patrol_user_name,
      adjustment_type:10
    })
    setAdjustType(10);
  },[])

  return (
    <div className="adjust-task">
      <Form name="patrol-adjust-task-form" form={form} colon={false} autoComplete="off" layout="inline">
        <Form.Item label="巡检任务单号" name="code"><Input disabled /></Form.Item>
        <Form.Item label="计划名称" name="patrol_plan_name"><Input disabled /></Form.Item>

        <Form.Item label="应执行时间" name="should_execution_time"><Input disabled /></Form.Item>
        <Form.Item label="设备名称" name="device_name"><Input disabled /></Form.Item>
        <Form.Item label="巡检班组" name="team_groups"><Input disabled /></Form.Item>
        <Form.Item label="巡检负责人" name="patrol_user_name"><Input disabled /></Form.Item>
        <Form.Item label="调整类型" name="adjustment_type" rules={[{required:true, message:"请选择调整类型"}]}>
          <Select
            onChange={(value) => {
              setAdjustType(value);
            }}
            options={[
              { value: 10, label: "调整应执行时间" },
              { value: 20, label: "跳过本次巡检" },
            ]}
            placeholder="请选择调整类型"
          />
        </Form.Item>

        {adjustType === 10 && (
          <Form.Item label="调整后应执行时间" name="last_should_execution_time"
            rules={[{required:true, message:"请选择调整后应执行时间"},
            {
              validator: (_, value) => {
                if(value && value < moment() ) {
                  return Promise.reject('调整后的应执行时间应大于当前时间')
                }
                return Promise.resolve()
              }
            },
            ]}>
            <DatePicker showTime format="YYYY/MM/DD HH:mm" showNow={false} placeholder="请选择执行时间"/>
          </Form.Item>
        )}
          <div className="adjust-remarks">
            <Form.Item label="调整原因" name="adjustment_reason" rules={[ {required:true, message:"请输入调整原因"},{ type: "string", max: 200, message: "调整原因不大于200位!", }, ]} >
              <Input.TextArea placeholder="请输入调整原因" />
            </Form.Item>
          </div>
      </Form>
    </div>
  );
};
export default AdjustTask;
