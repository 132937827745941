import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Button, Table} from "antd";
import moment from "moment";
import {getStatisticsExceptionRequest} from "../../../service";
import {toastErrorMessage} from "../../../utils";
import SelectFormException from "../components/selectFormException";
import '../../Statistics/components/index.css';
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";

/**
 * 异常统计分析
 */
const ExportJsonExcel = require('js-export-excel')

const Exception = (): React.ReactElement => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.statisticsExceptionItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startTime, setStartTime] = useState(moment(new Date()).startOf('month').format('YYYY/MM/DD'));
    const [endTime, setEndTime] = useState(moment(new Date()).endOf('month').format('YYYY/MM/DD'));
    const [exception, setException] = useState<undefined | Array<string>>(undefined);

    let exceptionCircleReact: any = React.createRef() || undefined
    let [exceptionCircleOption, setExceptionCircleOption] = useState<any>({
        title: {
            text: '异常类别分析',
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
                return params.name
            },
            backgroundColor: 'rgba(8, 16, 64, 0.8)',
            borderWidth: 1,
            borderColor: '#3B4373',
            textStyle: {
                color: '#C3F2FF',
                fontSize: 12,
            }
        },
        color: ["#3E7BFA", "#778DFF", "#FED561", "#F55466", "#39DD9E"],
        legend: {
            alignItems: 'center',
            justifyContent: 'center',
            right: 'right',
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
            itemGap: 8,
            textStyle: {
                color: '#666666',
            },
            formatter: function (name: string) {
                return echarts.format.truncateText(name, 60, 'Microsoft YaHei', '...', {})
            },
            selectedMode: false,
        },
        series: [
            {
                type: 'pie',
                radius: ['50%', '80%'],
                center: ['50%', '55%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    color: '#FFF'
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: '24',
                        fontWeight: 'bold',
                        textColor: "#FFF"
                    }
                },
                width: 300,
                justifyContent: 'center',
                labelLine: {
                    show: false
                },
                itemStyle: {
                    borderRadius: 1.5,
                    borderWidth: 2,
                },
                data: []
            }
        ],
    });
    let exceptionBarReact: any = React.createRef() || undefined
    let [exceptionBarOption, setExceptionBarOption] = useState<any>({
        title: {
            text: '异常数量统计',
        },
        grid: {
            left: 20,
            right: 30,
            bottom: 40,
            containLabel: true,
        },
        color: ["#3E7BFA"],
        xAxis: {
            type: 'category',
            axisLabel: {
                color: '#666666',
            },
            axisTick: {
                alignWithLabel: true,
                show: false
            },
            nameTextStyle: {
                color: '#666666'
            },
            data: [],
        },
        yAxis: {
            min: 0,
            scale: true,
            type: 'value',
            axisLabel: {
                color: '#666666',
                formatter: "{value} PCS",
            },
            axisTick: {
                inside: true
            },
            axisLine: {
                show: false
            },
            splitLine: {
                show: true,
            },
        },
        tooltip: {
            //提示框组件
            trigger: 'axis',
            background: '#3B4373',
            formatter: function (params: any) {
                let showContent = ''
                for (let i = 0; i < params.length; i++) {
                    if (i === 0) {
                        showContent += params[i].data + 'PCS' + '<br>'
                    }
                }
                return showContent
            },
            backgroundColor: 'rgba(8, 16, 64, 0.8)',
            borderWidth: 1,
            borderColor: '#3B4373',
            textStyle: {
                color: '#C3F2FF',
                fontSize: 12,
            },
            selectedMode: false,
        },
        series: [
            {
                name: 'rate',
                type: 'bar',
                barWidth: 16,
                data: [],
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#778DFF'},
                        {offset: 1, color: 'rgba(119,141,255,0.75)'}
                    ]),
                    barBorderRadius: [5, 5, 0, 0],
                }
            }
        ]
    });

    const columns: ColumnsType<API.statisticsExceptionItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true,
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {title: '异常类别', dataIndex: 'exception_type', align: 'center', ellipsis: true},
        {title: '异常代码', dataIndex: 'exception_code', align: 'center', ellipsis: true},
        {title: '异常描述', dataIndex: 'exception_describe', align: 'center', ellipsis: true},
        {title: '现象描述', dataIndex: 'phenomenal_description', align: 'center', ellipsis: true},
        {
            title: '异常状态', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 20 ? '#06C270' : status === 30 ? '#FF8800' : status === 40 ? '#3E7BFA' : status === 50 ? '#666666' : ''}}>{
                status === 20 ? '已提报' : status === 30 ? '处理中' : status === 40 ? '已完成' : status === 50 ? '已关闭' : ''
            }</div>
        },
        {title: '提报人', dataIndex: 'reporting_user', align: 'center', ellipsis: true},
        {
            title: '提报时间', dataIndex: 'reporting_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '处理人', dataIndex: 'handle_user', align: 'center', ellipsis: true},
        {
            title: '处理开始时间', dataIndex: 'handle_start_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '处理完成时间', dataIndex: 'handle_end_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '关闭时间', dataIndex: 'close_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
    ]

    useEffect(() => {
        window.addEventListener('resize', function () {
            exceptionBarReact && exceptionBarReact.getEchartsInstance() && exceptionBarReact.getEchartsInstance().resize()
        })
        onFinish(1, 10);

        return () => {
            exceptionBarReact = undefined
            exceptionCircleReact = undefined
        }
    }, [])

    const onFinish = (page: number, size: number, data?: {
        startTime: string,
        endTime: string,
        exception: undefined | Array<string>
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.startTime !== '') {
                param = {...param, start_date: moment(data.startTime).format('YYYY-MM-DD')}
            }
            if (data.endTime !== '') {
                param = {...param, end_date: moment(data.endTime).format('YYYY-MM-DD')}
            }
            if (data.exception && data.exception.length > 0) {
                param = {...param, exception_type_ids: data.exception.toString()}
            }
        } else {
            if (startTime !== '') {
                param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
            }
            if (endTime !== '') {
                param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
            }
            if (exception && exception.length > 0) {
                param = {...param, exception_type_ids: exception.toString()}
            }
        }
        getStatisticsExceptionRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.table_list.total)
                setDataSource(res.data.table_list.data)

                let xAxisDataBar: any[] = []
                let seriesDataBar: any[] = []
                res.data.bar_data.forEach((item) => {
                    xAxisDataBar.push(item.date)
                    seriesDataBar.push(item.total)
                })
                let barOption = exceptionBarOption
                barOption.xAxis.data = xAxisDataBar
                barOption.series[0].data = seriesDataBar
                setExceptionBarOption(barOption)
                exceptionBarReact && exceptionBarReact.getEchartsInstance() && exceptionBarReact.getEchartsInstance().setOption(barOption)

                let seriesDataPie: any[] = []
                res.data.pie_data.forEach((item) => {
                    seriesDataPie.push({
                        "value": item.total,
                        "name": item.exception_type_describe,
                    })
                })
                let circleOption = exceptionCircleOption
                circleOption.series[0].data = seriesDataPie
                setExceptionCircleOption(circleOption)
                exceptionCircleReact && exceptionCircleReact.getEchartsInstance() && exceptionCircleReact.getEchartsInstance().setOption(circleOption)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
            const myEvent = new Event("resize")
            window.dispatchEvent(myEvent)
        });
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div>
            <SelectFormException
                queryClick={(startTime: string, endTime: string, exception: undefined | Array<string>) => {
                    setStartTime(startTime)
                    setEndTime(endTime)
                    setException(exception)
                    onFinish(1, pageSize, {
                        startTime: startTime,
                        endTime: endTime,
                        exception: exception
                    })
                }}
            />
            <div style={{flex: 1, height: 366, display: "flex", flexDirection: "row", marginTop: 20}}>
                <div style={{width: 400, height: 366, backgroundColor: '#ffffff', borderRadius: 5}}>
                    <ReactECharts
                        ref={(e) => {
                            if (!e) {
                                return
                            } else {
                                exceptionCircleReact = e
                            }
                        }}
                        style={{paddingTop: 16, paddingRight: 16, paddingLeft: 12, height: 360}}
                        option={exceptionCircleOption}
                    />
                </div>
                <div style={{
                    flex: 1,
                    width: 'calc(100% - 400px)',
                    height: 366,
                    backgroundColor: '#ffffff',
                    borderRadius: 5,
                    marginLeft: 15
                }}>
                    <ReactECharts
                        ref={(e) => {
                            if (!e) {
                                return
                            } else {
                                exceptionBarReact = e
                            }
                        }}
                        style={{paddingTop: 16, paddingLeft: 12, height: 360}}
                        option={exceptionBarOption}
                    />
                </div>
            </div>
            <Table
                title={() => <div
                    style={{display: "flex", flexDirection: "row", flex: 1, alignItems: "center", height: 50}}>
                    <div style={{flex: 1, fontWeight: "bold", color: '#333333', marginLeft: 10, fontSize: 16}}>
                        异常记录
                    </div>
                    <Button
                        className={'buttonOutput'}
                        type={"primary"}
                        onClick={() => {
                            if (total > 0) {
                                let param: any = {page_num: 1, page_size: total}
                                if (startTime !== '') {
                                    param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
                                }
                                if (endTime !== '') {
                                    param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
                                }
                                if (exception && exception.length > 0) {
                                    param = {...param, exception_type_ids: exception.toString()}
                                }
                                getStatisticsExceptionRequest(param).then((res) => {
                                    if (res.code === 200) {
                                        let option: { fileName?: string, datas?: any[] } = {};
                                        let dataTable: any[] = [];
                                        res.data.table_list.data.length > 0 && res.data.table_list.data.forEach((item, index) => {
                                            let obj = {
                                                no: index + 1,
                                                exception_type: item.exception_type,
                                                exception_code: item.exception_code,
                                                exception_describe: item.exception_describe,
                                                phenomenal_description: item.phenomenal_description,
                                                status: item.status === 20 ? '已提报' : item.status === 30 ? '处理中' : item.status === 40 ? '已完成' : item.status === 50 ? '已关闭' : '',
                                                reporting_user: item.reporting_user,
                                                reporting_time: item.reporting_time === null ? '' : moment(item.reporting_time).format('YYYY/MM/DD HH:mm'),
                                                handle_user: item.handle_user,
                                                handle_start_time: item.handle_start_time === null ? '' : moment(item.handle_start_time).format('YYYY/MM/DD HH:mm'),
                                                handle_end_time: item.handle_end_time === null ? '' : moment(item.handle_end_time).format('YYYY/MM/DD HH:mm'),
                                                close_time: item.close_time === null ? '' : moment(item.close_time).format('YYYY/MM/DD HH:mm'),
                                            }
                                            dataTable.push(obj);
                                        })
                                        option.fileName = '异常记录' + moment(new Date()).format('YYYYMMDD')
                                        option.datas = [
                                            {
                                                sheetData: dataTable,
                                                sheetName: 'sheet',
                                                sheetFilter: ['no', 'exception_type', 'exception_code', 'exception_describe', 'phenomenal_description', 'status', 'reporting_user', 'reporting_time', 'handle_user', 'handle_start_time', 'handle_end_time', 'close_time'],
                                                sheetHeader: ['No', '异常类别', '异常代码', '异常描述', '现象描述', '异常状态', '提报人', '提报时间', '处理人', '处理开始时间', '处理完成时间', '关闭时间'],
                                            }
                                        ];
                                        let toExcel = new ExportJsonExcel(option);
                                        toExcel.saveExcel();
                                    } else {
                                        toastErrorMessage('导出失败')
                                    }
                                }).catch(() => {
                                    toastErrorMessage('导出失败')
                                });
                            }
                        }}
                    >
                        导出
                    </Button>
                </div>}
                className={'badTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />
        </div>
    )
}
export default Exception
