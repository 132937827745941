import React from 'react'
import {Button, Select} from "antd";
import Input from "antd/lib/input/Input";
import "./style.css"
import {getStorageList} from "../../../service";

/**
 * 仓储管理--库存查询--查询、重置
 */
const {Option} = Select;

interface Type {
    productName: string,
    houseValue: number | undefined,
    houseList: Array<any>,
}

export default class SelectFormQuery extends React.Component<{
    queryClick?: (productName: string, houseValue: number | undefined) => void,
}> {

    state: Type = {
        productName: '',
        houseValue: undefined,
        houseList: [],
    }

    componentDidMount() {
        getStorageList().then(res => {
            if (res.code === 200) {
                this.setState({
                    houseList: res.data.data.filter((item: any) => item.is_valid === true)
                })
            }
        })
    }

    render() {
        const {productName, houseValue, houseList} = this.state
        return <div className={'warehouseQueryTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14, marginLeft: 13}}>
                    产品名称
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={productName}
                    placeholder={`请输入产品名称`}
                    onChange={(e) => {
                        this.setState({
                            productName: e.target.value
                        })
                    }}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    仓库名称
                </div>
                <Select
                    value={houseValue}
                    showArrow
                    showSearch={false}
                    style={{marginLeft: 20, minWidth: 244, height: 30, borderRadius: 5}}
                    placeholder={`请选择仓库`}
                    onChange={(value) => {
                        this.setState({
                            houseValue: value,
                        })
                    }}
                >
                    {
                        houseList.map((item: any, index: number) => {
                            return <Option key={index} value={item.object_id}>
                                {item.storage_name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <Button
                className={'buttonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(productName, houseValue)
                }}
            >
                查询
            </Button>

            <Button
                className={'buttonReset'}
                type={"primary"}
                onClick={() => {
                    this.setState({
                        productName: '',
                        houseValue: undefined,
                    }, () => {
                        this.props.queryClick && this.props.queryClick('', undefined)
                    })
                }}
            >
                重置
            </Button>
        </div>
    }
}
