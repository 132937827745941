import React from 'react'
import { Modal, Select } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import { getAllProductionLineInfo, getAllProcedureInfo } from "../../../../service/index";
import { toastMessage } from "../../../../utils";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import '../../../../globalLess/global.css'
interface selectItem {
    value: string;
    label: string;
}
export default class EditModal extends React.Component<{
    isHasProcedure: boolean,
    isHasProductionLine: boolean,
    navigate: NavigateFunction,
    StatioinItem: API.Work_stationResponesItem
    closeClick: () => void,
    saveClick: (e: API.Work_stationUpdate) => void,
}> {
    state = {
        code: '',
        work_station_name: '',
        remark: '',
        line_name: '',
        work_process_name: '',
        line_object_id: 0,
        work_process_object_id: 0,
        selectLineInfo: [{
            value: '',
            label: ''
        }],
        selectWork_processInfo: [{
            value: '',
            label: ''
        }],
        LineData: [],
        Work_processData: []
    }
    async getLineInfo() {
        await getAllProductionLineInfo().then((res: any) => {
            if (res.code === 200) {
                const selectInfoDataSource: API.ProductionLineItem[] = res.data.data
                let selectInfoArray: selectItem[] = []
                for (let index = 0; index < selectInfoDataSource.length; index++) {
                    const item = selectInfoDataSource[index]
                    if (item.line_name) {
                        selectInfoArray.push({
                            value: `${item.object_id}`,
                            label: item.line_name
                        })
                    }
                }
                this.setState({
                    LineData: selectInfoDataSource,
                    selectLineInfo: selectInfoArray
                })
            }
        }).catch((err: any) => {
            console.log("err=>>>>", err);
        })
    }
    async getWork_processInfo() {
        await getAllProcedureInfo().then((res: any) => {
            if (res.code === 200) {
                const selectInfoDataSource: API.ProcedureResponseItem[] = res.data.data
                let selectInfoArray: selectItem[] = []
                for (let index = 0; index < selectInfoDataSource.length; index++) {
                    const item = selectInfoDataSource[index]
                    if (item.work_process_name) {
                        selectInfoArray.push({
                            value: `${item.object_id}`,
                            label: item.work_process_name
                        })
                    }
                }
                this.setState({
                    Work_processData: selectInfoDataSource,
                    selectWork_processInfo: selectInfoArray
                })
            }
        }).catch((err: any) => {
            console.log("err=>>>>", err);
        })
    }
    componentDidMount() {
        const {
            StatioinItem,
        } = this.props
        this.setState({
            code: StatioinItem.code,
            work_station_name: StatioinItem.work_station_name,
            line_name: StatioinItem.line_name,
            work_process_name: StatioinItem.work_process_name,
            remark: StatioinItem.remark,
            line_object_id: StatioinItem.line_id,
            work_process_object_id: StatioinItem.work_process_id
        }, () => {
            this.getLineInfo()
            this.getWork_processInfo()
        })

    }
    render() {
        const {
            code, work_station_name, selectLineInfo, LineData,
            Work_processData, selectWork_processInfo, remark, line_name, work_process_name, line_object_id, work_process_object_id
        } = this.state
        const { closeClick,
            saveClick, StatioinItem, isHasProcedure,
            isHasProductionLine,
            navigate } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'编辑工位信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='工位代码' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    placeholder='不填写，系统将自动生成'
                    value={code}
                    onChange={(value) => {
                        this.setState({
                            code: value
                        })
                    }}
                />
            </div>

            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1} title='工位名称' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }} placeholder='请输入工位名称' onChange={(value) => {
                    this.setState({
                        work_station_name: value
                    })
                }}
                    value={work_station_name}
                />

            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
            <div className='inputStyle'
                >
                    <div className='inputRequire'>*</div>
                    <div
                        className='dropdownTitle'
                        style={{ marginRight: 10, color: isHasProductionLine ? '#4680FA' : '#333333', textDecoration: isHasProductionLine ? "underline" : "none", cursor: isHasProductionLine ? "pointer" : "auto" }}
                        onClick={() => {
                            if (isHasProductionLine) {
                                navigate('/basic/productionLine')
                            }
                        }}
                    >所属产线</div>
                    <Select
                        style={{ width: 280 }}
                        placeholder='请选择所属产线'
                        options={selectLineInfo}
                        value={line_object_id?`${line_object_id}`: null}
                        onChange={(value: string) => {
                            const item: any = LineData.filter((item: any) => item.object_id.toString() === value)[0]
                            this.setState({
                                line_object_id: item.object_id,
                                line_name: item.line_name
                            },)
                        }}
                    />
                </div>

                {/* <Dropdown require={1}
                    title='所属产线'
                    navigateClick={() => {
                        if (isHasProductionLine) {
                            navigate('/basic/productionLine')
                        }
                    }}
                    titleStyle={{ marginRight: 10, color: isHasProductionLine ? '#4680FA' : '#333333', textDecoration: isHasProductionLine ? "underline" : "none", cursor: isHasProductionLine ? "pointer" : "auto" }}
                    dropdownButtonStyle={{ width: 281 }}
                    placeholder='请选择所属产线'
                    defaultValue={line_name}
                    overlay={<Menu 
                        style={{ overflow: 'scroll', maxHeight: 200, width: 281 }}
                        items={selectLineInfo}
                        onClick={(e) => {
                            const index = parseInt(e.key) - 1
                            const item: API.ProductionLineItem = LineData[index]
                            this.setState({
                                line_object_id: item.object_id,
                                line_name: item.line_name
                            })
                        }} />} /> */}
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
            <div className='inputStyle'
                >
                    <div
                        className='dropdownTitle'
                        style={{ marginRight: 10, color: isHasProcedure ? '#4680FA' : '#333333', textDecoration: isHasProcedure ? "underline" : "none", cursor: isHasProcedure ? "pointer" : "auto" }}
                        onClick={() => {
                            if (isHasProcedure) {
                                navigate('/basic/procedure')
                            }
                        }}
                    >所属工序</div>
                    <Select
                        style={{ width: 280 }}
                        placeholder='请选择所属工序'
                        value={work_process_object_id?`${work_process_object_id}`:null}
                        options={selectWork_processInfo}
                        onChange={(value: string) => {
                            const item: any = Work_processData.filter((item: any) => item.object_id.toString() === value)[0]
                            this.setState({
                                work_process_object_id: item.object_id,
                                work_process_name: item.work_process_name
                            },)
                        }}
                    />
                </div>
                {/* <Dropdown title='所属工序'
                    navigateClick={() => {
                        if (isHasProcedure) {
                            navigate('/basic/procedure')
                        }
                    }}
                    titleStyle={{ marginRight: 10, color: isHasProcedure ? '#4680FA' : '#333333', textDecoration: isHasProcedure ? "underline" : "none", cursor: isHasProcedure ? "pointer" : "auto" }}
                    dropdownButtonStyle={{ width: 281 }}
                    placeholder='请选择所属工序'
                    defaultValue={work_process_name}
                    overlay={<Menu 
                        style={{ overflow: 'scroll', maxHeight: 200, width: 281 }}
                        items={selectWork_processInfo}
                        onClick={(e) => {
                            const index = parseInt(e.key) - 1
                            const item: API.ProcedureResponseItem = Work_processData[index]
                            this.setState({
                                work_process_object_id: item.object_id,
                                work_process_name: item.work_process_name
                            })
                        }} />} /> */}
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注' remark titlestyle={{ width: 60, paddingLeft: 5, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入备注' onChange={(value) => {
                    this.setState({
                        remark: value
                    })
                }}
                    value={remark}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!work_station_name) {
                        toastMessage('工位名称不能为空')
                        return
                    }
                    if (!line_name) {
                        toastMessage('所属产线不能为空')
                        return
                    }
                    const item: API.Work_stationUpdate = {
                        object_id: StatioinItem.object_id,
                        line_object_id: line_object_id
                    }
                    if (work_station_name !== StatioinItem.work_station_name) {
                        item.work_station_name = work_station_name
                    }
                    if (work_process_object_id) {
                        item.work_process_object_id = work_process_object_id
                    }
                    if (remark) {
                        item.remark = remark
                    }
                    saveClick(item)
                }} />
            </div>
        </Modal>
    }
}