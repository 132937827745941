import Dashboard from "./Dashboard";
import Login from "./Login";
import User from "./System/User";
import Role from "./System/Role";
import Settings from "./Settings";
import Query from "./Production/Query";
import Report from "./Production/Report";
import Submission from "./Production/Submission";
import Task from "./Production/Task";
import OrderManagement from "./OrderManage/OrderManagement";
import OrderView from "./OrderManage/OrderView";
import Businessman from "./Basic/Businessman";
import Cause from "./Basic/Cause";
import Company from "./Basic/Company";
import Procedure from "./Basic/Procedure";
import Production from "./Basic/Production";
import ProductionLine from "./Basic/ProductionLine";
import Station from "./Basic/Station";
import Workmanship from "./Basic/Workmanship";
import Organization from "./System/Organization";
import Job from "./System/Job";
import Output from "./Statistics/Output";
import Bad from "./Statistics/Bad";
import Exception from "./Statistics/Exception";
import ExceptionPlato from "./Statistics/ExceptionPlato";
import EXCAssignment from "./Exception/Assignment";
import EXCCategory from "./Exception/Category";
import EXCCode from "./Exception/Code";
import EXCHandle from "./Exception/Handle";
import EXCQuery from "./Exception/Query";
import EXCReport from "./Exception/Report";
import WarehouseIn from "./Warehouse/WarehouseIn";
import WarehouseInfo from "./Warehouse/WarehouseInfo";
import WarehouseLoc from "./Warehouse/WarehouseLoc";
import WarehouseOut from "./Warehouse/warehouseOut";
import InventoryCount from "./Warehouse/WarehouseCheck";
import InventoryTransfer from "./Warehouse/WarehouseAllot";
import InventoryQuery from "./Warehouse/WarehouseQuery";
import Group from "./System/Group";
import DeviceFile from "./Device/DeviceFile";
import DevicePosition from "./Device/DevicePosition";
import DeviceType from "./Device/DeviceType";
import RepairExperience from "./Repair/RepairExperience";
import RepairReport from "./Repair/RepairReport";
import RepairSubmission from "./Repair/RepairSubmission";
import RepairWorkOrder from "./Repair/RepairWorkOrder";
import TroubleExperience from "./Trouble/TroubleExperience";
import TroubleType from "./Trouble/TroubleType";
import TroubleRepair from "./Trouble/TroubleRepair";
import PatrolPlan from "./Inspection/PatrolPlan";
import PatrolProject from "./Inspection/PatrolProject";
import PatrolReport from "./Inspection/PatrolReport";
import PatrolTask from "./Inspection/PatrolTask";
import PointPlan from "./Inspection/PointPlan";
import PointProject from "./Inspection/PointProject";
import PointReport from "./Inspection/PointReport";
import PointTask from "./Inspection/PointTask";
export interface Indexable {
    [key: string]: any;
}

export default {
    Dashboard,
    Login,
    User,
    Role,
    Settings,
    Organization,
    Job,
    Query,
    Report,
    Submission,
    Task,
    OrderManagement,
    OrderView,
    Businessman,
    Cause,
    Company,
    Procedure,
    Production,
    ProductionLine,
    Station,
    Workmanship,
    Output,
    Bad,
    Exception,
    ExceptionPlato,
    EXCAssignment,
    EXCCategory,
    EXCCode,
    EXCHandle,
    EXCQuery,
    EXCReport,
    WarehouseIn,
    WarehouseInfo,
    WarehouseLoc,
    WarehouseOut,
    InventoryCount,
    InventoryTransfer,
    InventoryQuery,
    Group,
    DeviceFile,
    DevicePosition,
    DeviceType,
    RepairExperience,
    RepairReport,
    RepairSubmission,
    RepairWorkOrder,
    TroubleExperience,
    TroubleType,
    TroubleRepair,
    PatrolPlan,
    PatrolProject,
    PatrolReport,
    PatrolTask,
    PointPlan,
    PointProject,
    PointReport,
    PointTask
} as Indexable
