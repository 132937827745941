import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Form, Button, Input, Select, Tree, message,Space} from 'antd';
import GeneralManagement, {QueryListItem} from "../../../components/GeneralManagement";
import ModalContainer from "../../../components/Modal";
import DeleteItem from "../../../components/DeleteItem";
import type {DataNode} from 'antd/es/tree';
import '../index.less';
import {
    createOrUpdateOrganization,
    deleteOrganization,
    getOrganization,
    getOrganizationList,
    getOrganizationTree
} from "../../../service";
import {filterData, getFormData, getTreeData, showError} from "../../../utils";
import {LoadingOutlined} from '@ant-design/icons';
import {ColumnsType} from "antd/es/table";

enum ModalTitle {
    ADD = '新增组织机构',
    EDIT = '编辑组织机构',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

interface AddOrEditJobProps {
    showParentId: boolean;
}

const Organization = (): React.ReactElement => {
    const {Option} = Select
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [treeLoading, setTreeLoading] = useState<boolean>(false);
    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'organization_name',
            name: '名称',
            placeholder: '请输入组织机构名称'
        }
    ]
    const columns: ColumnsType<any>  = [
        {
            title: '唯一编码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '机构名称',
            dataIndex: 'organization_name',
            key: 'organization_name',
            align: 'center',
            ellipsis: true
        },
        {
            title: '上级机构',
            dataIndex: 'parent',
            key: 'parent',
            align: 'center',
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace:'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight:0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const AddOrEditJob = React.memo((props: AddOrEditJobProps): React.ReactElement => {
        const [organizationList, setOrganizationList] = useState<any[]>([]);

        const {showParentId} = props;
        const layout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18},
        };

        useEffect(() => {
            getOrganizationList({page_num: 1}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        setOrganizationList(res.data.data);
                    }
                } else {
                    showError(res.message);
                }
            })
        }, [])

        return (
            <Form {...layout} colon={false} form={form}>
                <Form.Item label={'唯一编码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '唯一编码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'机构名称'} name={'organization_name'}
                           rules={[{required: true, message: '请输入机构名称!'}, {max: 32, message: '机构名称不得大于32位！'}]}
                >
                    <Input placeholder={'请输入机构名称'}/>
                </Form.Item>
                {
                    showParentId &&
                    <Form.Item label={'上级机构'} name={'parent_id'} rules={[{required: true, message: '请选择上级机构!'}]}
                    >
                        <Select placeholder={'请选择上级机构'}>
                            {
                                organizationList.map((e: any, i: number) =>
                                    <Option key={i} value={e.object_id}>{e.organization_name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                }
                <Form.Item label={'备注'} name={'remarks'} rules={[{max: 200, message: '备注不得大于200位！'}]}>
                    <Input placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item)
        if (title === ModalTitle.ADD) {
            if (selectedKeys.length) {
                form.setFieldsValue({
                    parent_id: selectedKeys[0],
                })
            }
            //若当前没有组织机构就不显示上级机构这一项
            let showParentId = !!treeData.length;
            setInnerComponent(<AddOrEditJob showParentId={showParentId}/>);
        } else if (title === ModalTitle.EDIT) {
            getOrganization({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        organization_name: res.data.organization_name,
                        parent_id: res.data.parent_id,
                        remarks: res.data.remarks,
                    })
                    setInnerComponent(<AddOrEditJob showParentId={!!res.data.parent_id}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createOrUpdateOrganization(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                        form.resetFields();
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                createOrUpdateOrganization(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                        form.resetFields();
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteOrganization(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.organizationListParams) => {
        return new Promise((resolve) => {
            getOrganizationList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data);
                    }
                } else {
                    showError(res.message);
                }
            })
        })
    }

    const afterOnOK = (text: string) => {
        setOpen(false);
        setSelectedItem(null);
        refreshData();
        message.success(text);
    }

    const refreshData = () => {
        getTree();
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const onSelect = (selectedKeys: any) => {
        setSelectedKeys(selectedKeys);
    }

    const getTree = () => {
        getOrganizationTree().then(res => {
            setTreeLoading(true)
            if (res.code === 200) {
                setTreeLoading(false)
                if (res.data) {
                    setTreeData(getTreeData(res.data));
                } else {
                    setTreeData([]);
                }
            } else {
                setTreeLoading(false)
                showError(res.message);
            }
        })
    }

    useEffect(() => {
        getTree();
    }, [])


    return (
        <div className={'mes-organization'}>
            <Row gutter={30} style={{marginLeft: 0}}>
                <Col span={3} className={'mes-organization-tree'}
                     style={{paddingLeft: 0, paddingRight: 0, overflow: "auto"}}>
                    {
                        treeLoading && <LoadingOutlined/>
                    }
                    {
                        !treeLoading && treeData.length > 0 &&
                        <Tree treeData={treeData} defaultExpandAll onSelect={onSelect} selectedKeys={selectedKeys}
                              blockNode/>}
                    {
                        !treeLoading && treeData.length == 0 &&
                        <div className={'tree-noData'}>暂无数据</div>
                    }
                </Col>
                <Col span={21}>
                    <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                                       treeID={selectedKeys} ExtraComponent={<ExtraComponent/>} ref={gRef}/>
                    {
                        modalTitle &&
                        <ModalContainer title={modalTitle} open={open}
                                        onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                        okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                        innerComponent={innerComponent}/>
                    }
                </Col>
            </Row>
        </div>
    )
}
export default Organization
