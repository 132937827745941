import React, { useContext, useEffect, useState } from "react";
import WorkmanshipMenu from "./workmanshipMenu";
import { Table, TableColumnsType } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../globalLess/global.css'
import { getWorkmanshipInfo, WorkmanshipCreate, WorkmanshipUpdate, WorkmanshipDelete } from "../../../service/index";
import CheckModal from "./Modal/checkModal";
import AddModal from "./Modal/addModal";
import EditModal from "./Modal/editModal";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/userContext";
import { toastErrorMessage, toastSuccessMessage, toastMessage } from "../../../utils";
import DeleteModal from "../../Production/components/deleteModal";
import moment from "moment";
const ExportJsonExcel = require('js-export-excel')


const Workmanship = (): React.ReactElement => {
    const columns: ColumnsType<API.Workmanship_ResponseInfo> = [
        {
            title: '工艺路线代码',
            dataIndex: 'code',
            width: 400,
            ellipsis: true,
        },
        {
            title: '工艺路线名称',
            dataIndex: 'work_craft_name',
            width: 400,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 600,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'object_id',
            render: (code: string, data: API.Workmanship_ResponseInfo) =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setWorkmanshipData(data)
                            setShowCheckModal(true)
                        }}
                    >
                        查看
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setWorkmanshipData(data)
                            setShowEditModal(true)
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setDeleteObjectString([data.object_id])
                            setIsShowDelete(true)
                        }}
                    >
                        删除
                    </div>
                </div>
        }
    ];
    let [code, setCode] = useState('');
    let [work_craft_name, setWork_craft_name] = useState('');
    const userInfo = useContext(UserContext)
    const [WorkmanshipData, setWorkmanshipData] = useState<API.Workmanship_ResponseInfo>();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.Workmanship_ResponseInfo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [showCheckModal, setShowCheckModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const navigate = useNavigate();
    const [isHasProcess, setIsHasProcess] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowMoreDelete, setIsShowMoreDelete] = useState(false);
    const [deleteObjectString, setDeleteObjectString] = useState<number[]>([]);

    const queryWorkmanship = () => {
        onFinish(1, pageSize, {
            code: code,
            work_craft_name: work_craft_name
        })
    }
   
    const BatchDelete = () => {
        if (selectedRowKeys.length > 0) {
            const num = selectedRowKeys.length
            let objArray = []
            for (let index = num - 1; index >= 0; index--) {
                let indexItem: any = selectedRowKeys[index]
                indexItem = indexItem- (currentPage-1)* pageSize
                const item = dataSource[indexItem]
                objArray.push(item.object_id)
            }
            WorkmanshipItemDelete(objArray)
            setSelectedRowKeys([])
        } else {
            return
        }
    }

    const WorkmanshipItemDelete = (object_ids: number[]) => {
        let objString: any;
        for (let index = 0; index < object_ids.length; index++) {
            if (index === 0) {
                objString = object_ids[index]
            } else {
                objString += ',' + object_ids[index]
            }

        }
        WorkmanshipDelete({ object_ids: objString }).then((res) => {
            if (res.code === 200) {
                setIsShowDelete(false)
                setIsShowMoreDelete(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }

    const EditWorkmanship = (e: API.Workmanship_Update) => {
        const dic = {
            work_craft_info: JSON.stringify(e)
        }
        WorkmanshipUpdate(dic).then((res) => {
            if (res.code === 200) {
                setShowEditModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('编辑成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }

    const AddWorkmanship = (e: API.Workmanship_Create) => {
        const dic = {
            work_craft_info: JSON.stringify(e)
        }
        WorkmanshipCreate(dic).then((res) => {
            if (res.code === 200) {
                setShowAddModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('添加成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }

    const exportFile = () => {
        let dict: any = { page_num: 1, page_size: total }
        if (code) {
            dict.code = code
        }
        if (work_craft_name) {
            dict.work_craft_name = work_craft_name
        }
        setIsLoading(true)
        getWorkmanshipInfo(dict).then((res: any) => {
            if (res.code === 200) {
                let dataSourceCopy: any = res.data.data
                if (dataSourceCopy.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    dataSourceCopy.forEach((item: any) => {
                        dataTable.push({
                            code: item.code,
                            work_craft_name: item.work_craft_name,
                            remark: item.remark
                        })
                    })
                    option.fileName = '工艺路线' + moment(new Date()).format('YYYYMMDD')
                    option.datas = [
                        {
                            sheetData: dataTable,
                            sheetName: 'sheet',
                            sheetFilter: ['code', 'work_craft_name', 'remark'],
                            sheetHeader: ['工艺路线代码', '工艺路线名称', '备注'],
                        }
                    ];
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err: any) => {
            toastErrorMessage(err.message)
            setIsLoading(false)
        })
    };
    const onFinish = (page: number, size: number, data?: {
        code?: string,
        work_craft_name?: string
    }) => {
        let dict: any = { page_num: page, page_size: size }

        if (data && data.code) {
            dict.code = data.code
        }
        if (data && data.work_craft_name) {
            dict.work_craft_name = data.work_craft_name
        }

        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        // data ? setDataSource(data) : setDataSource([])
        getWorkmanshipInfo(dict).then((res: any) => {
            if (res.code === 200) {
                console.log("=>200>>>>", res.data);
                setTotal(res.data.total)
                let dataCopy = []
                for (let index = 0; index < res.data.data.length; index++) {
                    let item = res.data.data[index]
                    item.key = index + (page - 1) * size
                    let dataChildCopy = []
                    for (let k = 0; k < item.process.length; k++) {
                        let cItem = item.process[k]
                        cItem.key = k
                        dataChildCopy.push(cItem)
                    }
                    item.process = dataChildCopy
                    dataCopy.push(item)
                }

                setDataSource(dataCopy)
            } else {
                toastErrorMessage(res.message)

            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
        })
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        userInfo.useInfo.authRoutes?.map((item: any) => {
            if (item === '/basic/procedure') {
                setIsHasProcess(true)
            }
        })
        onFinish(1, 10);
    }, [])

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999' }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }
    const rowRender = (record: API.Workmanship_ResponseInfo) => {
        const childColumns: TableColumnsType<API.Workmanship_Children> = [
            {
                key: 0, title: 'No', dataIndex: 'key', align: 'center', ellipsis: true, width: 100,
                render: (text: any, record: any, index: number) => <div>{index + 1}</div>
            },
            { key: 1, title: '工序代码', dataIndex: ['process_info', 'code'], align: 'center', ellipsis: true,  width: 350,},
            { key: 2, title: '工序名称', dataIndex: ['process_info', 'work_process_name'], align: 'center', ellipsis: true, width: 350, },
            { key: 3, title: '备注', dataIndex: ['process_info', 'craft_process_remark'], align: 'center', ellipsis: true, width: 520,},

        ];
        return (
            <Table
                className={'childTable'}
                style={{ marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 120 }}
                columns={childColumns}
                scroll={{x: 1320}}
                dataSource={record.process}
                pagination={false}
            />
        )
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <WorkmanshipMenu productionNum={code} productionName={work_craft_name}
                    codeOnChange={(num: string) => {
                        setCode(num)
                    }}
                    nameOnChange={(name: string) => {
                        setWork_craft_name(name)
                    }}
                    queryClick={() => {
                        queryWorkmanship()
                    }}
                    resetClick={() => {
                        setCode('')
                        setWork_craft_name('')
                        onFinish(1, pageSize);
                    }}
                    addClick={() => {
                        setShowAddModal(true)
                    }}
                    deleteClick={() => {
                        if (selectedRowKeys.length > 0) {
                            setIsShowMoreDelete(true)
                        } else {
                            toastMessage('请选择要删除的工艺路线')
                        }
                    }}
                    exportClick={() => {
                        exportFile()
                    }}
                />
            </div>
            <div >
                <Table
                    className={'commonTable'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{x: 1600}}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                    expandable={{
                        expandedRowRender: record => rowRender(record),
                        expandIcon: ({ expanded, onExpand, record }) => record.process!.length > 0 ? (expanded ?
                            <img style={{ width: 30, height: 30, cursor: "pointer" }}
                                src={require('../../../assets/icon_expanded.png')}
                                alt=""
                                onClick={e => onExpand(record, e)} /> :
                            <img style={{ width: 30, height: 30, cursor: "pointer" }}
                                src={require('../../../assets/icon_unexpanded.png')}
                                alt=""
                                onClick={e => onExpand(record, e)} />) : null,
                        rowExpandable: record => record.process!.length > 0,
                    }}
                />
            </div>
            {
                showCheckModal && <CheckModal
                    WorkmanshipData={WorkmanshipData}
                    closeClick={() => {
                        setShowCheckModal(false)
                    }}
                />
            }
            {
                showAddModal && <AddModal
                    isHasProcess={isHasProcess}
                    navigate={navigate}
                    closeClick={() => {
                        setShowAddModal(false)
                    }}
                    saveClick={(e) => {
                        AddWorkmanship(e)
                    }} />
            }
            {
                showEditModal && <EditModal
                    WorkmanshipData={WorkmanshipData}
                    isHasProcess={isHasProcess}
                    navigate={navigate}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(e) => {
                        EditWorkmanship(e)
                    }} />
            }
               {
                isShowDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowDelete(false)
                    }}
                    confirmClick={() => {
                        WorkmanshipItemDelete(deleteObjectString)
                    }}
                />
            }
            {
                isShowMoreDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowMoreDelete(false)
                        setSelectedRowKeys([])
                    }}
                    confirmClick={() => {
                        BatchDelete()
                    }}
                />
            }
        </div>
    )
}
export default Workmanship
