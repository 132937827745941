import React from "react";
import {Form, Button, Input, Select, DatePicker} from 'antd';
import './index.less';
import {QueryListItem} from "./index";
import {indexType} from "../../utils";

interface QueryBarProps {
    queryForm: any,
    formList: QueryListItem[],
    onFinish: () => void,
    onReset: () => void,
    ExtraComponent?: JSX.Element,
}

const QueryBar = (props: QueryBarProps) => {
    const {queryForm, formList, ExtraComponent, onFinish, onReset} = props;
    const {RangePicker} = DatePicker;

    const reset = () => {
        queryForm.resetFields();
        onReset();
    }

    const initialValues = (): Object => {
        let object: indexType = {}
        formList.map((e: any) => {
            if (e.defaultValue) {
                return object[e.key] = e.defaultValue
            }
        })
        return object
    }

    return (
        <div className={'mes-queryBar'}>
            <Form form={queryForm} onFinish={() => onFinish()} layout={'inline'} colon={false}
                  initialValues={initialValues()}>
                {
                    formList.map((e, i) => {
                            if (e.options) {
                                return (
                                    <Form.Item label={e.name} name={e.key} key={i}>
                                        <Select placeholder={e.placeholder} mode={e.multiple ? 'multiple' : undefined}
                                                showArrow showSearch={!!e.showSearch} maxTagCount='responsive'>
                                            {
                                                e.options.map((oe: any, oi: number) =>
                                                    <Select.Option key={oi} value={oe.id}>{oe.name}</Select.Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                )
                            } else if (e.date) {
                                return (
                                    <Form.Item label={e.name} name={e.key} key={i}>
                                        <RangePicker/>
                                    </Form.Item>
                                )
                            }else if (e.rangePick) {
                                return (
                                    <Form.Item
                                        label={e.name} name={e.key} key={i}>
                                        <RangePicker  format="YYYY/MM/DD" />
                                    </Form.Item>
                                )
                            } else {
                                return (
                                    <Form.Item label={e.name} name={e.key} key={i}>
                                        <Input placeholder={e.placeholder}/>
                                    </Form.Item>
                                )
                            }
                        }
                    )
                }
                <Form.Item>
                    <Button htmlType={'submit'} className={'mes-queryBar-submit'}>查询</Button>
                    <Button htmlType={'reset'} className={'mes-queryBar-reset'} onClick={() => reset()}>重置</Button>
                </Form.Item>
            </Form>
            {ExtraComponent}
        </div>
    )
}

export default React.memo(QueryBar);
