import React, {useContext, useEffect, useState} from 'react';
import Login from "./pages/Login";
import LayoutContainer from "./components/Layout";
import {Provider, UserContext, UserInfo} from "./contexts/userContext";
import {getCurrentUser} from "./service";
import {getMenus, showError} from "./utils";
import './globalLess/global.less';

const App = () => {
    const [useInfo, setUserInfo] = useState<UserInfo>({});

    return (
        <Provider value={{useInfo, setUserInfo}}>
            <JudgeLogin/>
        </Provider>
    )
}

const JudgeLogin = React.memo(() => {
    const isLogin = window.sessionStorage.getItem('isLogin')
    const {setUserInfo} = useContext(UserContext);

    const getUserInfo = () => {
        getCurrentUser().then((res) => {
            if (res.code === 200) {
                if (res.data.authoritys) {
                    const allRoutes = getRoutes(res.data.authoritys)
                    const authRoutes = getAuthRoutes(res.data.authoritys)
                    const menus = getMenus(res.data.authoritys)
                    setUserInfo({
                        account: res.data.account,
                        work_number: res.data.work_number,
                        object_id: res.data.object_id,
                        organization_id: res.data.organization_id,
                        organization: res.data.organization,
                        station_id: res.data.station_id,
                        station: res.data.station,
                        name: res.data.name,
                        sex: res.data.sex,
                        phone: res.data.phone,
                        email: res.data.email,
                        avatar: res.data.file ? res.data.file.url : '',
                        role_ids: res.data.role_ids,
                        allRoutes: allRoutes,
                        authRoutes: authRoutes,
                        menus: menus,
                    })
                    window.sessionStorage.setItem('isLogin', 'true')
                } else {
                    //新建的用户只能查看首页
                    setUserInfo({
                        account: res.data.account,
                        work_number: res.data.work_number,
                        object_id: res.data.object_id,
                        organization_id: res.data.organization_id,
                        organization: res.data.organization,
                        station_id: res.data.station_id,
                        station: res.data.station,
                        name: res.data.name,
                        sex: res.data.sex,
                        phone: res.data.phone,
                        email: res.data.email,
                        avatar: res.data.file ? res.data.file.url : '',
                        role_ids: res.data.role_ids,
                    })
                    window.sessionStorage.setItem('isLogin', 'true')
                }
            } else {
                showError(res.message)
            }
        })
    }

    const getRoutes = (array: any[]) => {
        let routers: any[] = [];
        array.map(e => {
            if (e.route) {
                routers.push(e.route)
            }
        })
        return routers
    }
    const getAuthRoutes = (array: any[]) => {
        let routers: any[] = [];
        array.map(e => {
            if (e.route && e.is_have) {
                routers.push(e.route)
            }
        })
        return routers
    }

    if (isLogin === "true") {
        return <LoggedIn getUserInfo={getUserInfo}/>
    }
    return <Login getUserInfo={getUserInfo}/>
})

interface LoggedInProps {
    getUserInfo: () => void
}

const LoggedIn = React.memo((props: LoggedInProps) => {
    const {getUserInfo} = props

    useEffect(() => {
        getUserInfo()
    }, [])

    return <LayoutContainer/>
})

export default App;

