//异常详情
import React, {useEffect, useState} from "react";
import {Col, Divider, Form, Input, Row, Select} from "antd";
import {EXCReportStatus, showError} from "../../../utils";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {getExceptionInfo, getUserList} from "../../../service";

interface HandleOrAssignDetailProps {
    form: FormInstance,
    showProcess?: boolean,
    objectId: number
}

//异常指派和异常处理
const HandleOrAssignDetail = (props: HandleOrAssignDetailProps): React.ReactElement => {
    const {form, showProcess = false, objectId} = props;
    const [handleManList, setHandleManList] = useState<any[]>([]);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        //获取异常详情
        getExceptionInfo({object_id: objectId}).then(res => {
            if (res.code === 200) {
                form.setFieldsValue({
                    exceptionNO: res.data.code,
                    productionLine: res.data.line_name,
                    procedure: res.data.work_process_name,
                    category: res.data.exception_type,
                    code: res.data.exception_describe,
                    person: res.data.reporting_user,
                    status: EXCReportStatus[res.data.status],
                    describe: res.data.phenomenal_description,
                    handleMan: res.data.handle_user_id,
                    time: res.data.reporting_time
                })
                if (res.data.file) {
                    setImageUrl(res.data.file.url)
                } else {
                    setImageUrl(undefined)
                }
            } else {
                showError(res.message)
            }
        })
        //获取处理人列表
        getUserList().then(res => {
            if (res.code === 200) {
                setHandleManList(res.data.data)
            } else {
                setHandleManList([])
                showError(res.message)
            }
        })
    }, [])

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    return <div className={'view-detail'}>
        <Form {...layout} form={form} colon={false}>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label={'异常单号'} name={'exceptionNO'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'产线'} name={'productionLine'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'工序'} name={'procedure'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'异常类别'} name={'category'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'异常代码'} name={'code'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'提报人'} name={'person'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'现象描述'} name={'describe'}>
                        <Input.TextArea disabled/>
                    </Form.Item>
                    <Form.Item label={'图片'} name={'picture'}>
                        {
                            imageUrl ? <img src={imageUrl} alt={''} className={'view-detail-img'}/> :
                                <img src={require('../../../assets/noImg.png')} alt={''}
                                     style={{height: 160, width: '100%'}}/>
                        }
                    </Form.Item>
                    <Form.Item label={'提报时间'} name={'time'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={12}>
                    <Form.Item label={'处理人'} name={'handleMan'}
                               rules={showProcess ? [] : [{required: true, message: '请选择处理人'}]}>
                        <Select placeholder={'请选择处理人'} disabled={showProcess}>
                            {
                                handleManList.map(e => <Select.Option key={e.object_id}
                                                                      value={e.object_id}>{e.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {
                showProcess && <Form.Item label={'处理过程'} name={'process'} labelCol={{span: 3}} wrapperCol={{span: 21}}
                                          rules={[{required: true, message: '请输入异常处理过程'},
                                              {
                                                  max: 200,
                                                  message: '处理过程不得大于200位！'
                                              }]}>
                    <Input.TextArea placeholder={'请输入异常处理过程'} rows={4}/>
                </Form.Item>
            }

        </Form>
    </div>
}

export default React.memo(HandleOrAssignDetail);
