import React, {useEffect, useImperativeHandle, useState, ReactNode} from 'react';
import {Button, Table, Form} from 'antd';
import type {FormInstance} from 'antd/es/form';
import type {ColumnsType} from 'antd/es/table';
import {arrayMoveImmutable} from "array-move";
import type {SortEnd} from 'react-sortable-hoc';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import './index.less';

interface EditTableProps {
    //表格标题
    title: string,
    //表格columns
    detailColumns: ColumnsType<any>,
    //表格dataSource
    detailDataSource: any[],
    //新增的数据
    newItem?: any,
    //可编辑表格
    tableForm: FormInstance,
    //是否能拖拽
    isDrag?: boolean,
    //是否滚动
    scroll?: boolean | object,
    //表格是否可编辑
    tableDisabled?: boolean,
    //对新增的一条数据设置默认值
    changeForm?: (index: number) => void,
    // 新增按钮的文本
    addText?: string | ReactNode,
    // 是否不能新增数据
    isNoneAdd?: boolean,
    //是否正在加载中
    tableLoading?: boolean
}

export interface TRef {
    handleDelete: (index: number) => void,
}

//实现拖拽、编辑表格功能
const EditOrDragTable = React.memo(React.forwardRef<TRef, EditTableProps>((props, ref) => {
    useImperativeHandle(ref, () => ({
        handleDelete,
    }));
    const {
        title,
        detailColumns,
        detailDataSource,
        newItem,
        tableForm,
        changeForm,
        isDrag = false,
        scroll = false,
        tableDisabled = false,
        addText,
        isNoneAdd,
        tableLoading
    } = props;

    //拖拽图标
    const sortColumn = [{
        dataIndex: 'sort',
        index: 'sort',
        width: 80,
        align: 'center',
        className: 'drag-visible',
        render: () => <DragHandle/>,
    }]
    const DragHandle = SortableHandle(() => <img src={require('../../assets/icon_move.png')}
                                                 style={{cursor: 'grab'}} alt={''}/>);
    const [editDetailColumns, setEditDetailColumns] = useState<any[]>([]);
    const [editDetailDataSource, setEditDetailDataSource] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>()

    useEffect(() => {
        if (isDrag) {
            setEditDetailColumns([...sortColumn, ...detailColumns])
        } else {
            setEditDetailColumns(detailColumns)
        }
    }, [detailColumns])

    useEffect(() => {
        setEditDetailDataSource(detailDataSource)
    }, [detailDataSource])

    useEffect(() => {
        setLoading(tableLoading)
    }, [tableLoading])

    const getFormToDatasource = () => {
        let temp = Object.values(tableForm.getFieldsValue())
        temp.map((e: any, i: number) => {
            e.index = i
        })
        return temp
    }

    const handleAdd = () => {
        let datasource = getFormToDatasource();
        let newEditDetailDataSource = datasource.concat({
            index: datasource.length,
            ...newItem,
        })
        tableForm.setFieldsValue({...newEditDetailDataSource});
        setEditDetailDataSource(newEditDetailDataSource);
        //对新增的数据添加默认值
        if (changeForm) {
            changeForm(editDetailDataSource.length)
        }
    }

    const handleDelete = (index: number) => {
        let datasource = getFormToDatasource();
        const newEditDetailDataSource = datasource.filter((item: any) => item.index !== index);
        tableForm.setFieldsValue({...newEditDetailDataSource});
        setEditDetailDataSource(newEditDetailDataSource);
    }

    const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        if (oldIndex !== newIndex) {
            let datasource = getFormToDatasource();
            const newData = arrayMoveImmutable(datasource.slice(), oldIndex, newIndex).filter(
                (el: any) => !!el,
            );
            tableForm.setFieldsValue({...newData})
            setEditDetailDataSource(newData);
        }
    };


    return <div className={'edit-table'}>
        <div className={'edit-table-row mes-content'}
             style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            {title === '' ? null : <span className={'edit-table-title'}>{title}</span>}
            {
                isNoneAdd ? null : (<div style={{display: "flex", flexDirection: "row"}}>
                    <Button className={addText ? 'mes-add-other' : 'mes-add'} style={{float: "right", padding: 0}}
                            onClick={() => handleAdd()}>{addText ? addText : '新增'}</Button>
                </div>)
            }
        </div>
        <div className={'edit-table-form'}>
            <Form form={tableForm} component={false} disabled={tableDisabled}>
                <Table columns={editDetailColumns} dataSource={editDetailDataSource} loading={loading}
                       className={'mes-edit-tableBar'}
                       pagination={false}
                       scroll={typeof scroll === 'boolean' ? scroll? {x: 2000, y: 400} : {y: 400} : scroll}
                       rowKey="index"
                       components={{
                           body: {
                               wrapper: React.memo((props: any) => <DraggableContainer
                                   onSortEnd={onSortEnd} {...props}/>),
                               row: React.memo((props: any) => <DraggableBodyRow
                                   editDetailDataSource={editDetailDataSource} {...props}/>),
                           },
                       }}/>
            </Form>
        </div>
    </div>
}))

const DraggableContainer = ({onSortEnd, ...restProps}: any) => {
    const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
        <tbody {...props} />
    ));

    return (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...restProps}
        />
    )
};

const DraggableBodyRow: React.FC<any> = ({className, style, editDetailDataSource, ...restProps}) => {
    const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => {
        return (<tr {...props} />)
    });

    const index = editDetailDataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
};

export default EditOrDragTable;
