import React from 'react'
import {Button, DatePicker} from "antd";
import '../../Statistics/components/index.css'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";

/**
 * 统计分析--异常柏拉图--查询重置
 */
const {RangePicker} = DatePicker;

interface Type {
    startTime: string,
    endTime: string,
}

export default class SelectFormExceptionPlato extends React.Component<{
    queryClick?: (startTime: string, endTime: string) => void,
}> {

    state: Type = {
        startTime: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
        endTime: moment(new Date()).endOf('month').format('YYYY/MM/DD'),
    }

    timeChanged = (dates: any) => {
        if (dates) {
            this.setState({
                startTime: dates[0].format('YYYY/MM/DD'),
                endTime: dates[1].format('YYYY/MM/DD'),
            })
        } else {
            this.setState({
                startTime: '',
                endTime: '',
            })
        }
    }

    disabledDate = (current: any) => {
        const tooLate = this.state.startTime && current.diff(this.state.startTime, 'days') > 89;
        const tooEarly = this.state.endTime && moment(this.state.endTime).diff(current, 'days') > 89;
        return !!tooEarly || !!tooLate;
    };

    render() {
        const {startTime, endTime} = this.state
        return <div className={'selectFormTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    提报时间
                </div>
                <RangePicker
                    locale={locale}
                    value={[moment(startTime), moment(endTime)]}
                    size="small"
                    clearIcon={false}
                    style={{marginLeft: 20, width: 244, height: 30}}
                    placeholder={['开始时间', '结束时间']}
                    disabledDate={this.disabledDate}
                    format={[(moment) => moment.format('YYYY/MM/DD'), 'YYYY/MM/DD']}
                    onChange={this.timeChanged}
                />
            </div>

            <Button
                className={'buttonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(startTime, endTime)
                }}
            >
                查询
            </Button>

            <Button
                className={'buttonReset'}
                type={"primary"}
                onClick={() => {
                    let start = moment(new Date()).startOf('month').format('YYYY/MM/DD')
                    let end = moment(new Date()).endOf('month').format('YYYY/MM/DD')
                    this.setState({
                        startTime: start,
                        endTime: end,
                    }, () => {
                        this.props.queryClick && this.props.queryClick(start, end)
                    })
                }}
            >
                重置
            </Button>
        </div>
    }
}
