import React, {useEffect, useState} from "react";
import {Table} from "antd";
import SelectFormQuery from "./selectFormQuery";
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import {getWarehouseQuery} from "../../../service";
import {toastErrorMessage} from "../../../utils";
import "./style.css"
import QueryDetailModal from "./queryDetailModal";

/**
 * 仓储管理--库存查询
 */
const WarehouseQuery = (): React.ReactElement => {
    const [dataSource, setDataSource] = useState<API.warehouseQueryResponseItem[]>([]);
    const columns: ColumnsType<API.warehouseQueryResponseItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '5%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {title: '产品代码', dataIndex: 'product_code', align: 'center', ellipsis: true},
        {title: '产品名称', dataIndex: 'product_name', align: 'left', ellipsis: true},
        {title: '规格型号', dataIndex: 'specification', align: 'center', ellipsis: true},
        {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
        {title: '仓库名称', dataIndex: 'storage_name', align: 'center', ellipsis: true},
        {title: '库管员', dataIndex: 'storage_user', align: 'center', ellipsis: true},
        {
            title: '库存数量', dataIndex: 'inventory_num', align: 'right', ellipsis: true,
            render: (num: number, record) => <div
                className={'warehouseQueryHasAuthority'}
                onClick={() => {
                    setObjectId(record.object_id)
                    setShowModal(true)
                }}
            >
                {num.toLocaleString()}
            </div>
        },
        {title: '最后修改人', dataIndex: 'modify_user', align: 'center', ellipsis: true},
        {
            title: '修改时间', dataIndex: 'modify_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null || date === '' ? '' : moment(date).format('YYYY/MM/DD HH:mm:ss')}</div>
        }
    ]
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [productName, setProductName] = useState('');
    const [house, setHouse] = useState<undefined | number>(undefined);
    const [objectId, setObjectId] = useState(-1);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        productName: string
        houseValue: number | undefined,
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.productName !== '') {
                param = {...param, product_name: data.productName}
            }
            if (data.houseValue) {
                param = {...param, storage_id: data.houseValue}
            }
        } else {
            if (productName !== '') {
                param = {...param, product_name: productName}
            }
            if (house) {
                param = {...param, storage_id: house}
            }
        }
        getWarehouseQuery(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleShowTotal = (total: number) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{display: "flex", marginLeft: 0, color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div>
            <SelectFormQuery
                queryClick={(productionName: string, houseValue: number | undefined) => {
                    setProductName(productionName)
                    setHouse(houseValue)
                    onFinish(1, pageSize, {productName: productionName, houseValue: houseValue})
                }}
            />
            <Table
                className={'warehouseQueryTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />

            {
                showModal && <QueryDetailModal
                    objectId={objectId}
                    closeClick={() => {
                        setShowModal(false)
                    }}
                />
            }
        </div>)
}

export default WarehouseQuery
