import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Dropdown, Button, Menu, Form, Input, message} from 'antd';
import ModalContainer from "../Modal";
import { UserContext} from "../../contexts/userContext";
import {changePassword, logout} from "../../service";
import {filterData, getFormData, showError} from "../../utils";

interface HeaderBarProps {
    collapsed: boolean,
    setCollapsed: (collapsed: boolean) => void;
}

const HeaderBar = (props: HeaderBarProps) => {
    const {useInfo, setUserInfo} = useContext(UserContext);
    const {collapsed, setCollapsed} = props;
    const navigate = useNavigate();
    const overlay = [
        {
            key: '/settings',
            label: '账户设置',
        },
        {
            key: '/changePassword',
            label: '密码修改',
        },
        {
            key: '/logout',
            label: '安全退出',
        },
    ]
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    const [open, setOpen] = useState<boolean>(false);
    const [form] = Form.useForm();
    const onOk = () => {
        form.validateFields().then(values => {
            const formData = getFormData(filterData({
                password: values.originalPassword,
                password_new: values.newPassword
            }))
            changePassword(formData).then(res => {
                if (res.code === 200) {
                    message.success('密码修改成功!')
                    setOpen(false)
                    window.sessionStorage.setItem('isLogin', 'null')
                    setUserInfo({})
                }else{
                    showError(res.message)
                }
            })
        }).catch(error => {
            console.log(error)
        })
    }

    const ModelInnerComponent = React.memo(() => {
        return (
            <Form
                {...layout}
                form={form}
            >
                <Form.Item
                    name="originalPassword"
                    label="原密码"
                    rules={[
                        {required: true, message: '请输入原密码!'},
                    ]}

                >
                    <Input placeholder={'请输入原密码'}/>
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="新密码"
                    rules={[
                        {required: true, message: '请输入新密码!'},
                        {
                            validator: (_, value) => {
                                if (value && form.getFieldValue('originalPassword') === value) {
                                    return Promise.reject(new Error('新密码不能与旧密码相同!'))
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        },
                        {
                            type: "string",
                            min: 10,
                            max: 18,
                            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
                            message: '密码由10-18位字母、数字组成!'
                        },
                    ]}

                >
                    <Input placeholder={'请输入新密码'}/>
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="确认密码"
                    rules={[
                        {required: true, message: '请再次输入密码!'},
                        {
                            validator: (_, value) => {
                                if (form.getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject(new Error('请确认新密码,两次输入一致!'))
                                }
                            }
                        },
                    ]}

                >
                    <Input placeholder={'请再次输入密码'}/>
                </Form.Item>
            </Form>
        )
    })

    const clickOverlayItem = (e: any) => {
        if (e.key === '/settings') {
            navigate(e.key)
        } else if (e.key === '/changePassword') {
            setOpen(true);
        } else if (e.key === '/logout') {
            logout().then(res => {
                if (res.code === 200) {
                    setUserInfo({})
                    navigate('/')
                    window.sessionStorage.setItem('isLogin', 'null')
                    message.success('您已安全退出！')
                }else{
                    showError(res.message)
                }
            })
        }
    }

    return (
        <div className={'mes-header'}>
            <div onClick={() => setCollapsed(!collapsed)}>
                <img src={collapsed?require('../../assets/collapsed-stow.png'):require('../../assets/collapsed-default.png')} alt=""/>
            </div>
            <div>
                <Dropdown
                    overlay={
                        <Menu items={overlay} onClick={(e) => clickOverlayItem(e)}
                              style={{textAlign: 'center'}}/>}
                    placement="bottom"
                    arrow={{pointAtCenter: true}}>
                    <Button type={'text'} style={{height: '100%',paddingRight:6}}>
                        <>
                            <span className={'mes-avatar'} style={{marginRight: 10}}>
                                    <img
                                        src={useInfo.avatar ? useInfo.avatar : require('../../assets/avatar.png')}
                                        alt=""
                                        style={useInfo.avatar ? {
                                            width: 25,
                                            height: 25,
                                            borderRadius: '50%'
                                        } : {width: '100%'}}/>
                            </span>
                            <span>{useInfo.name}</span>
                        </>
                    </Button>
                </Dropdown>
            </div>
            <ModalContainer title={'修改密码'} open={open}
                            onOk={() => onOk()}
                            onCancel={() => {
                                form.resetFields();
                                setOpen(false);}}
                            innerComponent={<ModelInnerComponent/>}/>
        </div>
    )
}

export default React.memo(HeaderBar);
