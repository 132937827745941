import React from 'react'
import {Button, DatePicker, Select} from "antd";
import {getStatus} from "../../../utils";
import "../../Warehouse/WarehouseQuery/style.css"
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";
import {getStorageList} from "../../../service";

/**
 * 仓储管理--库存盘点--查询、重置
 */
const {Option} = Select;
const {RangePicker} = DatePicker;

interface Type {
    houseValue: number | undefined,
    houseList: Array<any>,
    startTime: any,
    endTime: any,
}

export default class SelectFormCheck extends React.Component<{
    queryClick?: (originalHouseValue: number | undefined, startTime: any, endTime: any) => void,
    newAdd?: () => void,
    printClick?: () => void,
}> {

    state: Type = {
        houseValue: undefined,
        houseList: [],
        startTime: undefined,
        endTime: undefined,
    }

    componentDidMount() {
        getStorageList().then(res => {
            if (res.code === 200) {
                this.setState({
                    houseList: res.data.data
                })
            }
        })
    }

    timeChanged = (dates: any) => {
        if (dates) {
            this.setState({
                startTime: dates[0].format('YYYY/MM/DD'),
                endTime: dates[1].format('YYYY/MM/DD'),
            })
        } else {
            this.setState({
                startTime: undefined,
                endTime: undefined,
            })
        }
    }

    render() {
        const {houseValue, houseList, startTime, endTime} = this.state
        return <div className={'warehouseQueryTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14, marginLeft: 13}}>
                    仓库名称
                </div>
                <Select
                    value={houseValue}
                    showArrow
                    showSearch={false}
                    style={{marginLeft: 20, minWidth: 244, height: 30, borderRadius: 5}}
                    placeholder={`请选择仓库`}
                    onChange={(value) => {
                        this.setState({
                            houseValue: value,
                        })
                    }}
                >
                    {
                        houseList.map((item: any, index: number) => {
                            return <Option key={index} value={item.object_id}>
                                {item.storage_name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    盘点日期
                </div>
                <RangePicker
                    locale={locale}
                    value={[startTime && startTime !== '' ? moment(startTime) : null, endTime && endTime !== '' ? moment(endTime) : null]}
                    size="small"
                    clearIcon={false}
                    style={{marginLeft: 20, width: 244, height: 30}}
                    placeholder={['开始日期', '结束日期']}
                    format={[(moment) => moment.format('YYYY/MM/DD'), 'YYYY/MM/DD']}
                    onChange={this.timeChanged}
                />
            </div>

            <Button
                className={'buttonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(houseValue, startTime, endTime)
                }}
            >
                查询
            </Button>

            <Button
                className={'buttonReset'}
                type={"primary"}
                onClick={() => {
                    this.setState({
                        houseValue: undefined,
                        startTime: undefined,
                        endTime: undefined,
                    }, () => {
                        this.props.queryClick && this.props.queryClick(undefined, undefined, undefined)
                    })
                }}
            >
                重置
            </Button>

            <div style={{display: "flex", flex: 1, justifyContent: "right", marginRight: 25}}>
                <Button
                    style={{
                        display: 'flex',
                        width: 90,
                        height: 30,
                        backgroundColor: '#3E7BFA',
                        color: '#ffffff',
                        fontSize: 14,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 5,
                    }}
                    type={"primary"}
                    onClick={() => {
                        this.props.printClick && this.props.printClick()
                    }}
                >
                    打印盘点表
                </Button>
                <Button
                    style={{
                        display: 'flex',
                        width: 76,
                        height: 30,
                        backgroundColor: '#3E7BFA',
                        color: '#ffffff',
                        fontSize: 14,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 5,
                        marginLeft: 20
                    }}
                    type={"primary"}
                    onClick={() => {
                        this.props.newAdd && this.props.newAdd()
                    }}
                >
                    新增盘点
                </Button>
            </div>
        </div>
    }
}
