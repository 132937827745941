import React, {useEffect, useRef, useState} from "react";
import GeneralManagement, {Options, QueryListItem} from "../../components/GeneralManagement";
import {Button, Form, message, Space} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {
    createFaultReport,
    deleteFaultReport,
    getFaultReportById,
    getFaultReportList,
    getFaultTypeList,
    UpdateFaultReport
} from "../../service";
import {faultStatus, filterData, getFormData, isHalt, showError, troubleLevel} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';
import moment from "moment";
import AddOrEdit from "./components/AddOrEdit";
import ViewItem from "./components/ViewItem";
import {UploadFile} from "antd/es/upload/interface";

enum ModalTitle {
    ADD = '新增故障信息',
    EDIT = '编辑故障信息',
    VIEW = '查看报修信息',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

const TroubleRepair = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [troubleList, setTroubleList] = useState<Options[]>([]);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '报修单号',
            placeholder: '请输入报修单号'
        },
        {
            key: 'device_name',
            name: '设备名称',
            placeholder: '请输入设备名称',
        },
        {
            key: 'fault_type_id',
            name: '故障类型',
            placeholder: '请选择故障类型',
            options: troubleList,
            multiple:true
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            fixed: 'left',
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '报修单号',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
            width: 250,
            ellipsis: true,
            render: (value: string, item: any) => {
                return <span className={'mes-options'} onClick={() => {
                    openModal(ModalTitle.VIEW, item)
                }}>{value}</span>
            }
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            key: 'device_code',
            align: 'center',
            fixed: 'left',
            width: 120,
            ellipsis: true,
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            fixed: 'left',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'fault_time',
            title: '故障时间',
            dataIndex: 'fault_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'fault_name',
            title: '故障类型',
            dataIndex: 'fault_name',
            align: 'center',
            width: 100,
        },
        {
            key: 'shutdown_or_not',
            title: '是否停机',
            dataIndex: 'shutdown_or_not',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return isHalt.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_level',
            title: '故障等级',
            dataIndex: 'fault_level',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return troubleLevel.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '报修人',
            dataIndex: 'report_user_name',
            key: 'report_user_name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            width: 250,
            ellipsis: true, render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '提报时间',
            dataIndex: 'report_time',
            key: 'report_time',
            align: 'center',
            width: 250,
            ellipsis: true, render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: number) => {
                return faultStatus.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i} style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => {
                if (item.status === 10) {
                    return (
                        <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                            <span className={'mes-options'} onClick={() => {
                                openModal(ModalTitle.EDIT, item)
                            }}>编辑</span>
                            <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                                openModal(ModalTitle.DELETE, item)
                            }}>删除</span>
                        </Space>
                    )
                }
            }
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} style={{marginRight: 14}} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            setModalTitle(title);
            form.resetFields();
            setInnerComponent(<AddOrEdit form={form} fileList={fileList} setFileList={setFileList}/>)
        } else if (title === ModalTitle.EDIT) {
            setModalTitle(title);
            getFaultReportById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        device_id: res.data.device_id,
                        link_device_id: res.data.device_code,
                        fault_time: res.data.fault_time ? moment(res.data.fault_time) : '',
                        fault_type_id: res.data.fault_type_id,
                        shutdown_or_not: res.data.shutdown_or_not,
                        fault_level: res.data.fault_level,
                        fault_describe: res.data.fault_describe,
                    })
                    let temp: any[] = [];
                    res.data.image_fault_report.map((e: any) => {
                        temp.push({
                            uid: e.file.object_id,
                            name: e.file.name,
                            url: e.file.url,
                        })
                    })
                    setInnerComponent(<AddOrEdit form={form} fileList={temp} setFileList={setFileList}
                                                 initialDeviceName={res.data.device_name}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setModalTitle(title);
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.VIEW) {
            const ViewDeviceTitle = (): React.ReactElement => {
                return (<div>
                    <img src={require('../../assets/view-trouble-icon.png')} alt='暂无图片' style={{marginRight: 10}}/>
                    <span>{title}</span>
                </div>)
            }
            setModalTitle(ViewDeviceTitle as any);
            getFaultReportById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<ViewItem item={res.data}/>)
                } else {
                    showError(res.message)
                }
            })

        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle, type?: number) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const tempPics = Object.values(fileList).map((e) => {
                    return {file_id: e.uid}
                })
                const temp = {
                    ...values,
                    fault_time: values.fault_time.format('YYYY-MM-DD HH:mm'),
                    submit_type: type,
                    fault_report_images: tempPics
                }
                delete temp.link_device_id;
                const formData = getFormData(filterData(temp), true)
                createFaultReport(formData).then(res => {
                    if (res.code === 200) {
                        if (type === 10) {
                            afterOnOK('保存成功！');
                        } else {
                            afterOnOK('提交成功！');
                        }
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const tempPics = Object.values(fileList).map((e) => {
                    return {file_id: e.uid}
                })
                const temp = {
                    ...values,
                    object_id: selectedItem.object_id,
                    fault_time: values.fault_time.format('YYYY-MM-DD HH:mm'),
                    submit_type: type,
                    fault_report_images: tempPics
                }
                delete temp.link_device_id;
                delete temp.code;
                const formData = getFormData(filterData(temp), true)
                UpdateFaultReport(formData).then(res => {
                    if (res.code === 200) {
                        if (type === 10) {
                            afterOnOK('保存成功！');
                        } else {
                            afterOnOK('提交成功！');
                        }
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteFaultReport(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            setFileList([]);
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getFaultReportList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const setWidth = (): string => {
        if (modalTitle === ModalTitle.DELETE) {
            return '20%'
        } else {
            return '1071px'
        }
    }

    useEffect(() => {
        getFaultTypeList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.fault_name
                            })
                        })
                    }
                    setTroubleList(temp)
                }
            } else {
                showError(res.message);
            }
        })
    }, [])

    const getFooter = (): React.ReactNode => {
        if (typeof modalTitle === 'object') {
            return null
        }
        // 保存10提交20
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            return (<div>
                <Button onClick={() => onCancel(modalTitle)}>关闭</Button>
                <Button type={'primary'} onClick={() => onOK(modalTitle, 10)}>保存</Button>
                <Button type={'primary'} onClick={() => onOK(modalTitle, 20)}>提交</Button>
            </div>)
        }
    }

    const filterQueryData = (queryData: any) => {
        if (queryData.fault_type_id) {
            if(queryData.fault_type_id.length){
                queryData.fault_type_id = JSON.stringify(queryData.fault_type_id)
            }
        }
        return queryData;
    }


    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} scroll={true} filterQueryData={filterQueryData}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={setWidth()} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default TroubleRepair


