import React, {useEffect, useState} from 'react'
import {Button, Modal, Table, TableColumnsType} from "antd";
import "./style.css"
import "../WarehouseQuery/style.css"
import {getWarehouseCheckDetail} from "../../../service";
import {toastErrorMessage} from "../../../utils";
import moment from "moment";

/**
 * 盘点明细弹窗
 */
interface CheckDetailModalProps {
    dataItemId: number,
    closeClick: () => void
}

const CheckDetailModal = (props: CheckDetailModalProps): React.ReactElement => {
    const [data, setData] = useState<API.warehouseResponseDataCheckDetail>({
        code: '',
        create_time: '',
        inventoryDetails: [],
        inventory_date: '',
        is_valid: false,
        object_id: 0,
        object_name: '',
        remarks: '',
        status: 0,
        storage_id: 0,
        storage_name: '',
        update_time: '',
        user_id: 0,
        user_name: '',
    });
    const columns: TableColumnsType<any> = [
        {
            title: '仓库', children: [
                {
                    title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
                    render: (text, record, index) => <div>{index + 1}</div>
                },
                {title: '产品名称', dataIndex: 'product_name', align: 'left', ellipsis: true},
            ]
        },
        {
            title: data.storage_name, children: [
                {title: '产品代码', dataIndex: 'product_code', align: 'center', ellipsis: true},
                {title: '规格型号', dataIndex: 'specification', align: 'center', ellipsis: true},
                {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
            ]
        },
        {
            title: '盘点日期', children: [
                {
                    title: '库存数量',
                    dataIndex: 'inventory_quantity',
                    align: 'right',
                    ellipsis: true,
                    render: (num: number) => <div>{num === null || num < 0 ? '' : num.toLocaleString()}</div>
                },
                {
                    title: '实际数量', dataIndex: 'real_quantity', align: 'right', ellipsis: true,
                    render: (num: number) => <div>{num === null || num < 0 ? '' : num.toLocaleString()}</div>
                },
            ]
        },
        {
            title: data.inventory_date === '' ? '' : moment(data.inventory_date).format('YYYY/MM/DD'), children: [
                {
                    title: '盘盈或盘亏数', children: [
                        {
                            title: '盘盈',
                            dataIndex: 'inventory_profit',
                            align: 'right',
                            ellipsis: true,
                            render: (num: number) => <div>{num === null || num < 0 ? '' : num.toLocaleString()}</div>
                        },
                        {
                            title: '盘亏',
                            dataIndex: 'inventory_loss',
                            align: 'right',
                            ellipsis: true,
                            render: (num: number) => <div>{num === null || num < 0 ? '' : num.toLocaleString()}</div>
                        },
                    ]
                },
                {title: '差异原因', dataIndex: 'reasons', align: 'left', ellipsis: true},
            ]
        }
    ]

    useEffect(() => {
        getWarehouseCheckDetail({object_id: props.dataItemId}).then((res) => {
            if (res.code === 200) {
                setData(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }, [])

    return (
        <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => props.closeClick && props.closeClick()}
                />
            }
            destroyOnClose
            title={<div style={{fontSize: 16, fontWeight: "bold"}}>
                盘点明细
            </div>}
            footer={null}
            width={1080}
            centered
            open
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
                fontSize: 16
            }}>
                盘点信息
            </div>

            <div style={{display: "flex", flexDirection: "column", marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>盘点单号：</div>
                        <div className={'detailValue70Style'}>{data.code}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName70Style'}>仓库名称：</div>
                        <div className={'detailValue70Style'}>{data.storage_name}</div>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>盘点日期：</div>
                        <div
                            className={'detailValue70Style'}>{data.inventory_date === null || data.inventory_date === '' ? '' : moment(data.inventory_date).format('YYYY/MM/DD')}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName70Style'}>盘点人：</div>
                        <div className={'detailValue70Style'}>{data.user_name}</div>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                        <div className={'detailName70Style'}>备注：</div>
                        <div style={{
                            flex: 1,
                            marginLeft: 10,
                            wordBreak: "break-all",
                            color: '#333333',
                            fontSize: 14
                        }}>{data.remarks}</div>
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                flex: 1,
                height: 1,
                backgroundColor: '#000000',
                opacity: 0.1,
                marginTop: 20,
                marginBottom: 20
            }}/>

            <div style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 24,
                fontWeight: "bold",
                fontSize: 16
            }}>
                盘点明细
            </div>

            <Table
                className={'checkDetailTable'}
                dataSource={data.inventoryDetails}
                columns={columns}
                size={'small'}
                bordered
                pagination={false}
            />

            <div style={{display: "flex", marginTop: 30, justifyContent: "center"}}>
                <Button
                    style={{width: 130, height: 48, borderRadius: 5, backgroundColor: '#B8B8B8', color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        props.closeClick && props.closeClick()
                    }}
                >
                    关闭
                </Button>
            </div>
        </Modal>
    )
}

export default CheckDetailModal
