import type {TabsProps} from 'antd';
import {Button, Descriptions, Divider, Modal, Space, Tabs, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/es/upload";
import {faultStatus, getBase64, inspectionTaskStatus, isHalt, showError, troubleLevel} from "../../../utils";
import {ColumnsType} from "antd/es/table";
import {
    getDeviceInfoMaintain,
    getDeviceInfoPatrolPlan,
    getDeviceInfoPatrolTask,
    getDeviceInfoPointPlan,
    getDeviceInfoPointTask,
    getDeviceInfoRelatedDoc,
    getDeviceInfoRepair
} from "../../../service";
import moment from "moment";
import TableBar from "../../../components/GeneralManagement/TableBar";
import FileViewer from 'react-file-viewer';
import {saveAs} from 'file-saver';

interface ViewDeviceProps {
    item: any
}

const ViewDevice = (props: ViewDeviceProps) => {
    const {item} = props;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewFileOpen, setPreviewFileOpen] = useState(false);
    const [previewFileTitle, setPreviewFileTitle] = useState(null);
    const [previewChildren, setPreviewChildren] = useState<React.ReactNode | undefined>(undefined);

    const handleCancel = () => setPreviewOpen(false);

    const handleCancelFile = () => {
        setPreviewFileOpen(false);
        setPreviewFileTitle(null);
        setPreviewChildren(undefined)
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    //是否是巡检计划
    const [patrolDefault, setPatrolDefault] = useState<boolean>(true);
    //是否是点检计划
    const [pointDefault, setPointDefault] = useState<boolean>(true);
    //columns显示计划还是任务
    const [isPatrol, setIsPatrol] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    const columns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'file_name',
            title: '文件名',
            dataIndex: 'file_name',
            align: 'center',
            width: 150,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            key: 'file_extension',
            title: '文件后缀',
            dataIndex: 'file_extension',
            align: 'center',
            width: 100
        },
        {
            key: 'file_size',
            title: '文件大小(KB)',
            dataIndex: 'file_size',
            align: 'center',
            width: 150,
            render: (value: string) => <div style={{textAlign: 'right'}}>{value}</div>
        },
        {
            key: 'up_user_name',
            title: '上传者',
            dataIndex: 'up_user_name',
            align: 'center',
            width: 100
        },
        {
            key: 'up_time',
            title: '上传时间',
            dataIndex: 'up_time',
            align: 'center',
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'options',
            title: '操作',
            dataIndex: 'options',
            align: 'center',
            width: 100,
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        previewFile(item)
                    }}>预览</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        downloadFile(item)
                    }}>下载</span>
                </Space>
            )
        },
    ]

    const previewFile = (item: any) => {
        setPreviewFileOpen(true);
        setPreviewFileTitle(item.file_name);
        if (item.file_extension === "pdf") {
            setPreviewChildren(<iframe src={item.file.url} height={'900px'} width={'100%'}></iframe>)
        } else {
            setPreviewChildren(
                <FileViewer
                    fileType={item.file_extension}
                    filePath={item.file.url}/>
            )
        }
    }

    const getBlob = (url: string) => {
        return new Promise(resolve => {
            const xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.response)
                }
            }
            xhr.send()
        })
    }

    const downloadFile = async (item: any) => {
        const blob = await getBlob(item.file.url)
        // @ts-ignore
        saveAs(blob, item.file_name)
    }


    const protectColumns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'code',
            title: '报修单号',
            dataIndex: 'code',
            align: 'center',
            width: 200,
        },
        {
            key: 'report_user_name',
            title: '报修人',
            dataIndex: 'report_user_name',
            align: 'center',
            width: 150
        },
        {
            key: 'report_user_organization_name',
            title: '所属部门',
            dataIndex: 'report_user_organization_name',
            align: 'center',
            width: 150,
        },
        {
            key: 'report_user_phone',
            title: '联系方式',
            dataIndex: 'report_user_phone',
            align: 'center',
            width: 150
        },
        {
            key: 'fault_time',
            title: '故障时间',
            dataIndex: 'fault_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'fault_type_name',
            title: '故障类型',
            dataIndex: 'fault_type_name',
            align: 'center',
            width: 200,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'shutdown_or_not',
            title: '是否停机',
            dataIndex: 'shutdown_or_not',
            align: 'center',
            width: 150,
            render: (value: number) => {
                return isHalt.map(e => {
                    if (e.id === value) {
                        return <div>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_level',
            title: '故障等级',
            dataIndex: 'fault_level',
            align: 'center',
            width: 150,
            render: (value: number) => {
                return troubleLevel.map(e => {
                    if (e.id === value) {
                        return <div>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_describe',
            title: '故障描述',
            dataIndex: 'fault_describe',
            align: 'center',
            width: 300,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}} className={'text-show-ellipsis'}>{value}</div>,

        },
        {
            key: 'report_time',
            title: '提报时间',
            dataIndex: 'report_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'status',
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: 150,
            render: (value: number) => {
                return faultStatus.map(e => {
                    if (e.id === value) {
                        return <div style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },


    ]
    const maintainColumns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'code',
            title: '维修单号',
            dataIndex: ['fault_info', 'code'],
            align: 'center',
            width: 200,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            key: 'fault_time',
            title: '故障时间',
            dataIndex: ['fault_info', 'fault_time'],
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'fault_type_name',
            title: '故障类型',
            dataIndex: ['fault_info', 'fault_type_name'],
            align: 'center',
            width: 200,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'shutdown_or_not',
            title: '是否停机',
            dataIndex: ['fault_info', 'shutdown_or_not'],
            align: 'center',
            width: 150,
            render: (value: number) => {
                return isHalt.map(e => {
                    if (e.id === value) {
                        return <div>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_level',
            title: '故障等级',
            dataIndex: ['fault_info', 'fault_level'],
            align: 'center',
            width: 150,
            render: (value: number) => {
                return troubleLevel.map(e => {
                    if (e.id === value) {
                        return <div>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_describe',
            title: '故障描述',
            dataIndex: ['fault_info', 'fault_describe'],
            align: 'center',
            width: 300,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}} className={'text-show-ellipsis'}>{value}</div>,
        },
        {
            key: 'report_time',
            title: '提报时间',
            dataIndex: ['fault_info', 'report_time'],
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'status',
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: 200,
            render: (value: number) => {
                return faultStatus.map(e => {
                    if (e.id === value) {
                        return <div style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'group_name',
            title: '维修班组',
            dataIndex: 'group_name',
            align: 'center',
            width: 200
        },
        {
            key: 'head_user_name',
            title: '维修负责人',
            dataIndex: 'head_user_name',
            align: 'center',
            width: 100
        },
        {
            key: 'start_time',
            title: '开始时间',
            dataIndex: 'start_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'end_time',
            title: '结束时间',
            dataIndex: 'end_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'maintenance_costs',
            title: '维修费用（元）',
            dataIndex: 'maintenance_costs',
            align: 'center',
            width: 200
        },
        {
            key: 'maintenance_total_time',
            title: '维修总用时',
            dataIndex: 'maintenance_total_time',
            align: 'center',
            width: 200
        },
    ]
    const projectColumns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 60,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'code',
            title: '计划代码',
            dataIndex: 'code',
            align: 'center',
            width: 150,
        },
        {
            key: isPatrol ? 'patrol_plan_name' : 'spot_plan_name',
            title: '计划名称',
            dataIndex: isPatrol ? 'patrol_plan_name' : 'spot_plan_name',
            align: 'center',
            width: 100,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            key: 'plan_status',
            title: '状态',
            dataIndex: 'plan_status',
            align: 'center',
            width: 80,
            render: (value: number) => <div>{(value === 10 ?
                <span style={{color: '#06C270'}}>启用</span> : (value === 20 ?
                    <span style={{color: '#FF2D00'}}>停用</span> : ''))}</div>
        },
        {
            key: 'plan_start_date',
            title: '计划开始时间',
            dataIndex: 'plan_start_date',
            align: 'center',
            width: 120,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD')}</span>
                }
            }
        },
        {
            key: 'plan_end_date',
            title: '计划结束时间',
            dataIndex: 'plan_end_date',
            align: 'center',
            width: 120,
            render: (value: string, item: any) => {
                if (item.permanently_or_not) {
                    return <span>{"永久"}</span>
                } else {
                    return <span>{moment(value).format('YYYY/MM/DD')}</span>
                }
            }
        },
        {
            key: 'execute_cycle_describe',
            title: '执行周期',
            dataIndex: 'execute_cycle_describe',
            align: 'center',
            width: 200,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
    ]
    const taskColumns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'code',
            title: isPatrol ? '巡检任务单号' : '点检任务单号',
            dataIndex: 'code',
            align: 'center',
            width: 200,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            key: isPatrol ? 'patrol_plan_name' : 'spot_plan_name',
            title: '计划名称',
            dataIndex: isPatrol ? 'patrol_plan_name' : 'spot_plan_name',
            align: 'center',
            width: 100,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            key: 'should_execution_time',
            title: '应执行时间',
            dataIndex: 'should_execution_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'status',
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return inspectionTaskStatus.map(e => {
                    if (e.id === value) {
                        return <div style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'team_groups',
            title: isPatrol ? '巡检班组' : '点检班组',
            dataIndex: 'team_groups',
            align: 'center',
            width: 100
        },
        {
            key: isPatrol ? 'patrol_user_name' : 'spot_user_name',
            title: isPatrol ? '巡检负责人' : '点检负责人',
            dataIndex: isPatrol ? 'patrol_user_name' : 'spot_user_name',
            align: 'center',
            width: 100,
        },
        {
            key: 'real_start_date',
            title: '实际开始时间',
            dataIndex: 'real_start_date',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'real_end_date',
            title: '实际结束时间',
            dataIndex: 'real_end_date',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'results_of_execution',
            title: '执行结果',
            dataIndex: 'results_of_execution',
            align: 'center',
            width: 100,
            render: (value) => {
                return (value === 10 ? <span>正常</span> : (value === 20 ? <span>异常</span> : ''))
            }
        },
    ]
    //tab = 4 关联文档
    const [dataSource, setDataSource] = useState([]);
    const [total4, setTotal4] = useState<number>(0);
    const [currentPage4, setCurrentPage4] = useState<number>(1);
    const [pageSize4, setPageSize4] = useState<number>(10);
    const [totalPage4, setTotalPage4] = useState<number>(0);

    //tab = 5 报修记录
    const [protectDataSource, setProtectDataSource] = useState<any[]>([]);
    const [total5, setTotal5] = useState<number>(0);
    const [currentPage5, setCurrentPage5] = useState<number>(1);
    const [pageSize5, setPageSize5] = useState<number>(10);
    const [totalPage5, setTotalPage5] = useState<number>(0);

    //tab = 6 维修记录
    const [maintainDataSource, setMaintainDataSource] = useState<any[]>([]);
    const [total6, setTotal6] = useState<number>(0);
    const [currentPage6, setCurrentPage6] = useState<number>(1);
    const [pageSize6, setPageSize6] = useState<number>(10);
    const [totalPage6, setTotalPage6] = useState<number>(0);

    //tab = 7 巡检计划
    const [projectDataSource, setProjectDataSource] = useState<any[]>([]);
    const [total7, setTotal7] = useState<number>(0);
    const [currentPage7, setCurrentPage7] = useState<number>(1);
    const [pageSize7, setPageSize7] = useState<number>(10);
    const [totalPage7, setTotalPage7] = useState<number>(0);

    //tab = 71 巡检任务
    const [projectDataSource1, setProjectDataSource1] = useState<any[]>([]);
    const [total71, setTotal71] = useState<number>(0);
    const [currentPage71, setCurrentPage71] = useState<number>(1);
    const [pageSize71, setPageSize71] = useState<number>(10);
    const [totalPage71, setTotalPage71] = useState<number>(0);

    //tab = 8 点检计划
    const [taskDataSource, setTaskDataSource] = useState<any[]>([]);
    const [total8, setTotal8] = useState<number>(0);
    const [currentPage8, setCurrentPage8] = useState<number>(1);
    const [pageSize8, setPageSize8] = useState<number>(10);
    const [totalPage8, setTotalPage8] = useState<number>(0);

    //tab = 81 点检任务
    const [taskDataSource1, setTaskDataSource1] = useState<any[]>([]);
    const [total81, setTotal81] = useState<number>(0);
    const [currentPage81, setCurrentPage81] = useState<number>(1);
    const [pageSize81, setPageSize81] = useState<number>(10);
    const [totalPage81, setTotalPage81] = useState<number>(0);

    //选择巡检项目还是任务
    const changePatrol = () => {
        setPatrolDefault(!patrolDefault)
        setLoading(true);
        if (!patrolDefault) {
            //    巡检计划
            getTab7()
        } else {
            getTab71()
        }
    }
    //选择点检项目还是任务
    const changePoint = () => {
        setPointDefault(!pointDefault)
        setLoading(true);
        if (!pointDefault) {
            //    点检计划
            getTab8()
        } else {
            getTab81()
        }
    }

    const tabOnChange4 = (page: number, pageSize: number) => {
        setCurrentPage4(page);
        setPageSize4(pageSize);
        getTab4(page, pageSize)
    }

    const tabOnChange5 = (page: number, pageSize: number) => {
        setCurrentPage5(page);
        setPageSize5(pageSize);
        getTab5(page, pageSize)
    }
    const tabOnChange6 = (page: number, pageSize: number) => {
        setCurrentPage6(page);
        setPageSize6(pageSize);
        getTab6(page, pageSize)
    }

    const tabOnChange7 = (page: number, pageSize: number) => {
        setCurrentPage7(page);
        setPageSize7(pageSize);
        getTab7(page, pageSize)
    }

    const tabOnChange71 = (page: number, pageSize: number) => {
        setCurrentPage71(page);
        setPageSize71(pageSize);
        getTab71(page, pageSize)
    }

    const tabOnChange8 = (page: number, pageSize: number) => {
        setCurrentPage8(page);
        setPageSize8(pageSize);
        getTab8(page, pageSize)
    }

    const tabOnChange81 = (page: number, pageSize: number) => {
        setCurrentPage81(page);
        setPageSize81(pageSize);
        getTab81(page, pageSize)
    }

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: `设备信息`,
            children:
                <div>
                    <Descriptions column={2}>
                        <Descriptions.Item label="设备代码">{item.code ? item.code : ''}</Descriptions.Item>
                        <Descriptions.Item label="设备名称">{item.device_name ? item.device_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="规格型号">{item.device_specification ? item.device_specification : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="设备类型">{item.device_type_name ? item.device_type_name : ''}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1}>
                        <Descriptions.Item
                            label="存放位置">{item.position_name ? item.position_name : ''}</Descriptions.Item>
                        <Descriptions.Item label="备注">{item.remarks ? item.remarks : ''}</Descriptions.Item>
                    </Descriptions>
                </div>
        },
        {
            key: '2',
            label: `使用情况`,
            children: <Descriptions column={2}>
                <Descriptions.Item
                    label="当前状态">{item.status ? (item.status === 10 ? '正常运行' : '故障') : ''}</Descriptions.Item>
                <Descriptions.Item
                    label="使用部门">{item.organization_name ? item.organization_name : ''}</Descriptions.Item>
                <Descriptions.Item label="负责人">{item.head_user_name ? item.head_user_name : ''}</Descriptions.Item>
                <Descriptions.Item label="联系方式">{item.contact ? item.contact : ''}</Descriptions.Item>
                <Descriptions.Item
                    label="启用日期">{item.activation_date ? moment(item.activation_date).format('YYYY/MM/DD') : ''}</Descriptions.Item>
            </Descriptions>,
        },
        {
            key: '3',
            label: `设备图片`,
            children:
                <div>
                    <div className={'pictures-total'}>已上传{fileList.length}张图片</div>
                    <Upload className={'view-pictures'} disabled onPreview={handlePreview} listType="picture-card"
                            fileList={fileList}/>
                </div>,
        },
        {
            key: '4',
            label: `关联文档`,
            children:
                <div className={'mes-tableBar'}>
                    <TableBar columns={columns} dataSource={dataSource} loading={loading} onChange={tabOnChange4}
                              total={total4} currentPage={currentPage4} pageSize={pageSize4} totalPage={totalPage4}
                              showSelection={false}/>
                </div>
        },
        {
            key: '5',
            label: `报修记录`,
            children:
                <div className={'mes-tableBar'}>
                    <TableBar columns={protectColumns} dataSource={protectDataSource} loading={loading}
                              onChange={tabOnChange5}
                              total={total5} currentPage={currentPage5} pageSize={pageSize5} totalPage={totalPage5}
                              showSelection={false} scrollValue={{x: 1400}}/>
                </div>
        },
        {
            key: '6',
            label: `维修记录`,
            children:
                <div className={'mes-tableBar'}>
                    <TableBar columns={maintainColumns} dataSource={maintainDataSource} loading={loading}
                              onChange={tabOnChange6}
                              total={total6} currentPage={currentPage6} pageSize={pageSize6} totalPage={totalPage6}
                              showSelection={false} scrollValue={{x: 2000}}/>
                </div>
        },
        {
            key: '7',
            label: `巡检`,
            children: <div>
                {/*title*/}
                <div className={'view-patrol'}>
                    <Button type={patrolDefault ? 'primary' : 'text'}
                            className={'view-patrol-button'}
                            style={{marginRight: 20}}
                            onClick={() => changePatrol()}>巡检计划</Button>
                    <Button type={patrolDefault ? 'text' : 'primary'}
                            className={'view-patrol-button'}
                            onClick={() => changePatrol()}>巡检任务</Button>
                </div>
                {/*table*/}
                <div>
                    {
                        patrolDefault ? <div className={'mes-tableBar'}>
                            <TableBar columns={projectColumns} dataSource={projectDataSource} loading={loading}
                                      onChange={tabOnChange7}
                                      total={total7} currentPage={currentPage7} pageSize={pageSize7}
                                      totalPage={totalPage7}
                                      showSelection={false}/>
                        </div> : <div className={'mes-tableBar'}>
                            <TableBar columns={taskColumns} dataSource={projectDataSource1} loading={loading}
                                      onChange={tabOnChange71}
                                      total={total71} currentPage={currentPage71} pageSize={pageSize71}
                                      totalPage={totalPage71}
                                      showSelection={false}
                                      scrollValue={{x: 2000}}/>
                        </div>

                    }
                </div>
            </div>,
        },
        {
            key: '8',
            label: `点检`,
            children: <div>
                {/*title*/}
                <div className={'view-patrol'}>
                    <Button type={pointDefault ? 'primary' : 'text'}
                            className={'view-patrol-button'}
                            style={{marginRight: 20}}
                            onClick={() => changePoint()}>点检计划</Button>
                    <Button type={pointDefault ? 'text' : 'primary'}
                            className={'view-patrol-button'}
                            onClick={() => changePoint()}>点检任务</Button>
                </div>
                {/*table*/}
                <div>
                    {
                        pointDefault ? <div className={'mes-tableBar'}>
                            <TableBar columns={projectColumns} dataSource={taskDataSource} loading={loading}
                                      onChange={tabOnChange8}
                                      total={total8} currentPage={currentPage8} pageSize={pageSize8}
                                      totalPage={totalPage8}
                                      showSelection={false}/>
                        </div> : <div className={'mes-tableBar'}>
                            <TableBar columns={taskColumns} dataSource={taskDataSource1} loading={loading}
                                      onChange={tabOnChange81}
                                      total={total81} currentPage={currentPage81} pageSize={pageSize81}
                                      totalPage={totalPage81}
                                      showSelection={false}
                                      scrollValue={{x: 2000}}/>
                        </div>
                    }
                </div>
            </div>,
        },
    ];

    //    关联文档
    const getTab4 = (page?: number, size?: number) => {
        getDeviceInfoRelatedDoc({
            object_id: item.object_id,
            page_num: page ? page : currentPage4,
            page_size: size ? size : pageSize4
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setDataSource(res.data.data);
                setTotal4(res.data.total);
                setTotalPage4(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    //    报修记录
    const getTab5 = (page?: number, size?: number) => {
        getDeviceInfoRepair({
            device_id: item.object_id,
            page_num: page ? page : currentPage5,
            page_size: size ? size : pageSize5
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setProtectDataSource(res.data.data);
                setTotal5(res.data.total);
                setTotalPage5(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    //    维修记录
    const getTab6 = (page?: number, size?: number) => {
        getDeviceInfoMaintain({
            device_id: item.object_id,
            page_num: page ? page : currentPage6,
            page_size: size ? size : pageSize6
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setMaintainDataSource(res.data.data);
                setTotal6(res.data.total);
                setTotalPage6(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    //    巡检计划
    const getTab7 = (page?: number, size?: number) => {
        getDeviceInfoPatrolPlan({
            device_id: item.object_id,
            page_num: page ? page : currentPage7,
            page_size: size ? size : pageSize7
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setProjectDataSource(res.data.data);
                setTotal7(res.data.total);
                setTotalPage7(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    //    巡检任务
    const getTab71 = (page?: number, size?: number) => {
        getDeviceInfoPatrolTask({
            device_id: item.object_id,
            page_num: page ? page : currentPage71,
            page_size: size ? size : pageSize71
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setProjectDataSource1(res.data.data);
                setTotal71(res.data.total);
                setTotalPage71(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    //    点检计划
    const getTab8 = (page?: number, size?: number) => {
        getDeviceInfoPointPlan({
            device_id: item.object_id,
            page_num: page ? page : currentPage8,
            page_size: size ? size : pageSize8
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setTaskDataSource(res.data.data);
                setTotal8(res.data.total);
                setTotalPage8(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    //    点检任务
    const getTab81 = (page?: number, size?: number) => {
        getDeviceInfoPointTask({
            device_id: item.object_id,
            page_num: page ? page : currentPage81,
            page_size: size ? size : pageSize81
        }).then(res => {
            if (res.code === 200) {
                setLoading(false);
                setTaskDataSource1(res.data.data);
                setTotal81(res.data.total);
                setTotalPage81(res.data.pages);
            } else {
                showError(res.message)
            }
        })
    }

    const onChange = (key: string) => {
        setLoading(true);
        if (key === '4') {
            getTab4()
        } else if (key === '5') {
            getTab5()
        } else if (key === '6') {
            getTab6()
        } else if (key === '7') {
            setIsPatrol(true);
            setPatrolDefault(true);
            getTab7()
        } else if (key === '8') {
            setIsPatrol(false);
            setPointDefault(true);
            getTab8()
        }
    };

    useEffect(() => {
        const temp: UploadFile[] = [];
        item.image_device_info.map((e: any) => {
            temp.push({
                uid: e.file.object_id,
                name: e.file.name,
                url: e.file.url,
            })
        })
        setFileList(temp);
    }, [])

    return (
        <div className={'view-device'}>
            <div className={'files-flex'}>
                <Descriptions style={{width: '80%'}} column={2}>
                    <Descriptions.Item
                        label="创建人">{item.create_user_name ? item.create_user_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="更新人">{item.update_user_name ? item.update_user_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="创建时间">{item.create_time ? moment(item.create_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="更新时间">{item.update_time ? moment(item.update_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                </Descriptions>
                <div style={{width: '20%'}}>
                    <img
                        src={item.status === 10 ? require('../../../assets/device-status-normal.png') : require('../../../assets/device-status-fault.png')}
                        alt=""/>
                </div>
            </div>
            <Divider/>
            <Tabs centered defaultActiveKey="1" items={tabItems} onChange={onChange} tabBarGutter={80}/>
            {/*预览图片*/}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="加载图片失败" style={{width: '100%'}} src={previewImage}/>
            </Modal>
            {/*预览文档*/}
            <Modal open={previewFileOpen} title={previewFileTitle} footer={null} width={'80%'}
                   onCancel={handleCancelFile} children={<div style={{width:'100%',height: '900px'}}>{previewChildren}</div>}/>
        </div>
    )
}

export default ViewDevice;
