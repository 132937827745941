import React from 'react'
import {Button, Modal, Select} from "antd";
import {toastMessage} from "../../../utils";
import {getStorageList} from "../../../service";

/**
 * 选择盘点仓库对话框
 */
const {Option} = Select;

interface Type {
    houseId: undefined | number,
    house: any,
    houseList: Array<any>,
}

export default class ChooseHouseModal extends React.Component<{
    closeClick: () => void,
    confirmClick: (house: any) => void,
}> {

    state: Type = {
        houseId: undefined,
        house: undefined,
        houseList: [],
    }

    componentDidMount() {
        // 获取仓库列表
        getStorageList().then(res => {
            if (res.code === 200) {
                this.setState({
                    houseList: res.data.data.filter((item: any) => item.is_valid === true)
                })
            }
        })
    }

    render() {
        const {closeClick, confirmClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={<div style={{fontSize: 16, fontWeight: "bold"}}>
                选择盘点仓库
            </div>}
            footer={null}
            width={688}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{color: "red"}}>
                        *
                    </div>
                    <div>
                        仓库名称
                    </div>
                </div>
                <Select
                    className={'chooseModalSelectStyle'}
                    style={{
                        borderRadius: 5,
                        marginLeft: 10,
                        width: 560,
                        height: 38,
                    }}
                    value={this.state.houseId}
                    placeholder="请选择盘点仓库"
                    onChange={(value) => {
                        this.setState({
                            houseId: value,
                            house: this.state.houseList.find((item) => item.object_id === value)
                        })
                    }}
                >
                    {
                        this.state.houseList.length > 0 && this.state.houseList.map((item: any, index: number) => {
                            return <Option key={index} value={item.object_id}>
                                {item.storage_name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <div style={{display: "flex", justifyContent: "center", marginTop: 50}}>
                <Button style={{width: 130, height: 48, backgroundColor: '#B8B8B8', borderRadius: 5, color: '#ffffff'}}
                        htmlType="button"
                        onClick={() => {
                            closeClick && closeClick()
                        }}
                >
                    取消
                </Button>
                <Button style={{
                    marginLeft: 30,
                    width: 130,
                    backgroundColor: '#3E7BFA',
                    height: 48,
                    borderRadius: 5,
                    color: '#ffffff'
                }}
                        htmlType="submit"
                        onClick={() => {
                            if (this.state.houseId === undefined) {
                                toastMessage('请选择盘点仓库')
                                return;
                            }
                            confirmClick && confirmClick(this.state.house)
                        }}
                >
                    确定
                </Button>
            </div>
        </Modal>
    }
}
