import type {TabsProps} from 'antd';
import {Descriptions, Divider, Modal, Table, Tabs, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/es/upload";
import {convertSecToHHmm, faultStatus, getBase64, troubleLevel} from "../../../utils";
import {ColumnsType} from "antd/es/table";
import moment from "moment";

interface ViewItemProps {
    item: any
}

const ViewItem = (props: ViewItemProps) => {
    const {item} = props;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    //故障图片
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    //维修图片
    const [reportFileList, setReportFileList] = useState<UploadFile[]>([]);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };


    const columns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'workload_user_name',
            title: '姓名',
            dataIndex: 'workload_user_name',
            align: 'center',
            width: 200,
        },
        {
            key: 'repair_start_time',
            title: '维修开始时间',
            dataIndex: 'repair_start_time',
            align: 'center',
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'repair_end_time',
            title: '维修完成时间',
            dataIndex: 'repair_end_time',
            align: 'center',
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'repair_time',
            title: '维修用时',
            dataIndex: 'repair_time',
            align: 'center',
            width: 200,
            render: (value: number) => <div
                style={{textAlign: 'right'}}>{value ? `${convertSecToHHmm(value).hour}小时${convertSecToHHmm(value).minute}分钟` : ''}</div>
        },
        {
            key: 'repair_content',
            title: '维修内容',
            dataIndex: 'repair_content',
            align: 'center',
            width: 200,
        },
    ]

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: `故障报修信息`,
            children:
                <div>
                    <div className={'tabs-little-title'}>故障信息</div>
                    <Descriptions column={2}>
                        <Descriptions.Item
                            label="故障时间">{item.fault_info.fault_time ? moment(item.fault_info.fault_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="故障类型">{item.fault_info.fault_type_name ? item.fault_info.fault_type_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="是否停机">{item.fault_info.shutdown_or_not === 0 ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item
                            label="故障等级">{troubleLevel.map((e: any, i: number) => {
                            if (e.id === item.fault_info.fault_level) {
                                return <span key={i}>{e.name}</span>
                            }
                        })}</Descriptions.Item>
                        <Descriptions.Item
                            label="故障描述">{item.fault_info.fault_describe ? item.fault_info.fault_describe : ''}</Descriptions.Item>
                    </Descriptions>
                    <Divider/>
                    <div className={'tabs-little-title'}>报修信息</div>
                    <Descriptions column={2}>
                        <Descriptions.Item
                            label="报修单号">{item.fault_info.code ? item.fault_info.code : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="报修人">{item.fault_info.report_user_name ? item.fault_info.report_user_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="所属部门">{item.fault_info.report_user_organization_name ? item.fault_info.report_user_organization_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="联系方式">{item.fault_info.report_user_phone ? item.fault_info.report_user_phone : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="报修时间">{item.fault_info.report_time ? moment(item.fault_info.report_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                    </Descriptions>
                </div>,
        },
        {
            key: '2',
            label: '维修信息',
            children: <div>
                <div className={'tabs-little-title'}>维修信息</div>
                <Descriptions column={2}>
                    <Descriptions.Item
                        label="维修单号">{item.fault_info.code ? item.fault_info.code : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="维修班组">{item.group_name ? item.group_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="维修负责人">{item.head_user_name ? item.head_user_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="其他维修人员">{item.member_user_name_arr.length ? item.member_user_name_arr.map((e: any, i: number) => {
                        if (i == item.member_user_name_arr.length - 1) {
                            return e.name
                        } else {
                            return e.name + '、'
                        }

                    }) : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="开始时间">{item.start_time ? moment(item.start_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="结束时间">{item.end_time ? moment(item.end_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="维修费用(元)">{item.maintenance_costs ? item.maintenance_costs : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="维修总用时">{item.maintenance_total_time ? `${convertSecToHHmm(item.maintenance_total_time).hour}小时${convertSecToHHmm(item.maintenance_total_time).minute}分钟` : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="维修过程">{item.maintenance_process ? item.maintenance_process : ''}</Descriptions.Item>
                </Descriptions>
                <Divider/>
                <div className={'tabs-little-title'}>工作量</div>
                <Table columns={columns} dataSource={item.repair_order_workload} pagination={false} rowKey={'object_id'}/>
            </div>
        },
        {
            key: '3',
            label: `相关图片`,
            children:
                <div>
                    <div className={'tabs-little-title'}>故障图片</div>
                    <div className={'pictures-total'} style={{marginLeft: 10}}>已上传{fileList.length}张图片</div>
                    <Upload
                        className={'view-pictures'}
                        disabled
                        onPreview={handlePreview}
                        listType="picture-card"
                        fileList={fileList}/>
                    <div className={'tabs-little-title'}>维修图片</div>
                    <div className={'pictures-total'} style={{marginLeft: 10}}>已上传{reportFileList.length}张图片</div>
                    <Upload
                        className={'view-pictures'}
                        disabled
                        onPreview={handlePreview}
                        listType="picture-card"
                        fileList={reportFileList}/>
                </div>,
        },
    ];

    useEffect(() => {
        const temp: UploadFile[] = [];
        item.fault_info.image_fault_report.map((e: any) => {
            temp.push({
                uid: e.file.object_id,
                name: e.file.name,
                url: e.file.url,
            })
        })
        setFileList(temp);
        const tempReport: UploadFile[] = [];
        item.image_repair_order.map((e: any) => {
            tempReport.push({
                uid: e.file.object_id,
                name: e.file.name,
                url: e.file.url,
            })
        })
        setReportFileList(tempReport)
    }, [])


    return (
        <div className={'view-device'}>
            <div className={'files-flex'}>
                <Descriptions style={{width: '80%'}} column={3}>
                    <Descriptions.Item
                        label="设备名称">{item.fault_info.device_name ? item.fault_info.device_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="设备代码">{item.fault_info.device_code ? item.fault_info.device_code : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="规格型号">{item.fault_info.device_specification ? item.fault_info.device_specification : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="设备类型">{item.fault_info.device_type_name ? item.fault_info.device_type_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="存放位置">{item.fault_info.device_position ? item.fault_info.device_position : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="使用部门">{item.fault_info.device_organization_name ? item.fault_info.device_organization_name : ''}</Descriptions.Item>
                </Descriptions>
                <div style={{width: '20%'}}>
                    {
                        faultStatus.map(e => {
                            if (e.id === item.status) {
                                return <img src={e.src} alt="暂无图片" key={e.id}/>
                            }
                        })
                    }
                </div>
            </div>
            <Divider/>
            <Tabs defaultActiveKey="1" items={tabItems} tabBarGutter={80}/>
            {/*预览图片*/}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="加载图片失败" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    )
}

export default ViewItem;
