import React from 'react'
import { Modal } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import {toastMessage} from "../../../../utils";

export default class AddModal extends React.Component<{
    closeClick: () => void,
    saveClick: (e: API.EditProductionLineItem) => void,
}> {
    state = {
        code: '',
        line_name: '',
        remark: ''
    }
    render() {
        const {
            code,
            line_name,
            remark
        } = this.state
        const { closeClick, saveClick } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'新增产线信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='代码' style={{ width: 281, marginRight: 10 }}
                    placeholder='不填写，系统将自动生成'
                    value={code}
                    onChange={(value) => {
                        if (value === '') {
                            this.setState({
                                code: value
                            })
                        } else {
                                if (/^[A-Za-z0-9]+$/.test(value)) {
                                    this.setState({
                                        code: value
                                    })
                                }
                        }
                    }}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1} title='名称' style={{ width: 281, marginRight: 20 }} placeholder='请输入产线名称' onChange={(value) => {
                    this.setState({
                        line_name: value
                    })
                }}
                value={line_name}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注'remark titlestyle={{ paddingLeft: 5, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入备注' onChange={(value) => {
                    this.setState({
                        remark: value
                    })
                }}
                    value={remark}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!line_name) {
                        toastMessage('产线名称不能为空')
                        return
                    }
                    let value: API.EditProductionLineItem = {
                        line_name: line_name
                    }
                    if (code) {
                        value.code = code
                    }
                    if (remark) {
                        value.remark = remark
                    }
                    saveClick(value)
                }} />
            </div>
        </Modal>
    }
}