import React from 'react'
import {Button} from "antd";
import Input from "antd/lib/input/Input";
import './index.css'

/**
 * 生产管理--生产任务--查询重置
 */
export default class SelectFormTask extends React.Component<{
    titles: string[],
    queryClick?: (sellNum: string, clientName: string, productionName: string) => void,
    newAdd?: () => void,
    output?: () => void,
}> {

    state = {
        sellNum: '',
        clientName: '',
        productionName: '',
    }

    render() {
        const {sellNum, clientName, productionName} = this.state
        const {titles} = this.props
        return <div className={'selectFormTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    {titles && titles[0]}
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={sellNum}
                    placeholder={`请输入${titles && titles[0]}`}
                    onChange={(e) => {
                        this.setState({
                            sellNum: e.target.value
                        })
                    }}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    {titles && titles[1]}
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={clientName}
                    placeholder={`请输入${titles && titles[1]}`}
                    onChange={(e) => {
                        this.setState({
                            clientName: e.target.value
                        })
                    }}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    {titles && titles[2]}
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={productionName}
                    placeholder={`请输入${titles && titles[2]}`}
                    onChange={(e) => {
                        this.setState({
                            productionName: e.target.value
                        })
                    }}
                />
            </div>

            <Button
                className={'buttonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(sellNum, clientName, productionName)
                }}
            >
                查询
            </Button>

            <Button
                className={'buttonReset'}
                type={"primary"}
                onClick={() => {
                    this.setState({
                        sellNum: '',
                        clientName: '',
                        productionName: '',
                    }, () => {
                        this.props.queryClick && this.props.queryClick('', '', '')
                    })
                }}
            >
                重置
            </Button>
            <div style={{display: "flex", flex: 1}}/>
            <Button
                className={'buttonAdd'}
                type={"primary"}
                onClick={() => {
                    this.props.newAdd && this.props.newAdd()
                }}
            >
                新增
            </Button>

            <Button
                className={'buttonOutput'}
                type={"primary"}
                onClick={() => {
                    this.props.output && this.props.output()
                }}
            >
                导出
            </Button>
        </div>
    }
}
