import React, {useEffect, useRef, useState} from "react";
import GeneralManagement, {QueryListItem} from "../../components/GeneralManagement";
import {Button, Col, DatePicker, Divider, Form, Input, message, Row, Space} from "antd";
import ModalContainer from "../../components/Modal";
import {
    createRepairOrderComplete,
    createRepairOrderStart,
    getRepairOrderById,
    getRepairOrderListReport
} from "../../service";
import {
    convertHHmmToSec,
    convertMinToHHmm,
    convertSecToHHmm,
    filterData, filterDateParam,
    formatNumber,
    getFormData,
    isHalt,
    showError,
    troubleLevel,
    workOrderStatus
} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';
import {UploadFile} from "antd/es/upload/interface";
import moment from "moment";
import {InputNumber} from "antd/es";
import EditOrDragTable, {TRef} from "../../components/EditOrDragTable";
import ModalInner from "./components/ModalInner";
import ViewItem from "./components/ViewItem";
import UploadPictures from "../../components/UploadPictures";
import {useWatch} from "antd/es/form/Form";

enum ModalTitle {
    REPORT = '维修工单报工',
    VIEW = '查看维修工单',
}

export interface GRef {
    onFinish: () => void;
}

interface CostComponentProps {
    index: number
}

const RepairReport = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const [repairFileList, setRepairFileList] = useState<UploadFile[]>([]);
    const formList: QueryListItem[] = [
        {
            key: 'device_name',
            name: '设备名称',
            placeholder: '请输入设备名称',
        },
        {
            key: 'status',
            name: '工单状态',
            placeholder: '请选择工单状态',
            options: workOrderStatus,
            multiple: true,
            defaultValue: [20]
        },
        {
            key: 'report_date',
            name: '提报时间',
            placeholder: '请选择日期范围',
            rangePick: true
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            fixed: true,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '维修单号',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true,
            width: 180,
            fixed: true,
            render: (value: string, item: any) => {
                return <span className={'mes-options'} onClick={() => {
                    openModal(ModalTitle.VIEW, item)
                }}>{value}</span>
            }
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            key: 'device_code',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'fault_time',
            title: '故障时间',
            dataIndex: 'fault_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'fault_name',
            title: '故障类型',
            dataIndex: 'fault_name',
            align: 'center',
            width: 100,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'shutdown_or_not',
            title: '是否停机',
            dataIndex: 'shutdown_or_not',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return isHalt.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_level',
            title: '故障等级',
            dataIndex: 'fault_level',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return troubleLevel.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '提报时间',
            dataIndex: 'report_time',
            key: 'report_time',
            align: 'center',
            width: 250,
            ellipsis: true, render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'status',
            title: '工单状态',
            dataIndex: 'status',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return workOrderStatus.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i} style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'team_groups',
            title: '维修班组',
            dataIndex: 'team_groups',
            align: 'center',
            width: 100
        },
        {
            key: 'head_user_name',
            title: '维修负责人',
            dataIndex: 'head_user_name',
            align: 'center',
            width: 200
        },
        {
            title: '开始时间',
            dataIndex: 'start_time',
            key: 'start_time',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '结束时间',
            dataIndex: 'end_time',
            key: 'end_time',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            width: 100,
            render: (_: any, item: any) => {
                if (item.status === 20) {
                    return (
                        <span className={'mes-options'} onClick={() => {
                            handleStart(item.object_id)
                        }}>开始</span>
                    )
                } else if (item.status === 30) {
                    return (
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.REPORT, item)
                        }}>完成</span>
                    )
                }
            }
        },
    ]

    const [tableForm] = Form.useForm();
    const tRef = React.useRef<TRef>(null);

    const detailColumns: ColumnsType<any> = [
        {
            title: "NO",
            dataIndex: "NO",
            key: "NO",
            width: 60,
            ellipsis: true,
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        //不显示该项，width为0
        {
            title: 'id',
            dataIndex: 'user_id',
            key: 'user_id',
            align: 'center',
            ellipsis: true,
            width: 0,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'user_id']}>
                    <Input placeholder={'请输入ID'} disabled/>
                </Form.Item>
        },
        {
            title: '姓名',
            dataIndex: 'user_name',
            key: 'user_name',
            align: 'center',
            width: 100,
            ellipsis: true,
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>维修开始时间</span>,
            dataIndex: 'repair_start_time',
            key: 'repair_start_time',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'repair_start_time']} rules={[
                    {required: true, message: '请选择维修开始时间！'},
                    ({getFieldValue}) => ({
                        validator: (_, value) => {
                            if (value) {
                                if (getFieldValue([`${index}`, 'repair_end_time'])) {
                                    const end = getFieldValue([`${index}`, 'repair_end_time']).seconds(0).milliseconds(0)
                                    const start = value.seconds(0).milliseconds(0)
                                    const result = moment(end).diff(start, 'minutes')
                                    if (result > 0) {
                                        //    开始时间大于结束时间
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('开始时间必须小于结束时间！')
                                    }
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    })
                ]}>
                    <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm" allowClear={false}/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>维修结束时间</span>,
            dataIndex: 'repair_end_time',
            key: 'repair_end_time',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'repair_end_time']} rules={[
                    {required: true, message: '请选择维修结束时间！'},
                    ({getFieldValue}) => ({
                        validator: (_, value) => {
                            if (value) {
                                if (getFieldValue([`${index}`, 'repair_start_time'])) {
                                    const result = moment((value.seconds(0).milliseconds(0))).diff((getFieldValue([`${index}`, 'repair_start_time']).seconds(0).milliseconds(0)), 'minutes')
                                    if (result > 0) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('结束时间必须大于开始时间！')
                                    }
                                }
                            }

                        }
                    })
                ]}>
                    <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm" allowClear={false}/>
                </Form.Item>
        },
        {
            title: '维修用时',
            dataIndex: 'repair_time',
            key: 'repair_time',
            align: 'center',
            width: 200,
            ellipsis: true,
            render: (value: any, item: any, index: number) => <CostComponent index={index}/>
        },
        {
            title: '维修内容',
            dataIndex: 'repair_content',
            key: 'repair_content',
            align: 'center',
            ellipsis: true,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'repair_content']} rules={[{
                    max: 200,
                    message: '维修内容不得大于200位！'
                }]}>
                    <Input.TextArea placeholder={'请输入维修过程'}>
                    </Input.TextArea>
                </Form.Item>
        },
    ]

    const handleStart = (id: number) => {
        const formData = getFormData(filterData({object_id: id}))
        createRepairOrderStart(formData).then(res => {
            if (res.code === 200) {
                afterOnOK('操作成功！');
            } else {
                showError(res.message)
            }
        })
    }

    //维修用时
    const CostComponent = React.memo((props: CostComponentProps) => {
        const start = useWatch([`${props.index}`, 'repair_start_time'], tableForm);
        const end = useWatch([`${props.index}`, 'repair_end_time'], tableForm);

        useEffect(() => {
            if (start && end) {
                let tempEnd = end.seconds(0).milliseconds(0);
                let tempStart = start.seconds(0).milliseconds(0);
                const result = tempEnd.diff(tempStart, 'minutes')
                const cost = convertMinToHHmm(result);
                tableForm.setFieldValue([`${props.index}`, 'costHour'], cost.hour);
                tableForm.setFieldValue([`${props.index}`, 'costMinute'], cost.minute);
                let allCostHour = 0;
                let allCostMinute = 0;
                Object.values(tableForm.getFieldsValue()).map((e: any) => {
                    allCostMinute += Number(e.costMinute ? e.costMinute : 0);
                    allCostHour += Number(e.costHour ? e.costHour : 0);
                })
                let allCost = convertMinToHHmm(allCostHour * 60 + allCostMinute);
                form.setFieldValue('hour', allCost.hour)
                form.setFieldValue('minute', allCost.minute)
            }
        }, [start, end])

        return <Space size={2}>
            <Form.Item name={[`${props.index}`, 'costHour']} style={{textAlign: 'left'}} initialValue={0}>
                <InputNumber disabled/>
            </Form.Item>
            小时
            <Form.Item name={[`${props.index}`, 'costMinute']} style={{textAlign: 'left'}} initialValue={0}>
                <InputNumber disabled/>
            </Form.Item>
            分钟
        </Space>
    })

    const ModalReport = (props: any) => {
        const {initialDeviceName, initialDeviceId, initialDataSource, repairFileList} = props;
        const [loading, setLoading] = useState<boolean>();
        const [repairSelectedRows, setRepairSelectedRows] = useState<any[]>([]);
        const [repairSelectedRowKeys, setRepairSelectedRowKeys] = useState<any[]>([]);
        const [repairOpen, setRepairOpen] = useState<boolean>(false);
        const [detailDataSource, setDetailDataSource] = useState<any[]>([]);

        const selectRepairItem = () => {
            if (repairSelectedRows.length) {
                form.resetFields(['maintenance_process'])
                form.setFieldValue('maintenance_process', repairSelectedRows[0].maintenance_describe)
                setRepairOpen(false);
                setRepairSelectedRowKeys([]);
                setRepairSelectedRows([]);
            }
            cancelRepairItem()
        }

        const cancelRepairItem = () => {
            setRepairOpen(false);
            setRepairSelectedRowKeys([]);
            setRepairSelectedRows([]);
        }

        useEffect(() => {
            //设置维修工作量列表
            setLoading(true)
            if (initialDataSource) {
                setDetailDataSource(initialDataSource)
                setLoading(false)
            }
        }, [])

        return (<div className={'viewItem'}>
            <Form colon={false} form={form} className={'form-margin-long'}>
                <div className={'viewTitle'}>维修信息</div>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'维修单号'} name={'code'}>
                            <Input placeholder={'不填写，系统将自动生成'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备名称'} name={'device_id'}>
                            <Input placeholder={'请选择设备名称'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备代码'} name={'link_device_id'}>
                            <Input placeholder={'请输入设备代码'} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'维修班组'} name={'group_name'}>
                            <Input placeholder={'请选择维修班组'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'维修负责人'} name={'head_user_name'}>
                            <Input placeholder={'请选择维修负责人'} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'其他维修人员'} name={'member_user_name_arr'}>
                            <Input.TextArea placeholder={'请输入其他维修人员'} disabled>
                            </Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                <div className={'viewTitle'}>维修过程</div>
                <Row>
                    <Col span={8}>
                        <Form.Item label={<div><span className={'requiredIcon'}>*</span><span>维修费用</span></div>}>
                            <Space>
                                <Form.Item noStyle name={'maintenance_costs'} rules={[
                                    {required: true, message: '请输入维修费用!'}]}>
                                    <InputNumber placeholder={'请输入维修费用'} min={0}
                                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                                </Form.Item>
                                <div>元</div>
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'维修总用时'}>
                            <Space>
                                <Form.Item noStyle name={'hour'} initialValue={0}>
                                    <InputNumber disabled/>
                                </Form.Item>
                                <div style={{width: 30}}>小时</div>
                                <Form.Item noStyle name={'minute'} initialValue={0}>
                                    <InputNumber disabled/>
                                </Form.Item>
                                <div style={{width: 30}}>分钟</div>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'维修过程'} name={'maintenance_process'}
                                   rules={[{required: true, message: '请输入维修过程!'}, {
                                       max: 200,
                                       message: '维修过程不得大于200位！'
                                   }]}>
                            <Input.TextArea placeholder={'请输入维修过程'} className={'textarea-padding-24'} autoSize={{minRows:3}}>
                            </Input.TextArea>
                        </Form.Item>
                        <div className={'textArea-suffix'} onClick={() => {
                            setRepairOpen(true);
                        }}>
                            <img src={require('../../assets/repair-library-icon.png')} alt="暂无图片"
                                 style={{marginRight: 5}}/>
                            <span className={'trouble-library-text'}>维修经验</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'维修工作量'} name={'remarks2'}>
                            <EditOrDragTable tableForm={tableForm} detailColumns={detailColumns} tableLoading={loading}
                                             detailDataSource={detailDataSource} isNoneAdd={true}
                                             title={``} ref={tRef} scroll={false}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                <div className={'viewTitle'}>维修图片</div>
                <UploadPictures fileList={repairFileList} setFileList={setRepairFileList}/>
            </Form>
            {/*    故障经验弹窗*/}
            <ModalContainer title={'选择维修经验'} open={repairOpen} width={'1071px'}
                            onCancel={() => cancelRepairItem()} okText={'确定'}
                            footer={<Button type={'primary'} onClick={() => selectRepairItem()}>确定</Button>}
                            innerComponent={<ModalInner setSelectedRows={setRepairSelectedRows}
                                                        setSelectedRowKeys={setRepairSelectedRowKeys}
                                                        selectedRowKeys={repairSelectedRowKeys}
                                                        deviceId={initialDeviceId}
                                                        deviceCode={form.getFieldValue('link_device_id')}
                                                        deviceName={initialDeviceName}
                                                        type={'repair'}/>}/>
        </div>)
    }

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        if (title === ModalTitle.REPORT) {
            setModalTitle(title);
            getRepairOrderById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.fault_info.code,
                        device_id: res.data.fault_info.device_name,
                        link_device_id: res.data.fault_info.device_code,
                        fault_time: res.data.fault_info.fault_time ? moment(res.data.fault_info.fault_time) : '',
                        fault_type_id: res.data.fault_info.fault_type_name,
                        shutdown_or_not: res.data.fault_info.shutdown_or_not,
                        fault_level: res.data.fault_info.fault_level,
                        fault_describe: res.data.fault_info.fault_describe,
                        team_groups_id: res.data.team_groups_id,
                        head_user_id: res.data.head_user_id,
                        group_name: res.data.group_name,
                        head_user_name: res.data.head_user_name,
                        member_user_name_arr: res.data.member_user_name_arr ? res.data.member_user_name_arr.map((e: any) => {
                            return e.name
                        }) : '',
                        member_user_id: res.data.member_user_id,
                        maintenance_costs: res.data.maintenance_costs,
                        hour: convertSecToHHmm(res.data.maintenance_total_time).hour,
                        minute: convertSecToHHmm(res.data.maintenance_total_time).minute,
                        maintenance_process: res.data.maintenance_process,
                    })
                    //拼接所有成员数组，负责人位于第一个
                    const headList = [{name: res.data.head_user_name, object_id: res.data.head_user_id}]
                    const memberList = res.data.member_user_name_arr
                    const initialList = [...headList, ...memberList]
                    let initialDataSource: any = [];
                    initialList.map((e: any, i: number) => {
                        initialDataSource.push({
                            index: i,
                            user_id: e.object_id,
                            user_name: e.name,
                            repair_start_time: '',
                            repair_end_time: '',
                            repair_time: '',
                            repair_content: '',
                        })
                    })
                    tableForm.setFieldsValue({...initialDataSource})
                    let repairTemp: any[] = [];
                    res.data.image_repair_order.map((e: any) => {
                        repairTemp.push({
                            uid: e.file.object_id,
                            name: e.file.name,
                            url: e.file.url,
                        })
                    })
                    setInnerComponent(<ModalReport initialDataSource={initialDataSource}
                                                   initialDeviceName={res.data.fault_info.device_name}
                                                   initialDeviceId={res.data.fault_info.device_id}
                                                   repairFileList={repairTemp}/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.VIEW) {
            const ViewDeviceTitle = (): React.ReactElement => {
                return (<div>
                    <img src={require('../../assets/view-repair-icon.png')} alt='暂无图片' style={{marginRight: 10}}/>
                    <span>{title}</span>
                </div>)
            }
            setModalTitle(ViewDeviceTitle as any);
            getRepairOrderById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<ViewItem item={res.data}/>)
                } else {
                    showError(res.message)
                }
            })
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.REPORT) {
            form.validateFields().then(values => {
                tableForm.validateFields().then(values2 => {
                    let tempTable: any[] = []
                    Object.values(values2).map((e: any) => {
                        tempTable.push({
                            user_id: e.user_id,
                            repair_start_time: e.repair_start_time.format('YYYY-MM-DD HH:mm'),
                            repair_end_time: e.repair_end_time.format('YYYY-MM-DD HH:mm'),
                            repair_time: convertHHmmToSec(e.costHour, e.costMinute),
                            repair_content: e.repair_content,
                        })
                    })
                    tempTable.map((e: any) => {
                        filterData(e)
                    })
                    //处理图片
                    const tempPics2 = Object.values(repairFileList).map((e) => {
                        return {file_id: e.uid}
                    })
                    const temp = {
                        object_id: selectedItem.object_id,
                        maintenance_total_time: convertHHmmToSec(values.hour, values.minute),
                        maintenance_costs: values.maintenance_costs,
                        maintenance_process: values.maintenance_process,
                        repair_images: tempPics2,
                        repair_workloads: JSON.stringify(tempTable)
                    }
                    const formData = new FormData();
                    Object.entries(filterData(temp)).map(e => {
                        if (e[0] == "repair_images") {
                            formData.append(e[0], JSON.stringify(e[1]))
                        } else {
                            formData.append(e[0], e[1] as any)
                        }
                    })
                    createRepairOrderComplete(formData).then(res => {
                        if (res.code === 200) {
                            afterOnOK('新增成功！');
                        } else {
                            showError(res.message)
                        }
                    })
                })
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.REPORT) {
            form.resetFields();
            tableForm.resetFields();
            setRepairFileList([]);
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getRepairOrderListReport(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }


    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const getFooter = (): React.ReactNode => {
        if(typeof modalTitle === 'object'){
            return null
        }
    }

    const filterQueryData = (queryData: any) => {
        if (queryData.report_date) {
            queryData = filterDateParam(queryData, 'report_date', 'report_date_start', 'report_date_end')
        }
        if (queryData.status.length) {
            queryData.status = JSON.stringify(queryData.status)
        }
        return queryData;
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ref={gRef} scroll={true} filterQueryData={filterQueryData}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={'保存'} width={'1071px'} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default RepairReport


