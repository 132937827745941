import type {TabsProps} from 'antd';
import {Descriptions, Divider, Modal, Tabs, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/es/upload";
import {faultStatus, getBase64, troubleLevel} from "../../../utils";
import moment from "moment";

interface ViewItemProps {
    item: any
}

const ViewItem = (props: ViewItemProps) => {
    const {item} = props;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: `故障报修信息`,
            children:
                <div>
                    <div className={'tabs-little-title'}>故障信息</div>
                    <Descriptions column={2}>
                        <Descriptions.Item
                            label="故障时间">{item.fault_time ? moment(item.fault_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="故障类型">{item.fault_type_name ? item.fault_type_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="是否停机">{item.shutdown_or_not === 0 ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item
                            label="故障等级">{troubleLevel.map(e => {
                            if (e.id === item.fault_level) {
                                return e.name
                            }
                        })}</Descriptions.Item>
                        <Descriptions.Item
                            label="故障描述">{item.fault_describe ? item.fault_describe : ''}</Descriptions.Item>
                    </Descriptions>
                    <Divider/>
                    <div className={'tabs-little-title'}>报修信息</div>
                    <Descriptions column={2}>
                        <Descriptions.Item label="报修单号">{item.code ? item.code : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="报修人">{item.report_user_name ? item.report_user_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="所属部门">{item.report_user_organization_name ? item.report_user_organization_name : ''}</Descriptions.Item>
                        <Descriptions.Item
                            label="联系方式">{item.report_user_phone ? item.report_user_phone : ''}</Descriptions.Item>
                        <Descriptions.Item label="报修时间">{item.report_time ? moment(item.report_time).format('YYYY/MM/DD HH:mm') : ''}</Descriptions.Item>
                    </Descriptions>
                </div>,
        },
        {
            key: '2',
            label: `故障图片`,
            children:
                <div>
                    <div className={'tabs-little-title'}>故障图片</div>
                    <div className={'pictures-total'} style={{marginLeft: 10}}>已上传{fileList.length}张图片</div>
                    <Upload
                        className={'view-pictures'}
                        disabled
                        onPreview={handlePreview}
                        listType="picture-card"
                        fileList={fileList}/>
                </div>,
        },
    ];

    useEffect(() => {
        const temp: UploadFile[] = [];
        item.image_fault_report.map((e: any) => {
            temp.push({
                uid: e.file.object_id,
                name: e.file.name,
                url: e.file.url,
            })
        })
        setFileList(temp);
    }, [])


    return (
        <div className={'view-device'}>
            <div className={'files-flex'}>
                <Descriptions style={{width: '80%'}} column={3}>
                    <Descriptions.Item label="设备名称">{item.device_name ? item.device_name : ''}</Descriptions.Item>
                    <Descriptions.Item label="设备代码">{item.code ? item.code : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="规格型号">{item.device_specification ? item.device_specification : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="设备类型">{item.device_type_name ? item.device_type_name : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="存放位置">{item.device_position ? item.device_position : ''}</Descriptions.Item>
                    <Descriptions.Item
                        label="使用部门">{item.device_organization_name ? item.device_organization_name : ''}</Descriptions.Item>
                </Descriptions>
                <div style={{width: '20%'}}>
                    {
                        faultStatus.map(e => {
                            if (e.id === item.status) {
                                return <img src={e.src} alt="暂无图片" key={e.id}/>
                            }
                        })
                    }
                </div>
            </div>
            <Divider/>
            <Tabs defaultActiveKey="1" items={tabItems} tabBarGutter={80}/>
            {/*预览图片*/}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="加载图片失败" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    )
}

export default ViewItem;
