import React, { useRef, useState} from "react";
import GeneralManagement, {QueryListItem} from "../../components/GeneralManagement";
import {Button, Form, Input, message, Radio, Space, Switch} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {
    createPosition,
    deletePosition,
    getPositionById,
    getPositionList,
    StartStopPosition,
    UpdatePosition
} from "../../service";
import {deviceStatus, filterData, getFormData, showError} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';
import moment from "moment";

enum ModalTitle {
    ADD = '新增存放位置',
    EDIT = '编辑存放位置',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

const DevicePosition = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'position',
            name: '存放位置',
            placeholder: '请输入存放位置'
        },
        {
            key: 'status',
            name: '状态',
            placeholder: '请选择状态',
            options: deviceStatus
        }
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '存放位置',
            dataIndex: 'position',
            key: 'position',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            ellipsis: true,
            render: (value: boolean, item: any) => <ColumnStatus value={value} item={item}/>
        },
        {
            title: '创建人',
            dataIndex: 'create_user_name',
            key: 'create_user_name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const ColumnStatus = (props: any) => {
        const {value, item} = props;
        const [loading, setLoading] = useState<boolean>(false);
        const onChange = () => {
            setLoading(true)
            const formData = getFormData(filterData({object_id: item.object_id}))
            StartStopPosition(formData).then(res => {
                if (res.code === 200) {
                    refreshData()
                    setLoading(false)
                } else {
                    showError(res.message)
                }
            })
        };

        return <Switch checkedChildren="启用" unCheckedChildren="停用" checked={value === 1} loading={loading}
                       onChange={onChange}/>
    }

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const AddOrEditJob = React.memo((): React.ReactElement => {
        return (
            <Form colon={false} form={form} className={'form-margin'}>
                <Form.Item label={'存放位置'} name={'position'} rules={[
                    {required: true, message: '请输入存放位置!'},
                    {type: "string", max: 32, message: '存放位置不得大于32位!'}]}>
                    <Input placeholder={'请输入存放位置'}/>
                </Form.Item>
                <Form.Item name={'status'} label={'状态'} rules={[{required: true, message: '请选择状态!'}]}>
                    <Radio.Group>
                        {
                            deviceStatus.map((e: any) => <Radio key={e.id} value={e.id}>{e.name}</Radio>)
                        }
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={'备注'} name={'remarks'} rules={[{max: 200, message: '备注不得大于200位！'}]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditJob/>)
        } else if (title === ModalTitle.EDIT) {
            getPositionById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        position: res.data.position,
                        status: res.data.status,
                        remarks: res.data.remarks
                    })
                    setInnerComponent(<AddOrEditJob/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createPosition(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                UpdatePosition(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deletePosition(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getPositionList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={(modalTitle === ModalTitle.DELETE) ? '20%' : '688px'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default DevicePosition

