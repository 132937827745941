import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import {deleteAllot, getWarehouseAllot, newAllot, updateAllot} from "../../../service";
import {toastErrorMessage, toastSuccessMessage} from "../../../utils";
import "../WarehouseQuery/style.css"
import AllotDetailModal from "./allotDetailModal";
import DeleteModal from "../../Production/components/deleteModal";
import SelectFormAllot from "./selectFormAllot";
import AllotNewOrEditModal from "./allotNewOrEditModal";

/**
 * 仓储管理--库存调拨
 */
const WarehouseAllot = (): React.ReactElement => {
    const [originalHouse, setOriginalHouse] = useState<undefined | number>(undefined);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [dataSource, setDataSource] = useState<API.warehouseResponseDataAllotItem[]>([]);
    const [dataItem, setDataItem] = useState<API.warehouseResponseDataAllotItem>({
        code: '',
        create_time: '',
        is_valid: false,
        object_id: 0,
        object_name: '',
        objective_storage_id: 0,
        objective_storage_name: '',
        remarks: '',
        source_storage_id: 0,
        source_storage_name: '',
        status: 0,
        transfer_date: '',
        transfer_product: [],
        update_time: '',
        user_id: 0,
        user_name: '',
    });
    const columns: ColumnsType<API.warehouseResponseDataAllotItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '5%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {
            title: '调拨单号', dataIndex: 'code', align: 'center', ellipsis: true,
            render: (num: string, record) => <div
                className={'warehouseQueryHasAuthority'}
                onClick={() => {
                    setDataItem(record)
                    setShowDetailModal(true)
                }}
            >
                {num}
            </div>
        },
        {
            title: '调拨时间', dataIndex: 'transfer_date', align: 'center', ellipsis: true,
            render: (date) => <div className={'hiddenStyle'}>{date === null || date === '' ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '原仓库', dataIndex: 'source_storage_name', align: 'center', ellipsis: true},
        {title: '目的仓库', dataIndex: 'objective_storage_name', align: 'center', ellipsis: true},
        {title: '调拨人', dataIndex: 'user_name', align: 'center', ellipsis: true},
        {
            title: '调拨产品', dataIndex: 'transfer_product', align: 'left', ellipsis: true,
            render: (products) => <div className={'hiddenStyle'}>{products.join('、')}</div>
        },
        {title: '备注', dataIndex: 'remarks', align: 'left', ellipsis: true},
        {
            title: '状态', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (status) => <div
                style={{color: status === 10 ? '#EB4420' : status === 20 ? '#06C270' : '#ffffff'}}>{status === 10 ? '未提交' : status === 20 ? '已提交' : ''}</div>
        },
        {
            title: '操作', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (status: number, record) => {
                return status === 10 ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div className={'warehouseQueryHasAuthority'}
                         onClick={() => {
                             setDataItem(record)
                             setShowEditModal(true)
                         }}>
                        编辑
                    </div>
                    <div className={'warehouseQueryHasAuthority'} style={{marginLeft: 20}}
                         onClick={() => {
                             setDataItem(record)
                             setShowDeleteModal(true)
                         }}>
                        删除
                    </div>
                </div> : null
            }
        },
    ]
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);

    useEffect(() => {
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        houseValue: number | undefined,
        startTime?: any,
        endTime?: any,
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.houseValue) {
                param = {...param, source_storage_id: data.houseValue}
            }
            if (data.startTime && data.startTime !== '') {
                param = {...param, start_date: moment(data.startTime).format('YYYY-MM-DD')}
            }
            if (data.endTime && data.endTime !== '') {
                param = {...param, end_date: moment(data.endTime).format('YYYY-MM-DD')}
            }
        } else {
            if (originalHouse) {
                param = {...param, source_storage_id: originalHouse}
            }
            if (startTime && startTime !== '') {
                param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
            }
            if (endTime && endTime !== '') {
                param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
            }
        }
        getWarehouseAllot(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleShowTotal = (total: number) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{display: "flex", marginLeft: 0, color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    const deleteInfo = () => {
        deleteAllot({object_id: dataItem!.object_id}).then((res) => {
            if (res.code === 200) {
                setShowDeleteModal(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const addOrEditOrder = (type: string, data: {
        objectId?: number,
        allotNum: string,
        originalHouse: any,
        targetHouse: any,
        remark: string,
        allotList: Array<any>,
        submitType: number,
    }) => {
        let param: any = {
            source_storage_id: data.originalHouse,
            objective_storage_id: data.targetHouse,
            submit_type: data.submitType
        }
        if (type === 'edit') {
            param = {...param, object_id: data.objectId}
        }
        if (data.allotNum !== '') {
            param = {...param, code: data.allotNum}
        }
        if (data.allotList.length > 0) {
            let newList: any[] = []
            data.allotList.map((item) => {
                newList.push({
                    product_id: item.product_id,
                    transfer_num: item.transfer_num,
                })
            })
            param = {...param, inventory_transfer_details: JSON.stringify(newList)}
        }
        if (data.remark !== '') {
            param = {...param, remarks: data.remark}
        }
        if (type === 'new') {
            newAllot(param).then((res) => {
                if (res.code === 200) {
                    setShowNewModal(false)
                    if (10 === data.submitType) {
                        toastSuccessMessage('保存成功')
                    } else {
                        toastSuccessMessage('提交成功')
                    }
                    onFinish(currentPage, pageSize)
                } else {
                    toastErrorMessage(res.message)
                }
            }).catch((e: any) => {
                toastErrorMessage(e.message)
            })
        } else {
            updateAllot(param).then((res) => {
                if (res.code === 200) {
                    setShowEditModal(false)
                    if (10 === data.submitType) {
                        toastSuccessMessage('保存成功')
                    } else {
                        toastSuccessMessage('提交成功')
                    }
                    onFinish(currentPage, pageSize)
                } else {
                    toastErrorMessage(res.message)
                }
            }).catch((e: any) => {
                toastErrorMessage(e.message)
            })
        }
    }

    return (
        <div>
            <SelectFormAllot
                queryClick={(originalHouseValue: number | undefined, startTime: any, endTime: any) => {
                    setOriginalHouse(originalHouseValue)
                    setStartTime(startTime)
                    setEndTime(endTime)
                    onFinish(1, pageSize, {houseValue: originalHouseValue, startTime: startTime, endTime: endTime})
                }}
                newAdd={() => {
                    setShowNewModal(true)
                }}
            />
            <Table
                className={'warehouseQueryTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />

            {
                showNewModal && <AllotNewOrEditModal
                    type={'new'}
                    closeClick={() => {
                        setShowNewModal(false)
                    }}
                    saveClick={(allotNum: string, originalHouse: any, targetHouse: any, remark: string, allotList: Array<any>) => {
                        addOrEditOrder('new', {
                            allotNum: allotNum,
                            originalHouse: originalHouse,
                            targetHouse: targetHouse,
                            remark: remark,
                            allotList: allotList,
                            submitType: 10,
                        })
                    }}
                    commitClick={(allotNum: string, originalHouse: any, targetHouse: any, remark: string, allotList: Array<any>) => {
                        addOrEditOrder('new', {
                            allotNum: allotNum,
                            originalHouse: originalHouse,
                            targetHouse: targetHouse,
                            remark: remark,
                            allotList: allotList,
                            submitType: 20,
                        })
                    }}
                />
            }

            {
                showEditModal && <AllotNewOrEditModal
                    type={'edit'}
                    dataItem={dataItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(allotNum: string, originalHouse: any, targetHouse: any, remark: string, allotList: Array<any>, objectId: number | undefined) => {
                        addOrEditOrder('edit', {
                            objectId: objectId,
                            allotNum: allotNum,
                            originalHouse: originalHouse,
                            targetHouse: targetHouse,
                            remark: remark,
                            allotList: allotList,
                            submitType: 10,
                        })
                    }}
                    commitClick={(allotNum: string, originalHouse: any, targetHouse: any, remark: string, allotList: Array<any>, objectId: number | undefined) => {
                        addOrEditOrder('edit', {
                            objectId: objectId,
                            allotNum: allotNum,
                            originalHouse: originalHouse,
                            targetHouse: targetHouse,
                            remark: remark,
                            allotList: allotList,
                            submitType: 20
                        })
                    }}
                />
            }

            {
                showDetailModal && <AllotDetailModal
                    dataItemId={dataItem!.object_id}
                    closeClick={() => {
                        setShowDetailModal(false)
                    }}
                />
            }

            {
                showDeleteModal && <DeleteModal
                    closeClick={() => {
                        setShowDeleteModal(false)
                    }}
                    confirmClick={() => {
                        deleteInfo()
                    }}
                />
            }
        </div>)
}

export default WarehouseAllot
