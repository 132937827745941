import React from 'react'
import {Button, Modal} from "antd";

/**
 * 删除通用对话框
 */
export default class DeleteModal extends React.Component<{
    closeClick?: () => void
    confirmClick?: () => void
}> {

    render() {
        const {closeClick, confirmClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'系统确认'}
            footer={null}
            width={458}
            centered
            open
        >
            <div style={{marginTop: 60, display: "flex", justifyContent: "center"}}>
                <img
                    alt={''}
                    src={require('../../../assets/icon_delete_content.png')}
                />
            </div>
            <div style={{display: "flex", marginTop: 90, justifyContent: "center"}}>
                <Button
                    style={{width: 100, height: 35, backgroundColor: '#B8B8B8', borderRadius: 5, color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        closeClick && closeClick()
                    }}
                >
                    关闭
                </Button>
                <Button
                    style={{marginLeft: 20, width: 100, height: 35, borderRadius: 5}}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                        confirmClick && confirmClick()
                    }}
                >
                    确认
                </Button>
            </div>
        </Modal>
    }
}
