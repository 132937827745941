import React, {useEffect, useState} from 'react'
import {Button, Modal, Table, TableColumnsType} from "antd";
import moment from "moment";
import {
    getWarehouseQuery_In,
    getWarehouseQuery_Out,
    getWarehouseQuery_ProductInfo
} from "../../../service";
import "./style.css"
import {toastErrorMessage} from "../../../utils";

/**
 * 库存明细弹窗
 */
interface QueryDetailModalProps {
    objectId: number,
    closeClick: () => void
}

const QueryDetailModal = (props: QueryDetailModalProps): React.ReactElement => {
    const [productInfo, setProductInfo] = useState<API.warehouseResponseData_ProductInfo>({
        inventory_num: 0,
        last_inventory_date: '',
        last_inventory_real_quantity: 0,
        object_id: 0,
        product_code: '',
        product_id: 0,
        product_name: '',
        specification: '',
        storage_id: 0,
        storage_name: '',
        storage_user: '',
        unit_name: '',
    });
    const [currentPageIn, setCurrentPageIn] = useState(1);
    const [pageSizeIn, setPageSizeIn] = useState(10);
    const [totalIn, setTotalIn] = useState<number>(0);
    const [isLoadingIn, setIsLoadingIn] = useState(false);
    const [dataIn, setDataIn] = useState<API.warehouseResponseItem_In[]>([]);
    const [currentPageOut, setCurrentPageOut] = useState(1);
    const [pageSizeOut, setPageSizeOut] = useState(10);
    const [totalOut, setTotalOut] = useState<number>(0);
    const [isLoadingOut, setIsLoadingOut] = useState(false);
    const [dataOut, setDataOut] = useState<API.warehouseResponseItem_Out[]>([]);

    const columnsIn: TableColumnsType<API.warehouseResponseItem_In> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {title: '入库单号', dataIndex: 'code', align: 'center', ellipsis: true},
        {
            title: '入库类型', dataIndex: 'instore_type', align: 'center', ellipsis: true,
            render: (type) => <div>{type === 10 ? '采购入库' : type === 20 ? '生产入库' : type === 30 ? '销售退货' : type === 40 ? '调拨' : ''}</div>
        },
        {
            title: '入库时间', dataIndex: 'instore_time', align: 'center', ellipsis: true,
            render: (date) => <div>{moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '入库申请人', dataIndex: 'instore_user_name', align: 'center', ellipsis: true},
        {title: '所在部门', dataIndex: 'organization_name', align: 'center', ellipsis: true},
        {
            title: '入库数量', dataIndex: 'count', align: 'right', ellipsis: true,
            render: (num: number) =>
                <div>{num === undefined || num === null || num < 0 ? '' : num.toLocaleString()}</div>
        },
        {title: '备注', dataIndex: 'remark', align: 'left', ellipsis: true},
    ]

    const columnsOut: TableColumnsType<API.warehouseResponseItem_Out> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {title: '出库单号', dataIndex: 'code', align: 'center', ellipsis: true},
        {
            title: '出库类型', dataIndex: 'outstore_type', align: 'center', ellipsis: true,
            render: (type) => <div>{type === 10 ? '销售出库' : type === 20 ? '生产领料' : type === 30 ? '调拨' : ''}</div>
        },
        {
            title: '出库时间', dataIndex: 'outstore_time', align: 'center', ellipsis: true,
            render: (date) => <div>{moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '出库申请人', dataIndex: 'outstore_user_name', align: 'center', ellipsis: true},
        {title: '所在部门', dataIndex: 'organization_name', align: 'center', ellipsis: true},
        {
            title: '出库数量', dataIndex: 'count', align: 'right', ellipsis: true,
            render: (num: number) =>
                <div>{num === undefined || num === null || num < 0 ? '' : num.toLocaleString()}</div>
        },
        {title: '备注', dataIndex: 'remark', align: 'left', ellipsis: true},
    ]

    useEffect(() => {
        getWarehouseQuery_ProductInfo({object_id: props.objectId}).then((res) => {
            if (res.code === 200) {
                setProductInfo(res.data)
            }
        })
        onFinishIn(1, 10)
        onFinishOut(1, 10)
    }, [])

    const onFinishIn = (page: number, size: number) => {
        setCurrentPageIn(page);
        setPageSizeIn(size)
        setIsLoadingIn(true)
        let param: any = {object_id: props.objectId, page_num: page, page_size: size}
        getWarehouseQuery_In(param).then((res) => {
            if (res.code === 200) {
                setTotalIn(res.data.total)
                setDataIn(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoadingIn(false)
        })
    }

    const onFinishOut = (page: number, size: number) => {
        setCurrentPageOut(page);
        setPageSizeOut(size)
        setIsLoadingOut(true)
        let param: any = {object_id: props.objectId, page_num: page, page_size: size}
        getWarehouseQuery_Out(param).then((res) => {
            if (res.code === 200) {
                setTotalOut(res.data.total)
                setDataOut(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoadingOut(false)
        })
    }

    const handleShowTotalIn = (total: number) => {
        const totalPage = Math.ceil(total / pageSizeIn);
        return (
            <div style={{display: "flex", marginLeft: 0, color: '#999999'}}>
                {`共${total}条记录，第${currentPageIn}/${totalPage}页`}
            </div>
        )
    }

    const handleShowTotalOut = (total: number) => {
        const totalPage = Math.ceil(total / pageSizeOut);
        return (
            <div style={{display: "flex", marginLeft: 0, color: '#999999'}}>
                {`共${total}条记录，第${currentPageOut}/${totalPage}页`}
            </div>
        )
    }

    return (
        <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => props.closeClick && props.closeClick()}
                />
            }
            destroyOnClose
            title={<div style={{fontSize: 16, fontWeight: "bold"}}>
                库存明细
            </div>}
            footer={null}
            width={1080}
            centered
            open
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
                fontSize: 16
            }}>
                产品信息
            </div>

            <div style={{display: "flex", flexDirection: "column", marginTop: 30}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>产品代码：</div>
                        <div className={'detailValue70Style'}>{productInfo.product_code}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName100Style'}>产品名称：</div>
                        <div className={'detailValue100Style'}>{productInfo.product_name}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName100Style'}>规格型号：</div>
                        <div className={'detailValue100Style'}>{productInfo.specification}</div>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>单位：</div>
                        <div className={'detailValue70Style'}>{productInfo.unit_name}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName100Style'}>仓库名称：</div>
                        <div className={'detailValue100Style'}>{productInfo.storage_name}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName100Style'}>库管员：</div>
                        <div className={'detailValue100Style'}>{productInfo.storage_user}</div>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>库存数量：</div>
                        <div
                            className={'detailValue70Style'}>{productInfo.inventory_num === undefined || productInfo.inventory_num === null || productInfo.inventory_num < 0 ? '' : productInfo.inventory_num.toLocaleString()}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName100Style'}>上次盘点日期：</div>
                        <div
                            className={'detailValue100Style'}>{productInfo.last_inventory_date === '' || productInfo.last_inventory_date === null ? '' : moment(productInfo.last_inventory_date).format('YYYY/MM/DD')}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName100Style'}>上次盘点数量：</div>
                        <div
                            className={'detailValue100Style'}>{productInfo.last_inventory_real_quantity === undefined || productInfo.last_inventory_real_quantity === null || productInfo.last_inventory_real_quantity < 0 ? '' : productInfo.last_inventory_real_quantity.toLocaleString()}</div>
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                flex: 1,
                height: 1,
                backgroundColor: '#000000',
                opacity: 0.1,
                marginTop: 20,
                marginBottom: 20
            }}/>

            <div style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 24,
                fontWeight: "bold",
                fontSize: 16
            }}>
                入库明细
            </div>
            {
                dataIn.length > 0 ? <Table
                    style={{marginTop: 10}}
                    className={'queryDetailModalChildTable'}
                    dataSource={dataIn}
                    columns={columnsIn}
                    size={'small'}
                    scroll={{y: 200}}
                    loading={isLoadingIn}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPageIn,
                        pageSize: pageSizeIn,
                        showSizeChanger: true,
                        total: totalIn,
                        showQuickJumper: true,
                        onChange: onFinishIn,
                        showTotal: handleShowTotalIn
                    }}
                /> : <div style={{
                    flex: 1,
                    height: 200,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img style={{height: 84, width: 92}} src={require('../../../assets/queryHouseNoData.png')}/>
                    <div style={{marginTop: 24, fontSize: 14}}>没有入库明细</div>
                </div>
            }

            <div style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 24,
                fontWeight: "bold",
                fontSize: 16
            }}>
                出库明细
            </div>
            {
                dataOut.length > 0 ? <Table
                    style={{marginTop: 10}}
                    className={'queryDetailModalChildTable'}
                    dataSource={dataOut}
                    columns={columnsOut}
                    size={'small'}
                    scroll={{y: 200}}
                    loading={isLoadingOut}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPageOut,
                        pageSize: pageSizeOut,
                        showSizeChanger: true,
                        total: totalOut,
                        showQuickJumper: true,
                        onChange: onFinishOut,
                        showTotal: handleShowTotalOut
                    }}
                /> : <div style={{
                    flex: 1,
                    height: 200,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img style={{height: 84, width: 92}} src={require('../../../assets/queryHouseNoData.png')}/>
                    <div style={{marginTop: 24, fontSize: 14}}>没有出库明细</div>
                </div>
            }

            <div style={{display: "flex", marginTop: 30, justifyContent: "center"}}>
                <Button
                    style={{width: 130, height: 48, borderRadius: 5, backgroundColor: '#B8B8B8', color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        props.closeClick && props.closeClick()
                    }}
                >
                    关闭
                </Button>
            </div>
        </Modal>
    )
}

export default QueryDetailModal
