import React from 'react'
import {Button, DatePicker, Input, InputNumber, Modal, Select, Table} from "antd";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import locale from "antd/es/date-picker/locale/zh_CN";
import SellDetailModal from "./sellDetailModal";
import {ColumnsType} from "antd/lib/table";
import type {SortableContainerProps, SortEnd} from 'react-sortable-hoc';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import './index.css'
import moment from "moment";
import {toastMessage} from "../../../utils";
import {
    getAllProcedureInfo,
    getAllProductionLineInfo,
    getLineDetailRequest,
    getTaskDetailRequest,
    getWorkmanshipInfo
} from "../../../service";

/**
 * 新增、编辑生产任务对话框
 */
const {Option} = Select;
const {RangePicker} = DatePicker;

interface Type {
    taskNum: string,
    orderLine: any,
    sellNum: string,
    orderProcessLine: any,
    orderProduct: string,
    orderProductId: number,
    dateMoment: any,
    unit: string,
    taskCount: number,
    orderCount: number,
    remark: string,
    showSellDetail: boolean,
    lineInfoList: Array<any>,
    lineProcessList: Array<any>,
    processColumns: ColumnsType<any>,
    processList: Array<any>,
    processSelectList: Array<any>,
}

const DragHandle = SortableHandle(() => <img style={{cursor: "pointer", width: 25, height: 25}}
                                             src={require('../../../assets/icon_move.png')}/>);
const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
))
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
))

export default class TaskModal extends React.Component<{
    type: string,
    isHasLine: boolean,
    isHasProcessLine: boolean,
    isHasProcess: boolean,
    navigate: NavigateFunction,
    dataItem?: API.queryChildItem | undefined,
    closeClick?: () => void
    saveClick?: (data: any) => void
}> {

    private taskModalBody: any = React.createRef() || undefined
    state: Type = {
        taskNum: '',
        orderLine: undefined,
        sellNum: '',
        orderProcessLine: undefined,
        orderProduct: '',
        orderProductId: -1,
        dateMoment: undefined,
        unit: '',
        taskCount: 0,
        orderCount: 0,
        remark: '',
        showSellDetail: false,
        lineInfoList: [],
        lineProcessList: [],
        processColumns: [],
        processList: [],
        processSelectList: [],
    }

    async componentDidMount() {
        const {type, isHasProcess, navigate, dataItem} = this.props
        this.setState({
            processColumns: [
                {title: '', dataIndex: 'sort', align: 'center', width: '8%', render: () => <DragHandle/>},
                {title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%'},
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{color: "red"}}>*</div>
                            <div style={{
                                color: isHasProcess ? '#3E7BFA' : '#333333',
                                textDecoration: isHasProcess ? "underline" : "none",
                                cursor: isHasProcess ? "pointer" : "auto",
                                marginLeft: 5,
                            }} onClick={() => {
                                if (isHasProcess) {
                                    navigate('/basic/procedure')
                                }
                            }}>
                                工序
                            </div>
                        </div>
                    },
                    dataIndex: 'work_process_info_id',
                    ellipsis: true,
                    width: '20%',
                    render: (text: number, record: { key: React.Key }) => <Select
                        value={text}
                        style={{height: 26, width: 120, borderRadius: 5}}
                        placeholder={'请选择工序'}
                        onChange={(value) => {
                            const newList = this.state.processList.map((item) => {
                                if (item.key === record.key) {
                                    return {
                                        ...item,
                                        work_process_info_id: value
                                    }
                                } else {
                                    return item
                                }
                            })
                            this.setState({
                                processList: newList
                            })
                        }}
                    >
                        {
                            this.state.processSelectList.length > 0 && this.state.processSelectList.map((item: any, index: number) => {
                                return <Option key={index} value={item.work_process_info_id}>
                                    {item.process_name}
                                </Option>
                            })
                        }
                    </Select>
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{color: "red"}}>*</div>
                            <div style={{color: '#333333', marginLeft: 5}}>
                                工艺说明
                            </div>
                        </div>
                    },
                    dataIndex: 'process_explain',
                    ellipsis: true,
                    render: (text: string, record: { key: React.Key }) => <Input
                        style={{height: 30, flex: 1, display: "flex", borderRadius: 5}}
                        placeholder="请输入工艺说明"
                        value={text}
                        maxLength={200}
                        onChange={(newValue) => {
                            const newList = this.state.processList.map((item) => {
                                if (item.key === record.key) {
                                    return {
                                        ...item,
                                        process_explain: newValue.target.value
                                    }
                                } else {
                                    return item
                                }
                            })
                            this.setState({
                                processList: newList
                            })
                        }}
                    />
                },
                {
                    title: '操作',
                    dataIndex: 'operate',
                    align: 'center',
                    ellipsis: true,
                    width: '10%',
                    render: (text: string, record: { key: React.Key }) => <div
                        style={{color: '#3E7BFA', cursor: "pointer"}}
                        onClick={() => {
                            this.handleDelete(record.key)
                        }}
                    >
                        删除
                    </div>
                },
            ]
        })
        // 获取产线列表
        await getAllProductionLineInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    lineInfoList: res.data.data
                })
            }
        })
        // 获取工艺线路列表
        await getWorkmanshipInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    lineProcessList: res.data.data
                })
            }
        })
        // 获取工序列表
        await getAllProcedureInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    processSelectList: res.data.data
                })
            }
        })
        if (type === 'edit') {
            await getTaskDetailRequest({id: `${dataItem!.object_id}`}).then((res) => {
                if (res.code === 200) {
                    let newList: any[] = []
                    if (res.data.work.length > 0) {
                        res.data.work.map((item, index) => {
                            newList.push({
                                process_explain: item.process_explain,
                                work_process_info_id: this.state.processSelectList.find((processSelectItem) => processSelectItem.work_process_info_id === item.work_process_info_id) ? item.work_process_info_id : undefined,
                                key: index,
                                no: index + 1,
                            })
                        })
                    }
                    this.setState({
                        taskNum: res.data.task_number,
                        orderLine: this.state.lineInfoList.find((item) => item.object_id === res.data.line_info_id) ? res.data.line_info_id : undefined,
                        sellNum: res.data.order_number,
                        orderProcessLine: res.data.work_craft_info_id === undefined || null ? undefined : this.state.lineProcessList.find((item) => item.object_id === res.data.work_craft_info_id) ? res.data.work_craft_info_id : undefined,
                        orderProduct: res.data.product_name,
                        orderProductId: res.data.order_product_id,
                        dateMoment: [moment(res.data.start_date, 'YYYY/MM/DD'), moment(res.data.end_date, 'YYYY/MM/DD')],
                        unit: res.data.unit_name,
                        taskCount: res.data.task_quantity,
                        orderCount: res.data.order_quantity,
                        remark: res.data.remark,
                        processList: newList,
                    })
                }
            })
        }
    }

    handleDelete(key: React.Key) {
        const newData = this.state.processList.filter(item => item.key !== key);
        newData.map((item, index) => {
            item.key = index
            item.no = index + 1
        })
        this.setState({
            processList: newData
        })
    };

    handleAdd() {
        const newItem: any = {
            key: this.state.processList.length,
            no: this.state.processList.length + 1,
            work_process_info_id: undefined,
            process_explain: '',
        }
        const newData = [...this.state.processList, newItem]
        newData.map((item, index) => {
            item.key = index
            item.no = index + 1
        })
        this.setState({
            processList: newData
        })
    };

    onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(this.state.processList.slice(), oldIndex, newIndex).filter(
                (el: any) => !!el,
            );
            newData.map((item, index) => {
                item.key = index
                item.no = index + 1
            })
            this.setState({
                processList: newData
            })
        }
    };

    draggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            helperContainer={this.taskModalBody.current}
            {...props}
        />
    )

    // @ts-ignore
    draggableBodyRow = ({className, style, ...restProps}) => {
        const index = this.state.processList.findIndex((x: any) => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    }

    async getTaskList(objectId: number) {
        await getLineDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                this.setState({
                    processList: res.data.process.map((item, index) => {
                        return {
                            process_explain: item.process_info.process_explain,
                            work_process_info_id: this.state.processSelectList.find((processSelectItem) => processSelectItem.work_process_info_id === item.process_info.work_process_info_id) ? item.process_info.work_process_info_id : undefined,
                            key: index,
                            no: index + 1,
                        }
                    })
                })
            } else {
                this.setState({
                    processList: []
                })
            }
        }).catch(() => {
            this.setState({
                processList: []
            })
        })
    }

    render() {
        const {type, isHasLine, isHasProcessLine, navigate, closeClick, saveClick} = this.props
        return <Modal
            // @ts-ignore
            forwardRef={(e: any) => {
                this.taskModalBody = e;
            }}
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={type === 'new' ? '新增生产任务' : '编辑生产任务'}
            footer={null}
            width={855}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'newModalItemTitleLeft'}>任务单号</div>
                    <Input
                        value={this.state.taskNum}
                        className={'newModalItemInputStyle'}
                        placeholder="不填写，系统将自动生成"
                        maxLength={32}
                        onChange={(newValue) => {
                            if (newValue.target.value === '') {
                                this.setState({
                                    taskNum: newValue.target.value
                                })
                            } else {
                                if (/^[A-Za-z0-9]+$/.test(newValue.target.value)) {
                                    this.setState({
                                        taskNum: newValue.target.value
                                    })
                                }
                            }
                        }}
                        disabled={type !== 'new'}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 40, alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{color: "red"}}>*</div>
                        <div
                            className={'newModalItemTitleRight'}
                            style={{
                                color: isHasLine ? '#3E7BFA' : '#333333',
                                cursor: isHasLine ? 'pointer' : 'auto',
                                textDecoration: isHasLine ? 'underline' : 'none',
                                marginLeft: 5,
                            }}
                            onClick={() => {
                                if (isHasLine) {
                                    navigate('/basic/productionLine')
                                }
                            }}
                        >
                            产线
                        </div>
                    </div>
                    <Select
                        className={'newModalItemSelectStyle'}
                        value={this.state.orderLine}
                        placeholder={'请选择产线名称'}
                        onChange={(value, option) => {
                            this.setState({
                                orderLine: value
                            })
                        }}
                    >
                        {
                            this.state.lineInfoList.length > 0 && this.state.lineInfoList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.line_name}
                                </Option>
                            })
                        }
                    </Select>
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'newModalItemTitleLeft'}>销售单号</div>
                    <Input
                        value={this.state.sellNum}
                        className={'newModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 40, alignItems: "center"}}>
                    <div className={'newModalItemTitleRight'}
                         style={{
                             color: isHasProcessLine ? '#3E7BFA' : '#333333',
                             cursor: isHasProcessLine ? 'pointer' : 'auto',
                             textDecoration: isHasProcessLine ? 'underline' : 'none',
                             marginLeft: 12,
                         }}
                         onClick={() => {
                             if (isHasProcessLine) {
                                 navigate('/basic/workmanship')
                             }
                         }}
                    >
                        工艺路线
                    </div>
                    <Select
                        className={'newModalItemSelectStyle'}
                        value={this.state.orderProcessLine}
                        placeholder={'请选择工艺路线名称'}
                        onChange={(value, option) => {
                            this.setState({
                                orderProcessLine: value
                            }, () => {
                                this.getTaskList(value)
                            })
                        }}
                    >
                        {
                            this.state.lineProcessList.length > 0 && this.state.lineProcessList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.work_craft_name}
                                </Option>
                            })
                        }
                    </Select>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row", marginLeft: -10}}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        <div className={'newModalItemTitleLeft'}>
                            产品
                        </div>
                    </div>
                    <Input
                        className={'newModalItemInputStyle'}
                        placeholder="请选择产品"
                        value={this.state.orderProduct}
                        style={{width: 222}}
                        disabled
                        onChange={(newValue) => {
                            this.setState({
                                orderProduct: newValue.target.value
                            })
                        }}
                    />
                    <Button style={{
                        display: "flex",
                        marginLeft: 10,
                        width: 48,
                        height: 30,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center"
                    }} type="primary"
                            htmlType="submit"
                            onClick={() => {
                                this.setState({
                                    showSellDetail: true
                                })
                            }}
                    >
                        查询
                    </Button>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 40, alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{color: "red", marginRight: 5}}>*</div>
                        <div className={'newModalItemTitleRight'}>
                            计划日期
                        </div>
                    </div>
                    <RangePicker
                        className={'newModalItemRangePickerStyle'}
                        locale={locale}
                        value={this.state.dateMoment}
                        size="small"
                        allowClear={false}
                        placeholder={['请选择开始日期', '请选择结束时间']}
                        format={[(moment) => moment.format('YYYY/MM/DD'), (moment) => moment.format('YYYY/MM/DD')]}
                        onChange={(dates: any) => {
                            if (dates) {
                                this.setState({
                                    dateMoment: dates,
                                })
                            } else {
                                this.setState({
                                    dateMoment: undefined,
                                })
                            }
                        }}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'newModalItemTitleLeft'}>单位</div>
                    <Input
                        value={this.state.unit}
                        className={'newModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 40, alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{color: "red", marginRight: 7}}>*</div>
                        <div className={'newModalItemTitleRight'}>
                            任务数量
                        </div>
                    </div>
                    <InputNumber
                        value={this.state.taskCount}
                        className={'newModalItemInputStyle'}
                        min={this.state.orderCount}
                        placeholder="请输入任务数量"
                        onChange={(newValue) => {
                            this.setState({
                                taskCount: newValue
                            })
                        }}
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'newModalItemTitleLeft'}>订单数量</div>
                    <Input
                        value={this.state.orderCount.toLocaleString()}
                        className={'newModalItemInputStyle'}
                        disabled
                    />
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'newModalItemTitleLeft'}>备注</div>
                    <Input
                        style={{
                            display: "flex",
                            flex: 1,
                            width: 682,
                            height: 38,
                            color: '#333333',
                            fontSize: 14,
                            alignItems: "center",
                            borderRadius: 5,
                            marginLeft: 10,
                            paddingLeft: 10,
                            paddingRight: 10
                        }}
                        value={this.state.remark}
                        maxLength={200}
                        placeholder="请输入备注"
                        onChange={(newValue) => {
                            this.setState({
                                remark: newValue.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <Table
                title={() => <div style={{display: "flex", flexDirection: "row", flex: 1}}>
                    <div style={{flex: 1, fontWeight: "bold", color: '#333333', marginLeft: 10}}>
                        工艺流程
                    </div>
                    <Button
                        className={'orderButtonAdd'}
                        type={"primary"}
                        onClick={() => {
                            this.handleAdd()
                        }}
                    >
                        新增
                    </Button>
                </div>}
                style={{marginTop: 20, marginLeft: 15, marginRight: 15}}
                bordered
                className={'childTable'}
                dataSource={this.state.processList}
                columns={this.state.processColumns}
                size={'small'}
                scroll={{y: 200}}
                pagination={false}
                components={{
                    body: {
                        wrapper: this.draggableContainer,
                        row: this.draggableBodyRow,
                    }
                }}
            />

            <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                <Button style={{width: 100, height: 35, backgroundColor: '#B8B8B8', borderRadius: 5, color: '#ffffff'}}
                        htmlType="button"
                        onClick={() => {
                            closeClick && closeClick()
                        }}
                >
                    关闭
                </Button>
                <Button style={{marginLeft: 20, width: 100, height: 35, borderRadius: 5}} type="primary"
                        htmlType="submit"
                        onClick={() => {
                            if (this.state.orderLine === undefined) {
                                toastMessage('请选择产线')
                                return;
                            }
                            if (this.state.orderProduct === '' || this.state.orderProductId === -1) {
                                toastMessage('请选择产品')
                                return;
                            }
                            if (this.state.dateMoment === undefined || this.state.dateMoment[0] === undefined) {
                                toastMessage('请选择开始时间')
                                return;
                            }
                            if (this.state.dateMoment[1] === undefined) {
                                toastMessage('请选择结束时间')
                                return;
                            }
                            if (this.state.taskCount < this.state.orderCount) {
                                toastMessage('任务数量应大于等于订单数量')
                                return;
                            }
                            if (this.state.processList.length > 0) {
                                let isHasProcess = false
                                let isHasRemark = false
                                this.state.processList.map((item) => {
                                    if (item.work_process_info_id === undefined || null) {
                                        isHasProcess = true
                                    }
                                    if (item.process_explain === '') {
                                        isHasRemark = true
                                    }
                                })
                                if (isHasProcess) {
                                    toastMessage('请选择工序')
                                    return;
                                }
                                if (isHasRemark) {
                                    toastMessage('请输入工艺说明')
                                    return;
                                }
                            } else {
                                toastMessage('工艺流程不能为空')
                                return;
                            }
                            if (type === 'new') {
                                saveClick && saveClick({
                                    task_number: this.state.taskNum,
                                    order_product_id: this.state.orderProductId,
                                    line_info_id: this.state.orderLine,
                                    start_date: this.state.dateMoment[0].format('YYYY-MM-DD'),
                                    end_date: this.state.dateMoment[1].format('YYYY-MM-DD'),
                                    work_craft_info_id: this.state.orderProcessLine,
                                    process: this.state.processList,
                                    task_quantity: this.state.taskCount,
                                    remark: this.state.remark,
                                })
                            } else {
                                saveClick && saveClick({
                                    task_id: this.props.dataItem!.object_id,
                                    task_number: this.state.taskNum,
                                    order_product_id: this.state.orderProductId,
                                    line_info_id: this.state.orderLine,
                                    start_date: this.state.dateMoment[0].format('YYYY-MM-DD'),
                                    end_date: this.state.dateMoment[1].format('YYYY-MM-DD'),
                                    work_craft_info_id: this.state.orderProcessLine,
                                    process: this.state.processList,
                                    task_quantity: this.state.taskCount,
                                    remark: this.state.remark,
                                })
                            }
                        }}
                >
                    保存
                </Button>
            </div>

            {
                this.state.showSellDetail && <SellDetailModal
                    closeClick={() => {
                        this.setState({
                            showSellDetail: false
                        })
                    }}
                    saveClick={(item: API.sellListItem | undefined) => {
                        this.setState({
                            sellNum: item!.order_number,
                            orderProduct: item!.product_name,
                            orderProductId: item!.object_id,
                            unit: item!.unit_name,
                            orderCount: item!.num,
                            taskCount: item!.num,
                            showSellDetail: false
                        })
                    }}
                />
            }
        </Modal>
    }
}
