import React from 'react'
import {Button, DatePicker, Select, Input} from "antd";
import '../../Statistics/components/index.css'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";
import {getAllProcedureInfo, getUserList} from "../../../service";

/**
 * 统计分析--产量统计--查询重置
 */
const {Option} = Select;
const {RangePicker} = DatePicker;

interface Type {
    startTime: string,
    endTime: string,
    name: string,
    creator: undefined | number,
    process: undefined | number,
    creatorList: Array<any>,
    processList: Array<any>,
}

export default class SelectFormOutput extends React.Component<{
    queryClick?: (startTime: string, endTime: string, name: string, creator: undefined | number, process: undefined | number) => void,
    output?: () => void,
}> {

    state: Type = {
        startTime: moment(new Date()).startOf('month').format('YYYY/MM/DD'),
        endTime: moment(new Date()).endOf('month').format('YYYY/MM/DD'),
        name: '',
        creator: undefined,
        process: undefined,
        creatorList: [],
        processList: [],
    }

    async componentDidMount() {
        // 获取作业者列表
        getUserList().then(res => {
            if (res.code === 200) {
                this.setState({
                    creatorList: res.data.data,
                })
            }
        })
        // 获取工序列表
        await getAllProcedureInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    processList: res.data.data
                })
            }
        })
    }

    timeChanged = (dates: any) => {
        if (dates) {
            this.setState({
                startTime: dates[0].format('YYYY/MM/DD'),
                endTime: dates[1].format('YYYY/MM/DD'),
            })
        } else {
            this.setState({
                startTime: '',
                endTime: '',
            })
        }
    }

    disabledDate = (current: any) => {
        const tooLate = this.state.startTime && current.diff(this.state.startTime, 'days') > 89;
        const tooEarly = this.state.endTime && moment(this.state.endTime).diff(current, 'days') > 89;
        return !!tooEarly || !!tooLate;
    };

    render() {
        const {startTime, endTime, name, creator, process} = this.state
        return <div className={'selectFormTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    生产日期
                </div>
                <RangePicker
                    locale={locale}
                    value={[moment(startTime), moment(endTime)]}
                    size="small"
                    clearIcon={false}
                    style={{marginLeft: 20, width: 244, height: 30}}
                    placeholder={['开始时间', '结束时间']}
                    disabledDate={this.disabledDate}
                    format={[(moment) => moment.format('YYYY/MM/DD'), 'YYYY/MM/DD']}
                    onChange={this.timeChanged}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    产品名称
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={name}
                    placeholder={'请输入产品名称'}
                    onChange={(e) => {
                        this.setState({
                            name: e.target.value
                        })
                    }}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    作业者
                </div>
                <Select
                    value={creator}
                    style={{marginLeft: 20, minWidth: 244, height: 30, borderRadius: 5}}
                    placeholder={'请选择作业者'}
                    onChange={(value) => {
                        this.setState({
                            creator: value,
                        })
                    }}
                >
                    {
                        this.state.creatorList.length > 0 && this.state.creatorList.map((item: any, index: number) => {
                            return <Option key={index} value={item.object_id}>
                                {item.name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    工序
                </div>
                <Select
                    value={process}
                    style={{marginLeft: 20, minWidth: 244, height: 30, borderRadius: 5}}
                    placeholder={'请选择工序'}
                    onChange={(value) => {
                        this.setState({
                            process: value,
                        })
                    }}
                >
                    {
                        this.state.processList.length > 0 && this.state.processList.map((item: any, index: number) => {
                            return <Option key={index} value={item.work_process_info_id}>
                                {item.process_name}
                            </Option>
                        })
                    }
                </Select>
            </div>

            <Button
                className={'buttonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(startTime, endTime, name, creator, process)
                }}
            >
                查询
            </Button>

            <Button
                className={'buttonReset'}
                type={"primary"}
                onClick={() => {
                    let start = moment(new Date()).startOf('month').format('YYYY/MM/DD')
                    let end = moment(new Date()).endOf('month').format('YYYY/MM/DD')
                    this.setState({
                        startTime: start,
                        endTime: end,
                        name: '',
                        creator: undefined,
                        process: undefined,
                    }, () => {
                        this.props.queryClick && this.props.queryClick(start, end, '', undefined, undefined)
                    })
                }}
            >
                重置
            </Button>

            <div style={{display: "flex", flex: 1}}/>
            <Button
                className={'buttonOutput'}
                type={"primary"}
                onClick={() => {
                    this.props.output && this.props.output()
                }}
            >
                导出
            </Button>
        </div>
    }
}
