//新增或编辑设备信息
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message, Modal,
    Row,
    Select,
    Space,
    Table,
    TreeSelect,
    Upload
} from "antd";
import {fileDeviceStatus, getFormData, getTreeData, showError} from "../../../utils";
import React, {useEffect, useState} from "react";
import {
    getDeviceTypeList,
    getOrganizationTree,
    getPositionList,
    getUploadUrl,
    getUserList,
    request
} from "../../../service";
import {Options} from "../../../components/GeneralManagement";
import {ColumnsType} from "antd/es/table";
import {PlusOutlined} from '@ant-design/icons';
import type {UploadProps} from 'antd/es/upload';
import moment from "moment";
import {UserInfo} from "../../../contexts/userContext";
import UploadPictures from "../../../components/UploadPictures";
import {ModalTitle} from "../DeviceFile";
import FileViewer from "react-file-viewer";

interface AddOrEditDeviceProps {
    title: ModalTitle;
    form: any;
    fileList: any;
    setFileList: (fileList: any) => void;
    useInfo: UserInfo,
    dataSource: any[],
    setDataSource: (dataSource: any[]) => void;
}

const AddOrEditDevice = (props: AddOrEditDeviceProps) => {
    const {form, fileList, setFileList, useInfo, dataSource, setDataSource, title} = props;
    const [typeList, setTypeList] = useState<Options[]>([]);
    const [locList, setLocList] = useState<Options[]>([]);
    const [organizationList, setOrganizationList] = useState<any[]>([]);
    const [userList, setUserList] = useState<any[]>([]);
    const [tempDataSource, setTempDataSource] = useState<any[]>([]);
    const [previewFileOpen, setPreviewFileOpen] = useState(false);
    const [previewFileTitle, setPreviewFileTitle] = useState(null);
    const [previewChildren, setPreviewChildren] = useState<React.ReactNode | undefined>(undefined);

    const columns: ColumnsType<any> = [
        {
            key: 'NO',
            title: 'NO',
            dataIndex: 'NO',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) =>
                <span>{index + 1}</span>
        },
        {
            key: 'file_name',
            title: '文件名',
            dataIndex: 'file_name',
            align: 'center',
            width: 200,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            key: 'file_extension',
            title: '文件后缀',
            dataIndex: 'file_extension',
            align: 'center',
            width: 100
        },
        {
            key: 'file_size',
            title: '文件大小(KB)',
            dataIndex: 'file_size',
            align: 'center',
            width: 150,
            render: (value: string) => <div style={{textAlign: 'right'}}>{value}</div>
        },
        {
            key: 'user_name',
            title: '上传者',
            dataIndex: 'user_name',
            align: 'center',
            width: 100,
            ellipsis: true,
        },
        //不显示该项，width为0
        {
            key: 'up_user_id',
            title: 'up_user_id',
            dataIndex: 'up_user_id',
            align: 'center',
            width: 0,
            ellipsis: true,
        },
        {
            key: 'up_time',
            title: '上传时间',
            dataIndex: 'up_time',
            align: 'center',
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'options',
            title: '操作',
            dataIndex: 'options',
            align: 'center',
            width: 100,
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        previewFile(item)
                    }}>预览</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        deleteFile(item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const previewFile = (item: any) => {
        setPreviewFileOpen(true);
        setPreviewFileTitle(item.file_name);
        if (item.file_extension === "pdf") {
            setPreviewChildren(<iframe src={item.file.url} height={'900px'} width={'100%'}></iframe>)
        } else {
            setPreviewChildren(
                <FileViewer
                    style={{height:'900px'}}
                    fileType={item.file_extension}
                    filePath={item.file.url}/>
            )
        }
    }

    const handleCancelFile = () => {
        setPreviewFileOpen(false);
        setPreviewFileTitle(null);
        setPreviewChildren(undefined)
    }

    const deleteFile = (item: any) => {
        const temp = [...tempDataSource]
        temp.splice(temp.findIndex(e => e.file_id === item.file_id), 1)
        setTempDataSource(temp)
    }

    const customRequestDoc: UploadProps['customRequest'] = (options: any) => {
        const formData = getFormData({name: options.file.name});
        getUploadUrl(formData).then(res => {
            if (res.code === 200) {
                request.put(res.data.upload_url, {
                    data: options.file
                }).then(res1 => {
                    if (res1) {
                        message.error('上传失败！')
                    } else {
                        const temp = [...tempDataSource]
                        temp.push(
                            {
                                key:res.data.file.object_id,
                                file_id: res.data.file.object_id,
                                file_name: options.file.name,
                                file_extension: options.file.name.split('.').pop(),
                                file_size: (options.file.size / 1000).toFixed(2),
                                up_user_id: useInfo.object_id,
                                user_name: useInfo.name,
                                up_time: moment(new Date()).format('YYYY/MM/DD HH:mm'),
                                file: res.data.file,
                            }
                        )
                        setTempDataSource(temp)
                        options.onSuccess(res1)
                    }
                })
            } else {
                showError(res.message)
            }
        })
    }

    useEffect(() => {
        getDeviceTypeList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.device_name
                            })
                        })
                    }
                    setTypeList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        getPositionList({page_num: 1, page_size: 10000000,status:1}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.position
                            })
                        })
                    }
                    setLocList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        //     获取组织机构
        getOrganizationTree().then(res => {
            if (res.code === 200) {
                let temp = getTreeData(res.data)
                setOrganizationList(temp)
            } else {
                showError(res.message)
            }
        })
        //    获取人员列表
        getUserList({page_num: 1}).then((res) => {
            if (res.code === 200) {
                if (res.data) {
                    setUserList(res.data.data);
                }
            } else {
                showError(res.message);
            }
        })
        if (dataSource.length) {
            setTempDataSource(dataSource)
        }
    }, [])


    useEffect(() => {
        setDataSource(tempDataSource)
    }, [[...tempDataSource]])


    return (
        <div className={'viewItem'}>
            {/*    设备信息*/}
            <div className={'viewTitle'}>设备信息</div>
            <Form colon={false} form={form} className={'form-margin'}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'设备代码'} name={'code'} rules={[
                            {
                                type: "string",
                                max: 32,
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '设备代码由不大于32位的字母、数字组成!'
                            }
                        ]}>
                            <Input placeholder={'不填写，系统将自动生成'} disabled={title === ModalTitle.EDIT}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备名称'} name={'device_name'} rules={[
                            {required: true, message: '请输入设备名称!'},
                            {type: "string", max: 32, message: '设备名称不得大于32位!'}]}>
                            <Input placeholder={'请输入设备名称'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'规格型号'} name={'device_specification'} rules={[
                            {required: true, message: '请输入规格型号!'},
                            {type: "string", max: 32, message: '规格型号不得大于32位!'}]}>
                            <Input placeholder={'请输入规格型号'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'设备类型'} name={'device_type_id'} rules={[
                            {required: true, message: '请选择设备类型!'}]}>
                            <Select placeholder={'请选择设备类型'}>
                                {
                                    typeList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'存放位置'} name={'position_id'} rules={[
                            {required: true, message: '请选择存放位置!'}]}>
                            <Select placeholder={'请选择存放位置'}>
                                {
                                    locList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'备注'} name={'remarks'} rules={[{max: 200, message: '备注不得大于200位！'}]}>
                            <Input.TextArea placeholder={'请输入备注'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                {/*    使用情况*/}
                <div className={'viewTitle'}>使用情况</div>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'当前状态'} name={'status'} rules={[
                            {required: true, message: '请选择当前状态!'}]}>
                            <Select placeholder={'请选择当前状态'}>
                                {
                                    fileDeviceStatus.map(e => <Select.Option key={e.id}
                                                                             value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'使用部门'} name={'organization_id'} rules={[
                            {required: true, message: '请选择使用部门!'}]}>
                            <TreeSelect placeholder={'请选择部门'} showSearch treeData={organizationList}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'负责人'} name={'head_user_id'} rules={[
                            {required: true, message: '请选择负责人!'}]}>
                            <Select placeholder={'请选择负责人'}>
                                {
                                    userList.map(e => <Select.Option key={e.object_id}
                                                                     value={e.object_id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'联系方式'} name={'contact'} rules={[
                            {required: true, message: '请输入联系方式!'}]}>
                            <Input placeholder={'请输入联系方式'}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'启用日期'} name={'activation_date'} rules={[
                            {required: true, message: '请选择启用日期!'}]}>
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                {/*    设备图片*/}
                <div className={'viewTitle'}>设备图片</div>
                <UploadPictures fileList={fileList} setFileList={setFileList}/>
                <Divider/>
                {/*    关联文档*/}
                <div className={'files-flex'}>
                    <div className={'viewTitle'}>关联文档</div>
                    <Upload className={'files-upload'} multiple showUploadList={false}
                            accept={'.doc,.docx,.pdf,.xlsx,.xls,.dwg'}
                            customRequest={customRequestDoc}>
                        <Button type={'primary'}><PlusOutlined/>上传</Button>
                    </Upload>
                </div>
                <Table columns={columns} dataSource={tempDataSource} pagination={false}/>
                {/*预览文档*/}
                <Modal open={previewFileOpen} title={previewFileTitle} footer={null} width={'90%'}
                       onCancel={handleCancelFile} children={<div style={{width:'100%',height: '900px'}}>{previewChildren}</div>}/>
            </Form>

        </div>
    )
}
export default AddOrEditDevice;
