import React from 'react';
import '../globalLess/global.css'
import { Button, Dropdown as AntdDropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons';
declare type OverlayFunc = () => React.ReactElement;

export default class Dropdown extends React.Component<{
    require?: number,
    title: string,
    overlay :React.ReactElement | OverlayFunc,
    defaultValue: string | null,
    placeholder?: string,
    dropdownButtonStyle?: React.CSSProperties,
    titleStyle?: React.CSSProperties,
    disabled?: boolean,
    navigateClick?: ()=> void
}> {
    
    render() {
        const {
            require,
            title,
            defaultValue,
            placeholder,
            dropdownButtonStyle,
            titleStyle,
            disabled,
            navigateClick
        } = this.props
        return (
            <div className='inputStyle'
            >
                {
                    require &&
                    <div className='inputRequire'>*</div>
                }
                <div
                    className='dropdownTitle'
                    style={titleStyle}
                    onClick={()=>{
                        navigateClick && navigateClick()
                    }}
                    >{title}</div>
                <AntdDropdown
                  {...this.props}
                >
                    <Button className='dropdownButton' style={dropdownButtonStyle}>
                        <div className='dropdownButtonText' 
                        style={{ overflow:'hidden', whiteSpace:'nowrap',textOverflow:'ellipsis' , color:disabled ? '#BFBFBF ' : ( defaultValue   ? '#333333' : '#BFBFBF ') }}>{defaultValue ? defaultValue : placeholder}</div>
                        <div className='dropdownButtonIcon'> <DownOutlined /></div>
                    </Button>
                </AntdDropdown>
            </div>
        )
    }
}

