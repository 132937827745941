import React, { useContext, useEffect, useState } from "react";
import InputMenu from "./inputMenu";
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../globalLess/global.css'
import AddModal from "./Modal/addModal";
import CheckModal from "./Modal/checkModal";
import EditModal from "./Modal/editModal";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/userContext";
import { getAllProduct, getProductCreate_or_update, productDelete } from "../../../service/index";
import { toastErrorMessage, toastSuccessMessage, toastMessage } from "../../../utils";
import DeleteModal from "../../Production/components/deleteModal";
import moment from "moment";
const ExportJsonExcel = require('js-export-excel')
const Production = (): React.ReactElement => {
    const columns: ColumnsType<API.productItem> = [
        {
            title: '产品代码',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '产品名称',
            dataIndex: 'product_name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '单位',
            dataIndex: 'unit_name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '规格型号',
            dataIndex: 'specification',
            width: 400,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 400,
            ellipsis: true,
        },
        {
            title: '操作',
            dataIndex: 'object_id',
            width: 200,
            render: (object_id: number, data: API.productItem) =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setProductItem(data)
                            setShowCheckModal(true)
                        }}
                    >
                        查看
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setProductItem(data)
                            setShowEditModal(true)
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setDeleteObjectString([data.object_id])
                            setIsShowDelete(true)
                        }}
                    >
                        删除
                    </div>
                </div>
        }
    ];
    let [code, setCode] = useState('');
    let [product_name, setProduct_name] = useState('');
    let [productItem, setProductItem] = useState<API.productItem>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        product_name: '',
        product_unit_id: 0,
        remark: '',
        specification: '',
        unit_code: '',
        unit_name: '',
        update_time: ''
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCheckModal, setShowCheckModal] = useState(false);
    let [dataSource, setDataSource] = useState<API.productItem[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const userInfo = useContext(UserContext)
    const navigate = useNavigate();
    const [isHas, setIsHas] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowMoreDelete, setIsShowMoreDelete] = useState(false);
    const [deleteObjectString, setDeleteObjectString] = useState<number[]>([]);

    const queryProduct = () => {
        onFinish(1, pageSize, {
            code: code,
            product_name: product_name
        })
    }

    const BatchDelete = () => {
        const num = selectedRowKeys.length
        let objArray = []
        for (let index = num - 1; index >= 0; index--) {
            let indexItem: any = selectedRowKeys[index]
            indexItem = indexItem - (currentPage - 1) * pageSize
            const item = dataSource[indexItem]
            objArray.push(item.object_id)
        }
        ProductItemDelete(objArray)
        setSelectedRowKeys([])
    }
    const editProductItem = (newItem: API.productItem) => {
        let requestDic: any = {}
        requestDic.product_id = newItem.object_id
        if (productItem.product_name !== newItem.product_name) {
            requestDic.product_name = newItem.product_name
        }
        requestDic.product_unit_id = newItem.product_unit_id
        if (newItem.specification) {
            requestDic.specification = newItem.specification
        }
        if (newItem.remark) {
            requestDic.remark = newItem.remark
        }
        getProductCreate_or_update(requestDic).then((res) => {
            if (res.code === 200) {
                setShowEditModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('编辑成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const addProductItem = (newItem: API.productAddItem) => {
        let requestDic: any = {}
        if (newItem.code) {
            requestDic.code = newItem.code
        }
        requestDic.product_name = newItem.product_name
        requestDic.product_unit_id = newItem.product_unit_id
        if (newItem.specification) {
            requestDic.specification = newItem.specification
        }
        if (newItem.remark) {
            requestDic.remark = newItem.remark
        }
        getProductCreate_or_update(requestDic).then((res) => {
            if (res.code === 200) {
                setShowAddModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('新增成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const ProductItemDelete = (object_ids: number[]) => {
        let objString: any;
        for (let index = 0; index < object_ids.length; index++) {
            if (index === 0) {
                objString = object_ids[index]
            } else {
                objString += ',' + object_ids[index]
            }
        }
        productDelete({ object_ids: objString }).then((res) => {
            if (res.code === 200) {
                setIsShowDelete(false)
                setIsShowMoreDelete(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const onFinish = (page: number, size: number, data?: {
        code?: string,
        product_name?: string
    }) => {
        let dict: any = { page_num: page, page_size: size }
        if (data && data.code) {
            dict.code = data.code
        }
        if (data && data.product_name) {
            dict.product_name = data.product_name
        }
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        getAllProduct(dict).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let dataCopy = []
                for (let index = 0; index < res.data.data.length; index++) {
                    let item = res.data.data[index]
                    item.key = index + (page - 1) * size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            toastErrorMessage(err.message)
            setIsLoading(false)
        })
    }
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const exportFile = () => {
        let dict: any = { page_num: 1, page_size: total }
        if (code) {
            dict.code = code
        }
        if (product_name) {
            dict.product_name = product_name
        }

        setIsLoading(true)
        getAllProduct(dict).then((res: any) => {
            if (res.code === 200) {
                let dataSourceCopy: any = res.data.data
                if (dataSourceCopy.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    dataSourceCopy.forEach((item: any) => {
                        dataTable.push({
                            code: item.code,
                            product_name: item.product_name,
                            unit_name: item.unit_name,
                            specification: item.specification,
                            remark: item.remark
                        })
                    })
                    option.fileName = '产品信息' + moment(new Date()).format('YYYYMMDD')
                    option.datas = [
                        {
                            sheetData: dataTable,
                            sheetName: 'sheet',
                            sheetFilter: ['code', 'product_name', 'unit_name', 'specification', 'remark'],
                            sheetHeader: ['产品代码', '产品名称', '单位', '规格型号', '备注'],
                        }
                    ];
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            toastErrorMessage(err.message)
            setIsLoading(false)
        })
    };

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        userInfo.useInfo.authRoutes?.map((item: any) => {
            if (item === '/basic/company') {
                setIsHas(true)
            }
        })
        onFinish(1, 10);
    }, [])
    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999' }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <InputMenu productionNum={code} productionName={product_name}
                    codeOnChange={(num) => {
                        setCode(num)
                    }}
                    nameOnChange={(name) => {
                        setProduct_name(name)
                    }}
                    queryClick={() => {
                        queryProduct()
                    }}
                    resetClick={() => {
                        setCode('')
                        setProduct_name('')
                        onFinish(1, pageSize);
                    }}
                    addClick={() => {
                        setShowAddModal(true)
                    }}
                    deleteClick={() => {
                        if (selectedRowKeys.length > 0) {
                            setIsShowMoreDelete(true)
                        } else {
                            toastMessage('请选择要删除的产品数据')
                        }

                    }}
                    exportClick={() => {
                        exportFile()
                    }}
                />
            </div>
            <div >
                <Table
                    className={'commonTable'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{ x: 1600 }}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                />
            </div>
            {
                showAddModal && <AddModal
                    isHas={isHas}
                    navigate={navigate}
                    closeClick={() => {
                        setShowAddModal(false)
                    }}
                    saveClick={(e) => {
                        addProductItem(e)
                    }}
                />
            }
            {
                showEditModal &&
                <EditModal
                    isHas={isHas}
                    navigate={navigate}
                    productItem={productItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(e) => {
                        editProductItem(e)
                    }}
                />
            }
            {
                showCheckModal && <CheckModal
                    productItem={productItem}
                    closeClick={() => {
                        setShowCheckModal(false)
                    }}
                />
            }
            {
                isShowDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowDelete(false)
                    }}
                    confirmClick={() => {
                        ProductItemDelete(deleteObjectString)
                    }}
                />
            }
            {
                isShowMoreDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowMoreDelete(false)
                        setSelectedRowKeys([])
                    }}
                    confirmClick={() => {
                        BatchDelete()
                    }}
                />
            }
        </div>
    )
}
export default Production
