import {message} from "antd";
import {getExceptionTypeList} from "../service";
import {IDayData, IHourData, IMonthData, IOptions} from "../pages/Inspection/components/SetPlan";
import {Options} from "../components/GeneralManagement";
import {RcFile} from "antd/es/upload";
import moment from "moment";


export const filterData = (data: object) => {
    const params: any = data;
    for (let [key, value] of Object.entries(data)) {
        if (value === null || value === undefined || value === '' || value === '[]') {
            delete params[key]
        }
    }
    return params;
}

export const toastMessage = (msg: string) => {
    message.config({top: window.innerHeight / 2});
    message.warn(msg)
}

export const toastErrorMessage = (msg: string) => {
    message.config({top: window.innerHeight / 2});
    message.error(msg)
}

export const toastSuccessMessage = (msg: string) => {
    message.config({top: window.innerHeight / 2});
    message.success(msg)
}

export const getFormData = (values: object, convertToStr?: boolean) => {
    //之前几版参数都是以[a,b]形式传递，1.3版本开始有些接口变成'[a,b]'形式
    const formData = new FormData();
    Object.entries(values).map(e => {
        //convertToStr为true时以字符串传递，false时添加【】
        if (convertToStr) {
            if (typeof e[1] === 'object' && e[1] instanceof Array) {
                formData.append(e[0], JSON.stringify(e[1]))
            } else {
                formData.append(e[0], e[1])
            }
        } else {
            //将数组转换为"[a,b]"这种格式,并不是"a,b"
            if (typeof e[1] === 'object' && e[1] instanceof Array) {
                formData.append(e[0], "[" + e[1] + "]")
            } else {
                formData.append(e[0], e[1])
            }
        }
    })
    return formData;
}

export const getStatus = (type: string) => {
    if (type === 'query') {
        return [
            {
                name: '未派工',
                value: '20',
            },
            {
                name: '已派工',
                value: '30',
            },
            {
                name: '处理中',
                value: '40',
            },
            {
                name: '已完成',
                value: '50',
            }
        ]
    }
    if (type === 'report') {
        return [
            {
                name: '已派工',
                value: '30',
            },
            {
                name: '处理中',
                value: '40',
            },
            {
                name: '已完成',
                value: '50',
            }
        ]
    }
    return []
}

//封装当前用户所拥有的菜单
export const getMenus = (values: any) => {
    const menus: any[] = []
    values.map((e: any) => {
        if (!e.parent_id) {
            e.children = []
        }
    })
    values.map((e: any) => {
        if (e.is_have) {
            if (e.parent_id) {
                const parent = values.find((item: any) => item.object_id === e.parent_id);
                parent.children = parent.children || [];
                parent.children.push({
                    ...e,
                    is_valid: e.is_valid.toString()
                })
            } else {
                menus.push({
                    ...e,
                    is_valid: e.is_valid.toString()
                })
            }
        }
    })
    const getSort = (array: any) => {
        array.sort((a: any, b: any) => {
            if (a.object_id < b.object_id) {
                return -1
            } else {
                return 1
            }
        })
    }
    getSort(menus);
    menus.map(e => {
        if (e.children) {
            getSort(e.children);

        }
    })
    return menus
}

//封装所有的菜单
export const getAllMenus = (values: any) => {
    const menus: any[] = []
    values.map((e: any) => {
        if (!e.parent_id) {
            e.children = []
        }
    })
    values.map((e: any) => {
        if (e.parent_id) {
            const parent = values.find((item: any) => item.object_id === e.parent_id);
            parent.children = parent.children || [];
            parent.children.push({
                ...e,
                key: e.object_id.toString(),
                is_valid: e.is_valid.toString()
            })
        } else {
            menus.push({
                ...e,
                key: e.object_id.toString(),
                is_valid: e.is_valid.toString()
            })
        }
    })
    const getSort = (array: any) => {
        array.sort((a: any, b: any) => {
            if (a.object_id < b.object_id) {
                return -1
            } else {
                return 1
            }
        })
    }
    getSort(menus);
    menus.map(e => {
        if (e.children) {
            getSort(e.children);
        }
    })
    return menus
}

//封装组织机构树结构
export const getTreeData = (values: any[]) => {
    const getArray = (array: any[]) => {
        let temp: any[] = []
        array.map(e => {
            if (e.children) {
                temp.push({
                    title: e.organization_name,
                    value: e.object_id,
                    key: e.object_id,
                    children: getArray(e.children)
                })
            } else {
                temp.push({
                    title: e.organization_name,
                    value: e.object_id,
                    key: e.object_id
                })
            }
        })
        return temp
    }
    return getArray(values)
}

export interface indexType {
    [key: number]: string,
}

export const sexes: indexType = {
    0: '女',
    1: '男'
}

export const statuses: indexType = {
    0: '已禁用',
    1: '已启用',
}

export const reportStatus: indexType = {
    30: '已派工',
    40: '处理中',
    50: '已完成',
}

export const showError = (text: string) => {
    message.error(text)
}

//数量、金额等分位显示
export const formatNumber = (value: string) => value.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')

//异常类别
export const getCategoryList = () => {
    return new Promise(resolve => {
        getExceptionTypeList().then(res => {
            if (res.code === 200) {
                resolve(res.data.data)
            } else {
                showError(res.message);
            }
        })
    })
}

//异常提报状态
export const EXCReportStatus: indexType = {
    10: '编辑中',
    20: '已提报',
    30: '处理中',
    40: '已完成',
    50: '已关闭',
}

//异常处理状态
export const EXCHandleStatus = () => {
    return [
        {id: 20, name: '已提报'},
        {id: 30, name: '处理中'},
        {id: 40, name: '已完成'},
        {id: 50, name: '已关闭'},
    ]
}

//入库类型
export const typeList = [
    {id: 10, name: '采购入库'},
    {id: 20, name: '生产入库'},
    {id: 30, name: '销售退货'},
]

//出库类型
export const outTypeList = [
    {id: 10, name: '销售出库'},
    {id: 20, name: '生产领料'},
]

//入库状态
export const statusList: indexType = {
    10: '未提交',
    20: '已提交',
}



//设备存放位置状态
export const deviceStatus: any[] = [
    {id: 0, name: '停用', color: '#EB4420'},
    {id: 1, name: '启用', color: '#06C270'},
]

//设备档案-设备状态
export const fileDeviceStatus: any[] = [
    {id: 10, name: '正常运行', color: '#06C270'},
    {id: 20, name: '故障', color: '#FE9800'},
]

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

//工单状态
export const workOrderStatus: any[] = [
    {id: 20, name: '已提报', color: '#06C270'},
    {id: 30, name: '处理中', color: '#FF8800'},
    {id: 40, name: '已完成', color: '#3E7BFA'},
]

//故障报修状态
export const faultStatus: any[] = [
    {id: 10, name: '编辑中', src: require('../assets/status/edit.png'), color: '#F8CA16'},
    {id: 20, name: '已提报', src: require('../assets/status/report.png'), color: '#06C270'},
    {id: 30, name: '处理中', src: require('../assets/status/handle.png'), color: '#FF8800'},
    {id: 40, name: '已完成', src: require('../assets/status/finish.png'), color: '#3E7BFA'},
]

//是否停机
export const isHalt: Options[] = [
    {id: 0, name: '是'},
    {id: 1, name: '否'},
]

//故障等级
export const troubleLevel: Options[] = [
    {id: 0, name: '紧急'},
    {id: 1, name: '高'},
    {id: 2, name: '中'},
    {id: 3, name: '低'},
]

//巡检任务状态
export const inspectionTaskStatus: any[] = [
    {id: 10, name: '未开始', color: '#B93027'},
    {id: 20, name: '处理中', color: '#EC933D'},
    {id: 30, name: '已完成', color: '#5881F2'},
    {id: 40, name: '已跳过', color: '#6D6D6D'},
]

//分转换成时分-取商/取余%
export const convertMinToHHmm = (value: number) => {
    //取整数部分
    const hour = Math.trunc(value / 60);
    //取余数部分
    const minute = value % 60;
    return {hour, minute}
}

//秒转换为时分
export const convertSecToHHmm = (value: number) => {
    const hour = Math.trunc(value / 3600);
    const minute = Math.trunc((value - hour * 3600) / 60);
    return {hour, minute}
}

//时分转换成秒
export const convertHHmmToSec = (hour: number, minute: number) => {
    return minute * 60 + hour * 60 * 60;
}

//过滤时间参数
/**
 *
 * @param values 表单元素对象
 * @param param 需要过滤的参数名称日期
 * @param startParams 过滤好的参数开始日期
 * @param endParam 过滤好的参数结束日期
 */
export const filterDateParam = (values: any, param: string, startParams: string, endParam: string): Object => {
    if (Object.keys(values).includes(param)) {
        let temp = {
            ...values,
            [startParams]: moment(values[param][0]).format('YYYY-MM-DD'),
            [endParam]: moment(values[param][1]).format('YYYY-MM-DD'),
        }
        delete temp[param];
        return temp
    } else {
        return {...values}
    }
}

export function deBounce(func:any, wait:number) {
    let timeOut:any = null;
    return function (...args: any) {
        clearTimeout(timeOut);//一定要清除定时器
        timeOut = setTimeout(() => {
            func(...args);
        }, wait);
    };
}
