import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import SelectFormOutput from "../components/selectFormOutput";
import {getStatisticsOutputRequest} from "../../../service";
import {toastErrorMessage} from "../../../utils";
import '../../Statistics/components/index.css';
import '../components/index.css'

/**
 * 产量统计
 */
const ExportJsonExcel = require('js-export-excel')

interface DataKey {
    [index: string]: any;
}

const Output = (): React.ReactElement => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([
        {title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%'},
        {title: '产品代码', dataIndex: 'product_code', align: 'center', ellipsis: true},
        {title: '产品名称', dataIndex: 'product_name', align: 'center', ellipsis: true},
        {title: '规格型号', dataIndex: 'product_specification', align: 'center', ellipsis: true},
        {title: '单位', dataIndex: 'unit', align: 'center', ellipsis: true},
        {title: '工序', dataIndex: 'work_process', align: 'center', ellipsis: true},
        {
            title: '合计数量',
            dataIndex: 'qualified',
            align: 'center',
            ellipsis: true,
            render: (num: number) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        }
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [startTime, setStartTime] = useState(moment(new Date()).startOf('month').format('YYYY/MM/DD'));
    const [endTime, setEndTime] = useState(moment(new Date()).endOf('month').format('YYYY/MM/DD'));
    const [name, setName] = useState('');
    const [creator, setCreator] = useState<undefined | number>(undefined);
    const [process, setProcess] = useState<undefined | number>(undefined);

    useEffect(() => {
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        startTime: string,
        endTime: string,
        name: string,
        creator: undefined | number,
        process: undefined | number
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page: page, per_page: size}
        if (data) {
            if (data.startTime !== '') {
                param = {...param, start_date: moment(data.startTime).format('YYYY-MM-DD')}
            }
            if (data.endTime !== '') {
                param = {...param, end_date: moment(data.endTime).format('YYYY-MM-DD')}
            }
            if (data.name !== '') {
                param = {...param, product: data.name}
            }
            if (data.creator) {
                param = {...param, user: data.creator}
            }
            if (data.process) {
                param = {...param, work_process: data.process}
            }
        } else {
            if (startTime !== '') {
                param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
            }
            if (endTime !== '') {
                param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
            }
            if (name !== '') {
                param = {...param, product: name}
            }
            if (creator) {
                param = {...param, user: creator}
            }
            if (process) {
                param = {...param, work_process: process}
            }
        }
        getStatisticsOutputRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let dataSource: any[] = []
                let dataColumns: any[] = [
                    {title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: 80},
                    {title: '产品代码', dataIndex: 'product_code', align: 'center', ellipsis: true, width: 120},
                    {title: '产品名称', dataIndex: 'product_name', align: 'center', ellipsis: true, width: 120},
                    {title: '规格型号', dataIndex: 'product_specification', align: 'center', ellipsis: true, width: 120},
                    {title: '单位', dataIndex: 'unit', align: 'center', ellipsis: true, width: 120},
                    {title: '工序', dataIndex: 'work_process', align: 'center', ellipsis: true, width: 120},
                    {
                        title: '合计数量',
                        dataIndex: 'qualified',
                        align: 'center',
                        ellipsis: true,
                        width: 120,
                        render: (num: number) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
                    }
                ]
                res.data.data.forEach((item, index) => {
                    let dataSourceItem: DataKey = {
                        no: index + 1,
                        product_code: item.product_code,
                        product_name: item.product_name,
                        product_specification: item.product_specification,
                        unit: item.unit,
                        work_process: item.work_process,
                        qualified: item.qualified,
                    }

                    const final: API.dateItem[] = []
                    if (data) {
                        let temp = moment(data.startTime)
                        while (temp.isSameOrBefore(moment(data.endTime), 'd')) {
                            const find = item.detail.find((i) => i.date === temp.format('MM/DD'))
                            if (find) {
                                final.push(find)
                            } else {
                                let notFind: API.dateItem = {
                                    date: temp.format('MM/DD'),
                                    quantity: 0,
                                }
                                final.push(notFind)
                            }
                            temp.add(1, 'd')
                        }
                    } else {
                        let temp = moment(startTime)
                        while (temp.isSameOrBefore(moment(endTime), 'd')) {
                            const find = item.detail.find((i) => i.date === temp.format('MM/DD'))
                            if (find) {
                                final.push(find)
                            } else {
                                let notFind: API.dateItem = {
                                    date: temp.format('MM/DD'),
                                    quantity: 0,
                                }
                                final.push(notFind)
                            }
                            temp.add(1, 'd')
                        }
                    }

                    final.length > 0 && final.forEach((dateItem) => {
                        dataSourceItem[dateItem.date] = dateItem.quantity
                        if (index === 0) {
                            dataColumns.push({
                                title: dateItem.date,
                                dataIndex: dateItem.date,
                                align: 'center',
                                ellipsis: true,
                                width: 100,
                                render: (num: number) =>
                                    <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
                            })
                        }
                    })
                    dataSource.push(dataSourceItem)
                })
                setColumns(dataColumns)
                setDataSource(dataSource)
            } else {
                toastErrorMessage(res.message);
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        });
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div>
            <SelectFormOutput
                queryClick={(startTime: string, endTime: string, name: string, creator: undefined | number, process: undefined | number) => {
                    setStartTime(startTime)
                    setEndTime(endTime)
                    setName(name)
                    setCreator(creator)
                    setProcess(process)
                    onFinish(1, pageSize, {
                        startTime: startTime,
                        endTime: endTime,
                        name: name,
                        creator: creator,
                        process: process,
                    })
                }}
                output={() => {
                    if (total > 0) {
                        let param: any = {page: 1, per_page: total}
                        if (startTime !== '') {
                            param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
                        }
                        if (endTime !== '') {
                            param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
                        }
                        if (name !== '') {
                            param = {...param, product: name}
                        }
                        if (creator) {
                            param = {...param, user: creator}
                        }
                        if (process) {
                            param = {...param, work_process: process}
                        }
                        getStatisticsOutputRequest(param).then((res) => {
                            if (res.code === 200) {
                                let option: { fileName?: string, datas?: any[] } = {};
                                let dataTable: any[] = [];
                                let sheetHeaderArray = ['No', '产品代码', '产品名称', '规格型号', '单位', '工序', '合计数量']
                                let sheetFilterArray = ['no', 'product_code', 'product_name', 'product_specification', 'unit', 'work_process', 'qualified']
                                res.data.data.length > 0 && res.data.data.forEach((item, index) => {
                                    let obj: DataKey = {
                                        no: index + 1,
                                        product_code: item.product_code,
                                        product_name: item.product_name,
                                        product_specification: item.product_specification,
                                        unit: item.unit,
                                        work_process: item.work_process,
                                        qualified: item.qualified,
                                    }

                                    const temp = moment(startTime)
                                    const final: API.dateItem[] = []
                                    while (temp.isSameOrBefore(moment(endTime), 'd')) {
                                        const find = item.detail.find((i) => i.date === temp.format('MM/DD'))
                                        if (find) {
                                            final.push(find)
                                        } else {
                                            let notFind: API.dateItem = {
                                                date: temp.format('MM/DD'),
                                                quantity: 0,
                                            }
                                            final.push(notFind)
                                        }
                                        temp.add(1, 'd')
                                    }

                                    final.length > 0 && final.forEach((dateItem) => {
                                        obj[dateItem.date] = dateItem.quantity
                                        if (index === 0) {
                                            sheetHeaderArray.push(dateItem.date)
                                            sheetFilterArray.push(dateItem.date)
                                        }
                                    })
                                    dataTable.push(obj);
                                })
                                option.fileName = '产量统计' + moment(new Date()).format('YYYYMMDD')
                                option.datas = [
                                    {
                                        sheetData: dataTable,
                                        sheetName: 'sheet',
                                        sheetHeader: sheetHeaderArray,
                                        sheetFilter: sheetFilterArray,
                                    }
                                ];
                                let toExcel = new ExportJsonExcel(option);
                                toExcel.saveExcel();
                            } else {
                                toastErrorMessage('导出失败')
                            }
                        }).catch(() => {
                            toastErrorMessage('导出失败')
                        });
                    }
                }}
            />
            <Table
                className={'commonTable'}
                dataSource={dataSource}
                scroll={{x: 1500}}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />
        </div>
    )
}
export default Output
