import React, {useContext, useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Table, TableColumnsType} from "antd";
import SelectFormOrderManage from "../components/selectFormOrderManage";
import CheckOrderModal from "../components/checkOrderModal";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import DeleteModal from "../../Production/components/deleteModal";
import OrderModal from "../components/orderModal";
import {
    callbackOrderRequest,
    deleteOrderRequest,
    dispatchOrderRequest,
    getOrderListRequest,
    newOrEditOrderRequest
} from "../../../service";
import {UserContext} from "../../../contexts/userContext";
import {toastErrorMessage, toastSuccessMessage} from "../../../utils";

/**
 * 订单管理
 */
const OrderManagement = (): React.ReactElement => {
    const userInfo = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.orderItem[]>([]);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCheckModal, setShowCheckModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dataItem, setDataItem] = useState<API.orderItem | undefined>(undefined);
    const [isHasBusinessman, setIsHasBusinessman] = useState(false);
    const [isHasProduct, setIsHasProduct] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [customName, setCustomName] = useState('');
    const [sellNum, setSellNum] = useState('');

    const columns: ColumnsType<API.orderItem> = [
        {
            key: 0, title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '5%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        Table.EXPAND_COLUMN,
        {key: 1, title: '销售单号', dataIndex: 'code', ellipsis: true,},
        {key: 2, title: '客户', dataIndex: 'trader_name', ellipsis: true,},
        {
            key: 3, title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true,
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD')}</div>
        },
        {key: 4, title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true,},
        {
            key: 5, title: '状态', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (operate: number) => <div
                style={{color: operate === 10 ? '#EB4420' : operate === 20 ? '#3E7BFA' : '#666666'}}>
                {
                    operate === 10 ? '未下发' : operate === 20 ? '已下发' : ''
                }
            </div>
        },
        {key: 6, title: '创建者', dataIndex: 'user_name', align: 'center', ellipsis: true,},
        {
            key: 7, title: '创建时间', dataIndex: 'create_time', align: 'center', ellipsis: true,
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            key: 8, title: '下发时间', dataIndex: 'issue_time', align: 'center', ellipsis: true,
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            key: 9,
            title: '操作',
            dataIndex: 'status',
            ellipsis: true,
            width: '9%',
            render: (operate: number, data) => {
                return operate === 10 ? <div style={{display: "flex", flexDirection: "row"}}>
                    <div className={'hasAuthority'} onClick={() => {
                        setDataItem(data)
                        setShowCheckModal(true)
                    }}>
                        查看
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             setDataItem(data)
                             setShowEditModal(true)
                         }}>
                        编辑
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             setDataItem(data)
                             setShowDeleteModal(true)
                         }}>
                        删除
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             dispatchOrderRequest({object_id: data.object_id}).then((res) => {
                                 if (res.code === 200) {
                                     toastSuccessMessage('下发成功')
                                     onFinish(currentPage, pageSize)
                                 } else {
                                     toastErrorMessage(res.message)
                                 }
                             }).catch((e: any) => {
                                 toastErrorMessage(e.message)
                             })
                         }}>
                        下发
                    </div>
                </div> : operate === 20 ? <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'hasAuthority'} onClick={() => {
                        setDataItem(data)
                        setShowCheckModal(true)
                    }}>
                        查看
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             callbackOrderRequest({object_id: data.object_id}).then((res) => {
                                 if (res.code === 200) {
                                     toastSuccessMessage('撤回成功')
                                     onFinish(currentPage, pageSize)
                                 } else {
                                     toastErrorMessage(res.message)
                                 }
                             }).catch((e: any) => {
                                 toastErrorMessage(e.message)
                             })
                         }}>
                        撤回
                    </div>
                </div> : null;
            }
        },
    ]

    useEffect(() => {
        userInfo.useInfo.authRoutes?.map((item) => {
            if (item === '/basic/businessman') {
                setIsHasBusinessman(true)
            } else if (item === '/basic/production') {
                setIsHasProduct(true)
            }
        })
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        customName: string,
        sellNum: string
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.customName !== '') {
                param = {...param, trader_name: data.customName}
            }
            if (data.sellNum !== '') {
                param = {...param, code: data.sellNum}
            }
        } else {
            if (customName !== '') {
                param = {...param, trader_name: customName}
            }
            if (sellNum !== '') {
                param = {...param, code: sellNum}
            }
        }
        getOrderListRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const deleteOrder = () => {
        deleteOrderRequest({object_id: dataItem!.object_id}).then((res) => {
            if (res.code === 200) {
                toastSuccessMessage('删除成功')
                setShowDeleteModal(false)
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const addOrEditOrder = (type: string, data: {
        code: string,
        traderId: number
        finishDate: string
        remark: string
        objectId?: number,
        orderProductInfo: Array<API.childOrderItem>
    }) => {
        let param: any = {
            trader_id: data.traderId,
            finish_date: data.finishDate,
            orderproduct_info: JSON.stringify(data.orderProductInfo)
        }
        if (type === 'edit') {
            param = {...param, object_id: data.objectId}
        }
        if (data.code !== '') {
            param = {...param, code: data.code}
        }
        if (data.remark !== '') {
            param = {...param, remark: data.remark}
        }
        newOrEditOrderRequest(param).then((res) => {
            if (res.code === 200) {
                if (type === 'edit') {
                    setShowEditModal(false)
                } else {
                    setShowNewModal(false)
                }
                toastSuccessMessage(res.message)
                onFinish(currentPage, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    const rowRender = (record: API.orderItem) => {
        const childColumns: TableColumnsType<API.childOrderItem> = [
            {key: 0, title: 'No', dataIndex: 'serial_num', align: 'center', ellipsis: true,},
            {key: 1, title: '产品', dataIndex: 'productinfo_name', align: 'center', ellipsis: true,},
            {
                key: 2, title: '数量', dataIndex: 'num', align: 'center', ellipsis: true,
                render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
            },
            {key: 3, title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true,},
            {
                key: 4, title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true,
                render: (date) => <div>{date === null || undefined ? '' : moment(date).format('YYYY/MM/DD')}</div>
            },
            {key: 5, title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true,},
        ];
        return (
            <Table
                className={'childTable'}
                style={{marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 120}}
                columns={childColumns}
                dataSource={record.orderproduct_info}
                pagination={false}
            />
        )
    };

    return (
        <div>
            <SelectFormOrderManage
                queryClick={(customName: string, sellNum: string) => {
                    setCustomName(customName)
                    setSellNum(sellNum)
                    onFinish(1, pageSize, {customName: customName, sellNum: sellNum})
                }}
                newAdd={() => {
                    setShowNewModal(true)
                }}
            />
            <Table
                className={'commonTable'}
                dataSource={dataSource}
                rowKey={'object_id'}
                scroll={{x: 2000}}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
                expandable={{
                    expandedRowRender: record => rowRender(record),
                    expandIcon: ({expanded, onExpand, record}) => record.orderproduct_info.length > 0 ? (expanded ?
                        <img style={{width: 30, height: 30, cursor: "pointer"}}
                             src={require('../../../assets/icon_expanded.png')}
                             alt=""
                             onClick={e => onExpand(record, e)}/> :
                        <img style={{width: 30, height: 30, cursor: "pointer"}}
                             src={require('../../../assets/icon_unexpanded.png')}
                             alt=""
                             onClick={e => onExpand(record, e)}/>) : null,
                    rowExpandable: record => record.orderproduct_info.length > 0,
                }}
            />

            {
                showNewModal && <OrderModal
                    type={'new'}
                    isHasBusinessman={isHasBusinessman}
                    isHasProduct={isHasProduct}
                    navigate={navigate}
                    closeClick={() => {
                        setShowNewModal(false)
                    }}
                    saveClick={(sellNum: string, customer: number, date: any, remark: string, detailOrderList: Array<API.childOrderItem>) => {
                        addOrEditOrder('new', {
                            code: sellNum,
                            traderId: customer,
                            finishDate: date,
                            remark: remark,
                            orderProductInfo: detailOrderList,
                        })
                    }}
                />
            }

            {
                showEditModal && <OrderModal
                    type={'edit'}
                    isHasBusinessman={isHasBusinessman}
                    isHasProduct={isHasProduct}
                    navigate={navigate}
                    dataItem={dataItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(sellNum: string, customer: number, date: any, remark: string, detailOrderList: Array<API.childOrderItem>, objectId: number | undefined) => {
                        addOrEditOrder('edit', {
                            code: sellNum,
                            traderId: customer,
                            finishDate: date,
                            remark: remark,
                            objectId: objectId,
                            orderProductInfo: detailOrderList,
                        })
                    }}
                />
            }

            {
                showCheckModal && <CheckOrderModal
                    dataItem={dataItem}
                    closeClick={() => {
                        setShowCheckModal(false)
                    }}
                />
            }

            {
                showDeleteModal && <DeleteModal
                    closeClick={() => {
                        setShowDeleteModal(false)
                    }}
                    confirmClick={() => {
                        deleteOrder()
                    }}
                />
            }
        </div>
    )
}
export default OrderManagement
