import React, {useRef, useState} from "react";
import GeneralManagement, {QueryListItem,GRef} from "../../../components/GeneralManagement";
import {Button, Form, message, Space} from "antd";
import ModalContainer from "../../../components/Modal";
import DeleteItem from "../../../components/DeleteItem";
import {ColumnsType} from "antd/es/table";
import AddOrEditPointProject from "./components/AddOrEditPointProject";
import {getPointProject,getPointProjectInfo,postDeletePointProject} from "../../../service";
import { getFormData } from "../../../utils";
import "../index.less";

enum ModalTitle {
    ADD = '新增点检项目',
    EDIT = '编辑点检项目',
    DELETE = '系统确认',
}


const PointProject = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [objectId, setObjectId] = useState<number>();
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const addOrEditRef = useRef<{handleSave:Function}>(null);
    const formList: QueryListItem[] = [
        {
            key: 'spot_name',
            name: '点检项目名称',
            placeholder: '请输入点检项目名称'
        },
        {
            key: 'spot_content',
            name: '点检内容',
            placeholder: '请输入点检内容'
        }
    ]
    const columns: ColumnsType<any>  = [
        {
            title: 'No',
            dataIndex: 'index',
            align: 'center',
            ellipsis:true,
            width:100,
            render:(_:any,__:any,index:number)=>index+1
        },
        {
            title: '点检项目代码',
            dataIndex: 'code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '点检项目名称',
            dataIndex: 'spot_name',
            align: 'left',
            ellipsis: true
        },
        {
            title: '点检部位',
            dataIndex: 'spot_area',
            align: 'center',
            ellipsis: true
        },
        {
            title: '点检方法',
            dataIndex: 'spot_method',
            align: 'center',
            ellipsis: true,
            render:(m:number) => <span>{m === 10 ? '目视' : m === 20 ? '手感' : m === 30 ? '看听' : '看/听' }</span>
        },
        {
            title: '点检内容',
            dataIndex: 'spot_content',
            align: 'left',
            ellipsis: true
        },
        {
            title: '参数名称',
            dataIndex: 'parameter_name',
            align: 'center',
            ellipsis:true
        },
        {
            title: '点检结果类型',
            dataIndex: 'spot_type',
            align: 'center',
            ellipsis: true,
            render:(t:number) => <span>{t === 10 ? '数值' : t === 20 ? '单选' : '多选'} </span>
        },
        {
            title: '上限',
            dataIndex: 'upper_limit',
            align: 'center',
            ellipsis:true
        },
        {
            title: '下限',
            dataIndex: 'lower_limit',
            align: 'center',
            ellipsis:true
        },
        {
            title: '点检结果选项',
            dataIndex: 'results_options',
            align: 'center',
            ellipsis: true,
            render:(arr:Array<string>) => <span>{arr.join('；')}</span>
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            align: 'left',
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size='large' style={{whiteSpace:'nowrap'}}>
                    <span className='mes-options' onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className='mes-options' onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },

    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className='mes-patrol-project-add' onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))


    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            setInnerComponent(<AddOrEditPointProject ref={addOrEditRef} form={form} afterOnOK={afterOnOK}/>)
            setOpen(true)
        } else if (title === ModalTitle.EDIT) {
            getPointProjectInfo({
                object_id:item.object_id
            }).then(res => {
                if(res.code === 200) {
                    setInnerComponent(<AddOrEditPointProject ref={addOrEditRef} form={form} afterOnOK={afterOnOK} isEdit={true} selectItem={res.data}/>)
                    setOpen(true)
                } else {
                    message.warning(res.message)
                }
            }).catch(err => console.log('err',err)) 
            
        } else if (title === ModalTitle.DELETE) {
            setObjectId(item.object_id)
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
            setOpen(true)
        }
        
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
          form.validateFields().then(res => {
            addOrEditRef && addOrEditRef.current && addOrEditRef.current.handleSave(res)
          }).catch(err => console.log('err',err))
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(res => {
                addOrEditRef && addOrEditRef.current && addOrEditRef.current.handleSave(res);
            }).catch(err => console.log('err',err))
        } else if (title === ModalTitle.DELETE) {
            postDeletePointProject(getFormData({object_id:objectId})).then(res => {
                res.code === 200 ? afterOnOK('删除成功') : message.warning(res.message)
            }).catch(err => console.log('err',err))
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }


    const getDataAsync = (queryCondition: API.getPartrolPointParams) => {
        return new Promise((resolve) => {
            getPointProject(queryCondition).then(res => {
                res.code === 200 ? resolve(res.data) : message.warning(res.message)
            }).catch(err => console.log('err',err))
        })
    }


    return (
        <div className='mes-patrol-point-project'>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => setOpen(false)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                innerComponent={innerComponent} width={modalTitle === "系统确认"? "20%" : "1090px"}/>
            }
        </div>
    )
}
export default PointProject;
