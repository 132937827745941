import React, {useRef, useState, useEffect} from "react";
import GeneralManagement, {QueryListItem,GRef} from "../../../components/GeneralManagement";
import {Form, message, Image} from "antd";
import ModalContainer from "../../../components/Modal";
import {ColumnsType} from "antd/es/table";
import TaskReport from "./components/TaskReport";
import {getPointPlan,getPointReportList,postPointTaskStart, getPointReportInfo, getPointTaskInfo} from "../../../service";
import {IPlans} from "../PatrolTask/index";
import moment from "moment";
import { getFormData,filterDateParam } from "../../../utils";
import ShowTask  from "../PointTask/components/ShowTask";
import showTaskImage from "../../../assets/show-task.png";


const PointReport = (): React.ReactElement => {
    const [open, setOpen] = useState<boolean>(false);
    const [showTaskOpen,setShowTaskOpen] = useState<boolean>(false);
    const [plans,setPlans] = useState<IPlans[]>([]);
    const [selectItem,setSelectItem] = useState<any>();
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const completeRef = useRef<{handleSave:Function}>(null);
    const formList: QueryListItem[] = [
        {
            key: 'spot_plan_ids',
            name: '计划名称',
            placeholder: '请选择计划名称',
            options: plans,
            multiple: true
        },
        {
            key: 'date',
            name: '应执行时间',
            placeholder: '请输入应执行时间',
            rangePick: true
        },
        {
            key: 'status',
            name: '任务状态',
            placeholder: '请输入任务状态',
            options: [{id:10,name:"未开始"},{id:20,name:"处理中"},{id:30,name:"已完成"}],
            multiple: true
        }
    ]
    const columns: ColumnsType<any>  = [
        {
            title: 'No',
            dataIndex: 'index',
            align: 'center',
            ellipsis: true,
            width:60,
            fixed:true,
            render:(_:any,__:any,index:number)=>index+1
        },
        {
            title: '点检任务单号',
            dataIndex: 'code',
            align: 'center',
            ellipsis: true,
            width: 220,
            render:(text:string,record:any) => <span className="task-number" onClick={()=>{
                getPointTaskInfo({object_id:record.object_id}).then(res => {
                    if(res.code === 200 && res.data) {
                        setSelectItem(res.data);
                        setShowTaskOpen(true);
                    }else {
                        message.warning(res.message);
                    }
                }).catch(err => console.log(err))
            }}>{text}</span>
        },
        {
            title: '计划名称',
            dataIndex: 'spot_plan_name',
            align: 'left',
            ellipsis: true,
            width:100
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            align: 'left',
            ellipsis: true
        },
        {
            title: '规格型号',
            dataIndex: 'device_specification',
            align: 'center',
            ellipsis: true
        },
        {
            title: '应执行时间',
            dataIndex: 'should_execution_time',
            align: 'center',
            ellipsis: true,
            width:150,
            render:(i:string) => <span>{i && moment(i).format("YYYY-MM-DD HH:mm")}</span>
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render:(text:number) => {
                if(text === 10) {
                    return <span className="status-noStart">未开始</span>
                } else if(text === 20) {
                    return <span className="status-processing">处理中</span>
                } else {
                    return <span className="status-have-done">已完成</span>
                }
            }
        },
        {
            title: '点检班组',
            dataIndex: 'team_groups',
            align: 'left',
            ellipsis: true
        },
        {
            title: '点检负责人',
            dataIndex: 'spot_user_name',
            align: 'left',
            width:120,
            ellipsis: true
        },
        {
            title: '实际开始时间',
            dataIndex: 'real_start_date',
            align: 'center',
            ellipsis: true,
            width:150,
            render:(i:string) => <span>{i && moment(i).format("YYYY/MM/DD HH:mm")}</span>
        },
        {
            title: '实际结束时间',
            dataIndex: 'real_end_date',
            align: 'center',
            ellipsis: true,
            width:150,
            render:(i:string) => <span>{i && moment(i).format("YYYY/MM/DD HH:mm")}</span>
        },
        {
            title: '执行结果',
            dataIndex: 'results_of_execution',
            align: 'center',
            ellipsis: true,
            render:(i:number) => <span>{i ? i === 10 ? '正常' : '异常' : ''}</span>
        },
        {
            title: '操作',
            dataIndex: 'options',
            align: 'center',
            render: (_: any, record: any) => {
                if(record.status === 10) {
                    return <span className='mes-options' onClick={() => {
                        postPointTaskStart(getFormData({object_id:record.object_id})).then(res => {
                            res.code === 200 ? afterOnOK('操作成功') : message.warning(res.message) 
                        }).catch(err => console.log('err',err))
                    }}>开始</span>
                    
                } 
                else if(record.status === 20 ) {
                    return <span className='mes-options' onClick={() => {
                        getPointReportInfo({object_id:record.object_id}).then(res => {
                            if(res.code === 200) {
                                setSelectItem(res.data)
                                setOpen(true);
                            } else {
                                message.warning(res.message)
                            }
                        }).catch(err => console.log('err',err))
                    }}>完成</span>
                } else {
                    return null
                }
            }
        },

    ]

    useEffect(()=>{
        getPointPlan({page_num:1,page_size:10000}).then(res => {
            if(res.code === 200 && res.data.data) {
                setPlans(res.data.data.map((i:any) => {
                    return { id:i.object_id, name:i.spot_plan_name }
                }))
            } else {
                message.warning(res.message)
            }
        }) 
    },[])



    const onOK = () => {
       tableForm.validateFields().then(res => {
        completeRef && completeRef.current && completeRef.current.handleSave(Object.values(res))
       }).catch(err => console.log('err',err))
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setSelectItem(null);
        setOpen(false);
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }


    const getDataAsync = (queryCondition: API.getPointReportListParams) => {
        return new Promise((resolve) => {
            getPointReportList(queryCondition).then(res => {
                res.code === 200 ? resolve(res.data) : message.warning(res.message)
            }).catch(err => console.log('err',err))
        })
    }

    return (
        <div className='mes-patrol-point-report'>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync} ref={gRef}
               filterQueryData={(queryData:API.getPointReportListParams) =>{
                if(queryData.spot_plan_ids)  {
                    JSON.stringify(queryData.spot_plan_ids) === '[]' ? delete queryData.spot_plan_ids : queryData.spot_plan_ids = JSON.stringify(queryData.spot_plan_ids)
                }
                if(queryData.status) {
                    JSON.stringify(queryData.status) === '[]' ? delete queryData.status : queryData.status = JSON.stringify(queryData.status)
                }
                return filterDateParam(queryData, 'date', 'start_date', 'end_date')
             }}/>
            {
                open &&
                <ModalContainer title="点检任务报工" open={open}
                                onOk={onOK} onCancel={() => {setOpen(false);setSelectItem(null)}}
                                innerComponent={<TaskReport afterOnOK={afterOnOK} ref={completeRef} tableForm={tableForm} form={form} selectItem={selectItem}/>} width="1150px"/>
            }
            {
                showTaskOpen && 
                <ModalContainer  open={showTaskOpen} title={<div><Image preview={false} src={showTaskImage}></Image>查看点检任务</div>}
                onCancel={() => {setShowTaskOpen(false);setSelectItem(null)}}
                innerComponent={<ShowTask selectItem={selectItem}/>} width="1150px" footer={null}/>
            }
        </div>
    )
}
export default PointReport;