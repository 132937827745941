import {useState, useEffect} from "react";
import {Table, Image, Modal, Upload} from "antd";
import type { ColumnsType } from 'antd/es/table';
import processing from "../../../../assets/status/handle.png";
import noStart from "../../../../assets/no-start.png";
import completed from "../../../../assets/completed.png";
import skip from "../../../../assets/skip.png";
import {DataType} from "../../PatrolPlan/components/ShowPlan";
import type { UploadFile } from 'antd/es/upload/interface';
import moment from "moment";
import { nanoid } from 'nanoid'
interface IProps {
  selectItem: any
}
type IDataType =  {
  patrol_result:string
  options_exception:number
  remarks:string
} & DataType

const ShowTask = (props:IProps) => {
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const {selectItem} = props;
    const {device_name,device_code,device_specification,device_type_name,device_position,device_organization_name,code,adjustment_reason,patrol_plan_name,
      should_execution_time,team_groups,patrol_user_name,result_patrol_task,patrol_items,
      status,member_user_name_arr,real_start_date,real_end_date,image_patrol_task} = selectItem;
    const [type,setType] = useState<string>("任务信息");
    const [previewOpen, setPreviewOpen] = useState(false);
  
  
    const columns: ColumnsType<IDataType> = [
      {
        title: "No",
        dataIndex: "index",
        align:"center",
        width: 60,
        render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
      },
      {
        title: "巡检项目",
        dataIndex: "patrol_name",
        align:"left",
        ellipsis:true
      },
      {
        title: "巡检内容",
        dataIndex: "patrol_content",
        align:"left",
        ellipsis:true
      },
      {
        title: "参数名称",
        dataIndex: "parameter_name",
        align:"center",
        ellipsis:true
      },
      {
        title: "巡检结果类型",
        dataIndex: "patrol_type",
        align:"center",
        ellipsis:true,
        width:120,
        render:(i:number) => <span>{i === 10 ? '数值' : i === 20 ? '单选' : '多选'}</span>
      },
      {
        title: "巡检结果",
        dataIndex: "patrol_result",
        align:"left",
        ellipsis:true
      },
      {
        title: "是否正常",
        dataIndex: "options_exception",
        align:"center",
        ellipsis:true,
        render:((i:number) => <span>{i ? i === 10 ? '正常' : '异常' : ''}</span>)
      },
      {
        title: "备注",
        dataIndex: "remarks",
        align:"left",
        ellipsis:true,
        render:((i:string | null) => <span>{status === 30 ? i : ''}</span>)
      },
    ];

    useEffect(()=> { 
     setFileList(image_patrol_task.map((i:any)=> {
      return {
        uid: i.object_id,
        name: 'image.png',
        status: 'done',
        url: i.file.url,
      }
    }))
    },[])



    const handlePreview = async (file: UploadFile) => {
      setPreviewImage(file.url as string);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    return (
      <div className="show-task">
        <div className="show-task-top">
           <div className="show-task-top-left">
             <div className="top-box">
                <span><span className="title">设备名称：</span>{device_name}</span>
                <span><span className="title">设备类型：</span>{device_type_name}</span>
             </div>
             <div className="top-box">
                <span><span className="title">设备代码：</span>{device_code}</span>
                <span><span className="title">存放位置：</span>{device_position}</span>
             </div>
             <div className="top-box">
                <span><span className="title">规格型号：</span>{device_specification}</span>
                <span><span className="title">使用部门：</span>{device_organization_name}</span>
             </div>
           </div>
           <Image width={116} height={90} src={status === 10 ? noStart : status === 20 ? processing : status === 30 ? completed : skip} preview={false}></Image>
        </div>
        <div className="show-task-line"></div>
        <div className="show-task-bottom">
            <div className="bottom-box-one">
                <div className={type === "任务信息" ? "tab-active" : "tab"} onClick={()=>setType("任务信息")}>任务信息</div>
                <div className={type === "巡检项目" ? "tab-active" : "tab"} onClick={()=>setType("巡检项目")}>巡检项目</div>
                <div className={type === "巡检图片" ? "tab-active" : "tab"} onClick={()=>setType("巡检图片")}>巡检图片</div>
            </div>
            <div className="show-task-line"></div>
            <div className="bottom-box-two">
                {
                    type === "任务信息" && (
                        <div className="tab-message">
                            <div className="tab-column">
                                <div><span className="title">巡检任务单号：</span>{code}</div>
                                <div><span className="title">应执行时间：</span>{should_execution_time && moment(should_execution_time).format("YYYY-MM-DD HH:mm")}</div>
                                <div><span className="title">调整原因：</span>{adjustment_reason}</div>
                                <div><span className="title">巡检班组：</span>{team_groups}</div>
                                <div><span className="title">其他巡检人员：</span>{(member_user_name_arr.map((i:{name:string,object_id:number})=>i.name)).join('、')}</div>
                                <div><span className="title">实际开始时间：</span>{real_start_date && moment(real_start_date).format("YYYY-MM-DD HH:mm")}</div>
                            </div>
                            <div className="tab-column">
                                <div><span className="title">计划名称：</span>{patrol_plan_name}</div>
                                <div><span className="title">任务状态：</span>
                                {status === 10 ? '未开始' : status === 20 ? '处理中' : status === 30 ? '已完成' : '已跳过'}</div> 
                                <div><span className="title"></span></div>
                                <div><span className="title">巡检负责人：</span>{patrol_user_name}</div>
                                <div><span className="title"></span></div>
                                <div><span className="title">实际结束时间：</span>{real_end_date  && moment(real_end_date).format("YYYY-MM-DD HH:mm")}</div> 
                            </div>
                        </div>
                    )
                }
                {
                    type === "巡检项目" && (
                        <div className="tab-project">
                            <Table rowKey={() => nanoid()} columns={columns} dataSource={status === 30 ? result_patrol_task : patrol_items} scroll={{y:180}} pagination={false}></Table>
                        </div>
                    
                    )
                }
                {
                    type === "巡检图片" && (
                    <div className="tab-pratrol-image">
                      <div className="image-numbers">已上传{fileList.length}张图片</div>
                         <Upload
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            disabled={true}
                          >
                         </Upload>
                    </div>
                    )
                }
            </div>
        </div>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
           <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    )
}
export default ShowTask;