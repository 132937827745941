import React, {useEffect, useState} from 'react'
import {Button, Modal, Table, TableColumnsType} from "antd";
import "./style.css"
import {getWarehouseProduct_All} from "../../../service";
import {toastErrorMessage} from "../../../utils";

/**
 * 库存盘点打印弹窗
 */
interface CheckPrintModalProps {
    dataItem: any,
    printRef: any,
    closeClick: () => void
    printClick: () => void
}

const CheckPrintModal = (props: CheckPrintModalProps): React.ReactElement => {
    const [data, setData] = useState<Array<API.warehouseResponseData_All>>([]);
    const columns: TableColumnsType<API.warehouseResponseData_All> = [
        {
            title: '仓库', children: [
                {
                    title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
                    render: (text, record, index) => <div>{index + 1}</div>
                },
                {title: '产品名称', dataIndex: 'product_name', align: 'left', ellipsis: true},
            ]
        },
        {
            title: props.dataItem.storage_name, children: [
                {title: '产品代码', dataIndex: 'product_code', align: 'center', ellipsis: true},
                {title: '规格型号', dataIndex: 'specification', align: 'center', ellipsis: true},
                {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
            ]
        },
        {
            title: '盘点日期', children: [
                {
                    title: '库存数量',
                    dataIndex: 'inventory_num',
                    align: 'right',
                    ellipsis: true,
                    render: (num: number) => <div>{num === null || num < 0 ? '' : num.toLocaleString()}</div>
                },
                {title: '实际数量', dataIndex: '', align: 'right', ellipsis: true},
            ]
        },
        {
            title: '', children: [
                {
                    title: '盘盈或盘亏数', children: [
                        {title: '盘盈', dataIndex: '', align: 'right', ellipsis: true},
                        {title: '盘亏', dataIndex: '', align: 'right', ellipsis: true},
                    ]
                },
                {title: '差异原因', dataIndex: '', align: 'left', ellipsis: true},
            ]
        }
    ]

    useEffect(() => {
        getWarehouseProduct_All({storage_id: props.dataItem.object_id}).then((res) => {
            if (res.code === 200) {
                setData(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }, [])

    return (
        <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => props.closeClick && props.closeClick()}
                />
            }
            destroyOnClose
            title={<div style={{fontSize: 16, fontWeight: "bold"}}>
                库存盘点
            </div>}
            footer={null}
            width={1080}
            centered
            open
        >
            <div ref={props.printRef}>
                <div style={{
                    display: "flex",
                    marginBottom: 20,
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <div style={{color: '#333333', fontSize: 36, fontWeight: "bold"}}>
                        库存盘点表
                    </div>
                </div>
                <Table
                    className={'checkDetailTable'}
                    dataSource={data}
                    columns={columns}
                    size={'small'}
                    bordered
                    pagination={false}
                />
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                    marginRight: 100,
                    alignItems: "center",
                    justifyContent: "right"
                }}>
                    <div style={{color: '#333333', fontSize: 16, fontWeight: "bold"}}>
                        盘点人：
                    </div>
                </div>
            </div>

            <div style={{display: "flex", marginTop: 100, justifyContent: "center"}}>
                <Button
                    style={{width: 130, height: 48, borderRadius: 5, backgroundColor: '#B8B8B8', color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        props.closeClick && props.closeClick()
                    }}
                >
                    关闭
                </Button>
                <Button
                    style={{
                        width: 130,
                        height: 48,
                        marginLeft: 20,
                        borderRadius: 5,
                        backgroundColor: '#3E7BFA',
                        color: '#ffffff'
                    }}
                    htmlType="button"
                    onClick={() => {
                        props.printClick && props.printClick()
                    }}
                >
                    打印
                </Button>
            </div>
        </Modal>
    )
}

export default CheckPrintModal
