import React, {useEffect, useRef, useState} from "react";
import GeneralManagement, {Options, QueryListItem} from "../../components/GeneralManagement";
import {Button, Col, Form, Input, message, Row, Select, Space} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {
    createMaintenanceExperience,
    deleteMaintenanceExperience,
    getDeviceInfoById,
    getDeviceInfoList,
    getMaintenanceExperienceById,
    getMaintenanceExperienceList,
    UpdateMaintenanceExperience
} from "../../service";
import {filterData, getFormData, showError} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';

enum ModalTitle {
    ADD = '新增维修经验',
    EDIT = '编辑维修经验',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

const RepairExperience = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const [deviceList, setDeviceList] = useState<Options[]>([]);
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '经验代码',
            placeholder: '请输入维修经验代码'
        },
        {
            key: 'experience_name',
            name: '经验名称',
            placeholder: '请输入维修经验名称',
        },
        {
            key: 'device_name',
            name: '设备名称',
            placeholder: '请输入设备名称',
        }
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '维修经验代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '维修经验名称',
            dataIndex: 'experience_name',
            key: 'experience_name',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            key: 'device_code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '规格型号',
            dataIndex: 'device_specification',
            key: 'device_specification',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '设备类型',
            dataIndex: 'device_type_name',
            key: 'device_type_name',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '维修描述',
            dataIndex: 'maintenance_describe',
            key: 'maintenance_describe',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}} className={'text-show-ellipsis'}>{value}</div>
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const changeDevice = (id: number) => {
        getDeviceInfoById({object_id: id}).then(res => {
            if (res.code === 200) {
                form.setFieldsValue({
                    device_code: res.data.code,
                    device_specification: res.data.device_specification,
                    device_type_name: res.data.device_type_name,
                })
            } else {
                showError(res.message)
            }
        })
    }

    const AddOrEditJob = React.memo((): React.ReactElement => {
        return (
            <Form colon={false} form={form} className={'form-margin-long'}>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'维修经验代码'} name={'code'} rules={[
                            {
                                type: "string",
                                max: 32,
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '维修经验代码由不大于32位的字母、数字组成!'
                            }
                        ]}>
                            <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'维修经验名称'} name={'experience_name'} rules={[
                            {required: true, message: '请输入维修经验名称!'},
                            {type: "string", max: 32, message: '维修经验名称不得大于32位!'}]}>
                            <Input placeholder={'请输入维修经验名称'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'设备名称'} name={'device_id'} rules={[
                            {required: true, message: '请选择设备名称!'}]}>
                            <Select placeholder={'请选择设备名称'} onChange={(value) => {
                                changeDevice(value)
                            }}>
                                {
                                    deviceList.map(e => <Select.Option key={e.id}
                                                                             value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'设备代码'} name={'device_code'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={'规格型号'} name={'device_specification'}>
                            <Input  disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'设备类型'} name={'device_type_name'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'维修描述'} name={'maintenance_describe'} rules={[
                            {required: true, message: '请输入维修描述!'},
                            {max: 200, message: '维修描述不得大于200位！'}]}>
                            <Input.TextArea placeholder={'请输入维修描述'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        setModalTitle(title);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditJob/>)
        } else if (title === ModalTitle.EDIT) {
            getMaintenanceExperienceById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        experience_name: res.data.experience_name,
                        device_id: res.data.device_id,
                        device_code: res.data.device_code,
                        device_specification: res.data.device_specification,
                        device_type_name: res.data.device_type_name,
                        maintenance_describe: res.data.maintenance_describe
                    })
                    setInnerComponent(<AddOrEditJob/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createMaintenanceExperience(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                UpdateMaintenanceExperience(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteMaintenanceExperience(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getMaintenanceExperienceList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    useEffect(() => {
        getDeviceInfoList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.device_name
                            })
                        })
                    }
                    setDeviceList(temp)
                }
            } else {
                showError(res.message);
            }
        })
    }, [])


    return (
        <div  >
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={(modalTitle === ModalTitle.DELETE) ? '20%' : '1071px'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default RepairExperience


