import React, {useContext, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, Options, QueryListItem} from "../../components/GeneralManagement";
import {Button, Col, Form, message, Row, Space} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {
    createDeviceInfo,
    deleteDeviceInfo,
    getDeviceInfoById,
    getDeviceInfoList,
    getDeviceInfoRelatedDoc,
    getDeviceTypeList,
    UpdateDeviceInfo
} from "../../service";
import {fileDeviceStatus, filterData, getFormData, showError} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';
import moment from "moment";
import QRCode from 'qrcode.react';
import AddOrEditDevice from "./components/AddOrEditDevice";
import ViewDevice from "./components/ViewDevice";
import Print from 'react-print-html';
import {UserContext} from "../../contexts/userContext";
import {UploadFile} from "antd/es/upload/interface";


export enum ModalTitle {
    ADD = '新增设备信息',
    EDIT = '编辑设备信息',
    VIEW = '查看设备信息',
    QRCODE = '设备二维码',
    DELETE = '系统确认',
}

interface DeviceLabelProps {
    code: string,
    name: string,
    model: string,
    type: string,
    location: string,
    id: number
}

const DeviceFile = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [typeList, setTypeList] = useState<Options[]>([]);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const {useInfo} = useContext(UserContext);
    const gRef = useRef<GRef>(null);
    const printRef = useRef(null);
    const [isView, setIsView] = useState<boolean>(false);
    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '代码',
            placeholder: '请输入设备代码'
        },
        {
            key: 'device_name',
            name: '名称',
            placeholder: '请输入设备名称',
        },
        {
            key: 'device_type_id',
            name: '设备类型',
            placeholder: '请选择设备类型',
            options: typeList
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            fixed: 'left',
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '设备代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
            width: 120,
            ellipsis: true,
            render: (value: string, item: any) => {
                return <span className={'mes-options'} onClick={() => {
                    openModal(ModalTitle.VIEW, item)
                }}>{value}</span>
            }
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            fixed: 'left',
            width: 120,
            ellipsis: true,
            render: (value: string, item: any) => {
                return <span className={'mes-options'} style={{textAlign: 'left'}} onClick={() => {
                    openModal(ModalTitle.VIEW, item)
                }}>{value}</span>
            }
        },
        {
            title: '规格型号',
            dataIndex: 'device_specification',
            key: 'device_specification',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '设备类型',
            dataIndex: 'device_type_name',
            key: 'device_type_name',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '存放位置',
            dataIndex: 'position_name',
            key: 'position_name',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '使用部门',
            dataIndex: 'organization_name',
            key: 'organization_name',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '负责人',
            dataIndex: 'head_user_name',
            key: 'head_user_name',
            align: 'center',
            width: 120,
            ellipsis: true,
        },
        {
            title: '联系方式',
            dataIndex: 'contact',
            key: 'contact',
            align: 'center',
            width: 120,
            ellipsis: true,
        },
        {
            title: '启用日期',
            dataIndex: 'activation_date',
            key: 'activation_date',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD')}</span>
                }
            }
        },
        {
            title: '设备状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: number) => {
                return fileDeviceStatus.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div style={{color: e.color}} key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.QRCODE, item)
                    }}>设备二维码</span>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} style={{marginRight: 14}} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
            <Button className={'mes-export'} onClick={() => {
                exportExcel()
            }}>导出</Button>
        </div>
    ))

    const exportExcel = () => {
        const ExportJsonExcel = require("js-export-excel");
        if (gRef.current) {
            if (gRef.current.exportData) {
                gRef.current.exportData().then(res => {
                    let exportData: any[] = [];
                    res.map((e: any, i: number) => {
                        exportData.push({
                            NO: i + 1,
                            code: e.code,
                            device_name: e.device_name,
                            device_specification: e.device_specification,
                            device_type_name: e.device_type_name,
                            position_name: e.position_name,
                            organization_name: e.organization_name,
                            head_user_name: e.head_user_name,
                            contact: e.contact,
                            activation_date: e.activation_date ? moment(e.activation_date).format('YYYY/MM/DD') : '',
                            status: e.status === 10 ? '正常运行' : '故障',
                        })
                    })
                    const option: any = {};
                    option.fileName = '设备档案' + moment(new Date()).format('YYYYMMDD');
                    option.datas = [
                        {
                            sheetData: exportData,
                            sheetName: "sheet",
                            sheetFilter: ['NO', 'code', 'device_name', 'device_specification',
                                'device_type_name', 'position_name', 'organization_name',
                                'head_user_name', 'contact', 'activation_date', 'status'],
                            sheetHeader: ['序号', '设备代码', '设备名称', '规格型号', '设备类型', '存放位置', '使用部门', '负责人', '联系方式', '启用日期', '设备状态'],
                            columnWidths: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                        }
                    ];
                    const toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                })
            }
        }
    }

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            setModalTitle(title);
            form.resetFields();
            setInnerComponent(<AddOrEditDevice form={form} fileList={fileList} setFileList={setFileList}
                                               dataSource={dataSource} title={ModalTitle.ADD}
                                               setDataSource={setDataSource} useInfo={useInfo}/>)
        } else if (title === ModalTitle.EDIT) {
            setModalTitle(title);
            getDeviceInfoById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        device_name: res.data.device_name,
                        device_specification: res.data.device_specification,
                        device_type_id: res.data.device_type_id,
                        position_id: res.data.position_id,
                        remarks: res.data.remarks,
                        status: res.data.status,
                        organization_id: res.data.organization_id,
                        head_user_id: res.data.head_user_id,
                        contact: res.data.contact,
                        activation_date: moment(res.data.activation_date),
                    })
                    const temp: any[] = [];
                    res.data.image_device_info.map((e: any) => {
                        temp.push({
                            uid: e.file.object_id,
                            name: e.file.name,
                            url: e.file.url,
                        })
                    })
                    getDeviceInfoRelatedDoc({
                        object_id: item.object_id,
                    }).then(res1 => {
                        if (res1.code === 200) {
                            const tempDataSource: any[] = [];
                            res1.data.data.map((e: any) => {
                                tempDataSource.push({
                                    file_id: e.file_id,
                                    file_name: e.file_name,
                                    file_extension: e.file_extension,
                                    file_size: e.file_size,
                                    up_user_id: e.up_user_id,
                                    user_name: e.up_user_name,
                                    up_time: e.up_time,
                                    file: e.file
                                })
                            })
                            setInnerComponent(<AddOrEditDevice form={form} fileList={temp} setFileList={setFileList}
                                                               title={ModalTitle.EDIT}
                                                               dataSource={tempDataSource} setDataSource={setDataSource}
                                                               useInfo={useInfo}/>)
                        } else {
                            showError(res.message)
                        }
                    })

                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setModalTitle(title);
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.QRCODE) {
            setModalTitle(title);
            getDeviceInfoById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<DeviceLabel code={res.data.code} name={res.data.device_name}
                                                   model={res.data.device_specification}
                                                   type={res.data.device_type_name} location={res.data.position_name}
                                                   id={res.data.object_id}/>)
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.VIEW) {
            setIsView(true)
            const ViewDeviceTitle = (): React.ReactElement => {
                return (<div>
                    <img src={require('../../assets/view-device-icon.png')} alt='暂无图片' style={{marginRight: 10}}/>
                    <span>{item.device_name}</span>
                </div>)
            }
            getDeviceInfoById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setModalTitle(<ViewDeviceTitle/> as any);
                    setInnerComponent(<ViewDevice item={res.data}/>)
                } else {
                    showError(res.message);
                }
            })
        }
        setOpen(true);
    }

    const DeviceLabel = React.memo((props: DeviceLabelProps): React.ReactElement => {
        const {code, name, model, type, location, id} = props;

        useEffect(() => {
            const canvasToImage = (canvas: HTMLCanvasElement) => {
                const image = new Image();
                image.src = canvas.toDataURL("image/png");
                image.width = 200;
                return image;
            }
            const canvasContainer = document.getElementsByTagName('canvas')[0];
            const img = canvasToImage(canvasContainer);
            const ewmImg: HTMLElement | null = document.getElementById('ewm');
            if (ewmImg) {
                ewmImg.append(img);
            }
        }, [])

        return (
            <div className={'form-margin'} style={{padding: '68px auto'}}>
                <div className={'device-label'} ref={printRef}>
                    <Row style={{alignItems: 'center'}}>
                        <Col span={12}>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>设备名称</div>
                                <div className={'label-value'}>{name}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>设备代码</div>
                                <div className={'label-value'}>{code}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>规格型号</div>
                                <div className={'label-value'}>{model}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>设备类型</div>
                                <div className={'label-value'}>{type}</div>
                            </div>
                            <div className={'device-label-item'}>
                                <div className={'label-name'}>存放位置</div>
                                <div className={'label-value'}>{location}</div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <QRCode
                                id="qrCode"
                                value={`D_${id}_${name}`}
                                size={200}
                                fgColor="#000000"
                                style={{float: 'right', display: 'none'}}
                            />
                            <div className="codeImg" id="ewm"></div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    })

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const tempPics = Object.values(fileList).map((e) => {
                    return {file_id: e.uid}
                })
                const tempData = dataSource.map(e => {
                    delete e.user_name;
                    return e
                })
                const temp = {
                    ...values,
                    activation_date: values.activation_date.format('YYYY-MM-DD'),
                    device_images: tempPics,
                    device_files: tempData
                }
                const formData = getFormData(filterData(temp), true)
                createDeviceInfo(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const tempPics = Object.values(fileList).map((e) => {
                    return {file_id: e.uid}
                })
                const tempData = dataSource.map(e => {
                    delete e.user_name;
                    return e
                })
                const temp = {
                    ...values,
                    object_id: selectedItem.object_id,
                    activation_date: values.activation_date.format('YYYY-MM-DD'),
                    device_images: tempPics,
                    device_files: tempData
                }
                const formData = getFormData(filterData(temp), true)
                UpdateDeviceInfo(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteDeviceInfo(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.QRCODE) {
            if (printRef.current) {
                Print(printRef.current)
                onCancel(title)
            }
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            setFileList([]);
            form.resetFields();
            setDataSource([]);
        }
        setIsView(false);
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getDeviceInfoList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const setWidth = (): string => {
        if (modalTitle === ModalTitle.DELETE) {
            return '20%'
        } else if (modalTitle === ModalTitle.QRCODE) {
            return '688px'
        } else {
            return '1071px'
        }
    }

    const getFooter = (): React.ReactNode => {
        if (isView) {
            return null
        } else {
            if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
                return (<div>
                    <Button onClick={() => onCancel(modalTitle)}>关闭</Button>
                    <Button type={'primary'} onClick={() => onOK(modalTitle)}>保存</Button>
                </div>)
            } else if (modalTitle === ModalTitle.DELETE) {
                return <div>
                    <Button onClick={() => onCancel(modalTitle)}>关闭</Button>
                    <Button type={'primary'} onClick={() => onOK(modalTitle)}>确认</Button>
                </div>
            } else if (modalTitle === ModalTitle.QRCODE) {
                return <div>
                    <Button onClick={() => onCancel(modalTitle)}>关闭</Button>
                    <Button type={'primary'} onClick={() => onOK(modalTitle)}>打印</Button>
                </div>
            } else {
                return null
            }
        }
    }

    useEffect(() => {
        getDeviceTypeList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.device_name
                            })
                        })
                    }
                    setTypeList(temp)
                }
            } else {
                showError(res.message);
            }
        })
    }, [])


    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} scroll={true}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                width={setWidth()} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default DeviceFile


