//新增或编辑设备信息
import {Button, Col, DatePicker, Divider, Form, Input, message, Radio, Row, Select,} from "antd";
import {isHalt, showError, troubleLevel} from "../../../utils";
import React, {useEffect, useState} from "react";
import {getDeviceInfoList, getFaultTypeList} from "../../../service";
import {Options} from "../../../components/GeneralManagement";
import ModalContainer from "../../../components/Modal";
import ModalInner from "../../Repair/components/ModalInner";
import UploadPictures from "../../../components/UploadPictures";

interface AddOrEditProps {
    form: any;
    fileList: any;
    setFileList: (fileIDList: any) => void;
    initialDeviceName?: string,
}

const AddOrEdit = (props: AddOrEditProps) => {
    const {form, fileList, setFileList, initialDeviceName} = props;
    const [typeList, setTypeList] = useState<Options[]>([]);
    const [nameList, setNameList] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [deviceName, setDeviceName] = useState<string>();

    const selectItem = () => {
        if (selectedRows.length) {
            form.setFieldValue('fault_describe', selectedRows[0].fault_describe)
            cancelItem()
        } else {
            message.warning('请选择故障经验!')
        }
    }

    const cancelItem = () => {
        setOpen(false);
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    const selectDevice = (value: any, option: any) => {
        //获取设备名称
        setDeviceName(option.children)
        form.setFieldValue('link_device_id', option.title1)
    }

    useEffect(() => {
        //获取故障类型列表
        getFaultTypeList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.fault_name
                            })
                        })
                    }
                    setTypeList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        //获取设备名称列表
        getDeviceInfoList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: any[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.device_name,
                                code: e.code
                            })
                        })
                    }
                    setNameList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        //设置设备名称
        if (initialDeviceName) {
            setDeviceName(initialDeviceName)
        }
    }, [])
    return (
        <div className={'viewItem'}>
            {/*故障信息*/}
            <div className={'viewTitle'}>故障信息</div>
            <Form colon={false} form={form} className={'form-margin'}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'报修单号'} name={'code'} rules={[
                            {
                                type: "string",
                                max: 32,
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '报修单号由不大于32位的字母、数字组成!'
                            }
                        ]}>
                            <Input placeholder={'不填写，系统将自动生成'} disabled={!!initialDeviceName}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备名称'} name={'device_id'} rules={[
                            {required: true, message: '请选择设备名称!'}]}>
                            <Select placeholder={'请选择设备名称'} onSelect={selectDevice}>
                                {
                                    nameList.map(e => <Select.Option key={e.id} value={e.id}
                                                                     title1={e.code}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备代码'} name={'link_device_id'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'故障时间'} name={'fault_time'} rules={[
                            {required: true, message: '请选择故障时间!'}]}>
                            <DatePicker placeholder={'请选择故障时间'} showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'故障类型'} name={'fault_type_id'} rules={[
                            {required: true, message: '请选择故障类型!'}]}>
                            <Select placeholder={'请选择故障类型'}>
                                {
                                    typeList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'shutdown_or_not'} label={'是否停机'}
                                   rules={[{required: true, message: '请选择是否停机!'}]}>
                            <Radio.Group>
                                {
                                    isHalt.map((e: any) => <Radio key={e.id} value={e.id}>{e.name}</Radio>)
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'故障等级'} name={'fault_level'} rules={[
                            {required: true, message: '请选择故障等级!'}]}>
                            <Select placeholder={'请选择故障等级'}>
                                {
                                    troubleLevel.map(e => <Select.Option key={e.id}
                                                                         value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'故障描述'} name={'fault_describe'}
                                   rules={[{required: true, message: '请输入故障描述!'}, {
                                       max: 200,
                                       message: '故障描述不得大于200位！'
                                   }]}>
                            <Input.TextArea placeholder={'请输入故障描述'}>
                            </Input.TextArea>
                        </Form.Item>
                        <div className={'textArea-suffix'} onClick={() => {
                            if (form.getFieldValue('device_id')
                            ) {
                                setOpen(true);
                            } else {
                                message.warning('请先选择设备名称！');
                            }
                        }}>
                            <img src={require('../../../assets/trouble-library-icon.png')} alt="暂无图片"
                                 style={{marginRight: 5}}/>
                            <span className={'trouble-library-text'}>故障库</span>
                        </div>
                    </Col>
                </Row>
                <Divider/>
                {/*故障图片*/}
                <div className={'viewTitle'}>故障图片</div>
                <UploadPictures fileList={fileList} setFileList={setFileList}/>
                <Divider/>
            </Form>
            {/*选择故障经验*/}
            <ModalContainer title={'选择故障经验'} open={open} width={'1071px'}
                            onCancel={() => cancelItem()} okText={'确定'}
                            footer={<Button type={'primary'} onClick={() => selectItem()}>确定</Button>}
                            innerComponent={
                                <ModalInner setSelectedRows={setSelectedRows}
                                            setSelectedRowKeys={setSelectedRowKeys}
                                            selectedRowKeys={selectedRowKeys}
                                            deviceId={form.getFieldValue('device_id')}
                                            deviceCode={form.getFieldValue('link_device_id')}
                                            deviceName={deviceName}
                                            type={'trouble'}/>}/>
        </div>
    )
}
export default AddOrEdit;
