import React from 'react'
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import { Modal, Select } from 'antd'
import { getAllUnitInfo } from "../../../../service/index";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { toastMessage } from "../../../../utils";
import '../../../../globalLess/global.css'

interface selectItem {
    value: string;
    label: string;
}

export default class AddModal extends React.Component<{
    closeClick: () => void,
    saveClick: (e: API.productAddItem) => void,
    isHas: boolean,
    navigate: NavigateFunction,
}> {
    state = {
        code: '',
        product_name: '',
        product_unit_id: 0,
        specification: '',
        remark: '',
        selectInfo: [{
            value: '',
            label: ''
        }],
        dataSource: []
    }
    async getProductUnit() {
        await getAllUnitInfo().then((res: any) => {
            if (res.code === 200) {
                const selectInfoDataSource: API.productSelectItem[] = res.data.data
                let selectInfoArray: selectItem[] = []
                for (let index = 0; index < selectInfoDataSource.length; index++) {
                    const item = selectInfoDataSource[index]
                    if (item.unit_name) {
                        selectInfoArray.push({
                            value: item.object_id.toString(),
                            label: item.unit_name
                        })
                    }
                }
                this.setState({
                    dataSource: selectInfoDataSource,
                    selectInfo: selectInfoArray
                })
            }
        }).catch((err: any) => {
            console.log("err=>>>>", err);
        })
    }
    componentDidMount() {
        this.getProductUnit()
    }
    render() {
        const {
            closeClick,
            saveClick,
            isHas,
            navigate
        } = this.props
        const {
            code,
            product_name,
            product_unit_id,
            specification,
            remark,
            selectInfo,
            dataSource
        } = this.state
        return <Modal
            open
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'产品新增'}
            width={458}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='产品代码' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    placeholder='不填写，系统将自动生成'
                    value={code}
                    onChange={(value) => {
                        if (value === '') {
                            this.setState({
                                code: value
                            })
                        } else {
                                if (/^[A-Za-z0-9\-.]+$/.test(value)) {
                                    this.setState({
                                        code: value
                                    })
                                }
                        }
                    }}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1} title='产品名称' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }} placeholder='请输入产品名称' onChange={(value) => {
                    this.setState({
                        product_name: value
                    })
                }}
                    value={product_name}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <div className='inputStyle'
                >
                    <div className='inputRequire'>*</div>
                    <div
                        className='dropdownTitle'
                        style={{ marginRight: 10, color: isHas ? '#4680FA' : '#333333', textDecoration: isHas ? "underline" : "none", cursor: isHas ? "pointer" : "auto" }}
                        onClick={() => {
                            if (isHas) {
                                navigate('/basic/company')
                            }
                        }}
                    >产品单位</div>
                    <Select
                        style={{ width: 280 }}
                        placeholder='请选择产品单位'
                        options={selectInfo}
                        onChange={(value: string) => {
                            console.log("=>itemitem>>>", dataSource, value);
                            const item: any = dataSource.filter((item: any) => item.object_id.toString() === value)[0]
                            console.log("=>item>>>", item, item.object_id, !item.object_id);
                            this.setState({
                                product_unit_id: item.object_id,
                            },)
                        }}
                    />
                </div>
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='规格型号' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }} placeholder='请输入规格型号' onChange={(value) => {
                    this.setState({
                        specification: value
                    })
                }}
                    value={specification}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注' remark={true} titlestyle={{ paddingLeft: 2, width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入备注' onChange={(value) => {
                    this.setState({
                        remark: value
                    })
                }}
                    value={remark}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!product_name) {
                        toastMessage('产品名称不能为空')
                        return
                    }
                    if (!product_unit_id) {
                        toastMessage('产品单位不能为空')
                        return
                    }
                    const item: API.productAddItem = {
                        product_name,
                        product_unit_id: product_unit_id
                    }
                    if (code) {
                        item.code = code
                    }
                    if (specification) {
                        item.specification = specification
                    }
                    if (remark) {
                        item.remark = remark
                    }
                    saveClick(item)
                }} />
            </div>
        </Modal>
    }
}