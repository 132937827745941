import React, {useEffect, useRef, useState} from "react";
import GeneralManagement, {Options, QueryListItem} from "../../components/GeneralManagement";
import {Col, DatePicker, Divider, Form, Input, message, Modal, Radio, Row, Select, Upload} from "antd";
import ModalContainer from "../../components/Modal";
import {
    createRepairOrderDispatch,
    getGroupAllUser,
    getGroupList,
    getRepairOrderById,
    getRepairOrderListDispatch
} from "../../service";
import {filterData, filterDateParam, getBase64, isHalt, showError, troubleLevel, workOrderStatus} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/es/upload";
import moment from "moment";
import ViewItem from "./components/ViewItem";

enum ModalTitle {
    ASSIGN = '维修工单派工',
    VIEW = '查看维修工单',
}

export interface GRef {
    onFinish: () => void;
}

const RepairSubmission = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'device_name',
            name: '设备名称',
            placeholder: '请输入设备名称',
        },
        {
            key: 'status',
            name: '工单状态',
            placeholder: '请选择工单状态',
            options: workOrderStatus,
            multiple: true,
            defaultValue: [20]
        },
        {
            key: 'report_date',
            name: '提报时间',
            placeholder: '请选择日期范围',
            rangePick: true
        },
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '维修单号',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string, item: any) => {
                return <span className={'mes-options'} onClick={() => {
                    openModal(ModalTitle.VIEW, item)
                }}>{value}</span>
            }
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            key: 'device_code',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'fault_time',
            title: '故障时间',
            dataIndex: 'fault_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'fault_name',
            title: '故障类型',
            dataIndex: 'fault_name',
            align: 'center',
            width: 100,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'shutdown_or_not',
            title: '是否停机',
            dataIndex: 'shutdown_or_not',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return isHalt.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_level',
            title: '故障等级',
            dataIndex: 'fault_level',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return troubleLevel.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '提报时间',
            dataIndex: 'report_time',
            key: 'report_time',
            align: 'center',
            width: 250,
            ellipsis: true, render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'status',
            title: '工单状态',
            dataIndex: 'status',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return workOrderStatus.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i} style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'team_groups',
            title: '维修班组',
            dataIndex: 'team_groups',
            align: 'center',
            width: 100
        },
        {
            key: 'head_user_name',
            title: '维修负责人',
            dataIndex: 'head_user_name',
            align: 'center',
            width: 200
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            width: 100,
            render: (_: any, item: any) => {
                if (item.status === 20) {
                    return (
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.ASSIGN, item)
                        }}>派工</span>
                    )
                }
            }
        },
    ]

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    //维修工单派工
    const ModalInner = (props: any) => {
        const {
            fileList,
            editHeadList,
        } = props;
        const [groupList, setGroupList] = useState<Options[]>([]);
        const [headList, setHeadList] = useState<any[]>([]);


        useEffect(() => {
            //设置维修负责人列表
            if (editHeadList) {
                setHeadList(editHeadList)
            }
            //获取班组列表
            getGroupList({page_num: 1, page_size: 10000000}).then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        let temp: any[] = [];
                        if (res.data.data.length) {
                            res.data.data.map((e: any) => {
                                temp.push({
                                    id: e.object_id,
                                    name: e.group_name,
                                })
                            })
                        }
                        setGroupList(temp)
                    }
                } else {
                    showError(res.message);
                }
            })
        }, [])

        const changeGroup = (e: any) => {
            getGroupAllUser({object_id: e}).then(res => {
                if (res.code === 200) {
                    if (res.data.length) {
                        setHeadList(res.data)
                        const headItem = res.data.filter((e: any) => e.is_head === 1)
                        form.resetFields(['head_user_id'])
                        form.setFieldValue('head_user_id', headItem[0].object_id)
                        const otherItem = res.data.filter((e: any) => e.is_head === 0)
                        const temp = otherItem.map((e: any) => e.object_id)
                        form.setFieldValue('member_user_id', temp)
                    }
                } else {
                    showError(res.message)
                }
            })
        }

        const changeHead = (value: any) => {
            const temp: any[] = []
            headList.map(e => {
                if (e.object_id !== value) {
                    temp.push(e.object_id)
                }
            })
            form.setFieldValue('member_user_id', temp)
        }

        return (<div className={'viewItem'}>
            {/*    故障信息*/}
            <div className={'viewTitle'}>故障信息</div>
            <Form colon={false} form={form} className={'form-margin-long'}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'维修单号'} name={'code'}>
                            <Input placeholder={'不填写，系统将自动生成'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备名称'} name={'device_id'}>
                            <Input placeholder={'请选择设备名称'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备代码'} name={'link_device_id'}>
                            <Input placeholder={'请输入设备代码'} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'故障时间'} name={'fault_time'}>
                            <DatePicker placeholder={'请选择故障时间'} showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm"
                                        disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'故障类型'} name={'fault_type_id'}>
                            <Input placeholder={'请选择故障类型'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'shutdown_or_not'} label={'是否停机'}>
                            <Radio.Group disabled>
                                {
                                    isHalt.map((e: any) => <Radio key={e.id} value={e.id}>{e.name}</Radio>)
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'故障等级'} name={'fault_level'}>
                            <Select placeholder={'请选择故障等级'} disabled>
                                {
                                    troubleLevel.map(e => <Select.Option key={e.id}
                                                                         value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'故障描述'} name={'fault_describe'}>
                            <Input.TextArea placeholder={'请输入故障描述'} disabled>
                            </Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                {/*故障图片*/}
                <div className={'viewTitle'}>故障图片</div>
                <Upload
                    className={'view-pictures'}
                    disabled
                    onPreview={handlePreview}
                    listType="picture-card"
                    fileList={fileList}/>
                <Divider/>
                <div className={'viewTitle'}>维修派工</div>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'维修班组'} name={'team_groups_id'} rules={[
                            {required: true, message: '请选择维修班组!'}]}>
                            <Select placeholder={'请选择维修班组'} onChange={(value) => {
                                changeGroup(value)
                            }}>
                                {
                                    groupList.map(e => <Select.Option key={e.id}
                                                                      value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'维修负责人'} name={'head_user_id'} rules={[
                            {required: true, message: '请选择维修负责人!'}]}>
                            <Select placeholder={'请选择维修负责人'} onChange={(value) => changeHead(value)}>
                                {
                                    headList.map(e => <Select.Option key={e.object_id}
                                                                     value={e.object_id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'其他维修人员'} name={'member_user_id'}>
                            <Select disabled mode={'multiple'}>
                                {
                                    headList.map((e: any) =>
                                        <Select.Option key={e.object_id} value={e.object_id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>)
    }

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item)
        if (title === ModalTitle.ASSIGN) {
            setModalTitle(title);
            getRepairOrderById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.fault_info.code,
                        device_id: res.data.fault_info.device_name,
                        link_device_id: res.data.fault_info.device_code,
                        fault_time: res.data.fault_info.fault_time ? moment(res.data.fault_info.fault_time) : '',
                        fault_type_id: res.data.fault_info.fault_type_name,
                        shutdown_or_not: res.data.fault_info.shutdown_or_not,
                        fault_level: res.data.fault_info.fault_level,
                        fault_describe: res.data.fault_info.fault_describe,
                        team_groups_id: res.data.team_groups_id,
                        head_user_id: res.data.head_user_id,
                        member_user_id: res.data.member_user_id ? res.data.member_user_id : undefined,
                    })
                    let temp: any[] = [];
                    res.data.fault_info.image_fault_report.map((e: any) => {
                        temp.push({
                            uid: e.file.object_id,
                            name: e.file.name,
                            url: e.file.url,
                        })
                    })
                    if (res.data.team_groups_id) {
                        getGroupAllUser({object_id: res.data.team_groups_id}).then(res1 => {
                            if (res1.code === 200) {
                                if (res1.data.length) {
                                    setInnerComponent(<ModalInner fileList={temp} editHeadList={res1.data}/>)
                                }
                            } else {
                                showError(res.message)
                            }
                        })
                    } else {
                        setInnerComponent(<ModalInner fileList={temp} editHeadList={[]}/>)
                    }
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.VIEW) {
            const ViewDeviceTitle = (): React.ReactElement => {
                return (<div>
                    <img src={require('../../assets/view-repair-icon.png')} alt='暂无图片' style={{marginRight: 10}}/>
                    <span>{title}</span>
                </div>)
            }
            setModalTitle(ViewDeviceTitle as any);
            getRepairOrderById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<ViewItem item={res.data}/>)
                } else {
                    showError(res.message)
                }
            })
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ASSIGN) {
            form.validateFields().then(values => {
                const temp = {
                    object_id: selectedItem.object_id,
                    team_groups_id: values.team_groups_id,
                    head_user_id: values.head_user_id,
                    member_user_id: values.member_user_id,
                }
                const formData = new FormData();
                Object.entries(filterData(temp)).map(e => {
                    if (e[0] == "member_user_id") {
                        formData.append(e[0], JSON.stringify(e[1]))
                    } else {
                        formData.append(e[0], e[1] as any)
                    }
                })
                createRepairOrderDispatch(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('派工成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ASSIGN) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getRepairOrderListDispatch(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const getFooter = (): React.ReactNode => {
        if(typeof modalTitle === 'object'){
            return null
        }
    }

    const filterQueryData = (queryData: any) => {
        if (queryData.report_date) {
            queryData = filterDateParam(queryData, 'report_date', 'report_date_start', 'report_date_end')
        }
        if (queryData.status.length) {
            queryData.status = JSON.stringify(queryData.status)
        }
        return queryData;
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ref={gRef} scroll={true} filterQueryData={filterQueryData}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={'保存'} width={'1071px'} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
            {/*预览图片*/}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="加载图片失败" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    )
}

export default RepairSubmission


