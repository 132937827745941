import {extend} from 'umi-request';
import type {ResponseInterceptor} from 'umi-request';
import {message} from 'antd';
import {createHashHistory} from "history";
import Group from "../pages/System/Group";

const errorInterceptors: ResponseInterceptor = async (response) => {
    if (response.status === 401) {
        message.error('登录已过期！').then(() => {
            //跳转登录页面
            let history = createHashHistory()
            history.push('/')
            window.sessionStorage.setItem('isLogin', 'null')
            window.location.reload()
        })
        return response
    }
    return response
};

/** 配置request请求时的参数 */
export const request = extend({
    responseType: 'json',
    requestType: "form",
    timeout: 20000,
    credentials: "include",
});
request.interceptors.response.use(errorInterceptors);

//登录
export async function login(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/login', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取验证码
export async function getCaptcha(
    params?: API.listParams,
    options?: { [key: string]: any }
) {
    return request<string>(`/api/user/get_captcha`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取当前用户信息
export async function getCurrentUser(
    params?: API.listParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/user/current_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//退出登录
export async function logout(
    body?: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/logout', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//修改密码
export async function changePassword(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/change_password', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//主画面中任务统计和产线统计接口
export async function getLineStatistics(
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.lineStatisticsResponse>(`/api/line/statistics`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取用户列表
export async function getUserList(
    params?: API.userListParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/user/list_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新建或修改用户
export async function createOrUpdateUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/create_or_update_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取获取用户角色
export async function getUserRole(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getUserRoleResponse>(`/api/user/get_user_role`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//用户授权
export async function roleUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/role_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//重置密码
export async function resetPassword(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/reset_password', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//启用/禁用用户
export async function invokeDisableUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/invoke_disable_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除用户
export async function deleteUser(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/delete_user', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取用户信息
export async function getUser(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/user/get_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取文件上传url
export async function getUploadUrl(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/file/get_upload_url', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//上传头像
export async function updateProfilePhoto(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/user/update_profile_photo', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//===================
//班组管理
export async function getGroupList(
    params?: API.GroupParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/groups/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createGroup(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/groups/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateGroup(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/groups/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteGroup(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/groups/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getGroupById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/groups/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取班组所有成员信息
export async function getGroupAllUser(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/groups/get_all_user`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//===================
//=================
//设备类型
export async function getDeviceTypeList(
    params?: API.DeviceTypeParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/device_type/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createDeviceType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/device_type/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateDeviceType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/device_type/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteDeviceType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/device_type/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getDeviceTypeById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/device_type/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//=====================
//=================
//存放位置
export async function getPositionList(
    params?: API.PositionParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/position/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createPosition(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/position/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdatePosition(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/position/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deletePosition(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/position/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getPositionById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/position/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//启用/停用位置信息
export async function StartStopPosition(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/position/start_stop', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//=====================
//=================
//设备档案
export async function getDeviceInfoList(
    params?: API.DeviceInfoParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/device_info/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createDeviceInfo(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/device_info/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateDeviceInfo(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/device_info/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteDeviceInfo(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/device_info/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getDeviceInfoById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/device_info/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-关联文档
export async function getDeviceInfoRelatedDoc(
    params?: API.DeviceInfoRelatedDocParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/device_info/get_file`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-报修记录
export async function getDeviceInfoRepair(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_report/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-维修记录
export async function getDeviceInfoMaintain(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/repair_order/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-巡检计划
export async function getDeviceInfoPatrolPlan(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/patrol_plan/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-巡检任务
export async function getDeviceInfoPatrolTask(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/patrol_task/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-点检计划
export async function getDeviceInfoPointPlan(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/spot_plan/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//详情-点检任务
export async function getDeviceInfoPointTask(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/spot_task/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//====================
//故障类型
export async function getFaultTypeList(
    params?: API.FaultTypeParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_type/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createFaultType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_type/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateFaultType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_type/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteFaultType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_type/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getFaultTypeById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_type/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//========================
//====================
//故障经验库
export async function getFaultExperienceList(
    params?: API.FaultExperienceParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_experience/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createFaultExperience(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_experience/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateFaultExperience(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_experience/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteFaultExperience(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_experience/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getFaultExperienceById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_experience/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据设备ID获取故障经验库列表
export async function getFaultExperienceListByDeviceId(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_experience/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//========================

//====================
//故障报修
export async function getFaultReportList(
    params?: API.FaultReportListParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_report/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createFaultReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_report/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateFaultReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_report/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteFaultReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/fault_report/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getFaultReportById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/fault_report/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//========================
//====================
//维修经验库
export async function getMaintenanceExperienceList(
    params?: API.MaintenanceExperienceParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/maintenance_experience/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createMaintenanceExperience(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/maintenance_experience/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateMaintenanceExperience(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/maintenance_experience/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteMaintenanceExperience(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/maintenance_experience/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getMaintenanceExperienceById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/maintenance_experience/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据设备ID获取维修经验库列表
export async function getMaintenanceExperienceListByDeviceId(
    params?: API.FaultExperienceListByDeviceIdParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/maintenance_experience/list_device`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//========================
//====================
//维修工单
export async function getRepairOrderList(
    params?: API.RepairOrderParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/repair_order/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createRepairOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/repair_order/quick_work_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function UpdateRepairOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/repair_order/quick_work_order_update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteRepairOrder(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/repair_order/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getRepairOrderById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/repair_order/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//=======================
//=======================
//维修派工
export async function getRepairOrderListDispatch(
    params?: API.RepairOrderDispatchParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/repair_order/list_dispatch`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createRepairOrderDispatch(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/repair_order/dispatch', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// =======================
//=======================
//维修报工
export async function getRepairOrderListReport(
    params?: API.RepairOrderDispatchParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/repair_order/list_report`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createRepairOrderStart(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/repair_order/start', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function createRepairOrderComplete(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/repair_order/complete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

// =======================

//获取异常类别
export async function getExceptionTypeList(
    params?: API.exceptionTypeParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_type/list_exception_type`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改异常类别
export async function createOrUpdateExceptionType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_type/create_or_update_exception_type', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除异常类别
export async function deleteExceptionType(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_type/delete_exception_type', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取异常类别
export async function getExceptionTypeById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_type/get_exception_type`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取异常代码
export async function getExceptionCodeList(
    params: API.exceptionCodeParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_code/list_exception_code`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改异常代码
export async function createOrUpdateExceptionCode(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_code/create_or_update_exception_code', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除异常代码
export async function deleteExceptionCode(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_code/delete_exception_code', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取异常代码
export async function getExceptionCodeById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_code/get_exception_code`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据异常类型获取异常代码信息
export async function getExceptionCodeByType(
    params: API.getExceptionCodeByTypeParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_code/get_exception_code_by_type`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取异常查询列表和异常指派列表
export async function getExceptionQueryList(
    params: API.exceptionQueryParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_assign/list_exception_assign`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取异常查看详情
export async function getExceptionInfo(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_info/get_exception_info`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//异常指派详情
export async function getExceptionAssign(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_assign/assign_exception_assign', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取异常处理
export async function getExceptionHandleList(
    params?: API.exceptionQueryParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_handle/list_exception_handle`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取异常提报
export async function getExceptionReportList(
    params?: API.exceptionReportParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/exception_info/list_exception_info`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改异常提报
export async function createOrUpdateExceptionReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_info/create_or_update_exception_info', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除异常类别
export async function deleteExceptionReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_info/delete_exception_info', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//异常信息提报
export async function putForwardExceptionReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_info/put_forward_exception_info', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//异常信息撤回
export async function withdrawExceptionReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_info/withdraw_exception_info', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//异常信息关闭
export async function closeExceptionReport(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_info/close_exception_info', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//异常处理开始
export async function startExceptionHandle(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_handle/begin_exception_handle', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//异常处理完成
export async function endExceptionHandle(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/exception_handle/end_exception_handle', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取角色列表
export async function getRoleList(
    params: API.roleListParams,
    options?: { [key: string]: any }
) {
    return request<API.roleListResponse>(`/api/role/list_role`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改角色
export async function createOrUpdateRole(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/create_or_update_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除角色
export async function deleteRole(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/delete_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取角色信息
export async function getRole(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getRoleResponse>(`/api/role/get_role`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//角色授权
export async function authorizationRole(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/authorization_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//APP角色授权
export async function authorizationRoleApp(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/role/app_authorization_role', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据ID获取角色权限
export async function getRoleAuthority(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getUserRoleResponse>(`/api/role/get_role_authority`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据ID获取APP授权
export async function getRoleAuthorityApp(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getUserRoleResponse>(`/api/role/get_role_app_authority`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取组织机构
export async function getOrganizationList(
    params: API.organizationListParams,
    options?: { [key: string]: any }
) {
    return request<API.organizationListResponse>(`/api/organization/list_organization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新建或修改组织机构
export async function createOrUpdateOrganization(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/organization/create_or_update_organization', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除组织机构
export async function deleteOrganization(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/organization/delete_organization', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取组织结构树
export async function getOrganizationTree(
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/organization/get_organization_tree`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//根据ID获取组织机构信息
export async function getOrganization(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getOrganizationResponse>(`/api/organization/get_organization`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取岗位列表
export async function getStationList(
    params: API.jobListParams,
    options?: { [key: string]: any }
) {
    return request<API.jobListResponse>(`/api/station/list_station`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新增或修改岗位
export async function createOrUpdateStation(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/station/create_or_update_station', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除岗位
export async function deleteStation(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/station/delete_station', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//根据id获取岗位
export async function getStation(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    return request<API.getJobResponse>(`/api/station/get_station`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function getListRequest(
    params?: API.listParams,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}


export async function getAllProduct(
    params?: API.allProduct,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/product`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function productDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/product/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getProductCreate_or_update(
    body: API.ProductCreate_or_update,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>('/api/product/create_or_update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}


export async function getAllCompanyInfo(
    params?: API.allCompanyRequest,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/unit`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function postCompanyCreate_or_update(
    body: API.addCompanyItem,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>('/api/unit/create_or_update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function companyDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/unit/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getAllProcedureInfo(
    params?: API.allProcedureRequest,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_process`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function addProcedureItem(
    body: API.ProcedureAddItem,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_process/create_or_update`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function procedureDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_process/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}


export async function getAllProductionLineInfo(
    params?: API.ProductionLineRequest,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/line`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function ProductionLineUpdate(
    body: API.EditProductionLineItem,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/line/update`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function ProductionLineCreate(
    body: API.EditProductionLineItem,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/line/create`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function ProductionLineDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/line/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getCauseInfo(
    params?: API.requestCause,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/info/get_dereason_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function CauseUpdate(
    body: API.updateCause,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/info/update_dereason`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function CauseDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/info/delete_dereason`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getWork_stationInfo(
    params?: API.Work_stationRequest,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_station`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function Work_stationCreate(
    body: API.Work_stationCreate,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_station/create`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function Work_stationUpdate(
    body: API.Work_stationUpdate,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_station/update`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function Work_stationDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_station/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getBusinessmanInfo(
    params?: API.Businessman_RequestInfo,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/info/get_trader_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function BusinessmanUpdate(
    body: API.Businessman_Update,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/info/update_trader`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function BusinessmanDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/info/delete_trader`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getWorkmanshipInfo(
    params?: API.WorkmanshipRequest,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function WorkmanshipCreate(
    body: { work_craft_info?: string },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft/create`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function WorkmanshipUpdate(
    body: { work_craft_info?: string },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft/update`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function WorkmanshipDelete(
    body: {},
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/work_craft/delete`, {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

/**
 * --------------------------------订单管理相关--------------------------------
 */
export async function getOrderListRequest(
    params?: API.orderList,
    options?: { [key: string]: any }
) {
    return request<API.orderListResponse>(`/api/order/get_order_list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function getCheckOrderListRequest(
    params?: API.orderList,
    options?: { [key: string]: any }
) {
    return request<API.orderListResponse>(`/api/order/get_sendorder_list`, {

        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function getOrderDetailRequest(
    params?: API.orderCommon,
    options?: { [key: string]: any }
) {
    return request<API.detailOrderResponse>(`/api/order/get_order_detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function dispatchOrderRequest(
    body: API.orderCommon,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.orderCommonResponse>('/api/order/send_order', {

        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getAllUnitInfo(
    params?: API.allUnit,
    options?: { [key: string]: any }
) {
    return request<API.listResponse>(`/api/unit`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function callbackOrderRequest(
    body: API.orderCommon,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.orderCommonResponse>('/api/order/back_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteOrderRequest(
    body: API.orderCommon,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.orderCommonResponse>('/api/order/delete_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function newOrEditOrderRequest(
    body: API.newOrEditOrder,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.orderCommonResponse>('/api/order/update_order', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

/**
 * --------------------------------生产管理相关--------------------------------
 */
export async function queryRequest(
    params?: API.query,
    options?: { [key: string]: any }
) {
    return request<API.queryResponse>(`/api/task/progress`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function reportListRequest(
    params?: API.query,
    options?: { [key: string]: any }
) {
    return request<API.queryResponse>(`/api/report/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function startOrderRequest(
    body: API.startOrder,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.productionCommonResponse>('/api/report/start', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function finishOrderRequest(
    body: API.finishOrder,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.productionCommonResponse>('/api/report/done', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function workDetailRequest(
    params?: API.startOrder,
    options?: { [key: string]: any }
) {
    return request<API.workDetailResponse>(`/api/work/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function getDispatchListRequest(
    params?: API.dispatchList,
    options?: { [key: string]: any }
) {
    return request<API.queryResponse>(`/api/dispatch/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function dispatchRequest(
    body: API.dispatchOrder,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.productionCommonResponse>('/api/dispatch', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getTaskListRequest(
    params?: API.taskList,
    options?: { [key: string]: any }
) {
    return request<API.taskListResponse>(`/api/task/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function newTaskRequest(
    body: API.newTaskRequest,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.newTaskResponse>('/api/task', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getTaskDetailRequest(
    params?: { id: string },
    options?: { [key: string]: any }
) {
    return request<API.detailTaskResponse>(`/api/task/detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function deleteTaskRequest(
    body: { id: string },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.newTaskResponse>('/api/task/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function issueTaskRequest(
    body: { id: string },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.newTaskResponse>('/api/task/issue', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function recallTaskRequest(
    body: { id: string },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.newTaskResponse>('/api/task/recall', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getSellListRequest(
    params?: API.sellListRequest,
    options?: { [key: string]: any }
) {
    return request<API.sellListResponse>(`/api/task/order`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

/**
 * --------------------------------详情相关--------------------------------
 */
export async function getProductDetailRequest(
    params: { object_id: string },
    options?: { [key: string]: any }
) {
    return request<API.productDetailResponse>(`/api/product/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function getClientDetailRequest(
    params?: { object_id: string },
    options?: { [key: string]: any }
) {
    return request<API.clientDetailResponse>(`/api/info/get_trader_detail`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function getLineDetailRequest(
    params: { object_id: string },
    options?: { [key: string]: any }
) {
    return request<API.lineDetailResponse>(`/api/work_craft/${params.object_id}`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

/**
 * --------------------------------统计分析相关--------------------------------
 */
// 产量统计
export async function getStatisticsOutputRequest(
    params?: {
        start_date: string,
        end_date: string,
        product?: string,
        user?: number,
        work_process?: number,
        page?: number,
        per_page?: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.statisticsOutputResponse>(`/api/statistics/output`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 不良品统计
export async function getStatisticsBadRequest(
    params?: {
        start_date: string,
        end_date: string,
        line_info_id?: number,
        work_process_info_id?: number,
        page_num?: number,
        page_size?: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.statisticsBadResponse>(`/api/exception_statistics/get_dereason_statistics`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 异常统计分析
export async function getStatisticsExceptionRequest(
    params?: {
        exception_type_ids?: string,
        start_date: string,
        end_date: string,
        page_num?: number,
        page_size?: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.statisticsExceptionResponse>(`/api/exception_statistics/get_exception_statistical_analysis`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 获取异常类别列表
export async function getStatisticsExceptionListRequest(
    params?: {
        exception_type_describe?: string,
        page_num?: number,
        page_size?: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.statisticsExceptionListResponse>(`/api/exception_type/list_exception_type`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 异常柏拉图
export async function getStatisticsExceptionPlatoRequest(
    params?: {
        start_date: string,
        end_date: string,
        page_num?: number,
        page_size?: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.statisticsExceptionPlatoResponse>(`/api/exception_statistics/get_exception_plato`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取仓库信息列表
export async function getStorageList(
    params?: API.storageParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/storage`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createStorage(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/storage/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function updateStorage(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/storage/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteStorage(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/storage/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getStorageById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    const {object_id} = params;
    return request<API.basicResponse>(`/api/storage/${object_id}`, {
        method: 'GET',
        // params,
        ...(options || {}),
    })
}

// **********************************************
//获取库位信息列表
export async function getStorageAreaList(
    params?: API.storageAreaParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/storage_area`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createStorageArea(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/storage_area/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function updateStorageArea(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/storage_area/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteStorageArea(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/storage_area/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getStorageAreaById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    const {object_id} = params;
    return request<API.basicResponse>(`/api/storage_area/${object_id}`, {
        method: 'GET',
        // params,
        ...(options || {}),
    })
}

// **********************************************
//获取入库信息列表
export async function getInStoreList(
    params?: API.inStoreListParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/instore`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createInStore(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/instore/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function updateInStore(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/instore/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteInStore(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/instore/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getInStoreById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    const {object_id} = params;
    return request<API.basicResponse>(`/api/instore/${object_id}`, {
        method: 'GET',
        // params,
        ...(options || {}),
    })
}

// **********************************************
//获取出库信息列表
export async function getOutStoreList(
    params?: API.outStoreListParams,
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>(`/api/outstore`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

export async function createOutStore(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/outstore/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function updateOutStore(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/outstore/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function deleteOutStore(
    body?: FormData,
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.basicResponse>('/api/outstore/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

export async function getOutStoreById(
    params: API.getItemParams,
    options?: { [key: string]: any }
) {
    const {object_id} = params;
    return request<API.basicResponse>(`/api/outstore/${object_id}`, {
        method: 'GET',
        // params,
        ...(options || {}),
    })
}

// **********************************************

/**
 * --------------------------------仓储管理相关--------------------------------
 */
// 库存查询接口
export async function getWarehouseQuery(
    params?: {
        storage_id?: number,
        product_name?: string,
        page_num: number,
        page_size: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseQueryResponse>(`/api/inventory/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 库存明细-产品信息
export async function getWarehouseQuery_ProductInfo(
    params?: {
        object_id: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponse_ProductInfo>(`/api/inventory/detail_product`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 库存明细-入库明细
export async function getWarehouseQuery_In(
    params?: {
        object_id: number,
        page_num: number,
        page_size: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponse_In>(`/api/inventory/detail_in`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

// 库存明细-出库明细
export async function getWarehouseQuery_Out(
    params?: {
        object_id: number,
        page_num: number,
        page_size: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponse_Out>(`/api/inventory/detail_out`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取仓库下所有产品信息
export async function getWarehouseProduct_All(
    params?: {
        storage_id: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponse_All>(`/api/inventory/get_all_product`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取调拨列表
export async function getWarehouseAllot(
    params?: {
        source_storage_id?: number,
        start_date?: string,
        end_date?: string,
        page_num: number,
        page_size: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponseAllot>(`/api/inventory_transfer/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取调拨信息
export async function getWarehouseAllotDetail(
    params?: {
        object_id: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponseAllotDetail>(`/api/inventory_transfer/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新建调拨
export async function newAllot(
    body: {
        code?: string,
        source_storage_id: number,
        objective_storage_id: number,
        remarks?: string,
        inventory_transfer_details?: any,
        submit_type: number,
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.warehouseCommonResponse>('/api/inventory_transfer/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//更新调拨单信息
export async function updateAllot(
    body: {
        object_id: number,
        code?: string,
        source_storage_id: number,
        objective_storage_id: number,
        remarks?: string,
        inventory_transfer_details?: any,
        submit_type: number,
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.warehouseCommonResponse>('/api/inventory_transfer/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除调拨信息
export async function deleteAllot(
    body: {
        object_id: number,
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.warehouseCommonResponse>('/api/inventory_transfer/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//获取盘点列表
export async function getWarehouseCheck(
    params?: {
        storage_id?: number,
        start_date?: string,
        end_date?: string,
        page_num: number,
        page_size: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponseCheck>(`/api/inventory_count/list`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//获取盘点信息
export async function getWarehouseCheckDetail(
    params?: {
        object_id: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponseCheckDetail>(`/api/inventory_count/get`, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//打印盘点单
export async function getWarehousePrintCheck(
    params?: {
        object_id: number,
    },
    options?: { [key: string]: any }
) {
    return request<API.warehouseResponseCheckDetail>(``, {
        method: 'GET',
        params,
        ...(options || {}),
    })
}

//新建盘点
export async function newCheck(
    body: {
        code?: string,
        storage_id: number,
        inventory_date: string,
        user_id: number,
        remarks?: string,
        inventory_count_details?: any,
        submit_type: number,
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.warehouseCommonResponse>('/api/inventory_count/create', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//编辑盘点
export async function updateCheck(
    body: {
        object_id: number,
        code?: string,
        storage_id: number,
        inventory_date: string,
        user_id: number,
        remarks?: string,
        inventory_count_details?: any,
        submit_type: number,
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.warehouseCommonResponse>('/api/inventory_count/update', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}

//删除盘点信息
export async function deleteCheck(
    body: {
        object_id: number,
    },
    params?: {},
    options?: { [key: string]: any }
) {
    return request<API.warehouseCommonResponse>('/api/inventory_count/delete', {
        method: 'POST',
        params,
        data: body,
        ...(options || {}),
    })
}



/**
 * --------------------------------巡检点检相关--------------------------------
 */

// 巡检项目列表
export async function getPatrolProject(
    params?:API.getPartrolProjectParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_items/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 巡检项目详情
export async function getPatrolProjectInfo(
    params?:API.getPatrolProjectOrPointInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_items/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 增加巡检项目
export async function postAddPatrolProject(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_items/create',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 删除巡检项目
export async function postDeletePatrolProject(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_items/delete',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 修改巡检项目
export async function postEditPatrolProject(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_items/update',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 点检项目列表
export async function getPointProject(
    params?:API.getPartrolPointParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_items/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 点检项目详情
export async function getPointProjectInfo(
    params?:API.getPatrolProjectOrPointInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_items/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 增加点检项目
export async function postAddPointProject(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_items/create',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 删除点检项目
export async function postDeletePointProject(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_items/delete',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 修改点检项目
export async function postEditPointProject(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_items/update',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 巡检计划列表
export async function getPatrolPlan(
    params?:API.getPatrolPlanParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_plan/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 巡检计划详情
export async function getPatrolPlanInfo(
    params?:API.getPatrolPlanInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_plan/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 新建巡检计划
export async function postCreatePatrolPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_plan/create',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 修改巡检计划
export async function postEditPatrolPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_plan/update',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 删除巡检计划
export async function postDeletePatrolPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_plan/delete',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 启用或者停用巡检计划
export async function postEnableOrDeactivatePatrolPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_plan/enable_deactivate',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 点检计划列表
export async function getPointPlan(
    params?:API.getPointPlanParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_plan/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 点检计划详情
export async function getPointPlanInfo(
    params?:API.getPointPlanInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_plan/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 新建点检计划
export async function postCreatePointPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_plan/create',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 修改点检计划
export async function postEditPointPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_plan/update',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 删除点检计划
export async function postDeletePointPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_plan/delete',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 启用或者停用点检计划
export async function postEnableOrDeactivatePointPlan(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_plan/enable_deactivate',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 巡检任务列表
export async function getPatrolTaskList(
    params?:API.getPatrolTaskListParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_task/list',{
        method:'GET',
        params,
        ...(options || {})
    })
}

// 巡检任务详情
export async function getPatrolTaskInfo(
    params?:API.getPatrolTaskInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_task/get',{
        method:'GET',
        params,
        ...(options || {})
    })
}

// 调整巡检任务
export async function postAdjustPatrolTask(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_task/adjust',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 巡检任务派工
export async function postDispatchPatrolTask(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_task/distribute',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 点检任务列表
export async function getPointTaskList(
    params?:API.getPointTaskListParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_task/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 调整点检任务
export async function postAdjustPointTask(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_task/adjust',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 点检任务派工
export async function postDistributePointTask(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_task/distribute',{
        method:'post',
        data:body,
        params,
        ...(options || {})
    })
}

// 获取点检任务信息
export async function getPointTaskInfo(
    params?:API.getPointTaskInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_task/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 巡检报工列表
export async function getPatrolReportList(
    params?:API.getPatrolReportListParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_report/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 巡检任务开始
export async function postPatrolTaskStart(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_report/start', {
        method:"POST",
        params,
        data:body,
        ...(options || {})
    })
}

// 巡检任务完成
export async function postPatrolTaskCompelete(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_report/complete', {
        method:"POST",
        params,
        data:body,
        ...(options || {})
    })
}

// 获取巡检报工详情
export async function getPatrolReportInfo(
    params?:API.getPatrolReportInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/patrol_report/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 点检报工列表
export async function getPointReportList(
    params?:API.getPointReportListParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_report/list', {
        method:"GET",
        params,
        ...(options || {})
    })
}

// 点检任务开始
export async function postPointTaskStart(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_report/start', {
        method:"POST",
        params,
        data:body,
        ...(options || {})
    })
}

// 点检任务完成
export async function postPointTaskComplete(
    body?:FormData,
    params?:{},
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_report/complete', {
        method:"POST",
        params,
        data:body,
        ...(options || {})
    })
}

// 获取点检报工详情
export async function getPointReportInfo(
    params?:API.getPatrolReportInfoParams,
    options?:{[key:string]:any}
){
    return request<API.basicResponse>('/api/spot_report/get', {
        method:"GET",
        params,
        ...(options || {})
    })
}

