//新增或编辑设备信息
import {Button, Col, DatePicker, Divider, Form, Input, message, Radio, Row, Select, Space,} from "antd";
import {convertMinToHHmm, convertSecToHHmm, formatNumber, isHalt, showError, troubleLevel} from "../../../utils";
import React, {useEffect, useState} from "react";
import {getDeviceInfoList, getFaultTypeList, getGroupAllUser, getGroupList} from "../../../service";
import {Options} from "../../../components/GeneralManagement";
import {ColumnsType} from "antd/es/table";
import ModalContainer from "../../../components/Modal";
import {InputNumber} from "antd/es";
import ModalInner from "./ModalInner";
import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import {useWatch} from "antd/es/form/Form";
import UploadPictures from "../../../components/UploadPictures";
import moment from "moment";

interface AddOrEditProps {
    form: any;
    tableForm: any,
    fileList: any;
    setFileList: (fileIDList: any) => void;
    repairFileList: any;
    setRepairFileList: (repairFileList: any) => void;
    initialDeviceName?: string,
    editHeadList?: any[],
    initialDataSource?: any[]
}

interface CostComponentProps {
    index: number
}

const AddOrEdit = (props: AddOrEditProps) => {
    const {
        form,
        fileList,
        setFileList,
        repairFileList,
        setRepairFileList,
        initialDeviceName,
        tableForm,
        editHeadList,
        initialDataSource
    } = props;
    const [typeList, setTypeList] = useState<Options[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [repairOpen, setRepairOpen] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [repairSelectedRowKeys, setRepairSelectedRowKeys] = useState<any[]>([]);
    const [repairSelectedRows, setRepairSelectedRows] = useState<any[]>([]);
    const [detailDataSource, setDetailDataSource] = useState<any[]>([]);
    const tRef = React.useRef<TRef>(null);
    const [nameList, setNameList] = useState<any[]>([]);
    const [groupList, setGroupList] = useState<Options[]>([]);
    const [headList, setHeadList] = useState<any[]>([]);
    const [deviceName, setDeviceName] = useState<string>();
    const [loading, setLoading] = useState<boolean>()
    const detailColumns: ColumnsType<any> = [
        {
            title: "NO",
            dataIndex: "NO",
            key: "NO",
            width: 60,
            ellipsis: true,
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        //不显示该项，width为0
        {
            title: 'id',
            dataIndex: 'user_id',
            key: 'user_id',
            align: 'center',
            ellipsis: true,
            width: 0,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'user_id']}>
                    <Input placeholder={'请输入ID'} disabled/>
                </Form.Item>
        },
        {
            title: '姓名',
            dataIndex: 'user_name',
            key: 'user_name',
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'user_name']}>{value}</Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>维修开始时间</span>,
            dataIndex: 'repair_start_time',
            key: 'repair_start_time',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'repair_start_time']} rules={[
                    {required: true, message: '请选择维修开始时间！'},
                    ({getFieldValue}) => ({
                        validator: (_, value) => {
                            if (value) {
                                if (getFieldValue([`${index}`, 'repair_end_time'])) {
                                    const end = getFieldValue([`${index}`, 'repair_end_time']).seconds(0).milliseconds(0)
                                    const start = value.seconds(0).milliseconds(0)
                                    const result = moment(end).diff(start, 'minutes')
                                    if (result > 0) {
                                        //    开始时间大于结束时间
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject('开始时间必须小于结束时间！')
                                    }
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }
                    })
                ]}>
                    <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm" allowClear={false}/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>维修结束时间</span>,
            dataIndex: 'repair_end_time',
            key: 'repair_end_time',
            width: 200,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'repair_end_time']} rules={[
                    {required: true, message: '请选择维修结束时间！'},
                    ({getFieldValue}) => ({
                        validator: (_, value) => {
                            if (value) {
                                if (getFieldValue([`${index}`, 'repair_start_time'])) {
                                    const result = moment((value.seconds(0).milliseconds(0))).diff((getFieldValue([`${index}`, 'repair_start_time']).seconds(0).milliseconds(0)), 'minutes')
                                    if (result > 0) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('结束时间必须大于开始时间！')
                                    }
                                }
                            }

                        }
                    })
                ]}>
                    <DatePicker showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm" allowClear={false}/>
                </Form.Item>
        },
        {
            title: '维修用时',
            dataIndex: 'repair_time',
            key: 'repair_time',
            align: 'center',
            width: 200,
            ellipsis: true,
            render: (value: any, item: any, index: number) => <CostComponent index={index}/>
        },
        {
            title: '维修内容',
            dataIndex: 'repair_content',
            key: 'repair_content',
            align: 'center',
            ellipsis: true,
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'repair_content']} rules={[{
                    max: 200,
                    message: '维修内容不得大于200位！'
                }]}>
                    <Input.TextArea placeholder={'请输入维修过程'}>
                    </Input.TextArea>
                </Form.Item>
        },
    ]

    //维修用时
    const CostComponent = React.memo((props: CostComponentProps) => {
        const start = useWatch([`${props.index}`, 'repair_start_time'], tableForm);
        const end = useWatch([`${props.index}`, 'repair_end_time'], tableForm);

        useEffect(() => {
            if (start && end) {
                let tempEnd = end.seconds(0).milliseconds(0);
                let tempStart = start.seconds(0).milliseconds(0);
                const result = tempEnd.diff(tempStart, 'minutes')
                const cost = convertMinToHHmm(result);
                tableForm.setFieldValue([`${props.index}`, 'costHour'], cost.hour);
                tableForm.setFieldValue([`${props.index}`, 'costMinute'], cost.minute);
                let allCostHour = 0;
                let allCostMinute = 0;
                Object.values(tableForm.getFieldsValue()).map((e: any) => {
                    allCostMinute += Number(e.costMinute ? e.costMinute : 0);
                    allCostHour += Number(e.costHour ? e.costHour : 0);
                })
                let allCost = convertMinToHHmm(allCostHour * 60 + allCostMinute);

                form.setFieldValue('hour', allCost.hour)
                form.setFieldValue('minute', allCost.minute)
            }
        }, [start, end])

        return <Space size={2}>
            <Form.Item name={[`${props.index}`, 'costHour']} style={{textAlign: 'left'}} initialValue={0}>
                <InputNumber disabled/>
            </Form.Item>
            小时
            <Form.Item name={[`${props.index}`, 'costMinute']} style={{textAlign: 'left'}} initialValue={0}>
                <InputNumber disabled/>
            </Form.Item>
            分钟
        </Space>
    })

    const cancelItem = () => {
        setOpen(false);
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    const cancelRepairItem = () => {
        setRepairOpen(false);
        setRepairSelectedRowKeys([]);
        setRepairSelectedRows([]);
    }

    const selectItem = () => {
        if (selectedRows.length) {
            form.resetFields(['fault_describe'])
            form.setFieldValue('fault_describe', selectedRows[0].fault_describe)
        }
        cancelItem()
    }

    const selectRepairItem = () => {
        if (repairSelectedRows.length) {
            form.resetFields(['maintenance_process'])
            form.setFieldValue('maintenance_process', repairSelectedRows[0].maintenance_describe)
        }
        cancelRepairItem()
    }

    const selectDevice = (value: any, option: any) => {
        //获取设备名称
        setDeviceName(option.children)
        form.setFieldValue('link_device_id', option.title1)
    }

    const changeGroup = (e: any) => {
        setLoading(true)
        getGroupAllUser({object_id: e}).then(res => {
            if (res.code === 200) {
                if (res.data.length) {
                    setHeadList(res.data)
                    const headItem = res.data.filter((e: any) => e.is_head === 1)
                    form.resetFields(['head_user_id'])
                    form.setFieldValue('head_user_id', headItem[0].object_id)
                    const otherItem = res.data.filter((e: any) => e.is_head === 0)
                    const temp = otherItem.map((e: any) => e.object_id)
                    form.setFieldValue('member_user_id', temp)
                    const temp1: any[] = []
                    res.data.map((e: any) => {
                        temp1.push({
                            user_id: e.object_id,
                            user_name: e.name,
                            repair_start_time: '',
                            repair_end_time: '',
                            repair_time: '',
                            costHour: 0,
                            costMinute: 0,
                            repair_content: '',
                        })
                    })
                    setDetailDataSource(temp1)
                    tableForm.setFieldsValue({...temp1})
                    form.setFieldValue('hour', 0)
                    form.setFieldValue('minute', 0)
                    setLoading(false)
                }
            } else {
                setLoading(false)
                showError(res.message)
            }
        })
    }

    const changeHead = (value: any) => {
        setLoading(true)
        //设置其他维修人员
        const temp: any[] = []
        headList.map(e => {
            if (e.object_id !== value) {
                temp.push(e.object_id)
            }
        })
        form.setFieldValue('member_user_id', temp)
        // //设置维修工作量表格顺序
        const datasource = Object.values(tableForm.getFieldsValue())
        const tempHead = datasource.filter((e: any) => e.user_id == value)
        const tempOther = datasource.filter((e: any) => e.user_id !== value)
        const temp1: any[] = [...tempHead, ...tempOther]
        setDetailDataSource(temp1)
        tableForm.setFieldsValue({...temp1})
        setLoading(false)
    }

    useEffect(() => {
        //获取故障类型列表
        getFaultTypeList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: Options[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.fault_name
                            })
                        })
                    }
                    setTypeList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        //获取设备名称列表
        getDeviceInfoList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: any[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.device_name,
                                code: e.code
                            })
                        })
                    }
                    setNameList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        //设置设备名称
        if (initialDeviceName) {
            setDeviceName(initialDeviceName)
        }
        //获取班组列表
        getGroupList({page_num: 1, page_size: 10000000}).then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    let temp: any[] = [];
                    if (res.data.data.length) {
                        res.data.data.map((e: any) => {
                            temp.push({
                                id: e.object_id,
                                name: e.group_name,
                            })
                        })
                    }
                    setGroupList(temp)
                }
            } else {
                showError(res.message);
            }
        })
        //设置维修负责人列表
        if (editHeadList) {
            setHeadList(editHeadList)
        }
        //设置维修工作量列表
        if (initialDataSource) {
            setLoading(true)
            setDetailDataSource(initialDataSource)
            setLoading(false)
        }
    }, [])

    return (
        <div className={'viewItem'}>
            {/*    故障信息*/}
            <div className={'viewTitle'}>故障信息</div>
            <Form colon={false} form={form} className={'form-margin-long'}>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'维修单号'} name={'code'} rules={[
                            {
                                type: "string",
                                max: 32,
                                pattern: /^[A-Za-z0-9]+$/,
                                message: '报修单号由不大于32位的字母、数字组成!'
                            }
                        ]}>
                            <Input placeholder={'不填写，系统将自动生成'} disabled={!!initialDeviceName}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备名称'} name={'device_id'} rules={[
                            {required: true, message: '请选择设备名称!'}]}>
                            <Select placeholder={'请选择设备名称'} onSelect={selectDevice}>
                                {
                                    nameList.map(e => <Select.Option key={e.id} value={e.id}
                                                                     title1={e.code}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'设备代码'} name={'link_device_id'}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'故障时间'} name={'fault_time'} rules={[
                            {required: true, message: '请选择故障时间!'}]}>
                            <DatePicker placeholder={'请选择故障时间'} showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'故障类型'} name={'fault_type_id'} rules={[
                            {required: true, message: '请选择故障类型!'}]}>
                            <Select placeholder={'请选择故障类型'}>
                                {
                                    typeList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'shutdown_or_not'} label={'是否停机'}
                                   rules={[{required: true, message: '请选择是否停机!'}]}>
                            <Radio.Group>
                                {
                                    isHalt.map((e: any) => <Radio key={e.id} value={e.id}>{e.name}</Radio>)
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'故障等级'} name={'fault_level'} rules={[
                            {required: true, message: '请选择故障等级!'}]}>
                            <Select placeholder={'请选择故障等级'}>
                                {
                                    troubleLevel.map(e => <Select.Option key={e.id}
                                                                         value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'故障描述'} name={'fault_describe'}
                                   rules={[{required: true, message: '请输入故障描述!'}, {
                                       max: 200,
                                       message: '故障描述不得大于200位！'
                                   }]}>
                            <Input.TextArea placeholder={'请输入故障描述'} className={'textarea-padding-24'}
                                            autoSize={{minRows: 3}}></Input.TextArea>
                        </Form.Item>
                        <div className={'textArea-suffix'} onClick={() => {
                            if (form.getFieldValue('device_id')) {
                                setOpen(true);
                            } else {
                                message.warning('请先选择设备名称！');
                            }
                        }}>
                            <img src={require('../../../assets/trouble-library-icon.png')} alt="暂无图片"
                                 style={{marginRight: 5}}/>
                            <span className={'trouble-library-text'}>故障库</span>
                        </div>
                    </Col>
                </Row>
                <Divider/>
                {/*故障图片*/}
                <div className={'viewTitle'}>故障图片</div>
                <UploadPictures fileList={fileList} setFileList={setFileList}/>
                <Divider/>
                <div className={'viewTitle'}>维修人员</div>
                <Row>
                    <Col span={8}>
                        <Form.Item label={'维修班组'} name={'team_groups_id'} rules={[
                            {required: true, message: '请选择维修班组!'}]}>
                            <Select placeholder={'请选择维修班组'} onChange={(value) => {
                                changeGroup(value)
                            }}>
                                {
                                    groupList.map(e => <Select.Option key={e.id}
                                                                      value={e.id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'维修负责人'} name={'head_user_id'} rules={[
                            {required: true, message: '请选择维修负责人!'}]}>
                            <Select placeholder={'请选择维修负责人'} onChange={(value) => changeHead(value)}>
                                {
                                    headList.map(e => <Select.Option key={e.object_id}
                                                                     value={e.object_id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'其他维修人员'} name={'member_user_id'}>
                            <Select disabled mode={'multiple'}>
                                {
                                    headList.map((e: any) =>
                                        <Select.Option key={e.object_id} value={e.object_id}>{e.name}</Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                <div className={'viewTitle'}>维修过程</div>
                <Row>
                    <Col span={8}>
                        <Form.Item label={<div><span className={'requiredIcon'}>*</span><span>维修费用</span></div>}>
                            <Space>
                                <Form.Item noStyle name={'maintenance_costs'} rules={[
                                    {required: true, message: '请输入维修费用!'}]}>
                                    <InputNumber placeholder={'请输入维修费用'} min={0}
                                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                                </Form.Item>
                                <div>元</div>
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={'维修总用时'}>
                            <Space>
                                <Form.Item noStyle name={'hour'} initialValue={0}>
                                    <InputNumber disabled/>
                                </Form.Item>
                                <div style={{width: 30}}>小时</div>
                                <Form.Item noStyle name={'minute'} initialValue={0}>
                                    <InputNumber disabled/>
                                </Form.Item>
                                <div style={{width: 30}}>分钟</div>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'维修过程'} name={'maintenance_process'}
                                   rules={[{required: true, message: '请输入维修过程!'}, {
                                       max: 200,
                                       message: '维修过程不得大于200位！'
                                   }]}>
                            <Input.TextArea placeholder={'请输入维修过程'} className={'textarea-padding-24'}
                                            autoSize={{minRows: 3}}>
                            </Input.TextArea>
                        </Form.Item>
                        <div className={'textArea-suffix'} onClick={() => {
                            if (form.getFieldValue('device_id')
                            ) {
                                setRepairOpen(true);
                            } else {
                                message.warning('请先选择设备名称！');
                            }
                        }}>
                            <img src={require('../../../assets/repair-library-icon.png')} alt="暂无图片"
                                 style={{marginRight: 5}}/>
                            <span className={'trouble-library-text'}>维修经验</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={'维修工作量'} name={'remarks2'}>
                            <EditOrDragTable tableForm={tableForm} detailColumns={detailColumns} tableLoading={loading}
                                             detailDataSource={detailDataSource} isNoneAdd={true}
                                             title={``} ref={tRef} scroll={false}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider/>
                <div className={'viewTitle'}>维修图片</div>
                <UploadPictures fileList={repairFileList} setFileList={setRepairFileList}/>
                <Divider/>
            </Form>
            {/*    故障经验弹窗*/}
            <ModalContainer title={'选择故障经验'} open={open} width={'1071px'}
                            onCancel={() => cancelItem()} okText={'确定'}
                            footer={<Button type={'primary'} onClick={() => selectItem()}>确定</Button>}
                            innerComponent={<ModalInner setSelectedRows={setSelectedRows}
                                                        setSelectedRowKeys={setSelectedRowKeys}
                                                        selectedRowKeys={selectedRowKeys}
                                                        deviceId={form.getFieldValue('device_id')}
                                                        deviceCode={form.getFieldValue('link_device_id')}
                                                        deviceName={deviceName}
                                                        type={'trouble'}/>}/>
            {/*    故障经验弹窗*/}
            <ModalContainer title={'选择维修经验'} open={repairOpen} width={'1071px'}
                            onCancel={() => cancelRepairItem()} okText={'确定'}
                            footer={<Button type={'primary'} onClick={() => selectRepairItem()}>确定</Button>}
                            innerComponent={<ModalInner setSelectedRows={setRepairSelectedRows}
                                                        setSelectedRowKeys={setRepairSelectedRowKeys}
                                                        selectedRowKeys={repairSelectedRowKeys}
                                                        deviceId={form.getFieldValue('device_id')}
                                                        deviceCode={form.getFieldValue('link_device_id')}
                                                        deviceName={deviceName}
                                                        type={'repair'}/>}/>
        </div>
    )
}
export default AddOrEdit;
