import React from 'react'
import { Modal, Menu } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import Dropdown from '../../../../components/dropdown'
import { toastMessage } from "../../../../utils";


export default class AddModal extends React.Component<{
    closeClick: () => void,
    saveClick: (e: API.Businessman_Update) => void,
}> {
    state = {
        code: '',
        name: '',
        type: 0,
        link_name: '',
        contact: '',
        remark: ''
    }

    render() {
        const {
            code,
            name,
            type,
            link_name,
            contact,
            remark
        } = this.state
        const { closeClick,
            saveClick } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'新增客商信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='客商代码' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    placeholder='不填写，系统将自动生成'
                    value={code}
                    onChange={(value) => {
                        if (value === '') {
                            this.setState({
                                code: value
                            })
                        } else {
                                if (/^[A-Za-z0-9]+$/.test(value)) {
                                    this.setState({
                                        code: value
                                    })
                                }
                        }
                    }}
                />

            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1} title='客商名称' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }} placeholder='请输入客商名称' onChange={(value) => {
                    this.setState({
                        name: value
                    })
                }}
                value={name}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Dropdown require={1} title='客商类别' titleStyle={{ marginRight: 10 }} dropdownButtonStyle={{ width: 281 }} placeholder='请选择客商类别' defaultValue={type === 10 ? '客户' : type === 20 ? '供应商' : type === 30 ? '客商' : ''} overlay={<Menu items={[{ label: '客户', key: '1' }, { label: '供应商', key: '2' }, { label: '客商', key: '3' }]}
                    onClick={(e) => {
                        console.log('ssss', e);
                        this.setState({
                            type: e.key === '1' ? 10 : e.key === '2' ? 20 : 30
                        })
                    }} />} />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='联系人'
                    titlestyle={{ width: 60, textAlign: 'left' }}
                    style={{ width: 281, marginRight: 20 }}
                    placeholder='请输入联系人姓名'
                    onChange={(value) => {
                        if (value === '') {
                            this.setState({
                                link_name: value
                            })
                        } else {
                            this.setState({
                                link_name: value
                            })
                        }
                    }}
                    value={link_name}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='联系方式' titlestyle={{ width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入联系人方式，邮箱或电话' onChange={(value) => {
                    this.setState({
                        contact: value
                    })
                }}
                value={contact}
                />

            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注'remark titlestyle={{ width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入备注' onChange={(value) => {
                    this.setState({
                        remark: value
                    })
                }}
                    value={remark}
                />

            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!name) {
                        toastMessage('客商名称不能为空')
                        return
                    }
                    if (!type) {
                        toastMessage('客商类别不能为空')
                        return
                    }
                    let value: API.Businessman_Update = {
                        name: name,
                        type: type,
                    }
                    if (code) {
                        value.code = code
                    }
                    if (link_name) {
                        value.link_name = link_name
                    }
                    if (contact) {
                        value.contact = contact
                    }
                    if (remark) {
                        value.remark = remark
                    }
                    saveClick(value)
                }} />
            </div>
        </Modal>
    }
}