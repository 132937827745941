import {useState} from "react";
import {Table,Image} from "antd";
import type { ColumnsType } from 'antd/es/table';
import enabled from "../../../../assets/enable.png";
import unEnabled from "../../../../assets/un_enable.png";
import { nanoid } from 'nanoid'
interface IProps {
  selectItem:any
}
interface DataType {
    key: string
    name: string
    spot_name: string
    spot_content: string
    spot_type: number
    upper_limit:string
    lower_limit:string
    parameter_name:string
    results_options:string[]
    spot_area:string
    spot_method:number
}
const ShowPlan = (props:IProps) => {
    const [type,setType] = useState<string>("计划信息");
    const {selectItem} = props;
    const {create_user_name,update_user_name,create_time,update_time,code,spot_plan_name,plan_start_date,plan_end_date,plan_status,
      execute_cycle_describe,remarks,device_name,device_code,device_specification,device_type_name,device_organization_name,
      device_position,spot_items} = selectItem
    const columns: ColumnsType<DataType> = [
      {
        title: "No",
        dataIndex: "index",
        align:"center",
        fixed:true,
        render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
      },
      {
        title: "点检项目",
        dataIndex: "spot_name",
        fixed:true,
        align:"left",
        ellipsis: true
      },
      {
        title: "点检部位",
        dataIndex: "spot_area",
        align:"center",
        ellipsis: true
      },
      {
        title: "点检方法",
        dataIndex: "spot_method",
        align:"center",
        ellipsis: true,
        render:(i:number) => <span>{i ? i === 10 ? '目视' : i === 20 ? '手感' : i === 30 ? '看听' : i === 40 ? '看/听' : '' : '' }</span>
      },
      {
        title: "点检内容",
        dataIndex: "spot_content",
        ellipsis: true,
        align:"left",
      },
      {
        title: "参数名称",
        dataIndex: "parameter_name",
        align:"center",
        ellipsis: true
      },
      {
        title: "点检结果类型",
        dataIndex: "spot_type",
        align:"center",
        ellipsis: true,
        render:(i:number) => <span>{i === 10 ? '数值' : i === 20 ? '单选' : '多选'}</span>
      },
      {
        title: "上限",
        dataIndex: "upper_limit",
        align:"center",
        ellipsis: true
      },
      {
        title: "下限",
        dataIndex: "lower_limit",
        align:"center",
        ellipsis: true
      },
      {
        title: "选项",
        dataIndex: "results_options",
        align: "left",
        ellipsis: true,
        render:(i:string[]) => <span>{i.join('；')}</span>
      },
    ];
    return (
      <div className="show-plan">
        <div className="show-plan-top">
            <div className="show-plan-top-left">
              <div className="top-box">
                <span><span className="title">创建人：</span>{create_user_name}</span>
                <span><span className="title">创建时间：</span>{create_time}</span>
              </div>
              <div className="top-box">
                <span><span className="title">更新人：</span>{update_user_name}</span>
                <span><span className="title">更新时间：</span>{update_time}</span>
              </div>
            </div>           
            {plan_status === 10 ?  <Image src={enabled} preview={false} width={116} height={91}></Image> : <Image src={unEnabled} preview={false} width={116} height={91}></Image>}
        </div>
        <div className="show-plan-line"></div>
        <div className="show-plan-bottom">
            <div className="bottom-box-one">
                <div className={type === "计划信息" ? "tab-active" : "tab"} onClick={()=>setType("计划信息")}>计划信息</div>
                <div className={type === "设备信息" ? "tab-active" : "tab"} onClick={()=>setType("设备信息")}>设备信息</div>
                <div className={type === "点检项目" ? "tab-active" : "tab"} onClick={()=>setType("点检项目")}>点检项目</div>
            </div>
            <div className="show-plan-line"></div>
            <div className="bottom-box-two">
                {
                    type === "计划信息" && (
                        <div className="tab-message">
                            <div className="tab-column">
                                <div><span className="title">点检计划代码：</span>{code}</div>
                                <div><span className="title">计划开始日期：</span>{plan_start_date}</div>
                                <div><span className="title">计划状态：</span>{plan_status === 10 ? '启用' : '停用'}</div>
                                <div><span className="title">执行周期：</span>{execute_cycle_describe}</div>
                                <div><span className="title">备注：</span>{remarks}</div>
                            </div>
                            <div className="tab-column">
                                <div><span className="title">点检计划名称：</span>{spot_plan_name}</div>
                                <div><span className="title">计划结束日期：</span>{plan_end_date ? plan_end_date : '永久'}</div> 
                            </div>
                        </div>
                    )
                }
                {
                    type === "设备信息" && (
                    <div className="tab-device">
                        <div className="tab-column">
                            <span><span className="title">设备名称：</span>{device_name}</span>
                            <span><span className="title">规格型号：</span>{device_specification}</span>
                            <span><span className="title">存放位置：</span>{device_position}</span>
                            
                        </div>
                        <div className="tab-column">
                            <span><span className="title">设备代码：</span>{device_code}</span>
                            <span><span className="title">设备类型：</span>{device_type_name}</span> 
                            <span><span className="title">使用部门：</span>{device_organization_name}</span>
                        </div>
                    </div>
                    )
                }
                {
                    type === "点检项目" && (
                        <div className="tab-project">
                            <Table rowKey={() => nanoid()} columns={columns} dataSource={spot_items} pagination={false} scroll={{y:400}}></Table>
                        </div>
                    )
                }
            </div>
        </div>
      </div>
    )
}
export default ShowPlan;