import React, {useRef, useState} from "react";
import GeneralManagement, {QueryListItem} from "../../components/GeneralManagement";
import {Button, Form, message, Space} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {
    createRepairOrder,
    deleteRepairOrder,
    getGroupAllUser,
    getRepairOrderById,
    getRepairOrderList,
    UpdateRepairOrder
} from "../../service";
import {
    convertHHmmToSec,
    convertSecToHHmm,
    faultStatus,
    filterData, filterDateParam,
    getFormData,
    isHalt,
    showError,
    troubleLevel
} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';
import moment from "moment";
import AddOrEdit from "./components/AddOrEdit";
import ViewItem from "./components/ViewItem";
import {UploadFile} from "antd/es/upload/interface";

enum ModalTitle {
    ADD = '快速维修工单',
    EDIT = '编辑维修工单',
    VIEW = '查看维修工单',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

const RepairWorkOrder = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [repairFileList, setRepairFileList] = useState<UploadFile[]>([]);

    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '维修单号',
            placeholder: '请输入维修单号'
        },
        {
            key: 'device_name',
            name: '设备名称',
            placeholder: '请输入设备名称',
        },
        {
            key: 'status',
            name: '状态',
            placeholder: '请选择工单状态',
            options: faultStatus,
            multiple:true
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            fixed: 'left',
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '维修单号',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
            width: 200,
            ellipsis: true,
            render: (value: string, item: any) => {
                return <span className={'mes-options'} onClick={() => {
                    openModal(ModalTitle.VIEW, item)
                }}>{value}</span>
            }
        },
        {
            title: '设备代码',
            dataIndex: 'device_code',
            key: 'device_code',
            align: 'center',
            fixed: 'left',
            width: 120,
            ellipsis: true,
        },
        {
            title: '设备名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            fixed: 'left',
            width: 120,
            ellipsis: true,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'fault_time',
            title: '故障时间',
            dataIndex: 'fault_time',
            align: 'center',
            width: 250,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            key: 'fault_name',
            title: '故障类型',
            dataIndex: 'fault_name',
            align: 'center',
            width: 100,
            render: (value: string) => <div style={{textAlign: 'left'}}>{value}</div>,
        },
        {
            key: 'shutdown_or_not',
            title: '是否停机',
            dataIndex: 'shutdown_or_not',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return isHalt.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            key: 'fault_level',
            title: '故障等级',
            dataIndex: 'fault_level',
            align: 'center',
            width: 100,
            render: (value: number) => {
                return troubleLevel.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '报修人',
            dataIndex: 'report_user_name',
            key: 'report_user_name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            align: 'center',
            width: 250,
            ellipsis: true, render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '提报时间',
            dataIndex: 'report_time',
            key: 'report_time',
            align: 'center',
            width: 250,
            ellipsis: true, render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 120,
            ellipsis: true,
            render: (value: number) => {
                return faultStatus.map((e: any, i: number) => {
                    if (e.id === value) {
                        return <div key={i} style={{color: e.color}}>{e.name}</div>;
                    }
                });
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => {
                if (item.status === 10) {
                    return (
                        <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                            <span className={'mes-options'} onClick={() => {
                                openModal(ModalTitle.EDIT, item)
                            }}>编辑</span>
                            <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                                openModal(ModalTitle.DELETE, item)
                            }}>删除</span>
                        </Space>
                    )
                }
            }
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} style={{marginRight: 14, width: 76}} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>快速工单</Button>
        </div>
    ))

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            setModalTitle(title);
            form.resetFields();
            setInnerComponent(<AddOrEdit form={form} fileList={fileList} setFileList={setFileList}
                                         repairFileList={repairFileList} setRepairFileList={setRepairFileList}
                                         tableForm={tableForm}/>)
        } else if (title === ModalTitle.EDIT) {
            setModalTitle(title);
            getRepairOrderById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.fault_info.code,
                        device_id: res.data.fault_info.device_id,
                        link_device_id: res.data.fault_info.device_code,
                        fault_time: res.data.fault_info.fault_time ? moment(res.data.fault_info.fault_time) : '',
                        fault_type_id: res.data.fault_info.fault_type_id,
                        shutdown_or_not: res.data.fault_info.shutdown_or_not,
                        fault_level: res.data.fault_info.fault_level,
                        fault_describe: res.data.fault_info.fault_describe,
                        team_groups_id: res.data.team_groups_id,
                        head_user_id: res.data.head_user_id,
                        member_user_id: res.data.member_user_id,
                        maintenance_costs: res.data.maintenance_costs,
                        hour: convertSecToHHmm(res.data.maintenance_total_time).hour,
                        minute: convertSecToHHmm(res.data.maintenance_total_time).minute,
                        maintenance_process: res.data.maintenance_process,
                    })
                    let initialDataSource: any = []
                    if (res.data.repair_order_workload) {
                        res.data.repair_order_workload.map((e: any, i: number) => {
                            initialDataSource.push({
                                index: i,
                                user_id: e.user_id,
                                user_name: e.workload_user_name,
                                repair_start_time: moment(e.repair_start_time),
                                repair_end_time: moment(e.repair_end_time),
                                costHour: convertSecToHHmm(e.repair_total_time).hour,
                                costMinute: convertSecToHHmm(e.repair_total_time).minute,
                                repair_content: e.repair_content,
                            })
                        })
                    }
                    tableForm.setFieldsValue({...initialDataSource})
                    getGroupAllUser({object_id: res.data.team_groups_id}).then(res1 => {
                        if (res1.code === 200) {
                            if (res1.data.length) {
                                let temp: any[] = [];
                                res.data.fault_info.image_fault_report.map((e: any) => {
                                    temp.push({
                                        uid: e.file.object_id,
                                        name: e.file.name,
                                        url: e.file.url,
                                    })
                                })
                                let repairTemp: any[] = [];
                                res.data.image_repair_order.map((e: any) => {
                                    repairTemp.push({
                                        uid: e.file.object_id,
                                        name: e.file.name,
                                        url: e.file.url,
                                    })
                                })
                                setInnerComponent(<AddOrEdit form={form} fileList={temp} setFileList={setFileList}
                                                             editHeadList={res1.data}
                                                             initialDeviceName={res.data.fault_info.device_name}
                                                             initialDataSource={initialDataSource}
                                                             repairFileList={repairTemp}
                                                             setRepairFileList={setRepairFileList}
                                                             tableForm={tableForm}/>)
                            }
                        } else {
                            showError(res.message)
                        }
                    })

                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setModalTitle(title);
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.VIEW) {
            const ViewDeviceTitle = (): React.ReactElement => {
                return (<div>
                    <img src={require('../../assets/view-repair-icon.png')} alt='暂无图片' style={{marginRight: 10}}/>
                    <span>{title}</span>
                </div>)
            }
            setModalTitle(ViewDeviceTitle as any);
            getRepairOrderById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    setInnerComponent(<ViewItem item={res.data}/>)
                } else {
                    showError(res.message)
                }
            })
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle, type?: number) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                //处理维修工作量
                tableForm.validateFields().then(values2 => {
                    let tempTable: any[] = []
                    Object.values(values2).map((e: any) => {
                        tempTable.push({
                            user_id: e.user_id,
                            repair_start_time: e.repair_start_time.format('YYYY-MM-DD HH:mm'),
                            repair_end_time: e.repair_end_time.format('YYYY-MM-DD HH:mm'),
                            repair_time: convertHHmmToSec(e.costHour, e.costMinute),
                            repair_content: e.repair_content,
                        })
                    })
                    tempTable.map((e: any) => {
                        filterData(e)
                    })
                    //处理图片
                    const tempPics = Object.values(fileList).map((e) => {
                        return {file_id: e.uid}
                    })
                    const tempPics2 = Object.values(repairFileList).map((e) => {
                        return {file_id: e.uid}
                    })
                    const temp = {
                        ...values,
                        maintenance_total_time: convertHHmmToSec(values.hour, values.minute),
                        fault_time: values.fault_time.format('YYYY-MM-DD HH:mm'),
                        submit_type: type,
                        fault_report_images: tempPics,
                        repair_images: tempPics2,
                        repair_workloads: JSON.stringify(tempTable)
                    }
                    delete temp.link_device_id;
                    delete temp.hour;
                    delete temp.minute;
                    const formData = getFormData(filterData(temp), true)
                    createRepairOrder(formData).then(res => {
                        if (res.code === 200) {
                            afterOnOK('新增成功！');
                        } else {
                            showError(res.message)
                        }
                    })
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                tableForm.validateFields().then(values2 => {
                    let tempTable: any[] = []
                    Object.values(values2).map((e: any) => {
                        tempTable.push({
                            user_id: e.user_id,
                            repair_start_time: e.repair_start_time.format('YYYY-MM-DD HH:mm'),
                            repair_end_time: e.repair_end_time.format('YYYY-MM-DD HH:mm'),
                            repair_time: convertHHmmToSec(e.costHour, e.costMinute),
                            repair_content: e.repair_content,
                        })
                    })
                    tempTable.map((e: any) => {
                        filterData(e)
                    })
                    //处理图片
                    const tempPics = Object.values(fileList).map((e) => {
                        return {file_id: e.uid}
                    })
                    const tempPics2 = Object.values(repairFileList).map((e) => {
                        return {file_id: e.uid}
                    })
                    const temp = {
                        ...values,
                        object_id: selectedItem.object_id,
                        maintenance_total_time: convertHHmmToSec(values.hour, values.minute),
                        fault_time: values.fault_time.format('YYYY-MM-DD HH:mm'),
                        submit_type: type,
                        fault_report_images: tempPics,
                        repair_images: tempPics2,
                        repair_workloads: JSON.stringify(tempTable)
                    }
                    delete temp.link_device_id;
                    delete temp.hour;
                    delete temp.minute;
                    const formData = getFormData(filterData(temp), true)
                    UpdateRepairOrder(formData).then(res => {
                        if (res.code === 200) {
                            afterOnOK('编辑成功！');
                        } else {
                            showError(res.message)
                        }
                    })
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteRepairOrder(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
            tableForm.resetFields();
            setFileList([]);
            setRepairFileList([]);
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getRepairOrderList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }

    const setWidth = (): string => {
        if (modalTitle === ModalTitle.DELETE) {
            return '20%'
        } else {
            return '1071px'
        }
    }

    const getFooter = (): React.ReactNode => {
        if (typeof modalTitle === 'object') {
            return null
        }
        // 保存10提交20
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            return (<div>
                <Button onClick={() => onCancel(modalTitle)}>关闭</Button>
                <Button type={'primary'} onClick={() => onOK(modalTitle, 10)}>保存</Button>
                <Button type={'primary'} onClick={() => onOK(modalTitle, 20)}>提交</Button>
            </div>)
        }
    }

    const filterQueryData = (queryData: any) => {
        if (queryData.status) {
            if(queryData.status.length){
                queryData.status = JSON.stringify(queryData.status)
            }
        }
        return queryData;
    }


    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef} scroll={true} filterQueryData={filterQueryData}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={setWidth()} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default RepairWorkOrder


