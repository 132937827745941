import React, {useRef, useState} from "react";
import GeneralManagement, {QueryListItem} from "../../components/GeneralManagement";
import {Button, Form, Input, message, Space} from "antd";
import ModalContainer from "../../components/Modal";
import DeleteItem from "../../components/DeleteItem";
import {
    createDeviceType,
    deleteDeviceType,
    getDeviceTypeById,
    getDeviceTypeList,
    UpdateDeviceType
} from "../../service";
import {filterData, getFormData, showError} from "../../utils";
import {ColumnsType} from "antd/es/table";
import './index.less';

enum ModalTitle {
    ADD = '新增设备类型',
    EDIT = '编辑设备类型',
    DELETE = '系统确认',
}

export interface GRef {
    onFinish: () => void;
}

const DeviceType = (): React.ReactElement => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [form] = Form.useForm();
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '代码',
            placeholder: '请输入设备类型代码'
        },
        {
            key: 'device_name',
            name: '名称',
            placeholder: '请输入设备类型名称',
        }
    ]
    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '设备类型代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '设备类型名称',
            dataIndex: 'device_name',
            key: 'device_name',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: true,
            render: (value: string) => <div
                style={{textAlign: 'left'}}>{value}</div>
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => (
                <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                    <span className={'mes-options'} onClick={() => {
                        openModal(ModalTitle.EDIT, item)
                    }}>编辑</span>
                    <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                        openModal(ModalTitle.DELETE, item)
                    }}>删除</span>
                </Space>
            )
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const AddOrEditJob = React.memo((): React.ReactElement => {
        return (
            <Form colon={false} form={form} className={'form-margin-long'}>
                <Form.Item label={'设备类型代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '设备类型代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'设备类型名称'} name={'device_name'} rules={[
                    {required: true, message: '请输入设备类型名称!'},
                    {type: "string", max: 32, message: '设备类型名称不得大于32位!'}]}>
                    <Input placeholder={'请输入设备类型名称'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remarks'} rules={[{max: 200, message: '备注不得大于200位！'}]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setSelectedItem(item);
        setModalTitle(title);
        setOpen(true);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditJob/>)
        } else if (title === ModalTitle.EDIT) {
            getDeviceTypeById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        device_name: res.data.device_name,
                        remarks: res.data.remarks
                    })
                    setInnerComponent(<AddOrEditJob/>)
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createDeviceType(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                UpdateDeviceType(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message)
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteDeviceType(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message)
                }
            })
        }
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        refreshData();
        if (modalTitle) {
            onCancel(modalTitle)
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(null);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = (queryCondition: API.jobListParams) => {
        return new Promise((resolve) => {
            getDeviceTypeList(queryCondition).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        resolve(res.data)
                    }
                } else {
                    showError(res.message)
                }
            })
        })
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish()
        }
    }


    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={(modalTitle === ModalTitle.DELETE) ? '20%' : '688px'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default DeviceType

