import React, {useCallback, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, Options, QueryListItem} from "../../components/GeneralManagement";
import {Button, Input, Select, Form, message, Space} from "antd";
import {filterData, getFormData, showError} from "../../utils";
import {
    createStorageArea, deleteStorageArea,
    getStorageAreaById, getStorageAreaList, getStorageList,
    updateStorageArea,
} from "../../service";
import DeleteItem from "../../components/DeleteItem";
import ModalContainer from "../../components/Modal";
import QRCode from 'qrcode.react';
import './index.less';
import {ColumnsType} from "antd/es/table";
// @ts-ignore
import Print from "react-print-html";

enum ModalTitle {
    ADD = '新增库位信息',
    EDIT = '编辑库位信息',
    DELETE = '系统确认',
    LABEL = '打印标签',
}

const WarehouseLoc = () => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const printRef = useRef(null);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [warehouse, setWarehouse] = useState<Options[]>([]);
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const formList: QueryListItem[] = [
        {
            key: 'storage_id',
            name: '所在仓库',
            placeholder: '请选择仓库',
            options: warehouse
        },
        {
            key: 'storage_area_no',
            name: '库位编号',
            placeholder: '请输入库位编号'
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '所在仓库',
            dataIndex: 'storage_name',
            key: 'warehouse',
            align: 'center',
            ellipsis: true,
            width: 300,
            render: (_: string) => <div className={'remark-left'}>{_}</div>
        },
        {
            title: '库位编号',
            dataIndex: 'storage_area_no',
            key: 'number',
            align: 'center',
            ellipsis: true,
            width: 300
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            ellipsis: true,
            render: (_: string) => <div className={'remark-left'}>{_}</div>
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            width: 300,
            render: (_: any, record: any) => {
                return (
                    <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, record)
                        }}>编辑</span>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, record)
                        }}>删除</span>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.LABEL, record)
                        }}>打印标签</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEdit = React.memo((): React.ReactElement => {

        return (
            <Form colon={false} form={form} className={'form-margin'}>
                <Form.Item label={'所在仓库'} name={'storage_id'} rules={[
                    {required: true, message: '请选择所在仓库!'},
                ]}>
                    <Select placeholder={'请选择所在仓库'}>
                        {
                            warehouse.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'库位编号'} name={'storage_area_no'} rules={[
                    {required: true, message: '请输入库位编号!'},
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9-\-]+$/,
                        message: '库位编号由不大于32位的字母、数字或短杠（-）组成!'
                    }
                ]}>
                    <Input placeholder={'请输入库位编号'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remark'} rules={[
                    {max: 200, message: '备注不得大于200位！'}
                ]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const ExtraComponent = React.memo((): React.ReactElement => (
        <div>
            <Button className={'mes-add'} style={{width: 70}} onClick={() => {
                openModal(ModalTitle.ADD, null);
            }}>添加库位</Button>
        </div>
    ))

    const LabelItem = (props: any) => {
        const {number} = props;

        useEffect(() => {
            const canvasToImage = (canvas: HTMLCanvasElement) => {
                const image = new Image();
                image.src = canvas.toDataURL("image/png");
                image.width = 250;
                return image;
            }
            const canvasContainer = document.getElementsByTagName('canvas')[0];
            const img = canvasToImage(canvasContainer);
            const ewmImg: HTMLElement | null = document.getElementById('ewm');
            if (ewmImg) {
                ewmImg.append(img);
            }
        }, [])

        return (
            <div ref={printRef}>
                <QRCode
                    id="qrCode"
                    value={`KW_${number}`}
                    size={80}
                    fgColor="#000000"
                    style={{float: 'right', display: 'none'}}
                />
                <div className="codeImg" id="ewm"></div>
                <div className={'label-number'}>{number}</div>
            </div>
        )
    }

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEdit/>)
        } else if (title === ModalTitle.EDIT) {
            getStorageAreaById({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        form.setFieldsValue({
                            storage_id: res.data.storage_id,
                            storage_area_no: res.data.storage_area_no,
                            remark: res.data.remark,
                        })
                        setInnerComponent(<AddOrEdit/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.LABEL) {
            setInnerComponent(<LabelItem number={item.storage_area_no}/>)
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createStorageArea(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                updateStorageArea(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteStorageArea(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.LABEL) {
            if (printRef.current) {
                Print(printRef.current)
                // onCancel(title)
            }
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getStorageAreaList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const afterOnOK = (text: string) => {
        message.success(text);
        setSelectedItem(null);
        refreshData();
        setOpen(false);
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const okText = (): string => {
        if (modalTitle === ModalTitle.DELETE) {
            return '确认'
        } else if (modalTitle === ModalTitle.LABEL) {
            return '打印'
        } else {
            return '保存'
        }
    }

    useEffect(() => {
        let temp: Options[] = [];
        getStorageList().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    res.data.data.map((e: any) => {
                        temp.push({
                            id: e.object_id,
                            name: e.storage_name
                        })
                    })
                    setWarehouse(temp)
                }
            } else {
                showError(res.message)
            }
        })
    }, [])

    return (
        <div >
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={okText()}
                                width={(modalTitle === ModalTitle.DELETE) ? '20%' : '688px'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default WarehouseLoc
