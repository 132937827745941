import React, {useContext, useEffect, useState} from 'react';
import {Menu} from 'antd';
import {useNavigate, useLocation} from "react-router-dom";
import {usePrevious} from 'ahooks';
import {UserContext} from "../../contexts/userContext";

interface SiderBarProps {
    collapsed: boolean,
}

const SiderBar = (props: SiderBarProps) => {
    const {useInfo} = useContext(UserContext);

    const {collapsed} = props;
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const pPathName = usePrevious(location.pathname);

    const showMenuItem = (item: any) => {
        return <Menu.Item key={item.key} className={'mes-subMenu-text'}>
            <span className={'mes-subMenu-text-icon'}>{item.title}</span>
        </Menu.Item>
    }

    useEffect(() => {
        if (pPathName !== location.pathname) {
            setOpenKeys([location.pathname.split('/')[1]]);
            setSelectedKeys(location.pathname);
        }
        if (collapsed) {
            //解决收起时样式抖动问题
            setOpenKeys([]);
        }
    }, [location.pathname, collapsed])

    return (
        <Menu mode={'inline'}
              onClick={e => {
                  setSelectedKeys(e.key)
                  navigate(e.key)
              }}
              selectedKeys={[selectedKeys]}
              openKeys={openKeys}
              onOpenChange={setOpenKeys}
        >
            {
                useInfo.menus?.map(e => {
                    if (e.children) {
                        return (
                            <Menu.SubMenu
                                key={e.key}
                                title={<span className={'mes-menu-text'}>{e.title}</span>}
                                icon={
                                    <div style={{textAlign: 'center'}}>
                                        <img
                                            src={collapsed && location.pathname.split('/')[1] == e.key ? require(`../../assets/blue-${e.key}-icon.png`) : require(`../../assets/${e.key}-icon.png`)}
                                            alt={''} className={'mes-menu-icon'}/>
                                    </div>}>
                                {
                                    e.children.map((c: any) => showMenuItem(c))
                                }
                            </Menu.SubMenu>
                        )
                    }
                    return showMenuItem(e)
                })
            }
        </Menu>
    )
}

export default React.memo(SiderBar);
