import React from 'react'
import {Button, Modal} from "antd";
import './index.css'
import Table, {ColumnsType} from "antd/lib/table";
import moment from "moment";
import Input from "antd/lib/input/Input";
import {toastMessage} from "../../../utils";
import {getSellListRequest} from "../../../service";

/**
 * 查询销售明细对话框
 */
interface Type {
    selectItem: API.sellListItem | undefined,
    customQuery: string,
    sellQuery: string,
    productQuery: string,
    sellDetailList: Array<API.sellListItem>,
    selectedRowKeys: Array<number>
}

export default class SellDetailModal extends React.Component<{
    closeClick?: () => void,
    saveClick?: (selectItem: API.sellListItem | undefined) => void,
}> {

    private sellDetailColumns: ColumnsType<API.sellListItem> = [
        {title: '客户', dataIndex: 'trader_name', align: 'center', ellipsis: true},
        {title: '销售单号', dataIndex: 'order_number', align: 'center', ellipsis: true},
        {title: '产品', dataIndex: 'product_name', align: 'center', ellipsis: true},
        {
            title: '数量', dataIndex: 'num', align: 'center', ellipsis: true,
            render: (num: any) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
        {
            title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true,
            render: (date: any) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
    ]

    state: Type = {
        selectItem: undefined,
        customQuery: '',
        sellQuery: '',
        productQuery: '',
        sellDetailList: [],
        selectedRowKeys: [],
    }

    componentDidMount() {
        this.queryData()
    }

    queryData() {
        let param: any = {}
        if (this.state.customQuery !== '') {
            param = {...param, name: this.state.customQuery}
        }
        if (this.state.sellQuery !== '') {
            param = {...param, order_number: this.state.sellQuery}
        }
        if (this.state.productQuery !== '') {
            param = {...param, product: this.state.productQuery}
        }
        getSellListRequest(param).then((res) => {
            if (res.code === 200) {
                this.setState({
                    sellDetailList: res.data.data
                })
            }
        })
    }

    render() {
        const {customQuery, sellQuery, productQuery, selectedRowKeys} = this.state
        const {closeClick, saveClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'查询销售明细'}
            footer={null}
            width={855}
            centered
            open
        >
            <div style={{flex: 1, marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                    <div style={{color: '#333333', fontSize: 14}}>
                        客户
                    </div>
                    <Input
                        style={{marginLeft: 10, width: 130, height: 38}}
                        value={customQuery}
                        placeholder={'请输入客户名称'}
                        onChange={(e) => {
                            this.setState({
                                customQuery: e.target.value
                            })
                        }}
                    />
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 50, alignItems: 'center'}}>
                    <div style={{color: '#333333', fontSize: 14}}>
                        销售单号
                    </div>
                    <Input
                        style={{marginLeft: 10, width: 130, height: 38}}
                        value={sellQuery}
                        placeholder={'请输入销售单号'}
                        onChange={(e) => {
                            this.setState({
                                sellQuery: e.target.value
                            })
                        }}
                    />
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 50, alignItems: 'center'}}>
                    <div style={{color: '#333333', fontSize: 14}}>
                        产品
                    </div>
                    <Input
                        style={{marginLeft: 10, width: 130, height: 38}}
                        value={productQuery}
                        placeholder={'请输入产品名称'}
                        onChange={(e) => {
                            this.setState({
                                productQuery: e.target.value
                            })
                        }}
                    />
                </div>

                <Button
                    type="primary"
                    className={'buttonQuery'}
                    style={{marginLeft: 50}}
                    onClick={() => {
                        this.setState({
                            selectedRowKeys: [],
                        }, () => {
                            this.queryData()
                        })
                    }}
                >
                    查询
                </Button>

                <Button
                    type="primary"
                    className={'buttonReset'}
                    style={{background: '#3B9FFD'}}
                    onClick={() => {
                        this.setState({
                            customQuery: '',
                            sellQuery: '',
                            productQuery: '',
                            selectedRowKeys: [],
                        }, () => {
                            this.queryData()
                        })
                    }}
                >
                    重置
                </Button>
            </div>

            <Table
                title={() => <div
                    style={{flex: 1, fontWeight: "bold", color: '#333333', marginLeft: 10}}>
                    订单明细
                </div>}
                rowSelection={{
                    columnWidth: 80,
                    type: "radio",
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys: any[]) => {
                        this.setState({
                            selectedRowKeys: newSelectedRowKeys,
                        })
                    },
                    onSelect: (record) => {
                        this.setState({
                            selectItem: record,
                        })
                    }
                }}
                rowKey={'object_id'}
                bordered
                className={'childTable'}
                style={{marginTop: 27}}
                dataSource={this.state.sellDetailList}
                columns={this.sellDetailColumns}
                scroll={{y: 200}}
                size={'small'}
                pagination={false}
            />

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 80,
            }}>
                <Button
                    style={{width: 100, height: 35, backgroundColor: '#B8B8B8', borderRadius: 5, color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        closeClick && closeClick()
                    }}
                >
                    关闭
                </Button>
                <Button
                    style={{marginLeft: 20, width: 100, height: 35, borderRadius: 5}}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                        if (selectedRowKeys.length <= 0) {
                            toastMessage('请选择销售明细')
                            return;
                        }
                        saveClick && saveClick(this.state.selectItem)
                    }}
                >
                    保存
                </Button>
            </div>
        </Modal>
    }
}
