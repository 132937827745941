import React, {useContext, useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Table} from "antd";
import SelectFormDispatch from "../components/selectFormDispatch";
import {useNavigate} from "react-router-dom";
import DispatchModal from "../components/dispatchModal";
import {toastErrorMessage, toastMessage, toastSuccessMessage} from "../../../utils";
import moment from "moment";
import {
    dispatchRequest,
    getClientDetailRequest,
    getDispatchListRequest,
    getProductDetailRequest
} from "../../../service";
import CheckModal from "../../Basic/Production/Modal/checkModal";
import {UserContext} from "../../../contexts/userContext";
import BusinessmanCheckModal from "../../Basic/Businessman/Modal/checkModal";

/**
 * 生产派工
 */
const Submission = (): React.ReactElement => {
    const userInfo = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.queryItem[]>([]);
    const [dataItems, setDataItems] = useState<API.queryItem[]>([]);
    const [isHasCustomer, setIsHasCustomer] = useState(false);
    const [isHasProduct, setIsHasProduct] = useState(false);
    const [isHasUser, setIsHasUser] = useState(false);
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowClient, setIsShowClient] = useState(false);
    const [clientInfo, setClientInfo] = useState<API.Businessman_ResponseInfo>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        remark: '',
        update_time: '',
        link_name: '',
        contact: '',
        name: '',
        type: 0,
    });
    const [isShowProduct, setIsShowProduct] = useState(false);
    const [productInfo, setProductInfo] = useState<API.productItem>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        product_name: '',
        product_unit_id: 0,
        remark: '',
        specification: '',
        unit_code: '',
        unit_name: '',
        update_time: ''
    });
    const [taskStatus, setTaskStatus] = useState<Array<string>>(['20']);
    const [clientName, setClientName] = useState('');
    const [productName, setProductName] = useState('');

    const columns: ColumnsType<API.queryItem> = [
        {title: '任务单号', dataIndex: ['task', 'task_number'], align: 'center', ellipsis: true},
        {
            title: '客户', dataIndex: ['task', 'trader_name'], align: 'center', ellipsis: true,
            render: (customer: string, record) => <div
                className={isHasCustomer ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasCustomer) {
                        getDetailClientInfo(record.task.trader_id)
                    }
                }}
            >
                {customer}
            </div>
        },
        {
            title: '产品', dataIndex: ['task', 'product_name'], align: 'center', ellipsis: true,
            render: (product: string, record) => <div
                className={isHasProduct ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasProduct) {
                        getDetailProductInfo(record.task.product_id)
                    }
                }}
            >
                {product}
            </div>
        },
        {
            title: '订单数量', dataIndex: ['task', 'order_quantity'], align: 'center', ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '单位', dataIndex: ['task', 'unit_name'], align: 'center', ellipsis: true},
        {
            title: '交期', dataIndex: ['task', 'finish_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {title: '产线', dataIndex: ['task', 'line_info'], align: 'center', ellipsis: true},
        {title: '工序', dataIndex: 'process_name', align: 'center', ellipsis: true},
        {
            title: '任务数量', dataIndex: ['task', 'task_quantity'], align: 'center', ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {
            title: '计划开始时间', dataIndex: ['task', 'start_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '计划完成时间', dataIndex: ['task', 'end_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '派工时间', dataIndex: 'issue_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '作业者', dataIndex: 'user', align: 'center', ellipsis: true},
        {title: '备注', dataIndex: 'process_explain', align: 'center', ellipsis: true},
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 20 ? '#EB4420' : status === 30 ? '#3E7BFA' : status === 40 ? '#06C270' : status === 50 ? '#0053FF' : '#666666'}}>
                {
                    status === 20 ? '未派工' : status === 30 ? '已派工' : status === 40 ? '处理中' : status === 50 ? '已完成' : ''
                }
            </div>
        },
        {
            key: 15, title: '操作', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (operate: number, record) => {
                return operate === 20 || operate === 30 ?
                    <div className={'hasAuthority'} onClick={() => {
                        setDataItems([record])
                        setShowModal(true)
                    }}>
                        派工
                    </div> : null;
            }
        },
    ]

    useEffect(() => {
        userInfo.useInfo.authRoutes?.map((item) => {
            if (item === '/basic/businessman') {
                setIsHasCustomer(true)
            } else if (item === '/basic/production') {
                setIsHasProduct(true)
            } else if (item === '/system/user') {
                setIsHasUser(true)
            }
        })
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        taskStatus: Array<string>,
        clientName: string,
        productName: string
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page: page, per_page: size}
        if (data) {
            if (data.taskStatus.length > 0) {
                param = {...param, status: JSON.stringify(data.taskStatus)}
            }
            if (data.clientName !== '') {
                param = {...param, name: data.clientName}
            }
            if (data.productName !== '') {
                param = {...param, product: data.productName}
            }
        } else {
            if (taskStatus.length > 0) {
                param = {...param, status: JSON.stringify(taskStatus)}
            }
            if (clientName !== '') {
                param = {...param, name: clientName}
            }
            if (productName !== '') {
                param = {...param, product: productName}
            }
        }
        getDispatchListRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getDetailClientInfo = (objectId: number) => {
        getClientDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowClient(true)
                setClientInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const getDetailProductInfo = (objectId: number) => {
        getProductDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowProduct(true)
                setProductInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const dispatchTask = (objectId: number | undefined) => {
        let param: any = {user_id: `${objectId}`}
        let dataIds: number[] = []
        dataItems.map((item) => {
            dataIds.push(item.object_id)
        })
        param = {...param, ids: JSON.stringify(dataIds)}
        dispatchRequest(param).then((res) => {
            if (res.code === 200) {
                setShowModal(false)
                setDataItems([])
                setSelectedRowKeys([])
                toastSuccessMessage('派工成功')
                onFinish(currentPage, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const rowSelection = {
        columnWidth: 100,
        selectedRowKeys,
        onChange: (newSelectedRowKeys: Array<any>, selectedRows: Array<API.queryItem>) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setDataItems(selectedRows)
        },
        getCheckboxProps: (record: API.queryItem) => ({
            disabled: !(record.status === 20 || record.status === 30)
        }),
    };

    const handleShowTotal = (total: number) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div>
            <SelectFormDispatch
                titles={['任务状态', '客户名称', '产品名称']}
                queryClick={(taskStatus: Array<string>, taskClientName: string, productionName: string) => {
                    setTaskStatus(taskStatus)
                    setClientName(taskClientName)
                    setProductName(productionName)
                    onFinish(1, pageSize, {
                        taskStatus: taskStatus,
                        clientName: taskClientName,
                        productName: productionName
                    })
                }}
                batchClick={() => {
                    if (selectedRowKeys.length > 0) {
                        setShowModal(true)
                    } else {
                        toastMessage('请选择要派工的数据')
                    }
                }}
            />
            <Table
                className={'commonTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                scroll={{x: 2500}}
                loading={isLoading}
                rowSelection={rowSelection}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />
            {
                showModal && <DispatchModal
                    isHas={isHasUser}
                    navigate={navigate}
                    dataItems={dataItems}
                    closeClick={() => {
                        setShowModal(false)
                        setDataItems([])
                        setSelectedRowKeys([])
                    }}
                    saveClick={(producer: undefined | number) => {
                        dispatchTask(producer)
                    }}
                />
            }
            {
                isShowClient && <BusinessmanCheckModal
                    BusinessmanItem={clientInfo}
                    closeClick={() => {
                        setIsShowClient(false)
                    }}
                />
            }

            {
                isShowProduct && <CheckModal
                    productItem={productInfo}
                    closeClick={() => {
                        setIsShowProduct(false)
                    }}
                />
            }
        </div>
    )
}
export default Submission
