import React, {useCallback, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, Options, QueryListItem} from "../../components/GeneralManagement";
import {Button, Form, message, Space} from "antd";
import {
    filterData,
    getFormData,
    showError,
    statusList,
    typeList,
} from "../../utils";
import {
    createInStore,
    deleteInStore,
    getAllProduct,
    getInStoreById,
    getInStoreList,
    getStorageList,
    updateInStore,
} from "../../service";
import DeleteItem from "../../components/DeleteItem";
import ModalContainer from "../../components/Modal";
import './index.less';
import ViewItem from "./components/ViewItem";
import AddOrEditItem, {ProductNameOptions} from "./components/AddOrEditItem";
import moment from "moment";
import {ColumnsType} from "antd/es/table";

export enum ModalTitle {
    ADD = '新增入库',
    EDIT = '编辑入库信息',
    DETAIL = '入库明细',
    DELETE = '系统确认',
}

const WarehouseIn = () => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [warehouse, setWarehouse] = useState<Options[]>([]);
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();

    const formList: QueryListItem[] = [
        {
            key: 'instore_type',
            name: '入库类型',
            placeholder: '请选择入库类型',
            options: typeList
        },
        {
            key: 'date',
            name: '入库时间',
            placeholder: '请选择入库时间',
            date: true
        },
        {
            key: 'storage_id',
            name: '仓库名称',
            placeholder: '请选择仓库',
            options: warehouse
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            dataIndex: '序号',
            key: '序号',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '入库单号',
            dataIndex: 'code',
            key: 'number',
            align: 'center',
            ellipsis: true,
            width: 150,
            render: (text: string, record?: any) => <span className={'codeText'}
                                                          onClick={() => openModal(ModalTitle.DETAIL, record)}>{text}</span>
        },
        {
            title: '入库类型',
            dataIndex: 'instore_type',
            key: 'type',
            align: 'center',
            ellipsis: true,
            render: (_: number) => {
                return typeList.map(e => {
                    if (e.id === _) {
                        return <span>{e.name}</span>;
                    }
                });
            }
        },
        {
            title: '仓库名称',
            dataIndex: 'storage_name',
            key: 'name',
            align: 'center',
            ellipsis: true
        },
        {
            title: '入库申请人',
            dataIndex: 'instore_user',
            key: 'name',
            align: 'center',
            ellipsis: true
        },
        {
            title: '所在部门',
            dataIndex: 'instore_user_organization',
            key: 'department',
            align: 'center',
            width: 120,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            ellipsis: true,
            width: 120,
            render: (_: number) => <span
                style={_ === 10 ? {color: '#EB4420'} : {color: '#06C270'}}>{statusList[_]}</span>
        },
        {
            title: '入库时间',
            dataIndex: 'instore_time',
            key: 'inTime',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (_: string) => <div className={'remark-left'}>{_}</div>
        },
        {
            title: '最后修改人',
            dataIndex: 'modify_user',
            key: 'lastModify',
            align: 'center',
            width: 120,
            ellipsis: true
        },
        {
            title: '修改时间',
            dataIndex: 'update_time',
            key: 'modifyTime',
            align: 'center',
            ellipsis: true,
            width: 200,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            width: 150,
            render: (_: any, record: any) => {
                if (record.status === 10) {
                    return (
                        <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, record)
                        }}>编辑</span>
                            <span className={'mes-options'} onClick={() => {
                                openModal(ModalTitle.DELETE, record)
                            }}>删除</span>
                        </Space>
                    )
                }
            }
        },
    ]

    const ExtraComponent = React.memo((): React.ReactElement => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null);
            }}>入库</Button>
        </div>
    ))

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            getAllProduct().then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        const temp: ProductNameOptions[] = []
                        res.data.data.map((e: any) => {
                            temp.push({
                                object_id: e.object_id,
                                product_name: e.product_name,
                                product_code: e.code,
                                model: e.specification,
                                unit: e.unit_name,
                                kc_count: e.unit_name,
                            })
                            setInnerComponent(<AddOrEditItem name={'入库'} form={form} tableForm={tableForm} title={title}
                                                             productList={temp}/>)
                        })
                    }
                } else {
                    showError(res.message)
                }
            })
        } else if (title === ModalTitle.EDIT) {
            getInStoreById({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        form.setFieldsValue({
                            code: res.data.code,
                            instore_type: res.data.instore_type,
                            storage_id: res.data.storage_id,
                            bind_no: res.data.bind_no,
                            trader_id: res.data.trader_id,
                            organization_id: res.data.organization_id,
                            remark: res.data.remark,
                        })
                        let initialDataSource: any = []
                        if (res.data.detail) {
                            res.data.detail.map((e: any, i: number) => {
                                initialDataSource.push({
                                    ...e,
                                    index: i,
                                    product_name: e.product_id,
                                    code: e.code,
                                    specification: e.specification,
                                    unit_name: e.unit_name,
                                    count: e.count,
                                    remark: e.remark,
                                })
                            })
                        }
                        tableForm.setFieldsValue({...initialDataSource})
                        getAllProduct().then(res => {
                            if (res.code === 200) {
                                if (res.data.data) {
                                    const temp: ProductNameOptions[] = []
                                    res.data.data.map((e: any) => {
                                        temp.push({
                                            object_id: e.object_id,
                                            product_name: e.product_name,
                                            product_code: e.code,
                                            model: e.specification,
                                            unit: e.unit_name,
                                            kc_count: e.unit_name,
                                        })
                                        setInnerComponent(<AddOrEditItem name={'入库'} form={form} tableForm={tableForm}
                                                                         title={title}
                                                                         initialDataSource={initialDataSource}
                                                                         productList={temp}/>)
                                    })
                                }
                            } else {
                                showError(res.message)
                            }
                        })

                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DETAIL) {
            getInStoreById({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        const info = {
                            code: res.data.code,
                            instore_type: res.data.instore_type,
                            storage_name: res.data.storage_name,
                            instore_time: res.data.instore_time,
                            instore_user: res.data.instore_user,
                            instore_user_organization: res.data.instore_user_organization,
                            bind_no: res.data.bind_no,
                            trader: res.data.trader,
                            organization_name: res.data.organization_name,
                            remark: res.data.remark,
                        }
                        setInnerComponent(<ViewItem name={'入库'} dataSource={res.data.detail} info={info}/>)
                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle, type?: number) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                tableForm.validateFields().then(values2 => {
                    let tempTable: any[] = []
                    Object.values(values2).map((e: any) => {
                        tempTable.push({
                            object_id: e.product_name,
                            count: e.count,
                            remark: e.remark ? e.remark : '',
                        })
                    })
                    tempTable.map((e: any) => {
                        filterData(e)
                    })
                    if (tempTable.length === 0) {
                        message.warning('必须添加一条入库明细！')
                    } else {
                        //处理产品重复的情况
                        const productMap = tempTable.map((t: any) => t.object_id)
                        const productSet = Array.from(new Set(productMap))
                        if (productMap.length !== productSet.length) {
                            message.warning('产品重复，请重新选择!')
                        } else {
                            let realParams = {
                                ...values,
                                status: type,
                                product: JSON.stringify(tempTable)
                            }
                            const formData = getFormData(filterData(realParams))
                            createInStore(formData).then(res => {
                                if (res.code === 200) {
                                    afterOnOK('新增成功！');
                                } else {
                                    showError(res.message)
                                }
                            })
                        }
                    }
                })
            }).catch(error => {
                console.log('error', error)
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                tableForm.validateFields().then(values2 => {
                    let tempTable: any[] = []
                    Object.values(values2).map((e: any) => {
                        tempTable.push({
                            object_id: e.product_name,
                            count: e.count,
                            remark: e.remark ? e.remark : '',
                        })
                    })
                    tempTable.map((e: any) => {
                        filterData(e)
                    })
                    if (tempTable.length === 0) {
                        message.warning('必须添加一条入库明细！')
                    } else {
                        //处理产品重复的情况
                        const productMap = tempTable.map((t: any) => t.object_id)
                        const productSet = Array.from(new Set(productMap))
                        if (productMap.length !== productSet.length) {
                            message.warning('产品重复，请重新选择!')
                        } else {
                            let realParams = {
                                ...values,
                                object_id: selectedItem.object_id,
                                status: type,
                                product: JSON.stringify(tempTable)
                            }
                            const formData = getFormData(filterData(realParams))
                            updateInStore(formData).then(res => {
                                if (res.code === 200) {
                                    afterOnOK('编辑成功！');
                                } else {
                                    showError(res.message)
                                }
                            })
                        }
                    }
                })
            }).catch(error => {
                console.log('error', error)
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteInStore(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message);
                }
            })
        }
    }

    const onCancel = () => {
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            form.resetFields();
            tableForm.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getInStoreList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const afterOnOK = (text: string) => {
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            form.resetFields();
            tableForm.resetFields();
        }
        message.success(text);
        setSelectedItem(null);
        refreshData();
        setOpen(false);
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const setWidth = (): string => {
        if (modalTitle === ModalTitle.DELETE) {
            return '20%'
        } else {
            return '1071px'
        }
    }

    const getFooter = (): React.ReactNode => {
        // 保存10提交20
        if (modalTitle === ModalTitle.ADD || modalTitle === ModalTitle.EDIT) {
            return (<div>
                <Button onClick={() => onCancel()}>关闭</Button>
                <Button type={'primary'} onClick={() => onOK(modalTitle, 10)}>保存</Button>
                <Button type={'primary'} onClick={() => onOK(modalTitle, 20)}>提交</Button>
            </div>)
        } else if (modalTitle === ModalTitle.DETAIL) {
            return (<div>
                <Button onClick={() => onCancel()}>关闭</Button>
            </div>)
        }
    }

    useEffect(() => {
        let temp: Options[] = [];
        getStorageList().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    res.data.data.map((e: any) => {
                        temp.push({
                            id: e.object_id,
                            name: e.storage_name
                        })
                    })
                    setWarehouse(temp)
                }
            } else {
                showError(res.message)
            }
        })

    }, [])

    return (
        <div >
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel()}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={setWidth()} footer={getFooter()}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default WarehouseIn
