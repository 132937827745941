import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Button, Table} from "antd";
import moment from "moment";
import SelectFormExceptionPlato from "../components/selectFormExceptionPlato";
import {getStatisticsExceptionPlatoRequest} from "../../../service";
import {toastErrorMessage} from "../../../utils";
import '../../Statistics/components/index.css';
import ReactECharts from "echarts-for-react";
import * as echarts from 'echarts';

/**
 * 异常柏拉图
 */
const ExportJsonExcel = require('js-export-excel')

const ExceptionPlato = (): React.ReactElement => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.statisticsExceptionItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startTime, setStartTime] = useState(moment(new Date()).startOf('month').format('YYYY/MM/DD'));
    const [endTime, setEndTime] = useState(moment(new Date()).endOf('month').format('YYYY/MM/DD'));

    let exceptionPlatoReact: any = React.createRef() || undefined
    let [exceptionPlatoOption, setExceptionPlatoOption] = useState<any>({
        title: {
            text: '异常柏拉图',
        },
        grid: {
            left: 50,
            right: 60,
            bottom: 40,
        },
        color: ["#3E7BFA", "#3E7BFA"],
        xAxis: {
            type: 'category',
            axisLabel: {
                color: '#666666',
            },
            axisTick: {
                alignWithLabel: true,
                show: false
            },
            nameTextStyle: {
                color: '#C3F2FF'
            },
            data: [],
        },
        yAxis: [
            {
                min: 0,
                max: function (value: number) {
                    return value
                },
                interval: function (value: number) {
                    return value
                },
                splitNumber: 5,
                type: 'value',
                axisLabel: {
                    color: '#666666',
                },
                axisTick: {
                    inside: true
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        dashOffset: 5,
                    }
                }
            },
            {
                min: 0,
                max: 100,
                splitNumber: 5,
                type: 'value',
                axisLabel: {
                    color: '#666666',
                    formatter: "{value}%",
                },
                axisTick: {
                    inside: true
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        dashOffset: 5,
                    }
                }
            }
        ],
        tooltip: {
            trigger: 'axis',
            background: '#3B4373',
            formatter: function (params: any) {
                let showContent = ''
                for (let i = 0; i < params.length; i++) {
                    if (i === 0) {
                        showContent += params[i].axisValueLabel + '<br>'
                        showContent += params[i].seriesName + '：  ' + params[i].data + '个' + '<br>'
                    } else if (i === 1) {
                        showContent += params[i].seriesName + '：  ' + params[i].data + '%' + '<br>'
                    }
                }
                return showContent
            },
            backgroundColor: 'rgba(8, 16, 64, 0.8)',
            borderWidth: 1,
            borderColor: '#3B4373',
            textStyle: {
                color: '#C3F2FF',
                fontSize: 12,
            },
            selectedMode: false,
        },
        legend: {
            top: 'top',
            left: 'middle',
            icon: 'rect',
            itemHeight: 25,
            itemWidth: 40,
            itemGap: 30,
            right: 28,
            textStyle: {
                color: '#666666',
            },
            data: [
                {
                    name: 'sum',
                    icon: 'image://' + require('../image/icon_sum.png')
                },
                {
                    name: 'rate',
                    icon: 'image://' + require('../image/icon_rate.png')
                }
            ],
            selectedMode: false,
        },
        series: [
            {
                name: 'sum',
                type: 'bar', //线类型
                barWidth: 40,
                data: [], //坐标点数据
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {offset: 0, color: '#3E7BFA'},
                        {offset: 1, color: 'rgba(62,123,250,0.21)'}
                    ]),
                    barBorderRadius: [5, 5, 0, 0],
                },
                yAxisIndex: 0,
            },
            {
                name: 'rate',
                type: 'line', //线类型
                symbol: 'circle', //连线间点的形状
                symbolSize: 6, //连线间点的大小
                smooth: true, //是否光滑
                data: [], //坐标点数据
                yAxisIndex: 1, //使用的y轴的数据，在单个图表实例中存在多个y轴的时候用
            },
        ]
    });

    const columns: ColumnsType<API.statisticsExceptionItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true,
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {title: '异常类别', dataIndex: 'exception_type', align: 'center', ellipsis: true},
        {title: '异常代码', dataIndex: 'exception_code', align: 'center', ellipsis: true},
        {title: '异常描述', dataIndex: 'exception_describe', align: 'center', ellipsis: true},
        {title: '现象描述', dataIndex: 'phenomenal_description', align: 'center', ellipsis: true},
        {
            title: '异常状态', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 20 ? '#06C270' : status === 30 ? '#FF8800' : status === 40 ? '#3E7BFA' : status === 50 ? '#666666' : ''}}>{
                status === 20 ? '已提报' : status === 30 ? '处理中' : status === 40 ? '已完成' : status === 50 ? '已关闭' : ''
            }</div>
        },
        {title: '提报人', dataIndex: 'reporting_user', align: 'center', ellipsis: true},
        {
            title: '提报时间', dataIndex: 'reporting_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {title: '处理人', dataIndex: 'handle_user', align: 'center', ellipsis: true},
        {
            title: '处理开始时间', dataIndex: 'handle_start_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '处理完成时间', dataIndex: 'handle_end_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '关闭时间', dataIndex: 'close_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
    ]

    useEffect(() => {
        window.addEventListener('resize', function () {
            exceptionPlatoReact && exceptionPlatoReact.getEchartsInstance() && exceptionPlatoReact.getEchartsInstance().resize()
        })
        onFinish(1, 10);

        return () => {
            exceptionPlatoReact = undefined
        }
    }, [])

    const onFinish = (page: number, size: number, data?: {
        startTime: string,
        endTime: string,
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.startTime !== '') {
                param = {...param, start_date: moment(data.startTime).format('YYYY-MM-DD')}
            }
            if (data.endTime !== '') {
                param = {...param, end_date: moment(data.endTime).format('YYYY-MM-DD')}
            }
        } else {
            if (startTime !== '') {
                param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
            }
            if (endTime !== '') {
                param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
            }
        }
        getStatisticsExceptionPlatoRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.table_list.total)
                setDataSource(res.data.table_list.data)

                let xAxisData: any[] = []
                let yData1: any[] = []
                let yData2: any[] = []
                let isBigZero = false
                res.data.plato_data.forEach((item) => {
                    xAxisData.push(item.exception_code)
                    yData1.push(item.total)
                    if (item.total > 0) {
                        isBigZero = true
                    }
                    if (item.percent !== null) {
                        yData2.push(item.percent)
                    }
                })
                let platoOption = exceptionPlatoOption
                platoOption.xAxis.data = xAxisData
                platoOption.series[0].data = yData1
                platoOption.series[1].data = yData2
                if (isBigZero) {
                    platoOption.yAxis[0].max = Math.ceil(Math.max.apply(null, yData1))
                    platoOption.yAxis[0].interval = Math.ceil(Math.max.apply(null, yData1)) / 5
                } else {
                    platoOption.yAxis[0].max = 1
                    platoOption.yAxis[0].interval = 0.2
                }
                setExceptionPlatoOption(platoOption)
                exceptionPlatoReact && exceptionPlatoReact.getEchartsInstance() && exceptionPlatoReact.getEchartsInstance().setOption(platoOption)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
            const myEvent = new Event("resize")
            window.dispatchEvent(myEvent)
        });
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div>
            <SelectFormExceptionPlato
                queryClick={(startTime: string, endTime: string) => {
                    setStartTime(startTime)
                    setEndTime(endTime)
                    onFinish(1, pageSize, {startTime: startTime, endTime: endTime})
                }}
            />
            <div style={{height: 366, marginTop: 20, backgroundColor: '#ffffff', borderRadius: 5}}>
                <ReactECharts
                    ref={(e) => {
                        if (!e) {
                            return
                        } else {
                            exceptionPlatoReact = e
                        }
                    }}
                    style={{paddingTop: 16, paddingLeft: 12, height: 360}}
                    option={exceptionPlatoOption}
                />
            </div>
            <Table
                title={() => <div
                    style={{display: "flex", flexDirection: "row", flex: 1, alignItems: "center", height: 50}}>
                    <div style={{flex: 1, fontWeight: "bold", color: '#333333', marginLeft: 10, fontSize: 16}}>
                        异常记录
                    </div>
                    <Button
                        className={'buttonOutput'}
                        type={"primary"}
                        onClick={() => {
                            if (total > 0) {
                                let param: any = {page_num: 1, page_size: total}
                                if (startTime !== '') {
                                    param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
                                }
                                if (endTime !== '') {
                                    param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
                                }
                                getStatisticsExceptionPlatoRequest(param).then((res) => {
                                    if (res.code === 200) {
                                        let option: { fileName?: string, datas?: any[] } = {};
                                        let dataTable: any[] = [];
                                        res.data.table_list.data.length > 0 && res.data.table_list.data.forEach((item, index) => {
                                            let obj = {
                                                no: index + 1,
                                                exception_type: item.exception_type,
                                                exception_code: item.exception_code,
                                                exception_describe: item.exception_describe,
                                                phenomenal_description: item.phenomenal_description,
                                                status: item.status === 20 ? '已提报' : item.status === 30 ? '处理中' : item.status === 40 ? '已完成' : item.status === 50 ? '已关闭' : '',
                                                reporting_user: item.reporting_user,
                                                reporting_time: item.reporting_time === null ? '' : moment(item.reporting_time).format('YYYY/MM/DD HH:mm'),
                                                handle_user: item.handle_user,
                                                handle_start_time: item.handle_start_time === null ? '' : moment(item.handle_start_time).format('YYYY/MM/DD HH:mm'),
                                                handle_end_time: item.handle_end_time === null ? '' : moment(item.handle_end_time).format('YYYY/MM/DD HH:mm'),
                                                close_time: item.close_time === null ? '' : moment(item.close_time).format('YYYY/MM/DD HH:mm'),
                                            }
                                            dataTable.push(obj);
                                        })
                                        option.fileName = '异常记录' + moment(new Date()).format('YYYYMMDD')
                                        option.datas = [
                                            {
                                                sheetData: dataTable,
                                                sheetName: 'sheet',
                                                sheetFilter: ['no', 'exception_type', 'exception_code', 'exception_describe', 'phenomenal_description', 'status', 'reporting_user', 'reporting_time', 'handle_user', 'handle_start_time', 'handle_end_time', 'close_time'],
                                                sheetHeader: ['No', '异常类别', '异常代码', '异常描述', '现象描述', '异常状态', '提报人', '提报时间', '处理人', '处理开始时间', '处理完成时间', '关闭时间'],
                                            }
                                        ];
                                        let toExcel = new ExportJsonExcel(option);
                                        toExcel.saveExcel();
                                    } else {
                                        toastErrorMessage('导出失败')
                                    }
                                }).catch(() => {
                                    toastErrorMessage('导出失败')
                                });
                            }
                        }}
                    >
                        导出
                    </Button>
                </div>}
                className={'badTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />
        </div>
    )
}
export default ExceptionPlato
