import React from 'react'
import {Button} from "antd";
import Input from "antd/lib/input/Input";
import './index.css'

/**
 * 订单管理--订单管理--查询重置
 */
export default class SelectFormOrderManage extends React.Component<{
    queryClick?: (customName: string, sellNum: string) => void,
    newAdd?: () => void,
}> {

    state = {
        customName: '',
        sellNum: '',
    }

    render() {
        const {customName, sellNum} = this.state
        return <div className={'selectFormTitle'}>
            <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div style={{paddingLeft: 13, color: '#333333', fontSize: 14}}>
                    客户
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={customName}
                    placeholder={'请输入客户名称'}
                    onChange={(e) => {
                        this.setState({
                            customName: e.target.value
                        })
                    }}
                />
            </div>

            <div style={{display: "flex", flexDirection: "row", marginLeft: 27, alignItems: 'center'}}>
                <div style={{color: '#333333', fontSize: 14}}>
                    单号
                </div>
                <Input
                    style={{marginLeft: 20, width: 244, height: 30}}
                    value={sellNum}
                    placeholder={'请输入销售单号'}
                    onChange={(e) => {
                        this.setState({
                            sellNum: e.target.value
                        })
                    }}
                />
            </div>

            <Button
                className={'orderButtonQuery'}
                type={"primary"}
                onClick={() => {
                    this.props.queryClick && this.props.queryClick(customName, sellNum)
                }}
            >
                查询
            </Button>

            <Button
                className={'orderButtonReset'}
                type={"primary"}
                onClick={() => {
                    this.setState({
                        customName: '',
                        sellNum: '',
                    }, () => {
                        this.props.queryClick && this.props.queryClick('', '')
                    })
                }}
            >
                重置
            </Button>

            <div style={{display: "flex", flex: 1}}/>
            <Button
                className={'orderButtonAdd'}
                type={"primary"}
                onClick={() => {
                    this.props.newAdd && this.props.newAdd()
                }}
            >
                新增
            </Button>
        </div>
    }
}
