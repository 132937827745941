import React, { useEffect, useState } from "react";
import ProductionLineMenu from "./productionLineMenu";
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../globalLess/global.css'
import AddModal from "./Modal/addModal";
import EditModal from "./Modal/editModal";
import {toastErrorMessage, toastSuccessMessage, toastMessage} from "../../../utils";
import { getAllProductionLineInfo, ProductionLineUpdate, ProductionLineCreate, ProductionLineDelete } from "../../../service/index";
import DeleteModal from "../../Production/components/deleteModal";
import moment from "moment";
const ExportJsonExcel = require('js-export-excel')
const ProductionLine = (): React.ReactElement => {
    const columns: ColumnsType<API.ProductionLineItem> = [
        {
            title: '产线代码',
            dataIndex: 'code',
            width: 400,
            ellipsis: true,
        },
        {
            title: '产线名称',
            dataIndex: 'line_name',
            width: 400,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 600,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'object_id',
            render: (object_id: string, data: API.EditProductionLineItem) =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            console.log("operation");
                            setProductionLine_Item(data)
                            setShowEditModal(true)
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            data.object_id && setDeleteObjectString([data.object_id])
                            setIsShowDelete(true)
                        }}
                    >
                        删除
                    </div>
                </div>
        }
    ];
    let [code, setCode] = useState('');
    let [line_name, setLine_name] = useState('');
    let [ProductionLine_Item, setProductionLine_Item] = useState<API.EditProductionLineItem>({})
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [dataSource, setDataSource] = useState<API.ProductionLineItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowMoreDelete, setIsShowMoreDelete] = useState(false);
    const [deleteObjectString, setDeleteObjectString] = useState<number[]>([]);

    const queryProductionLine = () => {
        onFinish(1, pageSize, {
            code: code,
            line_name: line_name
        })
    }
 
    const BatchDelete = () => {
        if (selectedRowKeys.length > 0) {
            const num = selectedRowKeys.length
            let objArray = []
            for (let index = num - 1; index >= 0; index--) {
                let indexItem: any = selectedRowKeys[index]
                indexItem = indexItem- (currentPage-1)* pageSize
                const item = dataSource[indexItem]
                objArray.push(item.object_id)
            }
            ProductionLineDeletes(objArray)
            setSelectedRowKeys([])
        } else {
            toastMessage('请选择要删除的产品数据')
        }
    }
    const ProductionLineDeletes = (object_ids: number[]) => {
        let objString: any;
        for (let index = 0; index < object_ids.length; index++) {
            if (index === 0) {
                objString = object_ids[index]
            } else {
                objString += ',' + object_ids[index]
            }
        }
        ProductionLineDelete({ object_ids: objString }).then((res) => {
            if (res.code === 200) {
                setIsShowDelete(false)
                setIsShowMoreDelete(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const AddProductionLine = (e: API.EditProductionLineItem) => {
        ProductionLineCreate(e).then((res) => {
            if (res.code === 200) {
                setShowAddModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('保存成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const EditProductionLine = (e: API.EditProductionLineItem) => {
        ProductionLineUpdate(e).then((res) => {
            if (res.code === 200) {
                setShowEditModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('编辑成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }


    const exportFile = ( )=>{
        let dict: any = { page_num: 1, page_size: total }
        if (code) {
            dict.code = code
        }
        if (line_name) {
            dict.line_name = line_name
        }

        setIsLoading(true)
        getAllProductionLineInfo(dict).then((res: any) => {
            if (res.code === 200) {
                let dataSourceCopy: any = res.data.data
                if (dataSourceCopy.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    dataSourceCopy.forEach((item: any) => {
                        dataTable.push({
                            code:item.code,
                            line_name: item.line_name,
                            remark: item.remark,
                        })
                    })
                    option.fileName = '产线信息' + moment(new Date()).format('YYYYMMDD')
                    option.datas = [
                        {
                            sheetData: dataTable,
                            sheetName: 'sheet',
                            sheetFilter: ['code','line_name', 'remark'],
                            sheetHeader: ['产线代码','产线名称',  '备注'],
                        }
                    ];
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            toastErrorMessage(err.message)
            setIsLoading(false)
        })
    };

    const onFinish = (page: number, size: number, data?: {
        code?: string,
        line_name?: string
    }) => {
        let dict: any = { page_num: page, page_size: size }
        if (data && data.code) {
            dict.code = data.code
        }
        if (data && data.line_name) {
            dict.line_name = data.line_name
        }
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        getAllProductionLineInfo(dict).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let dataCopy = []
                for (let index = 0; index < res.data.data.length; index++) {
                    let item = res.data.data[index]
                    item.key = index + (page - 1) * size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
        })
    }
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(() => {
        onFinish(1, 10);

    }, [])
    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999' }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <ProductionLineMenu productionNum={code} productionName={line_name}
                    codeOnChange={(num: string) => {
                        setCode(num)
                    }}
                    nameOnChange={(name: string) => {
                        setLine_name(name)
                    }}
                    queryClick={() => {
                        queryProductionLine()
                    }}
                    resetClick={() => {
                        setCode('')
                        setLine_name('')
                        onFinish(1, 10);
                    }} 
                    addClick={() => {
                        setShowAddModal(true)
                    }}
                    deleteClick={() => {
                        if (selectedRowKeys.length > 0) {
                            setIsShowMoreDelete(true)
                        } else {
                            toastMessage('请选择要删除的产线数据')
                        }
                    }}
                    exportClick={()=>{
                        exportFile()
                    }}
                />
            </div>
            <div >
                <Table
                    className={'commonTable'}
                    scroll={{x: 1600}}
                    rowSelection={rowSelection}
                    columns={columns}
                    loading={isLoading}
                    dataSource={dataSource}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                />
            </div>
            {
                showAddModal && <AddModal
                    closeClick={() => {
                        setShowAddModal(false)
                    }}
                    saveClick={(e) => {
                        AddProductionLine(e)
                    }}
                />
            }
            {
                showEditModal && <EditModal
                    ProductionLineItem={ProductionLine_Item}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(e) => {
                        EditProductionLine(e)
                    }}
                />
            }
                {
                isShowDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowDelete(false)
                    }}
                    confirmClick={() => {
                        ProductionLineDeletes(deleteObjectString)
                    }}
                />
            }
            {
                isShowMoreDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowMoreDelete(false)
                        setSelectedRowKeys([])
                    }}
                    confirmClick={() => {
                        BatchDelete()
                    }}
                />
            }
        </div>
    )
}
export default ProductionLine
