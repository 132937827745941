import {useEffect,useState} from "react";
import {Form,Input,Select,Table,message} from "antd";
import { FormInstance } from "@ant-design/pro-components";
import {getGroupList,getGroupAllUser} from "../../../../service";
import { nanoid } from "nanoid";
interface IProps {
  selectItem: Record<string,any>
  form: FormInstance
}
interface IOptions {
  label:string
  value:number
}

const DispatchTask = (props:IProps) => {
    const [pointMans,setPointMans] = useState<{headMans:IOptions[],otherMans:IOptions[]}>({headMans:[],otherMans:[]});
    const [groups,setGroups] = useState<IOptions[]>([]);
    const {selectItem,form} = props;
    const {spot_user_id,team_groups_id,device_name,device_code,device_specification,device_type_name,device_position,device_organization_name,code,spot_plan_name,
      should_execution_time,spot_items} = selectItem
    const columns: any[] = [
        {
          title: 'No',
          width: 60,
          dataIndex: 'index',
          key: 'index',
          render: (_:any,__:any,index:number) => <span>{index+1}</span>,
        },
        {
          title: '点检项目',
          dataIndex: 'spot_name',
          ellipsis:true
        },
        {
          title: "点检部位",
          dataIndex: "spot_area",
          align:"center",
          ellipsis:true
        },
        {
          title: "点检方法",
          dataIndex: "spot_method",
          align:"center",
          ellipsis:true,
          render:(i:number) => <span>{i ? i === 10 ? '目视' : i === 20 ? '手感' : i === 30 ? '看听' : i === 40 ? '看/听' : '' : '' }</span>
        },
        {
          title: '点检内容',
          dataIndex: 'spot_content',
          ellipsis:true
        },
        {
          title: '参数名称',
          dataIndex: 'parameter_name',
          ellipsis:true
        },
        {
          title: '点检结果类型',
          dataIndex: 'spot_type',
          width:130,
          ellipsis:true,
          render: (i:number) => <span>{i === 10 ? "数值" : i === 20 ? '单选' : '多选'}</span>
        },
        {
          title: '上限',
          width:80,
          ellipsis:true,
          dataIndex: 'upper_limit',
        },
        {
          title: '下限',
          width:80,
          ellipsis:true,
          dataIndex: 'lower_limit',
        },
        {
          title: '选项',
          dataIndex: 'results_options',
          width: 250,
          ellipsis:true,
          render:(i:any) => <span>{i && i.join('；')}</span>
        }
      ];
    useEffect(()=>{
      form.resetFields()
      form.setFieldsValue({device_name,device_code,device_specification,device_type_name,device_position,device_organization_name,code,spot_plan_name,should_execution_time})
      getGroupList({page_num:1,page_size:10000}).then(res => {
        if(res.code === 200 && res.data.data) {
          let newGroups : IOptions[] = res.data.data.map((i:any) => {
            return {
              label: i.group_name,
              value: i.object_id
            }
          })
          setGroups(newGroups);
        } else {
          message.warning(res.message)
        }
      }).catch(err => console.log('err',err))
      team_groups_id && form.setFieldsValue({team_groups_id});
      team_groups_id && getGroupAllUserMethod(team_groups_id,'init');
    },[])


    const getGroupAllUserMethod = (group_id:number,type:'init' | 'change') => {
      getGroupAllUser({object_id:group_id}).then(res => {
        if(res.code === 200 && res.data) {
          let head_id:number = 0;
          let newHeadMans: IOptions[] = res.data.map((i:any) => {
            if(i.is_head) {head_id = i.object_id}
            return {
              label: i.name,
              value: i.object_id
            }
          })
          let newOtherMans : IOptions[] = newHeadMans.filter((i:IOptions) => i.value !== head_id)
          setPointMans({
            headMans: newHeadMans,
            otherMans: newOtherMans
          })
          type === 'change' ? form.setFieldValue('spot_user_id',head_id) : form.setFieldValue('spot_user_id',spot_user_id)
          type === 'change' && form.validateFields(['spot_user_id'])
          form.setFieldValue('member_user_id',newOtherMans.map((i:IOptions) => i.value))
          
        }
      }).catch(err => console.log('err',err))
    }


    const handleSelectOther = (head_id:number) => {
      setPointMans({...pointMans,otherMans:pointMans.headMans.filter((i:IOptions) => i.value !== head_id)})
      form.setFieldValue('member_user_id',pointMans.headMans.filter((i:IOptions) => i.value !== head_id).map((i:IOptions) => i.value))
    }


    return (
      <div className="dispatch-task">
        <div className="dispatch-title">设备信息</div>
        <Form name="patrol-dispatch-task-form" form={form} colon={false} autoComplete="off" layout="inline" >
          <Form.Item label="设备名称" name="device_name"><Input disabled /></Form.Item>
          <Form.Item label="设备代码" name="device_code"><Input disabled /></Form.Item>
          <Form.Item label="规格型号" name="device_specification"><Input disabled /></Form.Item>
          <Form.Item label="设备类型" name="device_type_name"><Input disabled /></Form.Item>
          <Form.Item label="存放位置" name="device_position"><Input disabled /></Form.Item>
          <Form.Item label="使用部门" name="device_organization_name"><Input disabled /></Form.Item>
        </Form>
        <div className="dispatch-line"></div>
        <div className="dispatch-title">任务信息</div>
        <Form name="patrol-dispatch-task-form" form={form} colon={false} autoComplete="off" layout="inline" >
          <Form.Item label="点检任务单号" name="code"><Input disabled /></Form.Item>
          <Form.Item label="计划名称" name="spot_plan_name"><Input disabled /></Form.Item>
          <Form.Item label="应执行时间" name="should_execution_time"><Input disabled /></Form.Item>
        </Form>
        <div className="dispatch-line"></div>
        <div className="dispatch-title">点检项目</div>
        <div className="dispatch-table-div"><Table columns={columns} rowKey={()=>nanoid()} dataSource={spot_items} className="dispatch-table" scroll={{y:165}} pagination={false}/></div>
        <div className="dispatch-title">点检派工</div>
        <Form name="patrol-dispatch-task-form" form={form} colon={false} autoComplete="off" layout="inline" >
          <Form.Item label="点检班组" name="team_groups_id" rules={[{ required: true, message: "请选择点检班组" }]} >
            <Select placeholder="请选择点检班组" options={groups} onSelect={(groups_id:number) => getGroupAllUserMethod(groups_id,'change')} getPopupContainer={trigger=>trigger.parentNode}/>
          </Form.Item>
          <Form.Item label="点检负责人" name="spot_user_id" rules={[{required:true,message:'请选择点检负责人'}]}>
            <Select placeholder="请选择点检负责人" options={pointMans.headMans} onSelect={handleSelectOther} getPopupContainer={trigger=>trigger.parentNode}/>
          </Form.Item>
          <div className="other-optioners">
            <Form.Item label="其他点检人员" name="member_user_id">
            <Select disabled options={pointMans.otherMans} mode="multiple"/>
            </Form.Item>
          </div>
          <div className="dispatch-line"></div>
        </Form>
      </div>
    );
}
export default DispatchTask;