import React from 'react'
import {Button, Input, InputNumber, Modal, Select, Table, TableColumnsType} from "antd";
import "./style.css"
import "../../Warehouse/WarehouseQuery/style.css"
import {toastMessage} from "../../../utils";
import {getStorageList, getWarehouseAllotDetail, getWarehouseProduct_All} from "../../../service";

/**
 * 新增编辑调拨弹窗
 */
const {Option} = Select;

interface Type {
    allotNum: string,
    originalHouse: any,
    targetHouse: any,
    remark: string,
    allotList: Array<any>,
    productionList: Array<any>,
    houseList: Array<any>,
    columns: TableColumnsType<any>,
}

export default class AllotNewOrEditModal extends React.Component<{
    type: string,
    dataItem?: any,
    closeClick: () => void
    saveClick: (allotNum: string, originalHouse: any, targetHouse: any, remark: string, allotList: Array<any>, objectId?: number) => void
    commitClick: (allotNum: string, originalHouse: any, targetHouse: any, remark: string, allotList: Array<any>, objectId?: number) => void
}> {
    state: Type = {
        allotNum: '',
        originalHouse: undefined,
        targetHouse: undefined,
        remark: '',
        allotList: [],
        productionList: [],
        houseList: [],
        columns: [],
    }

    async componentDidMount() {
        this.setState({
            columns: [
                {
                    title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
                    render: (text: any, record: any, index: number) => <div>{index + 1}</div>
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", justifyContent: "left"}}>
                            <div style={{color: "red"}}>*</div>
                            <div>
                                产品名称
                            </div>
                        </div>
                    }, dataIndex: 'product_id', align: 'center', ellipsis: true, width: 160,
                    render: (text: any, record: any) => <Select
                        style={{height: 30, width: 140, borderRadius: 5, textAlign: "left"}}
                        value={text}
                        placeholder={'请选择产品'}
                        onChange={(value) => {
                            const newList = this.state.allotList.map((childItem) => {
                                if (childItem.serialNum === record.serialNum) {
                                    const productionItem = this.state.productionList.find((item) => item.product_id === value)
                                    return {
                                        ...childItem,
                                        product_id: value,
                                        product_name: productionItem.product_name,
                                        product_code: productionItem.product_code,
                                        specification: productionItem.specification,
                                        unit_name: productionItem.unit_name,
                                        inventory_quantity: productionItem.inventory_num,
                                        transfer_num: undefined,
                                    }
                                } else {
                                    return childItem
                                }
                            })
                            this.setState({
                                allotList: newList
                            })
                        }}
                    >
                        {
                            this.state.productionList.length > 0 && this.state.productionList.map((item: any, index: number) => {
                                return <Option key={index} value={item.product_id}>
                                    {item.product_name}
                                </Option>
                            })
                        }
                    </Select>
                },
                {
                    title: '产品代码',
                    dataIndex: 'product_code',
                    align: 'center',
                    ellipsis: true,
                    render: (text: any) => <Input
                        style={{textAlign: "center"}}
                        value={text}
                        placeholder="请输入产品代码"
                        disabled={true}
                    />
                },
                {
                    title: '规格型号',
                    dataIndex: 'specification',
                    align: 'center',
                    ellipsis: true,
                    render: (text: any) => <Input
                        style={{textAlign: "center"}}
                        value={text}
                        placeholder="请输入规格型号"
                        disabled={true}
                    />
                },
                {
                    title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true, render: (text: any) => <Input
                        style={{textAlign: "center"}}
                        value={text}
                        placeholder="请输入单位"
                        disabled={true}
                    />
                },
                {
                    title: '库存数量', dataIndex: 'inventory_quantity', align: 'right', ellipsis: true,
                    render: (num: any) => <Input
                        style={{textAlign: "right"}}
                        value={num === undefined || num === null || num < 0 ? '' : num.toLocaleString()}
                        placeholder="请输入库存数量"
                        disabled={true}
                    />
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", justifyContent: "right"}}>
                            <div style={{color: "red"}}>*</div>
                            <div>
                                数量
                            </div>
                        </div>
                    }, dataIndex: 'transfer_num', align: 'right', ellipsis: true, width: 120,
                    render: (text: number, record: any) => <InputNumber
                        className={'allotModalTableInputStyle'}
                        value={text}
                        min={1}
                        placeholder="请输入数量"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        onChange={(newValue) => {
                            const newList = this.state.allotList.map((item) => {
                                if (item.serialNum === record.serialNum) {
                                    return {
                                        ...item,
                                        transfer_num: null !== newValue ? newValue : undefined
                                    }
                                } else {
                                    return item
                                }
                            })
                            this.setState({
                                allotList: newList
                            })
                        }}
                    />
                },
                {
                    title: '操作', dataIndex: '', align: 'center', ellipsis: true,
                    render: (text: any, record: any) =>
                        <div style={{color: "#0053FF", cursor: "pointer"}}
                             onClick={() => {
                                 this.handleDelete(record.serialNum)
                             }}>删除</div>
                },
            ]
        })

        await getStorageList().then((res) => {
            if (res.code === 200) {
                this.setState({
                    houseList: res.data.data.filter((item: any) => item.is_valid === true)
                })
            }
        })

        if (this.props.type === 'edit') {
            await getWarehouseAllotDetail({object_id: this.props.dataItem.object_id}).then(async (resAllotDetail) => {
                if (resAllotDetail.code === 200) {
                    await getWarehouseProduct_All({storage_id: resAllotDetail.data.source_storage_id}).then((res) => {
                        if (res.code === 200) {
                            this.setState({
                                productionList: res.data
                            }, () => {
                                let newList: any[] = []
                                if (resAllotDetail.data.inventoryTransferDetails.length > 0) {
                                    resAllotDetail.data.inventoryTransferDetails.map((item, index) => {
                                        newList.push({
                                            ...item,
                                            product_id: this.state.productionList.find((productionItem: any) => productionItem.product_id === item.product_id) ? item.product_id : undefined,
                                            serialNum: index,
                                        })
                                    })
                                }
                                this.setState({
                                    allotNum: resAllotDetail.data.code,
                                    originalHouse: this.state.houseList.find((item) => item.object_id === resAllotDetail.data.source_storage_id) ? resAllotDetail.data.source_storage_id : undefined,
                                    targetHouse: this.state.houseList.find((item) => item.object_id === resAllotDetail.data.objective_storage_id) ? resAllotDetail.data.objective_storage_id : undefined,
                                    remark: resAllotDetail.data.remarks,
                                    allotList: newList
                                })
                            })
                        }
                    })
                }
            })
        }
    }

    handleDelete(serialNum: number) {
        const newData = this.state.allotList.filter(item => item.serialNum !== serialNum);
        newData.map((item, index) => {
            item.serialNum = index + 1
        })
        this.setState({
            allotList: newData
        })
    }

    handleAdd() {
        const newItem: any = {
            serialNum: this.state.allotList.length,
            product_id: undefined,
            product_name: '',
            product_code: '',
            specification: '',
            unit_name: '',
            inventory_quantity: undefined,
            transfer_num: undefined,
        }
        const newData = [...this.state.allotList, newItem]
        newData.map((item, index) => {
            item.serialNum = index + 1
        })
        this.setState({
            allotList: newData
        })
    }

    allotRequest(typeStr: string) {
        if (this.state.originalHouse === undefined) {
            toastMessage('请选择原仓库')
            return;
        }
        if (this.state.targetHouse === undefined) {
            toastMessage('请选择目的仓库')
            return;
        }
        if (this.state.originalHouse === this.state.targetHouse) {
            toastMessage('目的仓库不得与原仓库相同，请重新选择')
            return;
        }
        if (this.state.allotList.length > 0) {
            let isHasProduct = false
            let isHasNum = false
            let isLarge = false
            this.state.allotList.map((item) => {
                if (item.product_id === undefined) {
                    isHasProduct = true
                }
                if (item.transfer_num === undefined || item.transfer_num === null) {
                    isHasNum = true
                }
                if (item.transfer_num > item.inventory_quantity) {
                    isLarge = true
                }
            })
            if (isHasProduct) {
                toastMessage('请选择产品')
                return;
            }
            if (isHasNum) {
                toastMessage('请输入数量')
                return;
            }
            if (isLarge) {
                toastMessage('数量不足，请重新输入')
                return;
            }
            if (this.isRepeat(this.state.allotList)) {
                toastMessage('产品重复，请重新选择')
                return;
            }
        }

        if (typeStr === 'commit') {
            if (this.props.type === 'new') {
                this.props.commitClick && this.props.commitClick(this.state.allotNum, this.state.originalHouse, this.state.targetHouse, this.state.remark, this.state.allotList)
            } else {
                this.props.commitClick && this.props.commitClick(this.state.allotNum, this.state.originalHouse, this.state.targetHouse, this.state.remark, this.state.allotList, this.props.dataItem.object_id)
            }
        } else {
            if (this.props.type === 'new') {
                this.props.saveClick && this.props.saveClick(this.state.allotNum, this.state.originalHouse, this.state.targetHouse, this.state.remark, this.state.allotList)
            } else {
                this.props.saveClick && this.props.saveClick(this.state.allotNum, this.state.originalHouse, this.state.targetHouse, this.state.remark, this.state.allotList, this.props.dataItem.object_id)
            }
        }
    }

    isRepeat(arrayCheck: Array<any>) {
        const newArr = arrayCheck.map((item) => item.product_id)
        return newArr.some((x, index, arr) => arr.findIndex((y) => y === x) !== index)
    }

    render() {
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => this.props.closeClick && this.props.closeClick()}
                />
            }
            destroyOnClose
            title={<div style={{fontSize: 16, fontWeight: "bold"}}>
                {this.props.type === 'new' ? '新增调拨' : '编辑调拨信息'}
            </div>}
            footer={null}
            width={1080}
            centered
            open
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
                fontSize: 16
            }}>
                调拨信息
            </div>

            <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'allotModalTitleLeft'}>调拨单号</div>
                    <Input
                        value={this.state.allotNum}
                        className={'allotModalInputStyle'}
                        placeholder="不填写，系统将自动生成"
                        maxLength={32}
                        onChange={(newValue) => {
                            if (newValue.target.value === '') {
                                this.setState({
                                    allotNum: newValue.target.value
                                })
                            } else {
                                if (/^[A-Za-z0-9]+$/.test(newValue.target.value)) {
                                    this.setState({
                                        allotNum: newValue.target.value
                                    })
                                }
                            }
                        }}
                        disabled={this.props.type !== 'new'}
                    />
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'allotModalTitleRight'}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        <div>
                            原仓库
                        </div>
                    </div>
                    <Select
                        className={'allotModalSelectStyle'}
                        placeholder={'请选择仓库'}
                        value={this.state.originalHouse}
                        onChange={(value) => {
                            this.setState({
                                originalHouse: value,
                                allotList: []
                            }, () => {
                                getWarehouseProduct_All({storage_id: value}).then((res) => {
                                    if (res.code === 200) {
                                        this.setState({
                                            productionList: res.data
                                        })
                                    }
                                })
                            })
                        }}
                    >
                        {
                            this.state.houseList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.storage_name}
                                </Option>
                            })
                        }
                    </Select>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'allotModalTitleRight'}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        <div>
                            目的仓库
                        </div>
                    </div>
                    <Select
                        className={'allotModalSelectStyle'}
                        placeholder={'请选择仓库'}
                        value={this.state.targetHouse}
                        onChange={(value) => {
                            this.setState({
                                targetHouse: value
                            })
                        }}
                    >
                        {
                            this.state.houseList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.storage_name}
                                </Option>
                            })
                        }
                    </Select>
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div className={'allotModalTitleLeft'}>备注</div>
                    <Input.TextArea
                        autoSize={{minRows: 3, maxRows: 4}}
                        value={this.state.remark}
                        className={'allotModalInputRemarkStyle'}
                        placeholder="请输入备注"
                        maxLength={200}
                        onChange={(newValue) => {
                            this.setState({
                                remark: newValue.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <div style={{
                display: "flex",
                flex: 1,
                height: 1,
                backgroundColor: '#000000',
                opacity: 0.1,
                marginTop: 20,
                marginBottom: 20
            }}/>

            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                <div style={{fontWeight: "bold", fontSize: 16}}>调拨明细</div>
                <div style={{flex: 1}}/>
                <div
                    style={{
                        width: 48,
                        height: 28,
                        backgroundColor: '#5DA198',
                        borderRadius: 5,
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        this.handleAdd()
                    }}>
                    <div style={{color: '#ffffff', marginTop: 3}}>新增</div>
                </div>
            </div>

            <Table
                className={'queryDetailModalChildTable'}
                dataSource={this.state.allotList}
                columns={this.state.columns}
                size={'small'}
                scroll={{y: 300}}
                pagination={false}
            />

            <div style={{display: "flex", marginTop: 30, justifyContent: "center"}}>
                <Button
                    style={{width: 130, height: 48, borderRadius: 5, backgroundColor: '#B8B8B8', color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        this.props.closeClick && this.props.closeClick()
                    }}
                >
                    关闭
                </Button>
                <Button
                    style={{
                        width: 130,
                        height: 48,
                        marginLeft: 20,
                        borderRadius: 5,
                        backgroundColor: '#3E7BFA',
                        color: '#ffffff'
                    }}
                    htmlType="button"
                    onClick={() => {
                        this.allotRequest('save')
                    }}
                >
                    保存
                </Button>
                <Button
                    style={{
                        width: 130,
                        height: 48,
                        marginLeft: 20,
                        borderRadius: 5,
                        backgroundColor: '#3E7BFA',
                        color: '#ffffff'
                    }}
                    htmlType="button"
                    onClick={() => {
                        this.allotRequest('commit')
                    }}
                >
                    提交
                </Button>
            </div>
        </Modal>
    }
}
