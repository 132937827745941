import React from 'react'
import {Button, Input, Modal, Select, Table} from "antd";
import moment from "moment";
import {ColumnsType} from "antd/lib/table";
import {getTaskDetailRequest} from "../../../service";

/**
 * 查看生产任务对话框
 */
export default class CheckTaskModal extends React.Component<{
    dataItem: API.queryChildItem | undefined,
    closeClick?: () => void
}> {

    state = {
        lineList: [],
    }

    private checkTaskColumns: ColumnsType<any> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
            render: (text: any, record: any, index: number) => <div>{index + 1}</div>
        },
        {title: '工序', dataIndex: 'process_name', align: 'center', ellipsis: true},
        {title: '备注', dataIndex: 'process_explain', align: 'center', ellipsis: true},
        {title: '作业者', dataIndex: 'user', align: 'center', ellipsis: true},
        {
            title: '实际开始时间', dataIndex: 'start_time', align: 'center', ellipsis: true, width: '20%',
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '实际完成时间', dataIndex: 'end_time', align: 'center', ellipsis: true, width: '20%',
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 10 ? '#EB4420' : status === 20 ? '#EB4420' : status === 30 ? '#4680FA' : status === 40 ? '#06C270' : status === 50 ? '#0053FF' : '#000000'}}>
                {
                    status === 10 ? '未下达' : status === 20 ? '未派工' : status === 30 ? '已派工' : status === 40 ? '处理中' : status === 50 ? '已完成' : ''
                }
            </div>
        },
    ]

    componentDidMount() {
        const {dataItem} = this.props
        getTaskDetailRequest({id: `${dataItem!.object_id}`}).then((res) => {
            if (res.code === 200) {
                this.setState({
                    lineList: res.data.work
                })
            }
        })
    }

    render() {
        const {dataItem, closeClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'查看生产任务'}
            footer={null}
            width={855}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'checkModalItemTitleLeft'}>任务单号</div>
                    <Input
                        value={dataItem && dataItem.task_number}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'checkModalItemTitleRight'}>产线</div>
                    <Select
                        className={'reportModalItemSelectStyle'}
                        value={dataItem && dataItem.line_info}
                        disabled
                    />
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'checkModalItemTitleLeft'}>销售单号</div>
                    <Input
                        value={dataItem && dataItem.order_number}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'checkModalItemTitleRight'}>工艺路线</div>
                    <Select
                        className={'reportModalItemSelectStyle'}
                        value={dataItem && dataItem.work_craft_info}
                        disabled
                    />
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'checkModalItemTitleLeft'}>产品</div>
                    <Input
                        value={dataItem && dataItem.product_name}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'checkModalItemTitleRight'}>计划日期</div>
                    <div className={'reportModalItemTextStyle'} style={{width: 120, justifyContent: "center"}}>
                        {dataItem ? dataItem.start_date === undefined || null ? '' : moment(dataItem.start_date).format('YYYY/MM/DD') : ''}
                    </div>
                    <div style={{display: "flex", width: 40, justifyContent: "center"}}>
                        ~
                    </div>
                    <div className={'reportModalItemTextStyle'}
                         style={{width: 120, justifyContent: "center", marginLeft: 0}}>
                        {dataItem ? dataItem.end_date === undefined || null ? '' : moment(dataItem.end_date).format('YYYY/MM/DD') : ''}
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'checkModalItemTitleLeft'}>单位</div>
                    <Input
                        value={dataItem && dataItem.unit_name}
                        className={'reportModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'checkModalItemTitleRight'}>任务数量</div>
                    <Input
                        value={dataItem && dataItem.task_quantity.toLocaleString()}
                        className={'reportModalItemInputStyle'}
                        disabled
                    />
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'checkModalItemTitleLeft'}>订单数量</div>
                    <Input
                        value={dataItem && dataItem.order_quantity.toLocaleString()}
                        className={'reportModalItemInputStyle'}
                        disabled
                    />
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'checkModalItemTitleLeft'}>备注</div>
                    <Input
                        style={{
                            display: "flex",
                            flex: 1,
                            width: 672,
                            height: 38,
                            backgroundColor: '#F2F2F2',
                            color: '#333333',
                            fontSize: 14,
                            alignItems: "center",
                            borderRadius: 5,
                            marginLeft: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            overflow: 'hidden',
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                        }}
                        value={dataItem && dataItem.remark}
                        disabled
                    />
                </div>
            </div>

            <Table
                title={() => <div style={{fontWeight: "bold", color: '#333333', marginLeft: 10}}>工艺流程</div>}
                style={{marginTop: 20, marginLeft: 15, marginRight: 15}}
                bordered
                className={'childTable'}
                rowKey={'object_id'}
                dataSource={this.state.lineList}
                columns={this.checkTaskColumns}
                size={'small'}
                scroll={{y: 200}}
                pagination={false}
            />

            <div style={{display: "flex", marginTop: 20, justifyContent: "center"}}>
                <Button style={{width: 100, height: 35, borderRadius: 5}}
                        htmlType="button"
                        type="primary"
                        onClick={() => {
                            closeClick && closeClick()
                        }}
                >
                    关闭
                </Button>
            </div>
        </Modal>
    }
}
