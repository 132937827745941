import React, {useEffect, useState} from 'react'
import {Button, Modal, Table, TableColumnsType} from "antd";
import "../WarehouseQuery/style.css"
import {getWarehouseAllotDetail} from "../../../service";
import {toastErrorMessage} from "../../../utils";
import moment from "moment";

/**
 * 调拨明细弹窗
 */
interface AllotDetailModalProps {
    dataItemId: number,
    closeClick: () => void
}

const AllotDetailModal = (props: AllotDetailModalProps): React.ReactElement => {

    const [data, setData] = useState<API.warehouseResponseDataAllotDetail>({
        code: '',
        create_time: '',
        inventoryTransferDetails: [],
        is_valid: false,
        object_id: 0,
        object_name: '',
        objective_storage_id: 0,
        objective_storage_name: '',
        remarks: '',
        source_storage_id: 0,
        source_storage_name: '',
        status: 0,
        transfer_date: '',
        transfer_product: [],
        update_time: '',
        user_id: 0,
        user_name: '',
    });
    const columns: TableColumnsType<API.warehouseResponseDataAllotDetailItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {title: '产品名称', dataIndex: 'product_name', align: 'left', ellipsis: true},
        {title: '产品代码', dataIndex: 'product_code', align: 'center', ellipsis: true},
        {title: '规格型号', dataIndex: 'specification', align: 'center', ellipsis: true},
        {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
        {
            title: '数量', dataIndex: 'transfer_num', align: 'right', ellipsis: true,
            render: (num: number) =>
                <div>{num === undefined || num === null || num < 0 ? '' : num.toLocaleString()}</div>
        },
        {title: '', dataIndex: '', align: 'center', ellipsis: true, width: 30},
    ]

    useEffect(() => {
        getWarehouseAllotDetail({object_id: props.dataItemId}).then((res) => {
            if (res.code === 200) {
                setData(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }, [])

    return (
        <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => props.closeClick && props.closeClick()}
                />
            }
            destroyOnClose
            title={<div style={{fontSize: 16, fontWeight: "bold"}}>
                调拨明细
            </div>}
            footer={null}
            width={1080}
            centered
            open
        >
            <div style={{
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
                fontSize: 16
            }}>
                调拨信息
            </div>

            <div style={{display: "flex", flexDirection: "column", marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>调拨单号：</div>
                        <div className={'detailValue70Style'}>{data.code}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName60Style'}>原仓库：</div>
                        <div className={'detailValue60Style'}>{data.source_storage_name}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName70Style'}>目的仓库：</div>
                        <div className={'detailValue70Style'}>{data.objective_storage_name}</div>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <div style={{display: "flex", flexDirection: "row", width: 300}}>
                        <div className={'detailName70Style'}>调拨时间：</div>
                        <div
                            className={'detailValue70Style'}>{data.transfer_date && data.transfer_date !== '' ? moment(data.transfer_date).format('YYYY/MM/DD HH:mm') : ''}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", width: 300, marginLeft: 10}}>
                        <div className={'detailName60Style'}>调拨人：</div>
                        <div className={'detailValue60Style'}>{data.user_name}</div>
                    </div>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                    <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                        <div className={'detailName70Style'}>备注：</div>
                        <div style={{
                            flex: 1,
                            marginLeft: 10,
                            wordBreak: "break-all",
                            color: '#333333',
                            fontSize: 14,
                        }}>{data.remarks}</div>
                    </div>
                </div>
            </div>

            <div style={{
                display: "flex",
                flex: 1,
                height: 1,
                backgroundColor: '#000000',
                opacity: 0.1,
                marginTop: 20,
                marginBottom: 20
            }}/>

            <div style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 24,
                fontWeight: "bold",
                fontSize: 16
            }}>
                调拨明细
            </div>
            <Table
                className={'queryDetailModalChildTable'}
                dataSource={data.inventoryTransferDetails}
                columns={columns}
                size={'small'}
                scroll={{y: 300}}
                pagination={false}
            />

            <div style={{display: "flex", marginTop: 30, justifyContent: "center"}}>
                <Button
                    style={{width: 130, height: 48, borderRadius: 5, backgroundColor: '#B8B8B8', color: '#ffffff'}}
                    htmlType="button"
                    onClick={() => {
                        props.closeClick && props.closeClick()
                    }}
                >
                    关闭
                </Button>
            </div>
        </Modal>
    )
}

export default AllotDetailModal
