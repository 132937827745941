import React from 'react'
import { Modal, } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import { toastMessage } from "../../../../utils";

export default class EditModal extends React.Component<{
    CauseItem: API.updateCause
    closeClick: () => void,
    saveClick: (e: API.updateCause) => void,
}> {
    state = {
        code: '',
        reason: '',
        remark: '',
    }
    componentDidMount() {
        const {
            CauseItem,
        } = this.props
        this.setState({
            code: CauseItem.code,
            reason: CauseItem.reason,
            remark: CauseItem.remark,
        })
    }
    render() {
        const {
            code,
            reason,
            remark
        } = this.state
        const { closeClick, saveClick, CauseItem } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'编辑不良原因'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='不良代码' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    placeholder=''
                    value={code}
                    onChange={(value) => {
                        this.setState({
                            code: value
                        })
                    }}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1}
                    title='不良原因'
                    titlestyle={{ width: 60 }}
                    style={{ width: 281 }}
                    placeholder='请输入不良原因'
                    value={reason}
                    onChange={(value) => {
                        this.setState({ reason: value })
                    }}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注'
                    remark
                    value={remark}
                    titlestyle={{ paddingLeft: 2, textAlign: 'left', width: 60 }}
                    style={{ width: 281, marginRight: 20 }}
                    placeholder='请输入备注'
                    onChange={(value) => {
                        this.setState({ remark: value })
                    }}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!reason) {
                        toastMessage('不良原因不能为空')
                        return
                    }
                    let value: API.updateCause = {
                        object_id: CauseItem.object_id,
                        reason: reason
                    }
                    if (remark) {
                        value.remark = remark
                    }
                    saveClick(value)
                }} />
            </div>
        </Modal>
    }

}