import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {ColumnsType} from "antd/es/table";
import {Form, Input, InputNumber, Select} from "antd";
import {formatNumber, showError} from "../../../utils";
import {ModalTitle} from "../WarehouseIn";
import {ModalTitle as ModalTitleOut} from "../warehouseOut";
import {ProductNameOptions} from "./AddOrEditItem";

interface ProductNameProps {
    index: number;
}

interface OutTableProps {
    tRef: any,
    tableForm: FormInstance,
    detailDataSource: any[],
    productListOut: ProductNameOptions[],
    newItem: any
}

const OutTable = (props: OutTableProps) => {
    const {tableForm, tRef, detailDataSource, productListOut, newItem} = props
    const OutColumns: ColumnsType<any> = [
        {
            title: "序号",
            dataIndex: "序号",
            key: "序号",
            width: 60,
            ellipsis: true,
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>产品名称</span>,
            dataIndex: 'product_name',
            key: 'product_name',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) => <ProductName index={index}/>
        },
        {
            title: <span>产品代码</span>,
            dataIndex: 'code',
            key: 'product_code',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'code']}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入产品代码'} disabled/>
                </Form.Item>
        },
        {
            title: <span>规格型号</span>,
            dataIndex: 'specification',
            key: 'model',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'specification']}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入规格型号'} disabled/>
                </Form.Item>
        },
        {
            title: <span>单位</span>,
            dataIndex: 'unit_name',
            key: 'unit',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'unit_name']}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入单位'} disabled/>
                </Form.Item>
        },
        {
            title: <span>库存数量</span>,
            dataIndex: 'kc_count',
            key: 'kc_count',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'kc_count']}
                           style={{textAlign: 'left'}}>
                    <InputNumber placeholder={'请输入库存数量'} min={1} disabled
                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>数量</span>,
            dataIndex: 'count',
            key: 'number',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'count']}
                           style={{textAlign: 'left'}}
                           rules={[
                               (({getFieldValue}) => ({
                                   validator(_, value) {
                                       if (value) {
                                           if (getFieldValue([`${index}`, 'kc_count'])) {
                                               if (value <= getFieldValue([`${index}`, 'kc_count'])) {
                                                   return Promise.resolve()
                                               } else {
                                                   return Promise.reject(new Error('数量不超过库存数量！'))
                                               }
                                           } else {
                                               return Promise.resolve()
                                           }
                                       } else {
                                           return Promise.reject(new Error('请输入数量！'))
                                       }
                                   }
                               }))
                           ]}>
                    <InputNumber placeholder={'请输入数量'} min={1}
                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                </Form.Item>
        },
        {
            title: <span>备注</span>,
            dataIndex: 'remark',
            key: 'remark',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'remark']}
                           style={{textAlign: 'left'}}
                           rules={[
                               {max: 200, message: '备注不得大于200位！'}
                           ]}
                >
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
        },
        {
            title: <span>操作</span>,
            dataIndex: 'options',
            key: 'options',
            width: 80,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <span className={'mes-options'} onClick={() => {
                    if (tRef.current) {
                        tRef.current.handleDelete(index)
                    }
                }}>删除</span>
        },
    ]

    /*出库产品名称*/
    const ProductName = React.memo((props: ProductNameProps) => {
        const {index} = props;

        const handleChange = (value: number, option: any) => {
            tableForm.setFieldValue([`${props.index}`, 'product_name'], value)
            tableForm.resetFields([[`${props.index}`, 'code'], [`${props.index}`, 'specification'], [`${props.index}`, 'unit_name'], [`${props.index}`, 'kc_count']])
            tableForm.setFieldValue([`${props.index}`, 'code'], option.include.product_code)
            tableForm.setFieldValue([`${props.index}`, 'specification'], option.include.model)
            tableForm.setFieldValue([`${props.index}`, 'unit_name'], option.include.unit)
            tableForm.setFieldValue([`${props.index}`, 'kc_count'], option.include.kc_count)
        }

        return <Form.Item name={[`${index}`, 'product_name']}
                          style={{textAlign: 'left'}}
                          rules={[
                              {required: true, message: '请选择产品！'},
                          ]}>

            {productListOut && <Select placeholder={`请选择产品`} onChange={handleChange}>{
                productListOut.map((e: any) => <Select.Option key={e.object_id} value={e.object_id}
                                                              include={e}>{e.product_name}</Select.Option>)
            }
            </Select>}
        </Form.Item>
    })

    return <EditOrDragTable tableForm={tableForm} detailColumns={OutColumns} detailDataSource={detailDataSource}
                            title={`出库明细`} newItem={newItem} ref={tRef} scroll={false}/>
}

export default OutTable
