import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Form, Input, Select, message } from "antd";
import { postAddPointProject,postEditPointProject } from "../../../../service";
import { getFormData } from "../../../../utils"
import { IProps, IResultOptions} from "../../PatrolProject/components/AddOrEditPatrolProject"
import "../../index.less";

const AddOrEditPointProject = forwardRef((props:IProps,ref) => {
  const [type, setType] = useState<string>("num");
  const [abnormal, setAbnormal] = useState<number | null>(null);
  const {form,afterOnOK,isEdit,selectItem} = props;

  useImperativeHandle(ref,() => ({
    handleSave
  }))

  useEffect(()=>{
    form.resetFields()
    if(isEdit) {
      let arr:Array<{option:string}> = []
      const {spot_type,results_options} = selectItem;
      spot_type === 10 ? setType('num') : spot_type === 20 ? setType('radio') : setType('checkBox') 
      if(spot_type !== 10 && results_options.length) {
        results_options.forEach((o:any,i:number) => {
          o.options_exception === 1 && setAbnormal(i+1)
          i > 1 && arr.push({option:o.options_name})
        })
        form.setFieldsValue({...selectItem,options:arr,optionOne:results_options[0].options_name,optionTwo:results_options[1].options_name})
      } else {
        form.setFieldsValue({...selectItem})
      }
    }

  },[])

  const handleSave = (res:any) => {
    if(res.upper_limit && res.lower_limit) {
      if(parseFloat(res.upper_limit) < parseFloat(res.lower_limit)) {
        return message.warning('上限值不能小于下限值,请重新输入')
      }
    }
    !res.code && delete res.code
    !res.remarks && delete res.remarks
    if(type === 'num') {
      res.spot_type = 10
    } else {
      if(!abnormal) {
        return message.warning('请设置异常选项')
      }
      let spot_results_options:Array<IResultOptions>= [
        {options_name:res.optionOne,options_exception:0},
        {options_name:res.optionTwo,options_exception:0}];
      res.options && res.options.forEach((o:{option:string}) => {
        spot_results_options.push({
          options_name:o.option,
          options_exception:0
        })
      })

      spot_results_options.forEach((o:IResultOptions,i:number) => {
        if(i+1 === abnormal) {
          o.options_exception = 1
        }
      })
      res.spot_results_options = JSON.stringify(spot_results_options)
      res.options && delete res.options
      delete res.optionOne
      delete res.optionTwo
      type === 'radio' ? res.spot_type = 20 : res.spot_type = 30
    } 
    if(isEdit) {
      res.code && delete res.code
      postEditPointProject(getFormData({...res,object_id:selectItem.object_id})).then(resolve => {
        resolve.code === 200 ? afterOnOK('编辑成功') : message.warning(resolve.message)
      }).catch(err => console.log('err',err))
    } else {
      postAddPointProject(getFormData(res)).then(res => {
       res.code === 200 ? afterOnOK('新增成功') : message.warning(res.message)
      }).catch(err => console.log('err',err))
    }
    
  }
  const handleClickType = (event:Record<string,any>) => {
    switch(event.target.id) {
      case "number":
        setType("num");
        form.resetFields(['upper_limit','lower_limit']);
        break;
      case "radio":
        setType("radio");
        setAbnormal(null);
        form.resetFields(['options','optionOne','optionTwo']);
        break;
      case "checkbox":
        setType("checkBox");
        setAbnormal(null);
        form.resetFields(['options','optionOne','optionTwo']);
        break;
    }
  }
  return (
    <div className="point-project-modal">
      <Form name="point-project-modal-form" form={form} colon={false} autoComplete="off" >
       <div className="point-row">
        <Form.Item label="点检项目代码" name="code" rules={[ { type: "string", max: 32, pattern: /^[A-Za-z0-9]+$/, message: "点检项目代码由不大于32位的字母、数字组成!", }, ]} >
          <Input placeholder="不填写，系统将自动生成" disabled={isEdit}/>
        </Form.Item>
        <Form.Item label="点检项目名称" name="spot_name" rules={[ { required: true, message: "请输入点检项目名称!" }, { type: "string", max: 32, message: "点检项目名称长度不大于32位!", }, ]} >
          <Input placeholder="请输入点检项目名称" />
        </Form.Item>
        <Form.Item label="点检部位" name="spot_area" rules={[{ required: true, message: "请输入点检部位!" }, { type: "string", max: 32, message: "点检部位长度不大于32位!", }, ]} >
          <Input placeholder="请输入点检部位" />
        </Form.Item>
        <Form.Item label="点检方法" name="spot_method" rules={[{ required: true, message: "请选择点检方法!" }]} >
          <Select
            placeholder="请选择点检方法"
            options={[
              { value: 10, label: "目视" },
              { value: 20, label: "手感" },
              { value: 30, label: "看听" },
              { value: 40, label: "看/听" },
            ]}
          />
        </Form.Item>
       </div>
        <Form.Item label="点检内容" name="spot_content" rules={[ { required: true, message: "请输入点检内容!" }, { type: "string", max: 200, message: "点检内容长度不大于200位!"}]}>
          <Input.TextArea placeholder="请输入点检内容"/>
        </Form.Item>
        <Form.Item label="点检结果类型" name="spot_type">
          <div className="point-project-btns" onClick={ isEdit ? undefined : handleClickType}>
            <div id="number" className={ type === "num" ? "point-project-btn-active" : !isEdit ? "point-project-btn" : "disabled-btn" }>数值</div>
            <div id="radio" className={ type === "radio" ? "point-project-btn-active" : !isEdit ? "point-project-btn" : "disabled-btn" } >单选</div>
            <div id="checkbox" className={ type === "checkBox" ? "point-project-btn-active" : !isEdit ? "point-project-btn" : "disabled-btn" }>多选</div>
          </div>
        </Form.Item>
        <Form.Item label="参数名称" name="parameter_name" rules={[ { required: true, message: "请输入参数名称!" }, { type: "string", max: 32, message: "参数名称不大于32位!"}]}>
          <Input placeholder="请输入参数名称" />
        </Form.Item>
        {type === "num" && (
          <div className="point-number-range">
            <Form.Item label="上限" name="upper_limit" rules={[{ required: true, message: "请输入上限!" },{
                pattern:/^[0-9][0-9]*(\.[0-9]{1,2})?$/,message:'只能输入整数或者两位小数!'
              }]} ><Input placeholder="请输入上限" /></Form.Item>
            <Form.Item label="下限" name="lower_limit" rules={[{ required: true, message: "请输入下限!" },{
                pattern:/^[0-9][0-9]*(\.[0-9]{1,2})?$/,message:'只能输入整数或者两位小数!'
              }]} ><Input placeholder="请输入下限" /></Form.Item>
          </div>
        )}
        {type !== "num" && (
          <div className="ponit-project-options-box">
            <div className="point-project-option">
              <Form.Item className="option-part-one" label="选项1" name="optionOne" rules={[{ required: true, message: "请输入选项内容" }]} >
                <Input placeholder="请输入选项内容" />
              </Form.Item>
              <Form.Item className="option-part-two" label="" labelCol={{ span: 0 }}>
                <div className="option-part-radio">
                  <div className={ abnormal === 1 ? "option-part-radio-circle-active" : "option-part-radio-circle" } onClick={() => { setAbnormal(1); }} >
                    <div className={abnormal === 1 ? "circle-div" : ""}></div>
                  </div>
                  <span>异常</span>
                </div>
              </Form.Item>
            </div>
            <div className="point-project-option">
              <Form.Item className="option-part-one" label="选项2" name="optionTwo" rules={[{ required: true, message: "请输入选项内容" }]} >
                <Input placeholder="请输入选项内容" />
              </Form.Item>
              <Form.Item className="option-part-two" label="" labelCol={{ span: 0 }}>
                <div className="option-part-radio">
                  <div className={ abnormal === 2 ? "option-part-radio-circle-active" : "option-part-radio-circle" } onClick={() => { setAbnormal(2); }} >
                    <div className={abnormal === 2 ? "circle-div" : ""}></div>
                  </div>
                  <span>异常</span>
                </div>
              </Form.Item>
            </div>
          </div>
        )}
        {type !== "num" && (
          <Form.List name="options">
            {(fields, { add, remove }) => {
              return (
                <>
                  <div className="ponit-project-options-box">
                  {fields.map((field) => (
                    <div key={field.key} className="point-project-option">
                      <Form.Item className="option-part-one" {...field} key={'option' + field.key} label={"选项" + (Number(field.name) + 3)} name={[field.name, "option"]} rules={[{ required: true, message: "请输入选项内容" }]} >
                        <Input placeholder="请输入选项内容" />
                      </Form.Item>
                      <Form.Item className="option-part-two" {...field} key={'radio' + field.key} label="" labelCol={{ span: 0 }}>
                        <div className="option-part-radio">
                          <div className={ abnormal === Number(field.name) + 3 ? "option-part-radio-circle-active" : "option-part-radio-circle" }
                            onClick={() => {
                              setAbnormal(Number(field.name) + 3);
                            }}
                          >
                            <div className={ abnormal === Number(field.name) + 3 ? "circle-div" : "" } ></div>
                          </div>
                          <span>异常</span>
                        </div>
                      </Form.Item>
                      <div className="option-part-three-box">
                        <div onClick={() => {
                          remove(field.name);
                          if(Number(field.name) + 3 === abnormal ) {
                            setAbnormal(null);
                          }
                        }} className="option-part-three" >
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ))}
                  </div>
                  <Form.Item>
                    <div className="point-project-add-button" onClick={() => add()} >
                      <div><span>+</span>添加更多选项</div>
                    </div>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        )}
        <Form.Item label="备注" name="remarks" rules={[ { type: "string", max: 200, message: "备注不大于200位!" }]} >
          <Input.TextArea placeholder="请输入备注" />
        </Form.Item>
      </Form>
    </div>
  );
});
export default AddOrEditPointProject;
