import React from 'react'
import {Button, Input, Modal, Select, Table, TableColumnsType} from "antd";
import moment from "moment";

/**
 * 查看订单对话框
 */
export default class CheckOrderModal extends React.Component<{
    dataItem: API.orderItem | undefined,
    closeClick?: () => void
}> {

    private detailOrderColumns: TableColumnsType<API.childOrderItem> = [
        {title: 'No', dataIndex: 'serial_num', align: 'center', ellipsis: true, width: '8%'},
        {title: '产品', dataIndex: 'productinfo_name', align: 'center', ellipsis: true},
        {
            title: '数量', dataIndex: 'num', align: 'center', ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
        {
            title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true, width: '20%',
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD')}</div>
        },
        {title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true, width: '20%'},
    ]

    render() {
        const {dataItem, closeClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'查看销售订单'}
            footer={null}
            width={855}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'orderModalItemTitleLeft'}>销售单号</div>
                    <Input
                        value={dataItem && dataItem.code}
                        className={'orderModalItemInputStyle'}
                        maxLength={32}
                        disabled
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'orderModalItemTitleRight'}>客户</div>
                    <Select
                        className={'orderModalItemSelectStyle'}
                        value={dataItem && dataItem.trader_name}
                        disabled
                    />
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'orderModalItemTitleLeft'}>交货日期</div>
                    <div className={'orderModalItemTextStyle'}>
                        {dataItem && moment(dataItem.finish_date).format('YYYY/MM/DD')}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'orderModalItemTitleRight'}>备注</div>
                    <Input
                        className={'orderModalItemInputStyle'}
                        value={dataItem && dataItem.remark}
                        maxLength={200}
                        disabled
                    />
                </div>
            </div>

            <Table
                title={() => <div style={{fontWeight: "bold", color: '#333333', marginLeft: 10}}>订单明细</div>}
                style={{marginTop: 20, marginLeft: 15, marginRight: 15}}
                bordered
                className={'childTable'}
                rowKey={'object_id'}
                dataSource={dataItem && dataItem.orderproduct_info}
                columns={this.detailOrderColumns}
                size={'small'}
                scroll={{y: 200}}
                pagination={false}
            />

            <div style={{display: "flex", marginTop: 20, justifyContent: "center"}}>
                <Button style={{width: 100, height: 35, borderRadius: 5}}
                        htmlType="button"
                        type="primary"
                        onClick={() => {
                            closeClick && closeClick()
                        }}
                >
                    关闭
                </Button>
            </div>
        </Modal>
    }
}
