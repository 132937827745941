import React, {useContext, useEffect, useRef, useState} from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import moment from "moment";
import {deleteCheck, getWarehouseCheck, newCheck, updateCheck} from "../../../service";
import {toastErrorMessage, toastSuccessMessage} from "../../../utils";
import "../../Warehouse/WarehouseQuery/style.css"
import DeleteModal from "../../Production/components/deleteModal";
import SelectFormCheck from "./selectFormCheck";
import CheckDetailModal from "./checkDetailModal";
import ChooseHouseModal from "./chooseHouseModal";
import CheckNewOrEditModal from "./checkNewOrEditModal";
import {UserContext} from "../../../contexts/userContext";
import CheckPrintModal from "./checkPrintModal";
// @ts-ignore
import Print from "react-print-html";

/**
 * 仓储管理--库存盘点
 */
const WarehouseCheck = (): React.ReactElement => {
    const userInfo = useContext(UserContext)
    const printRef = useRef();
    const [house, setHouse] = useState<undefined | number>(undefined);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [dataSource, setDataSource] = useState<API.warehouseResponseDataCheckItem[]>([]);
    const [dataItem, setDataItem] = useState<API.warehouseResponseDataCheckItem>({
        code: '',
        create_time: '',
        inventory_date: '',
        inventory_loss_product: [],
        inventory_profit_product: [],
        is_valid: false,
        object_id: 0,
        object_name: '',
        remarks: '',
        status: 0,
        storage_id: 0,
        storage_name: '',
        update_time: '',
        user_id: 0,
        user_name: '',
    });
    const columns: ColumnsType<API.warehouseResponseDataCheckItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '5%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        {
            title: '盘点单号', dataIndex: 'code', align: 'center', ellipsis: true,
            render: (num: string, record) => <div
                className={'warehouseQueryHasAuthority'}
                onClick={() => {
                    setDataItem(record)
                    setShowDetailModal(true)
                }}
            >
                {num}
            </div>
        },
        {title: '仓库名称', dataIndex: 'storage_name', align: 'center', ellipsis: true},
        {
            title: '盘点日期', dataIndex: 'inventory_date', align: 'center', ellipsis: true,
            render: (date) => <div className={'hiddenStyle'}>{date === null || date === '' ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {title: '盘点人', dataIndex: 'user_name', align: 'center', ellipsis: true},
        {
            title: '盘盈产品', dataIndex: 'inventory_profit_product', align: 'left', ellipsis: true,
            render: (products) => <div className={'hiddenStyle'}>{products.join('、')}</div>
        },
        {
            title: '盘亏产品', dataIndex: 'inventory_loss_product', align: 'left', ellipsis: true,
            render: (products) => <div className={'hiddenStyle'}>{products.join('、')}</div>
        },
        {title: '备注', dataIndex: 'remarks', align: 'left', ellipsis: true},
        {
            title: '状态', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (status) => <div
                style={{color: status === 10 ? '#EB4420' : status === 20 ? '#06C270' : '#ffffff'}}>{status === 10 ? '未提交' : status === 20 ? '已提交' : ''}</div>
        },
        {
            title: '操作', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (status: number, record) => {
                return status === 10 ? <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div className={'warehouseQueryHasAuthority'}
                         onClick={() => {
                             setDataItem(record)
                             setShowEditModal(true)
                         }}>
                        编辑
                    </div>
                    <div className={'warehouseQueryHasAuthority'} style={{marginLeft: 20}}
                         onClick={() => {
                             setDataItem(record)
                             setShowDeleteModal(true)
                         }}>
                        删除
                    </div>
                </div> : null
            }
        },
    ]
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showChooseModal, setShowChooseModal] = useState(false);
    const [printHouse, setPrintHouse] = useState<any>(undefined);
    const [showPrintModal, setShowPrintModal] = useState(false);

    useEffect(() => {
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        houseValue: number | undefined,
        startTime?: any,
        endTime?: any,
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.houseValue) {
                param = {...param, storage_id: data.houseValue}
            }
            if (data.startTime && data.startTime !== '') {
                param = {...param, start_date: moment(data.startTime).format('YYYY-MM-DD')}
            }
            if (data.endTime && data.endTime !== '') {
                param = {...param, end_date: moment(data.endTime).format('YYYY-MM-DD')}
            }
        } else {
            if (house) {
                param = {...param, storage_id: house}
            }
            if (startTime && startTime !== '') {
                param = {...param, start_date: moment(startTime).format('YYYY-MM-DD')}
            }
            if (endTime && endTime !== '') {
                param = {...param, end_date: moment(endTime).format('YYYY-MM-DD')}
            }
        }
        getWarehouseCheck(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleShowTotal = (total: number) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{display: "flex", marginLeft: 0, color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    const deleteInfo = () => {
        deleteCheck({object_id: dataItem!.object_id}).then((res) => {
            if (res.code === 200) {
                setShowDeleteModal(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const addOrEditOrder = (type: string, data: {
        objectId?: number,
        checkNum: string,
        houseId: any,
        inventoryDate: string,
        userId?: number,
        remark: string,
        checkList: Array<any>,
        submitType: number,
    }) => {
        let param: any = {
            storage_id: data.houseId,
            inventory_date: moment(data.inventoryDate).format('YYYY-MM-DD'),
            submit_type: data.submitType,
            user_id: userInfo.useInfo.object_id
        }
        if (type === 'edit') {
            param = {...param, object_id: data.objectId}
        }
        if (data.checkNum !== '') {
            param = {...param, code: data.checkNum}
        }
        if (data.checkList.length > 0) {
            let newList: any[] = []
            data.checkList.map((item) => {
                newList.push({
                    product_id: item.product_id,
                    inventory_quantity: item.inventory_quantity,
                    real_quantity: item.real_quantity,
                    inventory_profit: item.inventory_profit,
                    inventory_loss: item.inventory_loss,
                    reasons: item.reasons,
                })
            })
            param = {...param, inventory_count_details: JSON.stringify(newList)}
        }
        if (data.remark !== '') {
            param = {...param, remarks: data.remark}
        }
        if (type === 'new') {
            newCheck(param).then((res) => {
                if (res.code === 200) {
                    setShowNewModal(false)
                    if (10 === data.submitType) {
                        toastSuccessMessage('保存成功')
                    } else {
                        toastSuccessMessage('提交成功')
                    }
                    onFinish(currentPage, pageSize)
                } else {
                    toastErrorMessage(res.message)
                }
            }).catch((e: any) => {
                toastErrorMessage(e.message)
            })
        } else {
            updateCheck(param).then((res) => {
                if (res.code === 200) {
                    setShowEditModal(false)
                    if (10 === data.submitType) {
                        toastSuccessMessage('保存成功')
                    } else {
                        toastSuccessMessage('提交成功')
                    }
                    onFinish(currentPage, pageSize)
                } else {
                    toastErrorMessage(res.message)
                }
            }).catch((e: any) => {
                toastErrorMessage(e.message)
            })
        }
    }

    return (
        <div>
            <SelectFormCheck
                queryClick={(houseValue: number | undefined, startTime: any, endTime: any) => {
                    setHouse(houseValue)
                    setStartTime(startTime)
                    setEndTime(endTime)
                    onFinish(1, pageSize, {houseValue: houseValue, startTime: startTime, endTime: endTime})
                }}
                newAdd={() => {
                    setShowNewModal(true)
                }}
                printClick={() => {
                    setShowChooseModal(true)
                }}
            />
            <Table
                className={'warehouseQueryTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
            />

            {
                showNewModal && <CheckNewOrEditModal
                    type={'new'}
                    userInfo={userInfo}
                    closeClick={() => {
                        setShowNewModal(false)
                    }}
                    saveClick={(checkNum: string, house: any, checkDate: string, remark: string, checkList: Array<any>) => {
                        addOrEditOrder('new', {
                            checkNum: checkNum,
                            houseId: house,
                            inventoryDate: checkDate,
                            userId: userInfo.useInfo.object_id,
                            remark: remark,
                            checkList: checkList,
                            submitType: 10,
                        })
                    }}
                    commitClick={(checkNum: string, house: any, checkDate: string, remark: string, checkList: Array<any>) => {
                        addOrEditOrder('new', {
                            checkNum: checkNum,
                            houseId: house,
                            inventoryDate: checkDate,
                            userId: userInfo.useInfo.object_id,
                            remark: remark,
                            checkList: checkList,
                            submitType: 20,
                        })
                    }}
                />
            }

            {
                showEditModal && <CheckNewOrEditModal
                    type={'edit'}
                    userInfo={userInfo}
                    dataItem={dataItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(checkNum: string, house: any, checkDate: string, remark: string, checkList: Array<any>, objectId: number | undefined) => {
                        addOrEditOrder('edit', {
                            objectId: objectId,
                            checkNum: checkNum,
                            houseId: house,
                            inventoryDate: checkDate,
                            userId: userInfo.useInfo.object_id,
                            remark: remark,
                            checkList: checkList,
                            submitType: 10,
                        })
                    }}
                    commitClick={(checkNum: string, house: any, checkDate: string, remark: string, checkList: Array<any>, objectId: number | undefined) => {
                        addOrEditOrder('edit', {
                            objectId: objectId,
                            checkNum: checkNum,
                            houseId: house,
                            inventoryDate: checkDate,
                            userId: userInfo.useInfo.object_id,
                            remark: remark,
                            checkList: checkList,
                            submitType: 20,
                        })
                    }}
                />
            }

            {
                showDetailModal && <CheckDetailModal
                    dataItemId={dataItem!.object_id}
                    closeClick={() => {
                        setShowDetailModal(false)
                    }}
                />
            }

            {
                showChooseModal && <ChooseHouseModal
                    confirmClick={(house: any) => {
                        setShowChooseModal(false)
                        setPrintHouse(house)
                        setShowPrintModal(true)
                    }}
                    closeClick={() => {
                        setShowChooseModal(false)
                    }}
                />
            }

            {
                showPrintModal && <CheckPrintModal
                    dataItem={printHouse}
                    printRef={printRef}
                    closeClick={() => {
                        setShowPrintModal(false)
                    }}
                    printClick={() => {
                        if (printRef.current) {
                            Print(printRef.current)
                        }
                        setShowPrintModal(false)
                    }}
                />
            }

            {
                showDeleteModal && <DeleteModal
                    closeClick={() => {
                        setShowDeleteModal(false)
                    }}
                    confirmClick={() => {
                        deleteInfo()
                    }}
                />
            }
        </div>)
}

export default WarehouseCheck
