import React, {useContext, useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Table} from "antd";
import SelectFormReport from "../components/selectFormReport";
import ReportModal from "../components/reportModal";
import {
    finishOrderRequest,
    getProductDetailRequest,
    reportListRequest,
    startOrderRequest
} from "../../../service";
import moment from "moment";
import CheckModal from "../../Basic/Production/Modal/checkModal";
import {UserContext} from "../../../contexts/userContext";
import {toastErrorMessage, toastSuccessMessage} from "../../../utils";

/**
 * 生产报工
 */
const Report = (): React.ReactElement => {
    const userInfo = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.queryItem[]>([]);
    const [dataItem, setDataItem] = useState<API.queryItem>();
    const [isHasProduct, setIsHasProduct] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowProduct, setIsShowProduct] = useState(false);
    const [productInfo, setProductInfo] = useState<API.productItem>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        product_name: '',
        product_unit_id: 0,
        remark: '',
        specification: '',
        unit_code: '',
        unit_name: '',
        update_time: ''
    });
    const [taskStatus, setTaskStatus] = useState<Array<string>>(['30', '40']);
    const [taskNumber, setTaskNumber] = useState('');
    const [productName, setProductName] = useState('');

    const columns: ColumnsType<API.queryItem> = [
        {title: '任务单号', dataIndex: ['task', 'task_number'], align: 'center', ellipsis: true},
        {
            title: '产品', dataIndex: ['task', 'product_name'], align: 'center', ellipsis: true,
            render: (product: string, record) => <div
                className={isHasProduct ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasProduct) {
                        getDetailProductInfo(record.task.product_id)
                    }
                }}
            >
                {product}
            </div>
        },
        {
            title: '订单数量',
            dataIndex: ['task', 'order_quantity'],
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '单位', dataIndex: ['task', 'unit_name'], align: 'center', ellipsis: true},
        {title: '产线', dataIndex: ['task', 'line_info'], align: 'center', ellipsis: true},
        {title: '工序', dataIndex: 'process_name', align: 'center', ellipsis: true},
        {
            title: '任务数量',
            dataIndex: ['task', 'task_quantity'],
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {
            title: '计划开始时间', dataIndex: ['task', 'start_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '计划完成时间', dataIndex: ['task', 'end_date'], align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '实际开始时间', dataIndex: 'start_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '实际完成时间', dataIndex: 'end_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '合格数量',
            dataIndex: 'qualified',
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {
            title: '不良数量',
            dataIndex: 'defective',
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '不良原因', dataIndex: 'defect_reason', align: 'center', ellipsis: true},
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 30 ? '#3E7BFA' : status === 40 ? '#06C270' : status === 50 ? '#0053FF' : '#666666'}}>
                {
                    status === 30 ? '已派工' : status === 40 ? '处理中' : status === 50 ? '已完成' : ''
                }
            </div>
        },
        {
            title: '操作',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render: (operate: number, data: API.queryItem) => <div
                className={'hasAuthority'}
                onClick={() => {
                    if (operate === 30) {
                        startOrderRequest({id: data.object_id + ''}).then((res) => {
                            if (res.code === 200) {
                                onFinish(currentPage, pageSize)
                            } else {
                                toastErrorMessage(res.message)
                            }
                        }).catch((e: any) => {
                            toastErrorMessage(e.message)
                        })
                    } else if (operate === 40) {
                        setDataItem(data)
                        setSubmit(true)
                    }
                }}
            >
                {
                    operate === 30 ? '开工' : operate === 40 ? '完工' : operate === 50 ? '' : ''
                }
            </div>
        },
    ]

    useEffect(() => {
        userInfo.useInfo.authRoutes?.map((item) => {
            if (item === '/basic/production') {
                setIsHasProduct(true)
            }
        })
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        taskStatus: Array<string>,
        taskNum: string,
        productName: string
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page: page, per_page: size}
        if (data) {
            if (data.taskStatus.length > 0) {
                param = {...param, status: JSON.stringify(data.taskStatus)}
            }
            if (data.taskNum !== '') {
                param = {...param, task_number: data.taskNum}
            }
            if (data.productName !== '') {
                param = {...param, product: data.productName}
            }
        } else {
            if (taskStatus.length > 0) {
                param = {...param, status: JSON.stringify(taskStatus)}
            }
            if (taskNumber !== '') {
                param = {...param, task_number: taskNumber}
            }
            if (productName !== '') {
                param = {...param, product: productName}
            }
        }
        reportListRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getDetailProductInfo = (objectId: number) => {
        getProductDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowProduct(true)
                setProductInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const finishOrder = (data: {
        id: number,
        good: number,
        bad: number,
        reason: undefined | number
    }) => {
        let param: any = {id: data.id, qualified: data.good, defective: data.bad}
        if (data.reason) {
            param = {...param, defect_reason_id: data.reason}
        }
        finishOrderRequest(param).then((res) => {
            if (res.code === 200) {
                setSubmit(false)
                toastSuccessMessage('报工成功')
                onFinish(currentPage, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    return (
        <div>
            <SelectFormReport
                titles={['任务状态', '任务单号', '产品名称']}
                queryClick={(status: Array<string>, taskOrderNum: string, productionName: string) => {
                    setTaskStatus(status)
                    setTaskNumber(taskOrderNum)
                    setProductName(productionName)
                    onFinish(1, pageSize, {taskStatus: status, taskNum: taskOrderNum, productName: productionName})
                }}
            />
            <Table
                className={'commonTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                scroll={{x: 3000}}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: onFinish,
                    total: total,
                    showQuickJumper: true,
                    showTotal: handleShowTotal
                }}
            />
            {
                submit && <ReportModal
                    dataItem={dataItem}
                    closeClick={() => setSubmit(false)}
                    saveClick={(id: number, goodNum: number, badNum: number, reason: undefined | number) => {
                        finishOrder({id: id, good: goodNum, bad: badNum, reason: reason})
                    }}
                />
            }

            {
                isShowProduct && <CheckModal
                    productItem={productInfo}
                    closeClick={() => {
                        setIsShowProduct(false)
                    }}
                />
            }
        </div>
    )
}
export default Report
