import React, { useEffect, useState } from "react";
import BusinessmanMenu from "./businessmanMenu";
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../globalLess/global.css'
import { Menu } from 'antd'
import AddModal from "./Modal/addModal";
import EditModal from "./Modal/editModal";
import CheckModal from "./Modal/checkModal";
import { getBusinessmanInfo, BusinessmanUpdate, BusinessmanDelete } from "../../../service/index";
import {toastErrorMessage, toastSuccessMessage, toastMessage} from "../../../utils";
import DeleteModal from "../../Production/components/deleteModal";
import moment from "moment";
const ExportJsonExcel = require('js-export-excel')

const Businessman = (): React.ReactElement => {
    const columns: ColumnsType<API.Businessman_ResponseInfo> = [
        {
            title: '客商代码',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '客商名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '类别',
            dataIndex: 'type',
            width: 200,
            ellipsis: true,
            render: (type: number) => <div>{type === 10 ? '客户' : type === 20 ? '供应商' : type === 30 ? '客商' : ''}</div>
        },
        {
            title: '联系人',
            dataIndex: 'link_name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '联系方式',
            dataIndex: 'contact',
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 200,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'object_id',
            render: (object_id: string, data: API.Businessman_ResponseInfo) =>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setBusinessmanItem(data)
                            setShowCheckModal(true)
                        }}
                    >
                        查看
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer",
                            marginRight: 20
                        }}
                        onClick={() => {
                            setBusinessmanItem(data)
                            setShowEditModal(true)
                        }}
                    >
                        编辑
                    </div>
                    <div
                        style={{
                            color: '#3E7BFA',
                            textDecoration: 'underline',
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            data.object_id && setDeleteObjectString([data.object_id])
                            setIsShowDelete(true)
                        }}
                    >
                        删除
                    </div>
                </div>
        }
    ];
    const [BusinessmanItem, setBusinessmanItem] = useState<API.Businessman_ResponseInfo>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        remark: '',
        update_time: '',
        link_name: '',
        contact: '',
        name: '',
        type: 0,
    });
    let [name, setName] = useState('');
    let [type, setType] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [dataSource, setDataSource] = useState<API.Businessman_ResponseInfo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCheckModal, setShowCheckModal] = useState(false);
    const [isShowDelete, setIsShowDelete] = useState(false);
    const [isShowMoreDelete, setIsShowMoreDelete] = useState(false);
    const [deleteObjectString, setDeleteObjectString] = useState<number[]>([]);

    const queryBusinessman = () => {
        onFinish(1, pageSize, {
            name: name,
            type: type
        })
    }
 
    const BatchDelete = () => {
        if (selectedRowKeys.length > 0) {
            const num = selectedRowKeys.length
            let objArray = []
            for (let index = num - 1; index >= 0; index--) {
                let indexItem: any = selectedRowKeys[index]
                indexItem = indexItem- (currentPage-1)* pageSize

                const item = dataSource[indexItem]
                objArray.push(item.object_id)
            }
            BusinessmanItemDelete(objArray)
            setSelectedRowKeys([])
        } else {
            return
        }
    }
    const BusinessmanItemDelete = (object_ids: number[]) => {
        let objString: any;
        for (let index = 0; index < object_ids.length; index++) {
            if (index === 0) {
                objString = object_ids[index]
            } else {
                objString += ',' + object_ids[index]
            }
        }
        BusinessmanDelete({ object_ids: objString }).then((res) => {
            if (res.code === 200) {
                setIsShowDelete(false)
                setIsShowMoreDelete(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const addBusinessmanItem = (newItem: API.Businessman_Update) => {
        BusinessmanUpdate(newItem).then((res) => {
            if (res.code === 200) {
                setShowAddModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('保存成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const editBusinessmanItem = (newItem: API.Businessman_Update) => {
        BusinessmanUpdate(newItem).then((res) => {
            if (res.code === 200) {
                setShowEditModal(false)
                onFinish(currentPage, pageSize)
                toastSuccessMessage('编辑成功')
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((err) => {
            toastErrorMessage(err.message)
        })
    }
    const exportFile = ( )=>{
        let dict: any = { page_num: 1, page_size: total }
        if (name) {
            dict.name = name
        }
        if (type) {
            dict.type = type
        }
        setIsLoading(true)
        getBusinessmanInfo(dict).then((res: any) => {
            if (res.code === 200) {
                let dataSourceCopy: any = res.data.data
                if (dataSourceCopy.length > 0) {
                    let option: { fileName?: string, datas?: any[] } = {};
                    let dataTable: any = [];
                    dataSourceCopy.forEach((item: any) => {
                        dataTable.push({
                            code: item.code,
                            name: item.name,
                            type: item.type,
                            link_name: item.link_name,
                            contact: item.contact,
                            remark: item.remark,
                        })
                    })
                    option.fileName = '客商信息' + moment(new Date()).format('YYYYMMDD')
                    option.datas = [
                        {
                            sheetData: dataTable,
                            sheetName: 'sheet',
                            sheetFilter: ['code', 'name', 'type', 'link_name', 'contact', 'remark'],
                            sheetHeader: ['客商代码', '客商名称', '类别', '联系人', '联系方式', '备注'],
                        }
                    ];
                    let toExcel = new ExportJsonExcel(option);
                    toExcel.saveExcel();
                }
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err: any) => {
            toastErrorMessage(err.message)
            setIsLoading(false)
        })
    };
    const onFinish = (page: number, size: number, data?: {
        name?: string;
        type?: number
    }) => {
        let dict: any = { page_num: page, page_size: size }
        if (data && data.name) {
            dict.name = data.name
        }
        if (data && data.type) {
            dict.type = data.type
        }
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        getBusinessmanInfo(dict).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                let dataCopy = []
                for (let index = 0; index < res.data.data.length; index++) {
                    let item = res.data.data[index]
                    item.key = index + (page - 1) * size
                    dataCopy.push(item)
                }
                setDataSource(dataCopy)
            } else {
                toastErrorMessage(res.message)
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
        })
    }
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    useEffect(() => {
        onFinish(1, 10);
    }, [])
    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{ flex: 1, display: "flex", color: '#999999' }}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div className="ProductionTableStyle">
                <BusinessmanMenu productionNum={name} productionName={type === 10 ? '客户' : type === 20 ? '供应商' : type === 30 ? '客商' : ''}
                    codeOnChange={(num: string) => {
                        setName(num)
                    }}
                    typeInfo={<Menu items={[{ label: '客户', key: '1' }, { label: '供应商', key: '2' }, { label: '客商', key: '3' }]}
                        onClick={(e) => {
                            const typeCopy = e.key === '1' ? 10 : e.key === '2' ? 20 : 30
                            setType(typeCopy)
                        }}
                    />}
                    queryClick={() => {
                        queryBusinessman()
                    }}
                    resetClick={() => {
                        setName('')
                        setType(0)
                        onFinish(1, pageSize);
                    }}
                    addClick={() => {
                        setShowAddModal(true)
                    }}
                    deleteClick={() => {
                        if (selectedRowKeys.length > 0) {
                            setIsShowMoreDelete(true)
                        } else {
                            toastMessage('请选择要删除的客商数据')
                        }
                    }}
                    exportClick={() => {
                        exportFile()
                    }}
                />
            </div>
            <div >
                <Table
                    className={'commonTable'}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{x: 1600}}
                    pagination={{
                        position: ['bottomRight'],
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        current: currentPage,
                        pageSize: pageSize,
                        showSizeChanger: true,
                        total: total,
                        showQuickJumper: true,
                        onChange: onFinish,
                        showTotal: handleShowTotal
                    }}
                />
            </div>
            {
                showCheckModal && <CheckModal
                    BusinessmanItem={BusinessmanItem}
                    closeClick={() => {
                        setShowCheckModal(false)
                    }}
                />
            }
            {
                showAddModal && <AddModal
                    closeClick={() => {
                        setShowAddModal(false)
                    }}
                    saveClick={(e) => {
                        addBusinessmanItem(e)
                    }}
                />
            }
            {
                showEditModal && <EditModal
                    BusinessmanItem={BusinessmanItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(e) => {
                        editBusinessmanItem(e)
                    }}
                />
            }
             {
                isShowDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowDelete(false)
                    }}
                    confirmClick={() => {
                        BusinessmanItemDelete(deleteObjectString)
                    }}
                />
            }
            {
                isShowMoreDelete && <DeleteModal
                    closeClick={() => {
                        setIsShowMoreDelete(false)
                        setSelectedRowKeys([])
                    }}
                    confirmClick={() => {
                        BatchDelete()
                    }}
                />
            }
        </div>
    )
}
export default Businessman
