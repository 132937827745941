import { useState, forwardRef, useImperativeHandle, useEffect} from "react";
import { Radio, Select, message } from "antd";
import type { RadioChangeEvent } from "antd";
export interface IOptions {
    label: string;
    value: string | number;
}
export interface SRef {
    handleSetPlan: Function,
}
export interface IProps {
    cronStr:string | null
}
export interface IMonthData {
    radio:undefined | number,
    selectMonth:number[]
}
export interface IDayData {
    radio:undefined | number,
    selectDays:number[],
    selectWeeks:number[],
    selectFirstWeek:number[],
    selectLastWeek:number[]
}
export interface IHourData {
    radio:undefined | number,
    selectHour:number[]
}

// 星期几选择框数据
const weekOptions: IOptions[] = [
    {value: 0, label: "星期一"},
    {value: 1, label: "星期二"},
    {value: 2, label: "星期三"},
    {value: 3, label: "星期四"},
    {value: 4, label: "星期五"},
    {value: 5, label: "星期六"},
    {value: 6, label: "星期日"},
]

// 天数选择框数据
const getDayOptions = (radio: any, selectMonth: number[]) => {
    let arr = [];
    let days: number = 0;
    if (radio === 1) {
        // 如果选择的是每月，则按最少天数的二月展示
        days = 28;
    } else if (radio === 2 && selectMonth.indexOf(2) !== -1) {
        // 如果选择的是具体月数，而且具体月数包括2月，返回28
        days = 28;
    } else if (radio === 2 && (
        // 如果选择的是具体月数，而且具体月数包括4,6,9,11月，返回30
        selectMonth.indexOf(4) !== -1 || selectMonth.indexOf(6) !== -1 ||
        selectMonth.indexOf(9) !== -1 || selectMonth.indexOf(11) !== -1
    )) {
        days = 30;
    } else {
        days = 31;
    }
    for (let i = 1; i <= days; i++) {
        arr.push({
            label: i,
            value: i
        })
    }
    return arr;
};

// 月份或者小时选择框数据
const getHourOrMonthOptions = (type: string) => {
    let arr = [];
    if (type === "month") {
        for (let i = 1; i <= 12; i++) {
            arr.push({
                label: i,
                value: i
            })
        }
    }
    if (type === "hour") {
        for (let i = 0; i <= 23; i++) {
            arr.push({
                label: i,
                value: i
            })
        }
    }
    return arr;
}

// 生成描述
const generateDescription = (monthData: IMonthData, dayData: IDayData, hourData: IHourData) => {
    const {radio: radioMonth, selectMonth} = monthData;
    const {radio: radioDay, selectDays, selectFirstWeek, selectLastWeek, selectWeeks} = dayData;
    const {radio: radioHour, selectHour} = hourData;
    let monthDescription: string = '';
    let dayDescription: string = '';
    let hourDescription: string = '';
    // 生成月份的描述
    radioMonth === 1 ? monthDescription = "每月" : monthDescription = getMonthDesc(selectMonth.sort((a, b) => a - b));
    // 生成日期的描述

    radioDay === 1 ? dayDescription = "每天" : radioDay === 4 ? dayDescription = "第一天" :
        radioDay === 5 ? dayDescription = "第一个工作日" : radioDay === 7 ? dayDescription = "最后一天" :
            radioDay === 8 ? dayDescription = "最后一个工作日" : radioDay === 2 ?
                dayDescription = getDayDesc(selectDays.sort((a, b) => a - b)) : dayDescription = "";
    if (radioDay === 3) {
        dayDescription = getWeekDesc(selectWeeks.sort((a, b) => a - b));
    }
    if (radioDay === 6) {
        dayDescription = `第一个${weekOptions[selectFirstWeek[0]].label}`
    }
    if (radioDay === 9) {
        dayDescription = `最后一个${weekOptions[selectLastWeek[0]].label}`
    }
    // 生成小时的描述

    radioHour === 1 ? hourDescription = "每小时" : hourDescription = getHourDesc(selectHour.sort((a, b) => a - b));

    return `在${monthDescription}的${dayDescription}的${hourDescription}执行`
}

// 月，日，小时转换成cron表达式
const parseDateToCron = (monthData: IMonthData, dayData: IDayData, hourData: IHourData) => {
    const {radio: radioMonth, selectMonth} = monthData;
    const {radio: radioDay, selectDays, selectFirstWeek, selectLastWeek, selectWeeks} = dayData;
    const {radio: radioHour, selectHour} = hourData;
    let cronExpression: string = "";
    let monthCron: string;
    let dayCron: string;
    let weekCron: string;
    let hourCron: string;
    // 生成月份Cron
    radioMonth === 1 ? monthCron = "*" : radioMonth === 2 ? monthCron = selectMonth.sort((a, b) => a - b).join(',') : monthCron = "";
    // 生成日期Cron
    radioDay === 1 ? dayCron = "*" : radioDay === 4 ? dayCron = "1" :
        radioDay === 5 ? dayCron = "1W" : radioDay === 7 ? dayCron = "L" :
            radioDay === 8 ? dayCron = "LW" : radioDay === 2 ? dayCron = selectDays.sort((a, b) => a - b).join(',') : dayCron = "";
    // 生成星期Cron
    radioDay === 3 ? weekCron = selectWeeks.sort((a, b) => a - b).join(',') : radioDay === 6 ? weekCron = `1#${selectFirstWeek[0]}` : radioDay === 9 ? weekCron = `${selectLastWeek[0]}L` : weekCron = "";
    // 生成小时Cron
    radioHour === 1 ? hourCron = "8-18" : radioHour === 2 ? hourCron = selectHour.sort((a, b) => a - b).join(',') : hourCron = ""
    // 生成表达式
    // 如果选择了日期，那么星期选择栏置成？，意为不指定，避免冲突,年份选择栏可不写
    if (dayCron) {
        cronExpression = `0 ${hourCron} ${dayCron} ${monthCron} *`
    }
    if (weekCron) {
        cronExpression = `0 ${hourCron} * ${monthCron} ${weekCron}`
    }
    return cronExpression;
}


// 把cron表达式转化成可进行初始化的数据
const parseCronToInitData = (cronStr:string) => {
    let cronArr = cronStr.split(' ');
    let monthData:IMonthData = {radio:undefined,selectMonth:[]};
    let dayData:IDayData = {radio:undefined,selectDays:[],selectFirstWeek:[],selectLastWeek:[],selectWeeks:[]};
    let hourData:IHourData = {radio:undefined,selectHour:[]};
    // 月份初始化数据
    if(cronArr[3] === "*") {
        monthData.radio = 1
    } else {
        monthData = {
            radio:2,
            selectMonth: cronArr[3].split(',').map(x => Number(x))
        }
    }

    // 日期初始化数据
    (cronArr[2] === '*' && cronArr[4] === '*') ? dayData.radio = 1 : Number(cronArr[2]) === 1 ? dayData.radio = 4 : cronArr[2] === "1W" ?
    dayData.radio = 5 : cronArr[2] === 'L' ?  dayData.radio = 7 : cronArr[2] === 'LW' ? dayData.radio = 8 :cronArr[4] === '*' ?
    dayData = {...dayData, radio:2,selectDays:cronArr[2].split(',').map(x => Number(x))} : dayData = {...dayData}

    // 星期初始化数据
    if(cronArr[4].includes('1#')) {
        dayData = {
            ...dayData,
            radio:6,
            selectFirstWeek: cronArr[4].split('').map((x,index) => index === 2 && [Number(x)])[2] as number[]
        }
    } else if(cronArr[4].includes('L')) {
        dayData = {
            ...dayData,
            radio:9,
            selectLastWeek: cronArr[4].split('').map((x,index) => index === 0 && [Number(x)])[0] as number[]
        }
    } else if(cronArr[4] !== '*') {
        dayData = {
            ...dayData,
            radio:3,
            selectWeeks: cronArr[4].split(',').map(x => Number(x))
        }
    }

    // 初始化小时数据
    cronArr[1] === '8-18' ? hourData.radio = 1 : hourData = {radio:2,selectHour: cronArr[1].split(',').map(x => Number(x))}
    return {
        monthData,
        dayData,
        hourData
    }
    
}


// 将cron表达式反解析
// export const parseCronToDate = (cronStr: string) => {
//     let cronArr = cronStr.split(' ');
//     console.log("cronArr", cronArr);
//     let desc: string = "";
//     let hourDesc: string = "";
//     let dayDesc: string | undefined;
//     let monthDesc: string = "";
//     let weekDesc: string | undefined;
//     // 转换小时
//     cronArr[2] === "8-18" ? hourDesc = "每小时" : hourDesc = getHourDesc(cronArr[2].split(','));

//     // 转换日期
//     cronArr[3] === "*" ? dayDesc = "每天" : cronArr[3] === "1" ? dayDesc = "第一天" :
//         cronArr[3] === "1W" ? dayDesc = "第一个工作日" : cronArr[3] === "L" ? dayDesc = "最后一天" :
//             cronArr[3] === "LW" ? dayDesc = "最后一个工作日" : cronArr[3] === "?" ? dayDesc = undefined : dayDesc = getDayDesc(cronArr[3].split(','));
//     // 转换月份
//     cronArr[4] === "*" ? monthDesc = "每月" : monthDesc = getMonthDesc(cronArr[4].split(','));

//     // 转换星期
//     if (cronArr[5].split('').indexOf("#") !== -1) {
//         console.log("包含#号")
//         // 包含#说明时第1个星期几 1#m
//         weekDesc = `第一个${weekOptions[parseInt(cronArr[5].split('')[2]) - 1].label}`
//     } else if (cronArr[5].split('').indexOf("L") !== -1) {
//         // 包含L时最后一个星期几 nL
//         weekDesc = `最后一个${weekOptions[parseInt(cronArr[5].split('')[0]) - 1].label}`
//     } else if (cronArr[5] === "?") {
//         weekDesc = undefined;
//     } else {
//         weekDesc = getWeekDesc(cronArr[5].split(','))
//     }
//     // 生成描述
//     if (dayDesc) {
//         desc = `在${monthDesc}的${dayDesc}的${hourDesc}执行`
//     }
//     if (weekDesc) {
//         desc = `在${monthDesc}的${weekDesc}的${hourDesc}执行`
//     }
//     return desc;
// }


// 生成日期描述
const getDayDesc = (days: Array<string | number>) => {
    let dayD: string = "";
    for (let i = 0; i <= days.length - 1; i++) {
        if (i === days.length - 1) {
            dayD += `${days[i]}号`
        } else {
            dayD += `${days[i]}号、`
        }
    }
    return dayD;
}
// 生成月份描述
const getMonthDesc = (months: Array<string | number>) => {
    let monthD: string = ""
    for (let i = 0; i <= months.length - 1; i++) {
        if (i === months.length - 1) {
            monthD += `${months[i]}月`
        } else {
            monthD += `${months[i]}月、`
        }
    }
    return monthD;
}
// 生成小时描述
const getHourDesc = (hours: Array<string | number>) => {
    let hourD: string = "";
    for (let i = 0; i <= hours.length - 1; i++) {
        if (parseInt(hours[i] + "") < 10) {
            if (i === hours.length - 1) {
                hourD += `0${hours[i]}:00`
            } else {
                hourD += `0${hours[i]}:00、`
            }
        } else {
            if (i === hours.length - 1) {
                hourD += `${hours[i]}:00`
            } else {
                hourD += `${hours[i]}:00、`
            }
        }
    }
    return hourD;
}
// 生成周描述
const getWeekDesc = (weeks: Array<string | number>) => {
    let weekD: string = "每个";
    for (let i = 0; i <= weeks.length - 1; i++) {
        if (i === weeks.length - 1) {
            weekD += `${weekOptions[parseInt(weeks[i] + "")].label}`
        } else {
            weekD += `${weekOptions[parseInt(weeks[i] + "")].label}、`
        }
    }
    return weekD;
}

const SetPlan = forwardRef<SRef,IProps>((props,ref) => {
    const [selectTab, setSelectTab] = useState<string>("月");
    const [monthData,setMonthData] = useState<IMonthData>({radio:undefined,selectMonth:[]});
    const [dayData,setDayData] = useState<IDayData>({radio:undefined,selectDays:[],selectWeeks:[],selectFirstWeek:[],selectLastWeek:[]});
    const [hourData,setHourData] = useState<IHourData>({radio:undefined,selectHour:[]});

    useEffect(()=> {
        if(props.cronStr) {
            const initData = parseCronToInitData(props.cronStr);
            setMonthData(initData.monthData);
            setDayData(initData.dayData);
            setHourData(initData.hourData);
        }
    },[])

    // 通过forwardRef和useImperativeHandle的配合使用，把子组件的部分属性或者函数透传给父组件使用
    useImperativeHandle(ref, () => ({
        handleSetPlan
    }));
    const handleSetPlan = () => {
        // 点击执行计划之后需要校验radio和selectData,对于没有selectData的项，选择radio即可，对于有selectData的项，需要radio和selectData一并选择
        if(!monthData.radio || !dayData.radio || !hourData.radio
            || (monthData.radio === 2 && monthData.selectMonth.length === 0)
            || (dayData.radio === 2 && dayData.selectDays.length === 0)
            || (dayData.radio === 3 && dayData.selectWeeks.length === 0)
            || (dayData.radio === 6 && dayData.selectFirstWeek.length === 0)
            || (dayData.radio === 9 && dayData.selectLastWeek.length === 0)
            || (hourData.radio === 2 && hourData.selectHour.length === 0)) {
                message.warning("请先选择完各项再保存")
                return null
            }
        let description = generateDescription(monthData,dayData,hourData);
        // 把当前选择的数据转换成cron表达式
        let cronExpression = parseDateToCron(monthData,dayData,hourData);
        return {
            description,
            cronExpression
        }
    }
    const handleRadioChange = (type:string, e: RadioChangeEvent) => {
        if(type === "month") {
            setMonthData({radio:e.target.value,selectMonth:[]});
            // 重新选择月份后，清空日，小时选择的内容
            setDayData({radio:undefined,selectDays:[],selectWeeks:[],selectFirstWeek:[],selectLastWeek:[]});
            setHourData({radio:undefined,selectHour:[]});
        }
        type === "day" && setDayData({radio:e.target.value,selectDays:[],selectWeeks:[],selectFirstWeek:[],selectLastWeek:[]})
        type === "hour" && setHourData({radio:e.target.value,selectHour:[]});
    };
    const handleSelectChange = (type: string, value: any, matchRadioValue?:any) => {
        if(monthData.radio && monthData.radio === 2 && type === "month") {
            setMonthData({...monthData,selectMonth:value}); 
            // 重新选择月份后，清空日，小时选择的内容
            setDayData({radio:undefined,selectDays:[],selectWeeks:[],selectFirstWeek:[],selectLastWeek:[]});
            setHourData({radio:undefined,selectHour:[]});  
        }
        (!monthData.radio || monthData.radio === 1) && type === "month" && message.warning("请先切换到具体月数按钮再选择")
        // 当选择的是日期的tab栏时，将触发onChange事件的select框对应的radio的value值传递过来，
        // 若是value值与dayData中存储的radio的value值一致,将选中的select保存到selectDayOrWeek中
        if(type === "day") {
            if(matchRadioValue && matchRadioValue === dayData.radio) {
                matchRadioValue === 2 && setDayData({...dayData,selectDays:value});
                matchRadioValue === 3 && setDayData({...dayData,selectWeeks:value});
                matchRadioValue === 6 && setDayData({...dayData,selectFirstWeek:[value]})
                matchRadioValue === 9 && setDayData({...dayData,selectLastWeek:[value]});
                return
            }
            message.warning("请先切换到对应的单选按钮再选择")
        }
        hourData.radio && hourData.radio === 2 && type === "hour" && setHourData({...hourData,selectHour:value});
        (!hourData.radio || hourData.radio === 1)&& type === "hour" && message.warning("请先切换到具体小时数按钮再选择");
    }
    const determineDisabledTab = (type:string) => {
        if(type === "day") {
            if(monthData.radio === 1 || (monthData.radio === 2 && monthData.selectMonth.length !== 0)){
                return ""
            }
            return "item-bg"
        }
        if(type === "hour") {
          if(monthData.radio === 1 || (monthData.radio === 2 && monthData.selectMonth.length !== 0)){
            if(dayData.radio === 1 || dayData.radio === 4 || dayData.radio === 5 || dayData.radio === 7 || dayData.radio === 8
                || (dayData.radio === 2 && dayData.selectDays.length !== 0)
                || (dayData.radio === 3 && dayData.selectWeeks.length !== 0)
                || (dayData.radio === 6 && dayData.selectFirstWeek.length !== 0)
                || (dayData.radio === 9 && dayData.selectLastWeek.length !== 0)) {
                return ""
            }
            return "item-bg"
          }
            return "item-bg"
        }
        return ""
    }
    return (
        <div className="set-plan">
            <div className="set-plan-top">
                <div className={selectTab === "月" ? "set-plan-top-item-active" : "set-plan-top-item"} onClick={() => setSelectTab("月")}>月</div>
                <div className={`${selectTab === "日"? "set-plan-top-item-active": "set-plan-top-item"} ${determineDisabledTab("day")}`} onClick={() => {
                    if(determineDisabledTab('day') !== "item-bg") {
                        setSelectTab("日");
                        return;
                    }
                    message.warning("请按照月，日，小时，依次选择");
                }}>日</div>
                <div className={`${selectTab === "小时"? "set-plan-top-item-active": "set-plan-top-item"} ${determineDisabledTab("hour")}`} onClick={()=>{
                    if(determineDisabledTab('hour') !== "item-bg") {
                        setSelectTab("小时");
                        return;
                    }
                    message.warning("请按照月，日，小时，依次选择");
                }}>小时</div>
            </div>
            <div className="set-plan-center"></div>
            <div className="set-plan-bottom">
                {selectTab === "月" && (
                    <Radio.Group onChange={(e)=>handleRadioChange("month",e)} value={monthData.radio}>
                        <Radio value={1} className="radio">
                            每月
                        </Radio>
                        <div className="box">
                            <Radio value={2}>具体月数（可多选）</Radio>
                            <Select
                                value={monthData.selectMonth}
                                onChange={(value)=>handleSelectChange("month",value)}
                                placeholder="请选择月份"
                                mode="multiple"
                                allowClear
                                options={getHourOrMonthOptions('month')}
                            />
                        </div>
                    </Radio.Group>
                )}
                {selectTab === "日" && monthData.radio === 1 && (
                    <Radio.Group onChange={(e)=>handleRadioChange("day",e)} value={dayData.radio}>
                        <Radio value={1} className="radio">
                            每日
                        </Radio>
                        <div className="box">
                            <Radio value={2}>具体天数（可多选）</Radio>
                            <Select
                                value={dayData.selectDays}
                                onChange={(value)=>handleSelectChange("day",value,2)}
                                placeholder="请选择天数"
                                mode="multiple"
                                allowClear
                                options={getDayOptions(monthData.radio,monthData.selectMonth)}
                            />
                        </div>
                        <div className="box">
                            <Radio value={3}>具体星期几（可多选）</Radio>
                            <Select
                                value={dayData.selectWeeks}
                                mode="multiple"
                                onChange={(value)=>handleSelectChange("day",value,3)}
                                placeholder="请选择星期几"
                                allowClear
                                options={weekOptions}
                            />
                        </div>
                        <Radio value={4} className="radio">第一天</Radio>
                        <div className="box">
                          <Radio value={5} >第一个工作日</Radio>
                        </div>
                        <div className="box">
                            <Radio value={6}>第一个</Radio>
                            <Select
                                value={dayData.selectFirstWeek}
                                onChange={(value)=>handleSelectChange("day",value,6)}
                                placeholder="请选择星期几"
                                allowClear
                                options={weekOptions}
                            />
                        </div>
                        <Radio value={7} className="radio">最后一天</Radio>
                        <div className="box">
                          <Radio value={8}>最后一个工作日</Radio>
                        </div>
                        <div className="box">
                            <Radio value={9}>最后一个</Radio>
                            <Select
                                value={dayData.selectLastWeek}
                                onChange={(value)=>handleSelectChange("day",value,9)}
                                placeholder="请选择星期几"  
                                allowClear
                                options={weekOptions}
                            />
                        </div>
                    </Radio.Group>
                )}
                {selectTab === "日" && monthData.radio === 2 && (
                    <Radio.Group onChange={(e)=>handleRadioChange("day",e)} value={dayData.radio}>
                        <Radio value={1} className="radio">
                            每日
                        </Radio>
                        <div className="box">
                            <Radio value={2}>具体天数（可多选）</Radio>
                            <Select
                                value={dayData.selectDays}
                                onChange={(value)=>handleSelectChange("day",value,2)}
                                placeholder="请选择天数"
                                mode="multiple"
                                allowClear
                                options={getDayOptions(monthData.radio,monthData.selectMonth)}
                            />
                        </div>
                        <Radio value={4} className="radio">第一天</Radio>
                        <div className="box">
                          <Radio value={5} >第一个工作日</Radio>
                        </div>
                        <Radio value={7} className="radio">最后一天</Radio>
                        <div className="box">
                          <Radio value={8}>最后一个工作日</Radio>
                        </div>
                    </Radio.Group>
                )}
                 {selectTab === "小时" && (
                    <Radio.Group onChange={(e)=>handleRadioChange("hour",e)} value={hourData.radio}>
                        <Radio value={1} className="radio"> 每小时（8:00-18:00）</Radio>
                        <div className="box">
                            <Radio value={2}>具体小时数（可多选）</Radio>
                            <Select
                                value={hourData.selectHour}
                                onChange={(value)=>handleSelectChange("hour",value)}
                                placeholder="请选择小时"
                                mode="multiple"
                                allowClear
                                options={getHourOrMonthOptions('hour')}
                            />
                        </div>
                    </Radio.Group>
                )}
            </div>
        </div>
    );
});
export default SetPlan;
