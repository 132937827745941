import React from 'react'
import { Modal } from "antd";
import Input from '../../../../components/input'
import Dropdown from '../../../../components/dropdown'
import Button from '../../../../components/button'
import { Menu } from 'antd'


export default class CheckModal extends React.Component<{
    BusinessmanItem: API.Businessman_ResponseInfo
    closeClick: () => void,
}> {
    state = {
        code: '',
        name: '',
        type: '',
        link_name: '',
        contact: '',
        remark: ''
    }
   componentDidMount(){
    const {
        BusinessmanItem,
    } = this.props
    this.setState({
        code: BusinessmanItem.code,
        name: BusinessmanItem.name,
        type: BusinessmanItem.type === 10 ? '客商类别' : BusinessmanItem.type === 20 ? '供应商' : '客商',
        link_name: BusinessmanItem.link_name,
        contact: BusinessmanItem.contact,
        remark: BusinessmanItem.remark
    })
    
} 

    render() {
        const {
            closeClick,
        } = this.props
        const {
            code,
        name,
        type,
        link_name,
        contact,
        remark
        } = this.state
      
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'查看客商信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='客商代码' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    value={code}
                    disabled={true}
                />

            </div>

            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='客商名称' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }}
                    value={name}
                />

            </div>

            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Dropdown title='客商类别' require={1} disabled={true} titleStyle={{ marginRight: 10 }} dropdownButtonStyle={{ width: 281 }} defaultValue={type} overlay={<Menu items={[{ label: '选项1', key: '1' }, { label: '选项2', key: '2' }, { label: '选项3', key: '3' }]}
                    onClick={() => {
                    }} />} />

            </div>
            <div style={{ marginLeft: 12, marginTop: 20 }}>
                <Input title='联系人' disabled={true} titlestyle={{ width: 60, marginRight: 18 }} style={{ width: 281, marginRight: 20 }}
                    value={link_name}
                />

            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='联系方式' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }}
                    value={contact}
                />

            </div>

            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注' disabled={true} remark titlestyle={{ paddingLeft: 2, width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }}
                    value={remark}
                />

            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100 }} onClick={() => {
                    closeClick()
                }} />
            </div>
        </Modal>
    }

}