import React, {useCallback, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, Options} from "../../../components/GeneralManagement";
import {Button, Form, message, Space} from "antd";
import {getExceptionAssign, getExceptionQueryList} from "../../../service";
import {
    EXCHandleStatus,
    filterData,
    getCategoryList,
    getFormData,
    showError,
} from "../../../utils";
import ModalContainer from "../../../components/Modal";
import moment from "moment";
import ExceptionDetail from "../components/ExceptionDetail";
import HandleOrAssignDetail from "../components/HandleOrAssignDetail";
import {ColumnsType} from "antd/es/table";

export enum ModalTitle {
    VIEW = '异常详情',
    ASSIGN = '指派处理人'
}

/**
 * 异常指派
 */
const EXCAssignment = () => {
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState<Options[]>([]);
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const formList = [
        {
            key: 'exception_type_ids',
            name: '异常类别',
            placeholder: '请选择异常类别',
            options: categoryList,
            multiple: true
        },
        {
            key: 'status',
            name: '状态',
            placeholder: '请选择异常状态',
            options: EXCHandleStatus(),
            multiple: true,
            defaultValue: [20]
        },
        {
            key: 'date',
            name: '提报时间',
            placeholder: '请选择日期范围',
            date: true
        }
    ]

    const columns: ColumnsType<any>  = [

        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '异常单号',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '产线',
            dataIndex: 'line_name',
            key: 'line_name',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '工序',
            dataIndex: 'work_process_name',
            key: 'work_process_name',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '异常类别',
            dataIndex: 'exception_type',
            key: 'exception_type',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '异常代码',
            dataIndex: 'exception_code',
            key: 'exception_code',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '现象描述',
            dataIndex: 'phenomenal_description',
            key: 'phenomenal_description',
            align: 'center',
            width: 330,
            ellipsis: true
        },
        {
            title: '提报人',
            dataIndex: 'reporting_user',
            key: 'reporting_user',
            align: 'center',
            width: 150,
            ellipsis: true
        },
        {
            title: '提报时间',
            dataIndex: 'reporting_time',
            key: 'reporting_time',
            align: 'center',
            ellipsis: true,
            width: 180,
            render: (value: string) => {
                if (value) {
                    return <span>{moment(value).format('YYYY/MM/DD HH:mm')}</span>
                } else {
                    return <span/>
                }
            }
        },
        {
            title: '处理人',
            dataIndex: 'handle_user',
            key: 'handle_user',
            align: 'center',
            ellipsis: true,
            width: 180,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            ellipsis: true,
            width: 150,
            render: (value: number) => {
                return <span
                    style={(value === 20 ? {color: '#06C270'} : ((value === 30 ? {color: '#FF8800'} : ((value === 40 ? {color: '#3E7BFA'} : {})))))}>
                    {
                        EXCHandleStatus().map(e => {
                            if (e.id === value) {
                                return <div>{e.name}</div>
                            } else {
                                return <div></div>
                            }
                        })
                    }
                </span>
            }
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'left',
            ellipsis: true,
            width: 200,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.VIEW, item)
                        }}>查看</span>
                        {
                            item.status === 20 &&
                            <span className={'mes-options'} onClick={() => {
                                openModal(ModalTitle.ASSIGN, item)
                            }}>指派</span>
                        }
                    </Space>
                )
            }
        },
    ]

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.VIEW) {
            setInnerComponent(<ExceptionDetail objectId={item.object_id}/>)
        } else if (title === ModalTitle.ASSIGN) {
            setInnerComponent(<HandleOrAssignDetail form={form} objectId={item.object_id}/>)
        }
        setOpen(true);
    }

    const onOK = () => {
        form.validateFields().then(values => {
            const query = {
                object_id: selectedItem.object_id,
                user_id: values.handleMan
            }
            const formData = getFormData(filterData(query))
            getExceptionAssign(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('指派成功！');
                } else {
                    showError(res.message);
                }
            })
        })
    }

    const onCancel = () => {
        form.resetFields();
        setSelectedItem(null);
        setModalTitle(ModalTitle.VIEW);
        setInnerComponent(undefined);;
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getExceptionQueryList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const getFooter = (): React.ReactNode => {
        if (modalTitle === ModalTitle.VIEW) {
            return (<div>
                <Button onClick={() => onCancel()}>关闭</Button>
            </div>)
        }
    }

    useEffect(() => {
        getCategoryList().then((res: any) => {
            const temp: any[] = [];
            res.map((e: any) => {
                temp.push({
                    id: e.object_id,
                    name: e.exception_type_describe
                })
            })
            setCategoryList(temp)
        });
    }, [])

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<div/>}
                               getDataAsync={getDataAsync} ref={gRef} scroll={true}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open} okText={'保存'}
                                onOk={() => onOK()} onCancel={() => onCancel()}
                                width={'855px'} footer={getFooter()} innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default EXCAssignment


