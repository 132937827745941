import React, {useCallback, useEffect, useRef, useState} from "react";
import GeneralManagement, {GRef, QueryListItem} from "../../components/GeneralManagement";
import {Button, Input, Select, Form, message, Space} from "antd";
import {filterData, getFormData, showError} from "../../utils";
import {
    createStorage, deleteStorage,
    getStorageById, getStorageList, getUserList,
    updateStorage,
} from "../../service";
import DeleteItem from "../../components/DeleteItem";
import ModalContainer from "../../components/Modal";
import './index.less';
import {ColumnsType} from "antd/es/table";

enum ModalTitle {
    ADD = '新增仓库信息',
    EDIT = '编辑仓库信息',
    DELETE = '系统确认',
}

//仓库信息
const WarehouseInfo = () => {
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactElement | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const formList: QueryListItem[] = [
        {
            key: 'code',
            name: '仓库代码',
            placeholder: '请输入仓库代码'
        },
        {
            key: 'storage_name',
            name: '仓库名称',
            placeholder: '请输入仓库名称'
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: 'NO',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: '仓库代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '仓库名称',
            dataIndex: 'storage_name',
            key: 'name',
            align: 'center',
            ellipsis: true,
            render: (_: string) => <div className={'remark-left'}>{_}</div>
        },
        {
            title: '库管员',
            dataIndex: 'name',
            key: 'person',
            align: 'center',
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            ellipsis: true,
            width: 300,
            render: (_: string) => <div className={'remark-left'}>{_}</div>
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            width: 300,
            render: (_: any, record: any) => {
                return (
                    <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, record)
                        }}>编辑</span>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, record)
                        }}>删除</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEdit = React.memo((): React.ReactElement => {
        const [personList, setPersonList] = useState<any[]>([]);

        useEffect(() => {
            getUserList().then(res => {
                if (res.code === 200) {
                    if (res.data.data) {
                        setPersonList(res.data.data)
                    }
                } else {
                    showError(res.message);
                }
            })
        }, [])

        return (
            <Form colon={false} form={form} className={'form-margin'}>
                <Form.Item label={'仓库代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '仓库代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'仓库名称'} name={'storage_name'} rules={[
                    {required: true, message: '请输入仓库名称!'},
                    {
                        type: "string",
                        max: 32,
                        message: '仓库名称为不得大于32位！'
                    }
                ]}>
                    <Input placeholder={'请输入仓库名称'}/>
                </Form.Item>
                <Form.Item label={'库管员'} name={'storage_user_id'} rules={[
                    {required: true, message: '请选择人员!'},
                ]}>
                    <Select placeholder={'请选择人员'}>
                        {
                            personList.map(e => <Select.Option key={e.object_id}
                                                               value={e.object_id}>{e.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item label={'备注'} name={'remark'} rules={[
                    {max: 200, message: '备注不得大于200位！'}
                ]}>
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const ExtraComponent = React.memo((): React.ReactElement => (
        <div>
            <Button className={'mes-add'} style={{width: 70}} onClick={() => {
                openModal(ModalTitle.ADD, null);
            }}>添加仓库</Button>
        </div>
    ))

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEdit/>)
        } else if (title === ModalTitle.EDIT) {
            getStorageById({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        form.setFieldsValue({
                            code: res.data.code,
                            storage_name: res.data.storage_name,
                            storage_user_id: res.data.storage_user_id,
                            remark: res.data.remark,
                        })
                        setInnerComponent(<AddOrEdit/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createStorage(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                updateStorage(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteStorage(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message);
                }
            })
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);
        setOpen(false);
    }

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getStorageList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    const afterOnOK = (text: string) => {
        message.success(text);
        setSelectedItem(null);
        refreshData();
        setOpen(false);
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                width={(modalTitle === ModalTitle.DELETE) ? '20%' : '688px'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default WarehouseInfo
