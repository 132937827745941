//选择故障经验
import {ColumnsType} from "antd/es/table";
import {Col, Row} from "antd";
import GeneralManagement from "../../../components/GeneralManagement";
import React from "react";
import {getFaultExperienceListByDeviceId, getMaintenanceExperienceListByDeviceId} from "../../../service";
import {showError} from "../../../utils";

interface ModalInnerProps {
    setSelectedRows: (selectedRows: any[]) => void,
    setSelectedRowKeys: (selectedRowKeys: any[]) => void,
    selectedRowKeys: any[],
    //故障经验库 or  维修经验库
    type: 'trouble' | 'repair',
    deviceId?: number,
    deviceCode?: number,
    deviceName?: string
}

const ModalInner = (props: ModalInnerProps) => {
    const {setSelectedRows, setSelectedRowKeys, selectedRowKeys, type, deviceId, deviceCode, deviceName} = props;

    const formList = [
        {
            key: 'experience_name',
            name: type === 'trouble' ? '故障经验名称' : '维修经验名称',
            placeholder: type === 'trouble' ? '请输入故障经验名称' : '请输入维修经验名称',
        }
    ]

    const columns: ColumnsType<any> = [
        {
            title: '序号',
            dataIndex: 'NO',
            key: 'NO',
            align: 'center',
            width: 80,
            render: (text: string, record?: any, index?: number) =>
                <span>{typeof index === "number" ? index + 1 : null}</span>
        },
        {
            title: type === 'trouble' ? '故障经验代码' : '维修经验代码',
            dataIndex: 'code',
            key: 'code',
            width: 200,
            align: 'center',
        },
        {
            title: type === 'trouble' ? '故障经验名称' : '维修经验名称',
            dataIndex: 'experience_name',
            key: 'experience_name',
            width: 200,
            align: 'center',
        },
        {
            title: type === 'trouble' ? '故障描述' : '维修描述',
            dataIndex: type === 'trouble' ? 'fault_describe' : 'maintenance_describe',
            key: type === 'trouble' ? 'fault_describe' : 'maintenance_describe',
            align: 'center',
        },
    ]

    const getDataAsync = (queryCondition: API.FaultExperienceListByDeviceIdParams) => {
        return new Promise((resolve) => {
            if (type === 'trouble') {
                getFaultExperienceListByDeviceId({...queryCondition, device_id: deviceId}).then((res) => {
                    if (res.code === 200) {
                        if (res.data) {
                            resolve(res.data)
                        }
                    } else {
                        showError(res.message)
                    }
                })
            } else {
                getMaintenanceExperienceListByDeviceId({...queryCondition, device_id: deviceId}).then((res) => {
                    if (res.code === 200) {
                        if (res.data) {
                            resolve(res.data)
                        }
                    } else {
                        showError(res.message)
                    }
                })
            }
        })
    }


    return (
        <div>
            <Row style={{marginBottom: 15, marginLeft: 15}}>
                <Col span={12}>
                    <span className={'modal-inner-label'}>设备代码:</span>
                    <span>{deviceCode ? deviceCode : ''}</span>
                </Col>
                <Col span={12}>
                    <span className={'modal-inner-label'}>设备名称:</span>
                    <span>{deviceName ? deviceName : ''}</span>
                </Col>
            </Row>
            <GeneralManagement formList={formList} columns={columns}
                               showSelection={true} selectionType={'radio'} setSelectedRows={setSelectedRows}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}
                               getDataAsync={getDataAsync}/>
        </div>
    )
}

export default ModalInner
