import React from 'react'
import { Modal, message } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'


export default class EditModal extends React.Component<{
    procedureItem: API.ProcedureAddItem
    closeClick: () => void,
    saveClick: (e: API.ProcedureAddItem) => void,
}> {
    state = {
        code: '',
        work_process_name: '',
        remark: '',
    }
    componentDidMount() {
        const {
            procedureItem,
        } = this.props
        this.setState({
            code: procedureItem.code,
            work_process_name: procedureItem.work_process_name,
            remark: procedureItem.remark
        })
    }
    render() {
        const {
            code,
            work_process_name,
            remark,
        } = this.state
        const { closeClick, saveClick, procedureItem } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'编辑工序信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='工序代码' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    placeholder=''
                    value={code}
                    onChange={(value) => {
                        this.setState({
                            code: value
                        })
                    }}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1} title='工序名称' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }} placeholder='请输入工序名称' onChange={(value) => {
                    this.setState({
                        work_process_name: value
                    })
                }}
                    value={work_process_name}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注'remark titlestyle={{ paddingLeft: 2, width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入备注' onChange={(value) => {
                    this.setState({
                        remark: value
                    })
                }}
                    value={remark}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!work_process_name) {
                        message.info('工序名称不能为空')
                        return
                    }
                    let value: API.ProcedureAddItem = {
                        object_id: procedureItem.object_id,
                    }
                    if (work_process_name !== procedureItem.work_process_name) {
                        value.work_process_name = work_process_name
                    }
                    if (remark) {
                        value.remark = remark
                    }
                    saveClick(value)
                }} />
            </div>
        </Modal>
    }
}