import React from 'react'
import { Modal, Table, Select, Input as AInput } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { getAllProcedureInfo, } from "../../../../service/index";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { toastMessage } from "../../../../utils";

// eslint-disable-next-line jsx-a11y/alt-text
const DragHandle = SortableHandle(() => <img style={{ cursor: "pointer", width: 25, height: 25 }}
    src={require('../../../../assets/icon_move.png')} />);
const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
))
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
))
interface selectData {
    key: string;
    value: string;
    label: string;
    process_name: string;
    work_process_name: string;
    remark: string;
    object_id: number;
    craft_process_mark: string;
}
export default class EditModal extends React.Component<{
    WorkmanshipData: API.Workmanship_ResponseInfo | undefined
    isHasProcess: boolean,
    navigate: NavigateFunction,
    closeClick: () => void
    saveClick: (value: API.Workmanship_Update) => void,
}> {
    private addModalBody: any = React.createRef() || undefined
    state = {
        dataSource: [],
        code: '',
        work_craft_name: '',
        remark: '',
        processColumns: [],
        selectDataArray: [],
    }
    onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(this.state.dataSource.slice(), oldIndex, newIndex).filter(
                (el: any) => !!el,
            );
            newData.map((item: any, index) => {
                item.key = `${index + 1}`
                item.no = index + 1
            })
            this.setState({
                dataSource: newData
            })
        }
    };
    handleAdd() {
        const newItem: any = {
            key: `${this.state.dataSource.length}`,
            no: this.state.dataSource.length,
            process_name: '',
            remark: '',
            process_info: {
                code: '',
                craft_process_remark: '',
                work_process_name: '',
                object_id: 0,
            }
        }
        const newData = [...this.state.dataSource, newItem]
        newData.map((item, index) => {
            item.key = `${index + 1}`
            item.no = index + 1
        })
        this.setState({
            dataSource: newData
        })
    };
    draggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            helperContainer={this.addModalBody.current}
            {...props}
        />
    )
    // @ts-ignore
    draggableBodyRow = ({ className, style, ...restProps }) => {
        const index = this.state.dataSource.findIndex((x: any) => x.key === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    }
    async getProcedureInfo() {
        const {
            isHasProcess,
            navigate,
        } = this.props
        await getAllProcedureInfo({ page_num: 1 }).then((res) => {
            if (res.code === 200) {
                const dataArray = res.data.data
                if (dataArray.length > 0) {
                    let selectDataArrayCopy: selectData[] = []
                    for (let index = 0; index < dataArray.length; index++) {
                        const item = dataArray[index]
                        selectDataArrayCopy.push({
                            key: `${index + 1}`,
                            value: item.object_id.toString(),
                            label: item.process_name,
                            work_process_name: item.work_process_name,
                            process_name: item.process_name,
                            remark: item.remark,
                            object_id: item.object_id,
                            craft_process_mark: '',
                        })
                    }
                    this.setState({
                        selectDataArray: selectDataArrayCopy,
                        processColumns: [
                            { title: '', dataIndex: 'sort', align: 'center', width: '8%', render: () => <DragHandle /> },
                            {
                                title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
                                render: (text: any, record: any, index: number) => <div>{index + 1}</div>
                            }, {
                                title: () => {
                                    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ color: "red", marginLeft: 45 }}>*</div>
                                        <div style={{
                                            color: isHasProcess ? '#3E7BFA' : '#333333',
                                            textDecoration: isHasProcess ? "underline" : "none",
                                            cursor: isHasProcess ? "pointer" : "auto",
                                            marginLeft: 5,
                                        }} onClick={() => {
                                            if (isHasProcess) {
                                                navigate('/basic/procedure')
                                            }
                                        }}>
                                            工序
                                        </div>
                                    </div>
                                }, dataIndex: ['process_info', 'object_id'], align: 'center', ellipsis: true, width: '20%',
                                render: (text: any, data: API.Workmanship_Children, No: number) => 
                                <Select
                                style={{ width: 120 }}
                                value={text===0? null: `${text}`}
                                placeholder='请选择工序'
                                options={selectDataArrayCopy}
                                onChange={(value: string) => {
                                    this.selectProcess(value, No)
                                }}
                            />
                                // <Dropdown title='' dropdownButtonStyle={{ width: 104, marginLeft: -50 }}
                                //     defaultValue={data.process_info.work_process_name} overlay={
                                //         <Menu
                                //             style={{ overflow: 'scroll', maxHeight: 200, width: 104 }}
                                //             items={selectDataArrayCopy}
                                //             onClick={(e) => {
                                //                 this.selectProcess(e.key, No)
                                //             }} />

                                //     } />
                            },
                            {
                                title: '备注', dataIndex: ['process_info', 'craft_process_remark'], align: 'center', ellipsis: true,
                                render: (remark: string, record: { key: React.Key }) => <AInput
                                    style={{ height: 30, flex: 1, display: "flex", borderRadius: 5 }}
                                    placeholder="请输入备注"
                                    value={remark}
                                    onChange={(newValue) => {
                                        const newList = this.state.dataSource.map((item: any) => {
                                            if (item.key === record.key) {

                                                return {
                                                    ...item,
                                                    process_info: { ...item.process_info, craft_process_remark: newValue.target.value }
                                                }
                                            } else {
                                                return item
                                            }
                                        })

                                        this.setState({
                                            dataSource: newList
                                        })
                                    }}
                                />
                            },
                            {
                                title: '操作',
                                dataIndex: 'operate',
                                ellipsis: true,
                                width: '8%',
                                render: (text: string, record: { key: React.Key }) =>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div
                                            style={{
                                                color: '#3E7BFA',
                                                textDecoration: 'underline',
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                this.handleDelete(record.key)
                                            }}
                                        >
                                            删除
                                        </div>
                                    </div>
                            }
                        ]
                    })
                }
            }
        }).catch((err) => {
        })
    }
    handleDelete(keyString: React.Key) {
        const newData = this.state.dataSource.filter((item: any) => item.key !== keyString);
        newData.map((item: any, index) => {
            item.key = index
            item.no = index + 1
        })
        this.setState({
            dataSource: newData
        })
    };
    selectProcess(keyString: string, No: number) {
        const {
            dataSource,
            selectDataArray
        } = this.state

        let selectDataItem: any = selectDataArray.filter((e: selectData) => e.value === keyString)[0]
        const dataArray = JSON.parse(JSON.stringify(dataSource))
        let dataItem = dataArray[No]
        dataItem.process_info.work_process_name = selectDataItem.process_name
        dataItem.process_info.object_id = selectDataItem.object_id
        dataArray[No] = dataItem
        this.setState({
            dataSource: dataArray
        })
    }
    componentDidMount() {
        const {
            WorkmanshipData
        } = this.props
        this.setState({
            dataSource: WorkmanshipData?.process,
            code: WorkmanshipData?.code,
            work_craft_name: WorkmanshipData?.work_craft_name,
            remark: WorkmanshipData?.remark,
        }, () => {
            this.getProcedureInfo()
        })
    }
    render() {
        const {
            code,
            work_craft_name,
            remark,
            processColumns,
            dataSource,
        } = this.state
        const { closeClick, saveClick, WorkmanshipData } = this.props
        return <Modal
            // @ts-ignore
            ref={(e: any) => {
                this.addModalBody = e;
            }}
            style={{ background: "transparent", borderRadius: 5 }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick()}
                />
            }
            destroyOnClose
            title={'编辑工艺路线'}
            footer={null}
            width={855}
            centered
            open={true}
        >
            <div style={{ display: 'flex', flexDirection: "row", }}>
                <div style={{ marginLeft: 20 }}>
                    <Input title='代码' style={{ width: 281 }}
                        placeholder='不填写，系统将自动生成'
                        value={code}
                        disabled={true}
                    />
                </div>
                <div style={{ marginLeft: 80 }}>
                    <Input title='名称' require={1} style={{ width: 281, marginRight: 20, marginLeft: 0 }}
                        placeholder='请输入工艺路线名称'
                        value={work_craft_name}
                        onChange={(value) => {
                            this.setState({
                                work_craft_name: value
                            })
                        }}
                    />
                </div>
            </div>
            <div style={{ marginLeft: 23, marginTop: 15 }}>
                <Input title='备注' titlestyle={{ paddingLeft: 2, textAlign: 'left' }} style={{ width: 705, marginRight: 20 }}
                    value={remark}
                    remark
                    placeholder='请输入备注'
                    onChange={(value) => {
                        this.setState({
                            remark: value
                        })
                    }}
                />
            </div>
            <Table
                title={() => <div style={{ display: 'flex', flexDirection: "row", }}>
                    <div style={{ flex: 1, fontWeight: "bold", color: '#333333', marginLeft: 10 }}>工序列表</div>

                    <Button title='新增' titleStyle={{ color: '#FFF' }} buttonStyle={{ width: 48, backgroundColor: '#5DA198', marginRight: 10 }} onClick={() => {
                        this.handleAdd()
                    }} />
                </div>}
                style={{ marginTop: 20, marginLeft: 15, marginRight: 15 }}
                bordered
                className={'childTable'}
                dataSource={this.state.dataSource}
                columns={processColumns}
                size={'small'}
                scroll={{ y: 200 }}
                pagination={false}
                components={{
                    body: {
                        wrapper: this.draggableContainer,
                        row: this.draggableBodyRow,
                    }
                }}
            />

            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!work_craft_name) {
                        toastMessage('工艺名称不能为空')
                        return
                    }
                    let itemArray: any = []
                    if (dataSource.length > 0) {
                        let isReturn = false
                        dataSource.forEach((item: any) => {
                            if (!item.process_info.object_id) {
                                isReturn = true
                            } else {
                                if (item.process_info.craft_process_id
                                ) {
                                    itemArray.push({
                                        process_id: item.process_info.object_id,
                                        craft_process_mark: item.process_info.craft_process_remark,
                                        craft_process_id: item.process_info.craft_process_id
                                    })
                                } else {
                                    itemArray.push({
                                        process_id: item.process_info.object_id,
                                        craft_process_mark: item.process_info.craft_process_remark
                                    })
                                }
                            }
                        })
                        if (isReturn) {
                            toastMessage('请选择工序')
                            return
                        }
                    }
                    console.log('=dataSourcedataSource>>>>', dataSource);

                    if (WorkmanshipData) {
                        let value: API.Workmanship_Update = {
                            craft_id: WorkmanshipData.object_id,
                        }
                        if (work_craft_name !== WorkmanshipData.work_craft_name) {
                            value.craft_name = work_craft_name
                        }
                        if (remark) {
                            value.craft_remark = remark
                        }
                        if (itemArray.length > 0) {
                            value.process_info = itemArray
                        }
                        saveClick(value)
                    }
                }} />
            </div>
        </Modal>
    }
}
