import React from 'react'
import { Modal } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import { toastMessage } from "../../../../utils";

export default class EditModal extends React.Component<{
    companyItem: API.addCompanyItem
    closeClick: () => void,
    saveClick: (value: API.addCompanyItem) => void,
}> {
    state = {
        company_code: '',
        company_name: '',
        remark: '',
    }
    componentDidMount() {
        const {
            companyItem,
        } = this.props
        this.setState({
            company_code: companyItem.code,
            company_name: companyItem.unit_name,
            remark: companyItem.remark,
        })
    }
    render() {
        const {
            company_code,
            company_name,
            remark,
        } = this.state
        const { closeClick, companyItem, saveClick } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'编辑单位信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input title='单位代码' require={1} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    placeholder='请输入单位代码'
                    value={company_code}
                    onChange={(value) => {
                        if (value === '') {
                            this.setState({
                                company_code: value
                            })
                        } else {
                            if (/^[A-Za-z0-9]+$/.test(value)) {
                                this.setState({
                                    company_code: value
                                })
                            }
                        }
                    }}
                />
            </div>
            <div style={{ marginLeft: 3, marginTop: 20 }}>
                <Input require={1} title='单位名称' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }} placeholder='请输入单位名称' onChange={(value) => {
                    this.setState({
                        company_name: value
                    })
                }}
                    value={company_name}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注' remark titlestyle={{ paddingLeft: 2, width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }} placeholder='请输入备注' onChange={(value) => {
                    this.setState({
                        remark: value
                    })
                }}
                    value={remark}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100, backgroundColor: '#B8B8B8', marginRight: 10 }} onClick={() => {
                    closeClick()
                }} />
                <Button title='保存' buttonStyle={{ width: 100 }} onClick={() => {
                    if (!company_code) {
                        toastMessage('单位代码不能为空')
                        return
                    }
                    if (!company_name) {
                        toastMessage('单位名称不能为空')
                        return
                    }
                    let value: API.addCompanyItem = {
                        object_id: companyItem.object_id,
                    }
                    if (company_code !== companyItem.code) {
                        value.code = company_code
                    }
                    if (company_name !== companyItem.unit_name) {
                        value.unit_name = company_name
                    }
                    if (remark) {
                        value.remark = remark
                    }
                    saveClick(value)
                }} />
            </div>
        </Modal>
    }
}