import React from 'react'
import { Button, Modal, Table, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import Input from '../../../../components/input'
import '../../../../globalLess/global.css'

export default class CheckTaskModal extends React.Component<{
    WorkmanshipData: API.Workmanship_ResponseInfo | undefined,
    closeClick?: () => void
}> {
    state = {
        dataSource: [],
        code: '',
        work_craft_name: '',
        remark: ''
    }
    private checkTaskColumns: ColumnsType<API.Workmanship_Children> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
            render: (text: any, record: any, index: number) => <div>{index + 1}</div>
        },
        {
            title: '工序', dataIndex: ['process_info','work_process_name'], align: 'center', ellipsis: true,
            render: (text: any, data: API.Workmanship_Children) =>
            <Select
            style={{ width: 120 }}
            value={text}
            placeholder='请选择工序'
            disabled={true}
        />
            //  <Dropdown title='' disabled={true} dropdownButtonStyle={{ width: 104, marginLeft: 50 }} 
            // defaultValue={data.process_info.work_process_name} overlay={<Menu items={[{ label: '选项1', key: '1' }, { label: '选项2', key: '2' }, { label: '选项3', key: '3' }]}
            //     onClick={() => {
            //     }} />} />
        },
        { title: '备注', dataIndex: ['process_info','craft_process_remark'], align: 'center', ellipsis: true },
    ]
    componentDidMount() {
        const { WorkmanshipData } = this.props
        if (WorkmanshipData) {
            console.log("=>>>>",WorkmanshipData);
            
            this.setState({
                dataSource: WorkmanshipData.process,
                code: WorkmanshipData.code,
                work_craft_name: WorkmanshipData.work_craft_name,
                remark: WorkmanshipData.remark,
            })
        }
    }
    render() {
        const {
            dataSource,
            code,
            work_craft_name,
            remark,
        } = this.state
        const { closeClick } = this.props
        return <Modal
            style={{ background: "transparent", borderRadius: 5 }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={'查看工艺路线'}
            footer={null}
            width={855}
            centered
            open={true}
        >
            <div style={{ display: 'flex', flexDirection: "row", }}>
                <div style={{ marginLeft: 20 }}>
                    <Input title='代码' style={{ width: 281 }}
                        value={code}
                        disabled={true}
                    />
                </div>
                <div style={{ marginLeft: 80 }}>
                    <Input title='名称' require={1} disabled={true} style={{ width: 281, marginRight: 20, marginLeft: 0 }}
                        value={work_craft_name}
                    />
                </div>
            </div>
            <div style={{ marginLeft: 23, marginTop: 15 }}>
                <Input title='备注' disabled={true}  remark titlestyle={{ paddingLeft: 2, textAlign: 'left' }} style={{ width: 705, marginRight: 20 }}
                    value={remark}
                />
            </div>
            <Table
                title={() => <div style={{ fontWeight: "bold", color: '#333333', marginLeft: 10 }}>工序列表</div>}
                style={{ marginTop: 20, marginLeft: 15, marginRight: 15 }}
                bordered
                className={'childTable'}
                dataSource={dataSource}
                columns={this.checkTaskColumns}
                size={'small'}
                scroll={{ y: 200 }}
                pagination={false}
            />
            <div style={{ display: "flex", marginTop: 20, justifyContent: "center" }}>
                <Button style={{ width: 100, height: 35, borderRadius: 5 }}
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                        closeClick && closeClick()
                    }}
                >
                    关闭
                </Button>
            </div>
        </Modal>
    }
}
