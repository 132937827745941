import React, {useContext, useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Table} from "antd";
import SelectFormTask from "../components/selectFormTask";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import TaskModal from "../components/taskModal";
import CheckTaskModal from "../components/checkTaskModal";
import DeleteModal from "../components/deleteModal";
import {UserContext} from "../../../contexts/userContext";
import CheckOrderModal from "../../OrderManage/components/checkOrderModal";
import {
    deleteTaskRequest,
    getClientDetailRequest,
    getOrderDetailRequest,
    getProductDetailRequest,
    getTaskListRequest,
    issueTaskRequest,
    newTaskRequest,
    recallTaskRequest
} from "../../../service";
import CheckModal from "../../Basic/Production/Modal/checkModal";
import BusinessmanCheckModal from "../../Basic/Businessman/Modal/checkModal";
import {toastErrorMessage, toastSuccessMessage} from "../../../utils";

/**
 * 生产任务
 */
const ExportJsonExcel = require('js-export-excel')

const Task = (): React.ReactElement => {
    const userInfo = useContext(UserContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.queryChildItem[]>([]);
    const [isHasSell, setIsHasSell] = useState(false);
    const [isHasCustomer, setIsHasCustomer] = useState(false);
    const [isHasProduct, setIsHasProduct] = useState(false);
    const [isHasLine, setIsHasLine] = useState(false);
    const [isHasProcessLine, setIsHasProcessLine] = useState(false);
    const [isHasProcess, setIsHasProcess] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCheckModal, setShowCheckModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [dataItem, setDataItem] = useState<API.queryChildItem>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isShowSellOrder, setIsShowSellOrder] = useState(false);
    const [sellOrderInfo, setSellOrderInfo] = useState<API.orderItem>();
    const [isShowClient, setIsShowClient] = useState(false);
    const [clientInfo, setClientInfo] = useState<API.Businessman_ResponseInfo>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        remark: '',
        update_time: '',
        link_name: '',
        contact: '',
        name: '',
        type: 0,
    });
    const [isShowProduct, setIsShowProduct] = useState(false);
    const [productInfo, setProductInfo] = useState<API.productItem>({
        code: '',
        create_time: '',
        is_valid: true,
        object_id: 0,
        object_name: '',
        product_name: '',
        product_unit_id: 0,
        remark: '',
        specification: '',
        unit_code: '',
        unit_name: '',
        update_time: ''
    });
    const [sellNum, setSellNum] = useState('');
    const [clientName, setClientName] = useState('');
    const [productName, setProductName] = useState('');

    const columns: ColumnsType<API.queryChildItem> = [
        {title: '任务单号', dataIndex: 'task_number', align: 'center', ellipsis: true},
        {
            title: '销售单号',
            dataIndex: 'order_number',
            align: 'center',
            ellipsis: true,
            render: (saleNumber: string, record) => <div
                className={isHasSell ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasSell) {
                        getDetailSellOrderInfo(record.order_id!)
                    }
                }}
            >
                {saleNumber}
            </div>
        },
        {
            title: '客户', dataIndex: 'trader_name', align: 'center', ellipsis: true,
            render: (customer: string, record) => <div
                className={isHasCustomer ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasCustomer) {
                        getDetailClientInfo(record.trader_id)
                    }
                }}
            >
                {customer}
            </div>
        },
        {
            title: '产品', dataIndex: 'product_name', align: 'center', ellipsis: true,
            render: (product: string, record) => <div
                className={isHasProduct ? 'hasAuthority' : 'noAuthority'}
                onClick={() => {
                    if (isHasProduct) {
                        getDetailProductInfo(record.product_id)
                    }
                }}
            >
                {product}
            </div>
        },
        {
            title: '订单数量',
            dataIndex: 'order_quantity',
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true},
        {
            title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {title: '产线', dataIndex: 'line_info', align: 'center', ellipsis: true},
        {title: '工艺路线', dataIndex: 'work_craft_info', align: 'center', ellipsis: true},
        {
            title: '任务数量',
            dataIndex: 'task_quantity',
            align: 'center',
            ellipsis: true,
            render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
        },
        {
            title: '计划开始时间', dataIndex: 'start_date', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '计划完成时间', dataIndex: 'end_date', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD')}</div>
        },
        {
            title: '任务下达时间', dataIndex: 'issue_time', align: 'center', ellipsis: true,
            render: (date) => <div>{date === null ? '' : moment(date).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            ellipsis: true,
            render: (status: number) => <div
                style={{color: status === 10 ? '#EB4420' : status === 20 ? '#3E7BFA' : status === 30 ? '#06C270' : status === 40 ? '#0053FF' : '#666666'}}>
                {
                    status === 10 ? '未下达' : status === 20 ? '已下达' : status === 30 ? '处理中' : status === 40 ? '已完成' : ''
                }
            </div>
        },
        {title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true},
        {
            title: '操作', dataIndex: 'status', ellipsis: true, width: '9%',
            render: (operate: number, data) => {
                return operate === 10 ? <div style={{display: "flex", flexDirection: "row"}}>
                    <div className={'hasAuthority'} onClick={() => {
                        setDataItem(data)
                        setShowCheckModal(true)
                    }}>
                        查看
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             setDataItem(data)
                             setShowEditModal(true)
                         }}>
                        编辑
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             setDataItem(data)
                             setShowDeleteModal(true)
                         }}>
                        删除
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             issueTask(data.object_id)
                         }}>
                        下达
                    </div>
                </div> : operate === 20 ? <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'hasAuthority'} onClick={() => {
                        setDataItem(data)
                        setShowCheckModal(true)
                    }}>
                        查看
                    </div>
                    <div className={'hasAuthority'} style={{marginLeft: 15}}
                         onClick={() => {
                             recallTask(data.object_id)
                         }}>
                        撤回
                    </div>
                </div> : operate === 30 || operate === 40 ?
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <div className={'hasAuthority'} onClick={() => {
                            setDataItem(data)
                            setShowCheckModal(true)
                        }}>
                            查看
                        </div>
                    </div> : null;
            }
        },
    ]

    useEffect(() => {
        userInfo.useInfo.authRoutes?.map((item) => {
            if (item === '/order/management') {
                setIsHasSell(true)
            } else if (item === '/basic/businessman') {
                setIsHasCustomer(true)
            } else if (item === '/basic/production') {
                setIsHasProduct(true)
            } else if (item === '/basic/productionLine') {
                setIsHasLine(true)
            } else if (item === '/basic/workmanship') {
                setIsHasProcessLine(true)
            } else if (item === '/basic/procedure') {
                setIsHasProcess(true)
            }
        })
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        sellNum: string,
        clientName: string,
        productionName: string
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page: page, per_page: size}
        if (data) {
            if (data.sellNum !== '') {
                param = {...param, order_number: data.sellNum}
            }
            if (data.clientName !== '') {
                param = {...param, name: data.clientName}
            }
            if (data.productionName !== '') {
                param = {...param, product: data.productionName}
            }
        } else {
            if (sellNum !== '') {
                param = {...param, order_number: sellNum}
            }
            if (clientName !== '') {
                param = {...param, name: clientName}
            }
            if (productName !== '') {
                param = {...param, product: productName}
            }
        }
        getTaskListRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    const getDetailSellOrderInfo = (objectId: number) => {
        getOrderDetailRequest({object_id: objectId}).then((res) => {
            if (res.code === 200) {
                setIsShowSellOrder(true)
                setSellOrderInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const getDetailClientInfo = (objectId: number) => {
        getClientDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowClient(true)
                setClientInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const getDetailProductInfo = (objectId: number) => {
        getProductDetailRequest({object_id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                setIsShowProduct(true)
                setProductInfo(res.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const newOrEditTask = (type: string, data: {
        task_id?: number,
        task_number?: string,
        order_product_id: number,
        line_info_id: number,
        start_date: string,
        end_date: string,
        work_craft_info_id?: any,
        process: Array<any>,
        task_quantity: number,
        remark?: string,
    }) => {
        let list: any[] = []
        data.process.map((item: any) => {
            let process = []
            process.push(item.work_process_info_id)
            process.push(item.process_explain)
            list.push(process)
        })
        let param: any = {
            order_product_id: data.order_product_id,
            line_info_id: data.line_info_id,
            start_date: data.start_date,
            end_date: data.end_date,
            task_quantity: data.task_quantity,
            process: JSON.stringify(list),
        }
        if (type === 'edit') {
            param = {...param, task_id: data.task_id}
        }
        if (type === 'new' && data.task_number !== '') {
            param = {...param, task_number: data.task_number}
        }
        if (data.work_craft_info_id) {
            param = {...param, work_craft_info_id: data.work_craft_info_id}
        }
        if (data.remark !== '') {
            param = {...param, remark: data.remark}
        }
        newTaskRequest(param).then((res) => {
            if (res.code === 200) {
                if (type === 'edit') {
                    setShowEditModal(false)
                } else {
                    setShowNewModal(false)
                }
                toastSuccessMessage(res.message)
                onFinish(currentPage, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const deleteTask = () => {
        deleteTaskRequest({id: `${dataItem!.object_id}`}).then((res) => {
            if (res.code === 200) {
                setShowDeleteModal(false)
                toastSuccessMessage('删除成功')
                onFinish(1, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const issueTask = (objectId: number) => {
        issueTaskRequest({id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                toastSuccessMessage('下达成功')
                onFinish(currentPage, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    const recallTask = (objectId: number) => {
        recallTaskRequest({id: `${objectId}`}).then((res) => {
            if (res.code === 200) {
                toastSuccessMessage('撤回成功')
                onFinish(currentPage, pageSize)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        })
    }

    return (
        <div>
            <SelectFormTask
                titles={['销售单号', '客户名称', '产品名称']}
                queryClick={(sellNum: string, clientName: string, productionName: string) => {
                    setSellNum(sellNum)
                    setClientName(clientName)
                    setProductName(productionName)
                    onFinish(1, pageSize, {sellNum: sellNum, clientName: clientName, productionName: productionName})
                }}
                newAdd={() => {
                    setShowNewModal(true)
                }}
                output={() => {
                    if (total > 0) {
                        let param: any = {page: 1, per_page: total}
                        if (sellNum !== '') {
                            param = {...param, order_number: sellNum}
                        }
                        if (clientName !== '') {
                            param = {...param, name: clientName}
                        }
                        if (productName !== '') {
                            param = {...param, product: productName}
                        }
                        getTaskListRequest(param).then((res) => {
                            if (res.code === 200) {
                                let option: { fileName?: string, datas?: any[] } = {};
                                let dataTable: any[] = [];
                                res.data.data.length > 0 && res.data.data.forEach(item => {
                                    let obj = {
                                        task_number: item.task_number,
                                        order_number: item.order_number,
                                        trader_name: item.trader_name,
                                        product_name: item.product_name,
                                        order_quantity: item.order_quantity,
                                        unit_name: item.unit_name,
                                        finish_date: item.finish_date,
                                        line_info: item.line_info,
                                        work_craft_info: item.work_craft_info,
                                        task_quantity: item.task_quantity,
                                        start_date: item.start_date,
                                        end_date: item.end_date,
                                        issue_time: item.issue_time,
                                        status: item.status === 10 ? '未下达' : item.status === 20 ? '已下达' : item.status === 30 ? '处理中' : item.status === 40 ? '已完成' : '',
                                        remark: item.remark,
                                    }
                                    dataTable.push(obj);
                                })
                                option.fileName = '生产任务' + moment(new Date()).format('YYYYMMDD')
                                option.datas = [
                                    {
                                        sheetData: dataTable,
                                        sheetName: 'sheet',
                                        sheetFilter: ['task_number', 'order_number', 'trader_name', 'product_name', 'order_quantity', 'unit_name', 'finish_date', 'line_info', 'work_craft_info', 'task_quantity', 'start_date', 'end_date', 'issue_time', 'status', 'remark'],
                                        sheetHeader: ['任务单号', '销售单号', '客户', '产品', '订单数量', '单位', '交期', '产线', '工艺路线', '任务数量', '计划开始时间', '计划完成时间', '任务下达时间', '状态', '备注'],
                                    }
                                ];
                                let toExcel = new ExportJsonExcel(option);
                                toExcel.saveExcel();
                            } else {
                                toastErrorMessage('导出失败')
                            }
                        }).catch(() => {
                            toastErrorMessage('导出失败')
                        })
                    }
                }}
            />
            <Table
                className={'commonTable'}
                rowKey={'object_id'}
                dataSource={dataSource}
                columns={columns}
                size={'small'}
                scroll={{x: 2500}}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: onFinish,
                    total: total,
                    showQuickJumper: true,
                    showTotal: handleShowTotal
                }}
            />

            {
                showNewModal && <TaskModal
                    type={'new'}
                    isHasLine={isHasLine}
                    isHasProcessLine={isHasProcessLine}
                    isHasProcess={isHasProcess}
                    navigate={navigate}
                    closeClick={() => {
                        setShowNewModal(false)
                    }}
                    saveClick={(data: any) => {
                        newOrEditTask('new', data)
                    }}
                />
            }
            {
                showEditModal && <TaskModal
                    type={'edit'}
                    isHasLine={isHasLine}
                    isHasProcessLine={isHasProcessLine}
                    isHasProcess={isHasProcess}
                    navigate={navigate}
                    dataItem={dataItem}
                    closeClick={() => {
                        setShowEditModal(false)
                    }}
                    saveClick={(data: any) => {
                        newOrEditTask('edit', data)
                    }}
                />
            }
            {
                showCheckModal && <CheckTaskModal
                    dataItem={dataItem}
                    closeClick={() => {
                        setShowCheckModal(false)
                    }}
                />
            }
            {
                showDeleteModal && <DeleteModal
                    closeClick={() => {
                        setShowDeleteModal(false)
                    }}
                    confirmClick={() => {
                        deleteTask()
                    }}
                />
            }
            {
                isShowSellOrder && <CheckOrderModal
                    dataItem={sellOrderInfo}
                    closeClick={() => {
                        setIsShowSellOrder(false)
                    }}
                />
            }

            {
                isShowClient && <BusinessmanCheckModal
                    BusinessmanItem={clientInfo}
                    closeClick={() => {
                        setIsShowClient(false)
                    }}
                />
            }

            {
                isShowProduct && <CheckModal
                    productItem={productInfo}
                    closeClick={() => {
                        setIsShowProduct(false)
                    }}
                />
            }
        </div>
    )
}
export default Task
