import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import './index.less';
import QueryBar from "./QueryBar";
import TableBar from "./TableBar";
import {filterData, filterDateParam} from "../../utils";
import {Form} from "antd";
import {ColumnsType} from "antd/es/table";

export interface GRef {
    onFinish: (objectID?: string) => void;
    exportData?: () => Promise<any>;
}

export interface Options {
    id: number,
    name: string
}

export interface QueryListItem {
    key: string,
    name: string,
    placeholder?: string,
    options?: Options[],
    multiple?: boolean,
    date?: boolean,
    rangePick?: boolean,
    defaultValue?: number[],
    showSearch?:boolean
}

interface GeneralManagementProps {
    //表单查询条件
    formList: QueryListItem[],
    //表格表头
    columns: ColumnsType<any>,
    //功能按钮组件
    ExtraComponent?: JSX.Element,
    //调用接口方法
    getDataAsync: (queryCondition: any) => Promise<any>,
    //组织机构上级机构treeID
    treeID?: number[],
    //是否显示多选框
    showSelection?: boolean,
    //表格选择框是单选还是多选
    selectionType?: 'checkbox' | 'radio',
    //修改勾选的key值数组
    setSelectedRowKeys?: (selectedRowKeys: any[]) => void,
    //勾选的key值数组
    selectedRowKeys?: any[],
    //修改勾选的数据
    setSelectedRows?: (selectedRows: any[]) => void,
    //是否显示表格内滚动条
    scroll?: boolean,
    // 表格内滑动的长度
    scrollValue?: { x: number },
    // 过滤queryData
    filterQueryData?: (queryData: any) => any,
}

const GeneralManagement = forwardRef<GRef, GeneralManagementProps>((props, ref) => {
    useImperativeHandle(ref, () => ({
        onFinish,
        exportData
    }));

    const {
        formList,
        columns,
        ExtraComponent,
        getDataAsync,
        treeID,
        showSelection = false,
        setSelectedRowKeys,
        selectedRowKeys,
        scroll,
        scrollValue,
        selectionType,
        setSelectedRows,
        filterQueryData,
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [queryForm] = Form.useForm();

    //表单查询
    const onFinish = () => {
        setCurrentPage(1);
        getList({...queryForm.getFieldsValue(), object_id: treeID?.join(), page_num: 1, page_size: pageSize});
    }

    //表单重置
    const onReset = () => {
        setCurrentPage(1);
        getList({...queryForm.getFieldsValue(), object_id: treeID?.join(), page_num: 1, page_size: pageSize});
    }

    //分页查询
    const onChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        getList({...queryForm.getFieldsValue(), object_id: treeID?.join(), page_num: page, page_size: pageSize});
    }

    const filterDate = (queryData: any) => {
        if (queryData.date) {
            queryData = filterDateParam(queryData, 'date', 'start_date', 'end_date')
        }
        return queryData;
    }

    //导出数据
    const exportData = (): Promise<any> => {
        let realParams = filterQueryData ? filterQueryData(filterData(Object.assign(queryForm.getFieldsValue(), {
            page_num: 1,
            page_size: 10000
        }))) : filterDate(filterData(Object.assign(queryForm.getFieldsValue(), {page_num: 1, page_size: 10000})));
        return new Promise((rescolve, reject) => {
            getDataAsync(realParams).then((res: any) => {
                rescolve(res.data);
            }).catch(err => {
                reject(err)
            })
        })
    }

    //获取数据
    const getList = (values: any) => {
        setLoading(true);
        let realParams = filterQueryData ? filterQueryData(filterData(values)) : filterDate(filterData(values))
        getDataAsync(realParams).then((res: any) => {
            setLoading(false);
            res.data.map((e: any) => e.key = e.key ? e.key : e.object_id)
            setDataSource(res.data);
            setTotal(res.total);
            setTotalPage(res.pages)
        })
    }

    //初始调用接口
    useEffect(() => {
        getList({page_num: currentPage, page_size: pageSize, ...queryForm.getFieldsValue()})
    }, [])

    return (
        <div className={'mes-generalManagement'}>
            <QueryBar queryForm={queryForm} formList={formList} onReset={onReset} onFinish={onFinish}
                      ExtraComponent={ExtraComponent}/>
            <TableBar columns={columns} dataSource={dataSource} onChange={onChange} total={total} scroll={scroll}
                      showSelection={showSelection} setSelectedRowKeys={setSelectedRowKeys}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRows={setSelectedRows}
                      scrollValue={scrollValue} selectionType={selectionType}
                      currentPage={currentPage} pageSize={pageSize} loading={loading} totalPage={totalPage}/>
        </div>
    )
})

export default React.memo(GeneralManagement);
