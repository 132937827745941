import React, {useState} from "react";
import {Table} from 'antd';
import './index.less';

interface TableBarProps {
    columns: any[],
    dataSource: any[],
    onChange: any,
    total: number,
    currentPage: number,
    pageSize: number,
    loading: boolean,
    totalPage: number,
    showSelection: boolean,
    setSelectedRowKeys?: (selectionList: any[]) => void,
    selectedRowKeys?: any[],
    scroll?: boolean,
    scrollValue?: { x: number },
    //表格选择框是单选还是多选
    selectionType?: 'checkbox' | 'radio',
    //修改勾选的数据
    setSelectedRows?: (selectedRows: any[]) => void,
}

const TableBar = (props: TableBarProps) => {
    const {
        columns,
        dataSource,
        onChange,
        total,
        currentPage,
        pageSize,
        loading,
        totalPage,
        showSelection,
        setSelectedRowKeys,
        selectedRowKeys,
        scroll,
        scrollValue,
        selectionType,
        setSelectedRows,
    } = props;

    const showTotal = () => {
        return <div className={'mes-table-pagination-total'}>
            <span style={{marginRight: 12}}>{`共${total}条记录`}</span>{`第${currentPage}/${totalPage}页`}
        </div>
    }

    const rowOnChange = (selectedRowKeys: React.Key[],selectedRows:any[]) => {
        if (setSelectedRowKeys) {
            setSelectedRowKeys(selectedRowKeys)
        }
        if (setSelectedRows) {
            setSelectedRows(selectedRows)
        }
    }

    return (
        <div className={'mes-tableBar'}>
            <Table columns={columns} dataSource={dataSource} loading={loading}
                   rowSelection={showSelection ? {type: selectionType, selectedRowKeys, onChange: rowOnChange, columnWidth: 100} : undefined}
                   scroll={scrollValue ? scrollValue : scroll ? {x: 2000} : undefined}
                   pagination={
                       {
                           position: ['bottomRight'],
                           defaultCurrent: 1,
                           defaultPageSize: 10,
                           showSizeChanger: true,
                           showQuickJumper: true,
                           current: currentPage,
                           pageSize: pageSize,
                           total: total,
                           onChange: onChange,
                           showTotal: showTotal
                       }
                   }/>
        </div>
    )
}

export default React.memo(TableBar);
