import React from 'react'
import {Button, ConfigProvider, DatePicker, Input, InputNumber, Modal, Select, Table} from "antd";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import moment from "moment";
import localeValues from 'antd/lib/locale/zh_CN'
import {ColumnsType} from "antd/lib/table";
import {toastMessage} from "../../../utils";
import {getAllProduct, getAllUnitInfo, getBusinessmanInfo, getOrderDetailRequest} from "../../../service";

/**
 * 新增、编辑销售订单对话框
 */
const {Option} = Select;

interface Type {
    sellNum: string,
    customer: any,
    date: any,
    remark: string,
    detailOrderColumns: ColumnsType<API.childOrderItem>,
    detailOrderList: Array<API.childOrderItem>,
    customerList: Array<any>,
    productionList: Array<any>,
    unitList: Array<any>,
}

export default class OrderModal extends React.Component<{
    type: string,
    isHasBusinessman: boolean,
    isHasProduct: boolean,
    navigate: NavigateFunction,
    dataItem?: API.orderItem,
    closeClick?: () => void
    saveClick?: (sellNum: string, customer: any, date: string, remark: string, detailOrderList: Array<API.childOrderItem>, objectId?: number) => void
}> {

    state: Type = {
        sellNum: '',
        customer: undefined,
        date: undefined,
        remark: '',
        detailOrderColumns: [],
        detailOrderList: [],
        customerList: [],
        productionList: [],
        unitList: [],
    }

    async componentDidMount() {
        const {type, isHasProduct, dataItem, navigate} = this.props
        this.setState({
            detailOrderColumns: [
                {
                    title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '8%',
                    render: (text: any, record: API.childOrderItem, index: number) => <div>{index + 1}</div>
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <div style={{color: "red"}}>*</div>
                            <div style={{
                                color: isHasProduct ? '#3E7BFA' : '#333333',
                                textDecoration: isHasProduct ? "underline" : "none",
                                cursor: isHasProduct ? "pointer" : "auto",
                                marginLeft: 5,
                            }} onClick={() => {
                                if (isHasProduct) {
                                    navigate('/basic/production')
                                }
                            }}>
                                产品
                            </div>
                        </div>
                    }, dataIndex: 'productinfo_id', align: 'center', ellipsis: true, width: '15%',
                    render: (text: any, record: API.childOrderItem) => <Select
                        style={{height: 26, width: 90, borderRadius: 5}}
                        value={text}
                        placeholder={'请选择产品'}
                        onChange={(value) => {
                            const newList = this.state.detailOrderList.map((childItem) => {
                                if (childItem.serial_num === record.serial_num) {
                                    const productionItem = this.state.productionList.find((item) => item.object_id === value)
                                    const unitItem = this.state.unitList.find((item) => item.object_id === productionItem.product_unit_id)
                                    return {
                                        ...childItem,
                                        productinfo_id: value,
                                        productinfo_name: productionItem.product_name,
                                        unit_id: unitItem ? unitItem.object_id : undefined,
                                        unit_name: unitItem ? unitItem.unit_name : undefined,
                                    }
                                } else {
                                    return childItem
                                }
                            })
                            this.setState({
                                detailOrderList: newList
                            })
                        }}
                    >
                        {
                            this.state.productionList.length > 0 && this.state.productionList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.product_name}
                                </Option>
                            })
                        }
                    </Select>
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <div style={{color: "red"}}>*</div>
                            <div style={{marginLeft: 5}}>数量</div>
                        </div>
                    }, dataIndex: 'num', align: 'center', ellipsis: true, width: '12%',
                    render: (text: number, record: API.childOrderItem) => <InputNumber
                        style={{height: 30, width: 70, borderRadius: 5, alignItems: "center"}}
                        value={text}
                        min={1}
                        placeholder="请输入数量"
                        onChange={(newValue) => {
                            const newList = this.state.detailOrderList.map((item) => {
                                if (item.serial_num === record.serial_num) {
                                    return {
                                        ...item,
                                        num: newValue
                                    }
                                } else {
                                    return item
                                }
                            })
                            this.setState({
                                detailOrderList: newList
                            })
                        }}
                    />
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <div style={{color: "red"}}>*</div>
                            <div style={{marginLeft: 5}}>单位</div>
                        </div>
                    }, dataIndex: 'unit_id', align: 'center', width: '15%', ellipsis: true,
                    render: (text: any, record: API.childOrderItem) => <Select
                        value={text}
                        style={{height: 26, width: 90, borderRadius: 5}}
                        placeholder={'请选择单位'}
                        onChange={(value) => {
                            const newList = this.state.detailOrderList.map((item) => {
                                if (item.serial_num === record.serial_num) {
                                    return {
                                        ...item,
                                        unit_id: value,
                                        unit_name: this.state.unitList.find((item) => item.object_id === value).unit_name
                                    }
                                } else {
                                    return item
                                }
                            })
                            this.setState({
                                detailOrderList: newList
                            })
                        }}
                    >
                        {
                            this.state.unitList.length > 0 && this.state.unitList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.unit_name}
                                </Option>
                            })
                        }
                    </Select>
                },
                {
                    title: () => {
                        return <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <div style={{color: "red"}}>*</div>
                            <div style={{marginLeft: 5}}>交期</div>
                        </div>
                    }, dataIndex: 'finish_date', align: 'center', ellipsis: true, width: '25%',
                    render: (text: any, record: API.childOrderItem) => <ConfigProvider locale={localeValues}>
                        <DatePicker
                            value={text ? moment(text, 'YYYY/MM/DD') : undefined}
                            onChange={(d) => {
                                const newList = this.state.detailOrderList.map((item) => {
                                    if (item.serial_num === record.serial_num) {
                                        return {
                                            ...item,
                                            finish_date: moment(d).format('YYYY-MM-DD')
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                this.setState({
                                    detailOrderList: newList
                                })
                            }}
                            clearIcon={false}
                            format={'YYYY/MM/DD'}
                            placeholder="请选择交期"
                            picker="date"
                        />
                    </ConfigProvider>
                },
                {
                    title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true, width: '17%',
                    render: (text: string, record: API.childOrderItem) => <Input
                        style={{height: 30, width: 100, borderRadius: 5}}
                        placeholder="请输入备注"
                        value={text}
                        maxLength={200}
                        onChange={(newValue) => {
                            const newList = this.state.detailOrderList.map((item) => {
                                if (item.serial_num === record.serial_num) {
                                    return {
                                        ...item,
                                        remark: newValue.target.value
                                    }
                                } else {
                                    return item
                                }
                            })
                            this.setState({
                                detailOrderList: newList
                            })
                        }}
                    />
                },
                {
                    title: '操作', dataIndex: 'operate', align: 'center', ellipsis: true, width: '8%',
                    render: (text: string, record: API.childOrderItem) => <div
                        style={{color: '#3E7BFA', cursor: "pointer", textDecoration: "underline"}}
                        onClick={() => {
                            this.handleDelete(record.serial_num)
                        }}
                    >
                        删除
                    </div>
                },
            ]
        })
        await getBusinessmanInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    customerList: res.data.data
                })
            }
        })
        await getAllUnitInfo().then((res) => {
            if (res.code === 200) {
                this.setState({
                    unitList: res.data.data
                })
            }
        })
        await getAllProduct().then((res) => {
            if (res.code === 200) {
                this.setState({
                    productionList: res.data.data
                })
            }
        })
        if (type === 'edit') {
            await getOrderDetailRequest({object_id: dataItem!.object_id}).then((res) => {
                if (res.code === 200) {
                    let newList: API.childOrderItem[] = []
                    if (res.data.orderproduct_info.length > 0) {
                        res.data.orderproduct_info.map((item) => {
                            newList.push({
                                ...item,
                                productinfo_id: this.state.productionList.find((productionItem) => productionItem.object_id === item.productinfo_id) ? item.productinfo_id : undefined,
                                unit_id: this.state.unitList.find((unitItem) => unitItem.object_id === item.unit_id) ? item.unit_id : undefined,
                            })
                        })
                    }
                    this.setState({
                        sellNum: res.data.code,
                        customer: this.state.customerList.find((item) => item.object_id === res.data.trader_id) ? res.data.trader_id : undefined,
                        date: moment(res.data.finish_date, 'YYYY/MM/DD'),
                        remark: res.data.remark,
                        detailOrderList: newList,
                    })
                }
            })
        }
    }

    handleDelete(serialNum: number) {
        const newData = this.state.detailOrderList.filter(item => item.serial_num !== serialNum);
        newData.map((item, index) => {
            item.serial_num = index + 1
        })
        this.setState({
            detailOrderList: newData
        })
    };

    handleAdd() {
        const newItem: API.childOrderItem = {
            serial_num: this.state.detailOrderList.length,
            productinfo_id: undefined,
            num: 1,
            unit_id: undefined,
            finish_date: this.state.date ? moment(this.state.date).format('YYYY/MM/DD') : undefined,
            remark: '',
        }
        const newData = [...this.state.detailOrderList, newItem]
        newData.map((item, index) => {
            item.serial_num = index + 1
        })
        this.setState({
            detailOrderList: newData
        })
    };

    render() {
        const {sellNum, customer, date, remark, detailOrderColumns} = this.state
        const {type, isHasBusinessman, navigate, dataItem, closeClick, saveClick} = this.props
        return <Modal
            style={{background: "transparent", borderRadius: 5}}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            destroyOnClose
            title={type === 'new' ? '新增销售订单' : '编辑销售订单'}
            footer={null}
            width={855}
            centered
            open
        >
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'orderModalItemTitleLeft'}>销售单号</div>
                    <Input
                        value={sellNum}
                        className={'orderModalItemInputStyle'}
                        placeholder="不填写，系统将自动生成"
                        maxLength={32}
                        onChange={(newValue) => {
                            if (newValue.target.value === '') {
                                this.setState({
                                    sellNum: newValue.target.value
                                })
                            } else {
                                if (/^[A-Za-z0-9]+$/.test(newValue.target.value)) {
                                    this.setState({
                                        sellNum: newValue.target.value
                                    })
                                }
                            }
                        }}
                        disabled={type !== 'new'}
                    />
                </div>

                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'orderModalItemTitleRight'}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        <div style={{
                            color: isHasBusinessman ? '#3E7BFA' : '#333333',
                            textDecoration: isHasBusinessman ? "underline" : "none",
                            cursor: isHasBusinessman ? "pointer" : "auto",
                        }} onClick={() => {
                            if (isHasBusinessman) {
                                navigate('/basic/businessman')
                            }
                        }}>
                            客户
                        </div>
                    </div>
                    <Select
                        className={'orderModalItemSelectStyle'}
                        placeholder={'请选择客户'}
                        value={customer}
                        onChange={(value) => {
                            this.setState({
                                customer: value
                            })
                        }}
                    >
                        {
                            this.state.customerList.length > 0 && this.state.customerList.map((item: any, index: number) => {
                                return <Option key={index} value={item.object_id}>
                                    {item.name}
                                </Option>
                            })
                        }
                    </Select>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", marginLeft: 20, marginTop: 20}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div className={'orderModalItemTitleLeft'}>
                        <div style={{color: "red", marginRight: 5}}>
                            *
                        </div>
                        交货日期
                    </div>
                    <ConfigProvider locale={localeValues}>
                        <DatePicker
                            className={'orderModalItemDatePickerStyle'}
                            value={date}
                            onChange={(d) => {
                                this.setState({
                                    date: moment(d, 'YYYY/MM/DD'),
                                })
                            }}
                            clearIcon={false}
                            format={'YYYY/MM/DD'}
                            placeholder="请选择交货日期"
                            picker="date"
                        />
                    </ConfigProvider>
                </div>
                <div style={{display: "flex", flexDirection: "row", marginLeft: 20, alignItems: "center"}}>
                    <div className={'orderModalItemTitleRight'}>备注</div>
                    <Input
                        className={'orderModalItemInputStyle'}
                        value={remark}
                        placeholder="请输入备注"
                        maxLength={200}
                        onChange={(newValue) => {
                            this.setState({
                                remark: newValue.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <Table
                title={() => <div style={{display: "flex", flexDirection: "row", flex: 1}}>
                    <div style={{flex: 1, fontWeight: "bold", color: '#333333', marginLeft: 10}}>
                        订单明细
                    </div>
                    <Button
                        className={'orderButtonAdd'}
                        type={"primary"}
                        onClick={() => {
                            this.handleAdd()
                        }}
                    >
                        新增
                    </Button>
                </div>}
                style={{marginTop: 20, marginLeft: 15, marginRight: 15}}
                bordered
                className={'childTable'}
                rowKey={'object_id'}
                dataSource={this.state.detailOrderList}
                columns={detailOrderColumns}
                size={'small'}
                scroll={{y: 200}}
                pagination={false}
            />

            <div style={{display: "flex", marginTop: 20, justifyContent: "center"}}>
                <Button style={{width: 100, height: 35, borderRadius: 5, backgroundColor: '#B8B8B8', color: '#ffffff'}}
                        htmlType="button"
                        onClick={() => {
                            closeClick && closeClick()
                        }}
                >
                    关闭
                </Button>
                <Button style={{marginLeft: 20, width: 100, height: 35, borderRadius: 5}}
                        htmlType="button"
                        type="primary"
                        onClick={() => {
                            if (customer === undefined) {
                                toastMessage('请选择客户')
                                return;
                            }
                            if (date === undefined || date === '') {
                                toastMessage('请选择交货日期')
                                return;
                            }
                            if (this.state.detailOrderList.length > 0) {
                                let isHasProduct = false
                                let isHasProductNum = false
                                let isHasUnit = false
                                let isHasCompleteTime = false
                                this.state.detailOrderList.map((item) => {
                                    if (item.productinfo_id === undefined) {
                                        isHasProduct = true
                                    }
                                    if (item.num <= 0) {
                                        isHasProductNum = true
                                    }
                                    if (item.unit_id === undefined) {
                                        isHasUnit = true
                                    }
                                    if (item.finish_date === '' || item.finish_date === undefined) {
                                        isHasCompleteTime = true
                                    }
                                })
                                if (isHasProduct) {
                                    toastMessage('请选择产品')
                                    return;
                                }
                                if (isHasProductNum) {
                                    toastMessage('请选择数量')
                                    return;
                                }
                                if (isHasUnit) {
                                    toastMessage('请选择单位')
                                    return;
                                }
                                if (isHasCompleteTime) {
                                    toastMessage('请选择交期')
                                    return;
                                }
                            } else {
                                toastMessage('订单明细不能为空')
                                return;
                            }
                            if (type === 'new') {
                                saveClick && saveClick(sellNum, customer, moment(date).format('YYYY-MM-DD'), remark, this.state.detailOrderList)
                            } else {
                                saveClick && saveClick(sellNum, customer, moment(date).format('YYYY-MM-DD'), remark, this.state.detailOrderList, dataItem!.object_id)
                            }
                        }}
                >
                    保存
                </Button>
            </div>
        </Modal>
    }
}
