import React, {useEffect, useState} from "react";
import {Col, Divider, Form, Input, Row, Steps} from "antd";
import {EXCReportStatus, showError} from "../../../utils";
import {getExceptionInfo} from "../../../service";
import moment from "moment";

interface ExceptionDetailProps {
    objectId: number
}

interface DetailSteps {
    title: string,
    person: string,
    time: string,
    describe?: string,
    show: boolean
}

//异常查看
const ExceptionDetail = (props: ExceptionDetailProps): React.ReactElement => {
    const [steps, setSteps] = useState<DetailSteps[]>([]);
    const [currentStatus, setCurrentStatus] = useState<number>();
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const {objectId} = props;
    const [form] = Form.useForm();
    useEffect(() => {
        //获取异常详情
        getExceptionInfo({object_id: objectId}).then(res => {
            if (res.code === 200) {
                setCurrentStatus(res.data.status)
                form.setFieldsValue({
                    exceptionNO: res.data.code,
                    productionLine: res.data.line_name,
                    procedure: res.data.work_process_name,
                    category: res.data.exception_type,
                    code: res.data.exception_describe,
                    status: EXCReportStatus[res.data.status],
                    describe: res.data.phenomenal_description,
                })
                if (res.data.file) {
                    setImageUrl(res.data.file.url)
                } else {
                    setImageUrl(undefined)
                }
                setSteps([
                    {
                        title: '创建',
                        person: res.data.reporting_user,
                        time: moment(res.data.create_time).format('YYYY/MM/DD HH:mm'),
                        show: true
                    },
                    {
                        title: '提报',
                        person: res.data.reporting_user,
                        time: moment(res.data.reporting_time).format('YYYY/MM/DD HH:mm'),
                        show: res.data.status === 20 || res.data.status === 30 || res.data.status === 40 || res.data.status === 50
                    },
                    {
                        title: '处理',
                        person: res.data.handle_user,
                        time: res.data.status === 30 ? `${moment(res.data.handle_start_time).format('YYYY/MM/DD HH:mm')}` :
                            `${moment(res.data.handle_start_time).format('YYYY/MM/DD HH:mm')} ~ ${moment(res.data.handle_end_time).format('YYYY/MM/DD HH:mm')}`,
                        show: res.data.status === 30 || res.data.status === 40 || res.data.status === 50,
                        describe: res.data.handle_process
                    },
                    {
                        title: '关闭',
                        person: res.data.close_user,
                        time: moment(res.data.close_time).format('YYYY/MM/DD HH:mm'),
                        show: res.data.status === 50
                    },
                ])
            } else {
                setSteps([])
                showError(res.message)
            }
        })

    }, [])

    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };

    const description = (item: any): React.ReactNode => {
        return <div>
            <Row>
                <Col span={8}>{item.title}人：{item.person}</Col>
                <Col span={16}>{item.title}时间：{item.time}</Col>
            </Row>
            {
                item.title === '处理' && currentStatus !== 30 && <Row>
                    处理过程：{item.describe}
                </Row>
            }
        </div>
    }

    return <div className={'view-detail'}>
        <div className={'view-title'}>基本信息</div>
        <Form {...layout} colon={false} form={form}>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label={'异常单号'} name={'exceptionNO'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'产线'} name={'productionLine'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'工序'} name={'procedure'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'异常类别'} name={'category'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'异常代码'} name={'code'}>
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item label={'异常状态'} name={'status'}>
                        <Input disabled/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={'现象描述'} name={'describe'}>
                        <Input.TextArea disabled/>
                    </Form.Item>
                    <Form.Item label={'图片'} name={'picture'}>
                        {
                            imageUrl ? <img src={imageUrl} alt={''} className={'view-detail-img'}/> :
                                <img src={require('../../../assets/noImg.png')} alt={''}
                                     style={{height: 160, width: '100%'}}/>
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Divider/>
        </Form>
        <div className={'view-title'}>过程信息</div>
        <Steps direction={'vertical'} progressDot current={steps.length}>
            {
                steps.map((e: any, i: number) => {
                    if (e.show) {
                        return <Steps.Step key={i} title={e.title} description={description(e)}></Steps.Step>
                    }
                })
            }
        </Steps>
    </div>
}

export default React.memo(ExceptionDetail);
