import {Divider, Form, Row, Col, Input, Select, InputNumber, TreeSelect} from "antd";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {formatNumber, getTreeData, outTypeList, showError, typeList} from "../../../utils";
import React, {useEffect, useState} from "react";
import EditOrDragTable, {TRef} from "../../../components/EditOrDragTable";
import {ColumnsType} from "antd/es/table";
import {ModalTitle} from "../WarehouseIn";
import {ModalTitle as ModalTitleOut} from "../warehouseOut";
import {Options} from "../../../components/GeneralManagement";
import {useWatch} from "antd/es/form/Form";
import {
    getBusinessmanInfo,
    getOrganizationTree,
    getStorageList,
    getWarehouseProduct_All
} from "../../../service";
import OutTable from "./OutTable";


interface AddOrEditItemProps {
    name: '入库' | '出库',
    form: FormInstance,
    tableForm: FormInstance,
    title: ModalTitle.ADD | ModalTitle.EDIT | ModalTitleOut.ADD | ModalTitleOut.EDIT,
    //入库产品列表
    productList?: ProductNameOptions[];
    //出库产品列表
    productListOuts?: ProductNameOptions[];
    //编辑时明细初始值
    initialDataSource?: any[]
}

export interface ProductNameOptions {
    object_id: number,
    product_name: string,
    product_code: number,
    model: string,
    unit: string,
    kc_count: string,
}

interface ProductNameProps {
    index: number;
}

const AddOrEditItem = (props: AddOrEditItemProps) => {
    const {name, form, tableForm, title, initialDataSource, productList, productListOuts} = props;
    const tRef = React.useRef<TRef>(null);
    const defaultColumns: ColumnsType<any> = [
        {
            title: "序号",
            dataIndex: "序号",
            key: "序号",
            width: 60,
            ellipsis: true,
            align: 'center',
            render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>产品名称</span>,
            dataIndex: 'product_name',
            key: 'product_name',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) => <ProductNameIn index={index}/>
        },
        {
            title: <span>产品代码</span>,
            dataIndex: 'code',
            key: 'product_code',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'code']}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入产品代码'} disabled/>
                </Form.Item>
        },
        {
            title: <span>规格型号</span>,
            dataIndex: 'specification',
            key: 'model',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'specification']}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入规格型号'} disabled/>
                </Form.Item>
        },
        {
            title: <span>单位</span>,
            dataIndex: 'unit_name',
            key: 'unit',
            width: 100,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'unit_name']}
                           style={{textAlign: 'left'}}>
                    <Input placeholder={'请输入单位'} disabled/>
                </Form.Item>
        },
        {
            title: <span><span className={'requiredIcon'}>*</span>数量</span>,
            dataIndex: 'count',
            key: 'number',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'count']}
                           style={{textAlign: 'left'}}
                           rules={[{required: true, message: '请输入数量！'}]}>
                    <InputNumber placeholder={'请输入数量'} min={1}
                                 formatter={(value: any) => value ? formatNumber(value.toString()) : ''}/>
                </Form.Item>
        },
        {
            title: <span>备注</span>,
            dataIndex: 'remark',
            key: 'remark',
            width: 150,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <Form.Item name={[`${index}`, 'remark']}
                           style={{textAlign: 'left'}}
                           rules={[
                               {max: 200, message: '备注不得大于200位！'}
                           ]}
                >
                    <Input.TextArea placeholder={'请输入备注'}/>
                </Form.Item>
        },
        {
            title: <span>操作</span>,
            dataIndex: 'options',
            key: 'options',
            width: 80,
            ellipsis: true,
            align: 'center',
            render: (value: any, item: any, index: number) =>
                <span className={'mes-options'} onClick={() => {
                    if (tRef.current) {
                        tRef.current.handleDelete(index)
                    }
                }}>删除</span>
        },
    ]
    const newItem = {
        product_name: undefined,
        code: undefined,
        specification: undefined,
        unit_name: undefined,
        count: undefined,
        kc_count: undefined,
        remark: undefined,
    }
    const [warehouse, setWarehouse] = useState<Options[]>([]);
    const [typeName, setTypeName] = useState('');
    const [detailDataSource, setDetailDataSource] = useState<any[]>([]);
    const [realTypeList, setRealTypeList] = useState(typeList);
    const [organizationList, setOrganizationList] = useState<any>([]);
    const [supplierList, setSupplierList] = useState<any>([]);
    const [productListOut, setProductListOut] = useState<ProductNameOptions[]>([]);
    const type = useWatch((name === '入库') ? 'instore_type' : 'outstore_type', form)

    /*入库产品名称*/
    const ProductNameIn = (props: ProductNameProps) => {
        const {index} = props;

        const handleChange = (value: number, option: any) => {
            tableForm.setFieldValue([`${props.index}`, 'product_name'], value)
            tableForm.resetFields([[`${props.index}`, 'code'], [`${props.index}`, 'specification'], [`${props.index}`, 'unit_name']])
            tableForm.setFieldValue([`${props.index}`, 'code'], option.include.product_code)
            tableForm.setFieldValue([`${props.index}`, 'specification'], option.include.model)
            tableForm.setFieldValue([`${props.index}`, 'unit_name'], option.include.unit)
        }

        return <Form.Item name={[`${index}`, 'product_name']}
                          style={{textAlign: 'left'}}
                          rules={[
                              {required: true, message: '请选择产品！'},
                          ]}>
            {
                productList && <Select placeholder={`请选择产品`} onChange={handleChange}>
                    {
                        productList.map((e: any) => <Select.Option key={e.object_id} value={e.object_id}
                                                                   include={e}>{e.product_name}</Select.Option>)
                    }
                </Select>
            }
        </Form.Item>
    }

    useEffect(() => {
        //设置入库出库类型列表
        //设置入库、出库类型name
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            setRealTypeList(typeList)
            setTypeName('instore_type')
        } else if (title === ModalTitleOut.ADD || title === ModalTitleOut.EDIT) {
            setRealTypeList(outTypeList)
            setTypeName('outstore_type')
        }

        //设置表头数据
        if (title === ModalTitle.ADD || title === ModalTitleOut.ADD) {
            setDetailDataSource([])
        }
    }, [title])

    //编辑时同步获取初始数据
    useEffect(()=>{
        if (initialDataSource) {
            setDetailDataSource(initialDataSource)
        }
    },[initialDataSource])

    //编辑出库时需要设置产品列表
    useEffect(()=>{
        if (title === ModalTitleOut.EDIT) {
            if (productListOuts) {
                setProductListOut(productListOuts)
            }
        }
    },[productListOuts])

    //获取接口数据
    useEffect(() => {
        //获取组织机构树形结构
        getOrganizationTree().then(res => {
            if (res.code === 200) {
                let temp = getTreeData(res.data)
                setOrganizationList(temp)
            } else {
                showError(res.message)
            }
        })
        //获取客商信息列表
        getBusinessmanInfo().then(res => {
            if (res.code === 200) {
                setSupplierList(res.data.data)
            } else {
                showError(res.message)
            }
        })

        //获取仓库信息
        let temp: Options[] = [];
        getStorageList().then(res => {
            if (res.code === 200) {
                if (res.data.data) {
                    res.data.data.map((e: any) => {
                        temp.push({
                            id: e.object_id,
                            name: e.storage_name
                        })
                    })
                    setWarehouse(temp)
                }
            } else {
                showError(res.message)
            }
        })
    }, [])

    //根据选择类型去校验其他表单字段
    useEffect(() => {
        if (type) {
            form.validateFields(['trader_id', 'organization_id'])
        }
    }, [type])


    return (<div className={'viewItem'}>
        <div className={'viewTitle'} style={{marginTop: -20}}>{name}信息</div>
        <Form form={form} className={'form-margin'}>
            <Row>
                <Col span={8}>
                    <Form.Item name={'code'} label={`${name}单号`} rules={[
                        {
                            type: "string",
                            max: 32,
                            pattern: /^[A-Za-z0-9]+$/,
                            message: `${name}单号由不大于32位的字母、数字组成!`
                        }
                    ]}>
                        <Input placeholder={'不填写，系统将自动生成'}
                               disabled={title === ModalTitle.EDIT || title === ModalTitleOut.EDIT}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={typeName}
                               label={<span>{`${name}类型`}</span>}
                               rules={[{required: true, message: `请选择${name}类型!`}]}>
                        <Select placeholder={`请选择${name}类型`}>
                            {
                                realTypeList.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'storage_id'} label={`仓库名称`} rules={[{required: true, message: '请选择仓库!'}]}>
                        <Select placeholder={'请选择仓库'} onChange={(value) => {
                            if (name === '出库') {
                                getWarehouseProduct_All({storage_id: value}).then(res => {
                                    if (res.code === 200) {
                                        if (res.data) {
                                            const temp: ProductNameOptions[] = []
                                            res.data.map((e: any) => {
                                                temp.push({
                                                    object_id: e.product_id,
                                                    product_name: e.product_name,
                                                    product_code: e.product_code,
                                                    model: e.specification,
                                                    unit: e.unit_name,
                                                    kc_count: e.inventory_num,
                                                })
                                            })
                                            setProductListOut(temp)
                                            setDetailDataSource([])
                                        }
                                    } else {
                                        showError(res.message)
                                    }
                                })
                            }
                        }}>
                            {
                                warehouse.map(e => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Form.Item name={'bind_no'} label={`关联单号`} rules={[
                        {
                            type: "string",
                            max: 32,
                            pattern: /^[A-Za-z0-9]+$/,
                            message: '关联单号由不大于32位的字母、数字组成!'
                        }
                    ]}>
                        <Input placeholder={'请输入关联单号'}/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'trader_id'} label={`对方单位`} rules={[
                        (({}) => ({
                            validator(_, value) {
                                if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
                                    if (!value && (type == 10 || type == 30)) {
                                        return Promise.reject(new Error('请选择对方单位!'))
                                    } else {
                                        return Promise.resolve()
                                    }
                                } else if (ModalTitleOut.ADD || ModalTitleOut.EDIT) {
                                    if (!value && type == 10) {
                                        return Promise.reject(new Error('请选择对方单位!'))
                                    } else {
                                        return Promise.resolve()
                                    }
                                }
                            }
                        }))
                    ]}>
                        <Select placeholder={`请选择对方单位`}>
                            {
                                supplierList.map((e: any) => <Select.Option key={e.object_id}
                                                                            value={e.object_id}>{e.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'organization_id'} label={`部门`} rules={[
                        (({}) => ({
                            validator(rule, value) {
                                if (!value && type == 20) {
                                    return Promise.reject(new Error('请选择部门!'))
                                } else {
                                    return Promise.resolve()
                                }
                            }
                        })),
                    ]}>
                        <TreeSelect placeholder={'请选择部门'} showSearch treeData={organizationList}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label={'备注'} name={'remark'} rules={[
                        {max: 200, message: '备注不得大于200位！'}
                    ]}>
                        <Input.TextArea placeholder={'请输入备注'}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Divider/>
        <div className={'form-margin'}>
            {name === '入库' &&
                <EditOrDragTable tableForm={tableForm} detailColumns={defaultColumns} detailDataSource={detailDataSource}
                                 title={`入库明细`} newItem={newItem} ref={tRef} scroll={false}/>}
            {name === '出库' &&
                <OutTable tRef={tRef} tableForm={tableForm} detailDataSource={detailDataSource}
                          productListOut={productListOut} newItem={newItem}></OutTable>}
        </div>
    </div>)
}

export default AddOrEditItem
