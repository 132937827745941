import React from 'react'
import { Modal, Select } from "antd";
import Input from '../../../../components/input'
import Button from '../../../../components/button'
import '../../../../globalLess/global.css'

export default class CheckModal extends React.Component<{
    productItem: API.productItem
    closeClick: () => void,
}> {
    state = {
        code: '',
        product_name: '',
        product_unit_id: '',
        specification: '',
        remark: ''
    }
    componentDidMount() {
        const {
            productItem,
        } = this.props
        this.setState({
            code: productItem.code,
            product_name: productItem.product_name,
            product_unit_id: productItem.unit_name,
            specification: productItem.specification,
            remark: productItem.remark
        })
    }
    render() {
        const {
            closeClick,
        } = this.props
        const {
            code,
            product_name,
            specification,
            remark,
            product_unit_id
        } = this.state
        return <Modal
            style={{ background: "transparent", borderRadius: 5, }}
            closeIcon={
                <img
                    alt=''
                    src={require('../../../../assets/icon_close.png')}
                    onClick={() => closeClick && closeClick()}
                />
            }
            title={'查看产品信息'}
            width={458}
            open={true}
            footer={null}
            centered
        >
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='产品代码' titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 10 }}
                    value={code}
                    disabled={true}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='产品名称' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }}
                    value={product_name}
                />

            </div>

            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <div className='inputStyle'
                >
                    <div
                        className='dropdownTitle'
                        style={{ marginRight: 10, color: '#333333', textDecoration: "none", cursor: "auto" }}

                    >产品单位</div>
                    <Select
                        disabled={true}
                        style={{ width: 280 }}
                        placeholder='请选择产品单位'
                        value={product_unit_id}
                    />
                </div>
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='规格型号' disabled={true} titlestyle={{ width: 60 }} style={{ width: 281, marginRight: 20 }}
                    value={specification}
                />
            </div>
            <div style={{ marginLeft: 19, marginTop: 20 }}>
                <Input title='备注' disabled={true} remark titlestyle={{ paddingLeft: 2, width: 60, textAlign: 'left' }} style={{ width: 281, marginRight: 20 }}
                    value={remark}
                />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 100
            }}>
                <Button title='关闭' buttonStyle={{ width: 100 }} onClick={() => {
                    closeClick()
                }} />
            </div>
        </Modal>
    }
}