import React, {useContext, useRef, useState} from "react";
import GeneralManagement, {QueryListItem} from "../../../components/GeneralManagement";
import ModalContainer from "../../../components/Modal";
import {Button, Form, Input, message, Tree, Space, Checkbox} from "antd";
import DeleteItem from "../../../components/DeleteItem";
import {CaretDownOutlined} from '@ant-design/icons';
import '../index.less';
import {
    authorizationRole, authorizationRoleApp,
    createOrUpdateRole,
    deleteRole,
    getRole,
    getRoleAuthority, getRoleAuthorityApp,
    getRoleList,
} from "../../../service";
import {filterData, getAllMenus, getFormData, showError} from "../../../utils";
import type {DataNode} from 'antd/es/tree';
import {UserContext} from "../../../contexts/userContext";
import type {CheckboxValueType} from 'antd/es/checkbox/Group';
import type {Key} from 'rc-tree/lib/interface';
import {ColumnsType} from "antd/es/table";

enum ModalTitle {
    ADD = '新增角色',
    EDIT = '编辑角色',
    DELETE = '系统确认',
    EMPOWER = 'PC授权',
    APPEMPOWER = 'APP授权'
}

interface EmpowerProps {
    empowerList: DataNode[],
    defaultCheckedKeys: number[],
    setSelectEmpowerList: (selectEmpowerList: number[]) => void,
}

interface AppEmpowerProps {
    defaultValue: CheckboxValueType[],
    setAppEmpowerList: (appEmpowerList: CheckboxValueType[]) => void,
    options: Option[]
}

interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

export interface GRef {
    onFinish: () => void;
}

const Role = (): React.ReactElement => {
    const {useInfo} = useContext(UserContext);
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectEmpowerList, setSelectEmpowerList] = useState<Key[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [appEmpowerList, setAppEmpowerList] = useState<CheckboxValueType[]>([]);
    const gRef = useRef<GRef>(null);
    const formList: QueryListItem[] = [
        {
            key: 'role_name',
            name: '名称',
            placeholder: '请输入角色名称'
        }
    ];
    const [form] = Form.useForm();
    const columns: ColumnsType<any> = [
        {
            title: '代码',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            ellipsis: true
        },
        {
            title: '名称',
            dataIndex: 'role_name',
            key: 'role_name',
            align: 'center',
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            render: (_: any, item: any) => {
                if (useInfo.role_ids?.includes(item.object_id)) {
                    return <div/>
                } else {
                    return (
                        <Space size={'large'} style={{whiteSpace: 'nowrap'}}>
                            <span className={'mes-options'} onClick={() => {
                                openModal(ModalTitle.EDIT, item)
                            }}>编辑</span>
                            <span className={'mes-options'} onClick={() => {
                                openModal(ModalTitle.DELETE, item)
                            }}>删除</span>
                            <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                                openModal(ModalTitle.EMPOWER, item)
                            }}>PC授权</span>
                            <span className={'mes-options'} style={{marginRight: 0}} onClick={() => {
                                openModal(ModalTitle.APPEMPOWER, item)
                            }}>APP授权</span>
                        </Space>
                    )
                }
            }
        },
    ]

    const ExtraComponent = React.memo((): JSX.Element => (
        <div>
            <Button className={'mes-add'} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
        </div>
    ))

    const AddOrEditRole = React.memo((): React.ReactElement => {
        const layout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18},
        };
        return (
            <Form {...layout} colon={false} form={form}>
                <Form.Item label={'角色代码'} name={'code'} rules={[
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[A-Za-z0-9]+$/,
                        message: '角色代码由不大于32位的字母、数字组成!'
                    }
                ]}>
                    <Input placeholder={'不填写，系统将自动生成'} disabled={form.getFieldValue('code')}/>
                </Form.Item>
                <Form.Item label={'角色名称'} name={'role_name'} rules={[
                    {required: true, message: '请输入角色名称!'},
                    {max: 32, message: '角色名称不得大于32位！'}
                ]}>
                    <Input placeholder={'请输入角色名称'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remarks'} rules={[
                    {max: 200, message: '备注不得大于200位！'}
                ]}>
                    <Input placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const Empower = React.memo((props: EmpowerProps): React.ReactElement => {
        const {empowerList, defaultCheckedKeys, setSelectEmpowerList} = props
        const onCheck = (items: any, info: any) => {
            const selectedObjectID: number[] = [];
            info.checkedNodes.map((e: any) => {
                selectedObjectID.push(e.object_id)
            })
            setSelectEmpowerList(selectedObjectID);
        }

        return (
            <div className={'mes-role-empower'}>
                <Tree checkable treeData={empowerList} onCheck={onCheck} defaultExpandAll selectable={false}
                      defaultCheckedKeys={defaultCheckedKeys} showLine switcherIcon={<CaretDownOutlined/>}/>
            </div>
        )
    })

    const AppEmpower = React.memo((props: AppEmpowerProps): React.ReactElement => {
        const {defaultValue, setAppEmpowerList, options} = props;
        const onChange = (e: CheckboxValueType[]) => {
            setAppEmpowerList(e)
        }

        return <div style={{textAlign: 'center'}}>
            <Checkbox.Group options={options} defaultValue={defaultValue} onChange={onChange}
                            className={'mes-role-app-empower'}/>
        </div>
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditRole/>)
        } else if (title === ModalTitle.EDIT) {
            getRole({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        code: res.data.code,
                        role_name: res.data.role_name,
                        remarks: res.data.remarks
                    })
                    setInnerComponent(<AddOrEditRole/>);
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        } else if (title === ModalTitle.EMPOWER) {
            getRoleAuthority({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        const menu = getAllMenus(res.data);
                        const defaultCheckedKeys: number[] = [];
                        res.data.map(e => {
                            if (e.is_have) {
                                defaultCheckedKeys.push(e.object_id.toString());
                            }
                        })
                        setSelectEmpowerList(defaultCheckedKeys)
                        setInnerComponent(<Empower empowerList={menu} defaultCheckedKeys={defaultCheckedKeys}
                                                   setSelectEmpowerList={setSelectEmpowerList}/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.APPEMPOWER) {
            getRoleAuthorityApp({object_id: item.object_id}).then((res) => {
                if (res.code === 200) {
                    if (res.data) {
                        let options: Option[] = [];
                        let defaultValue: string[] = [];
                        res.data.map(e => {
                            options.push({
                                label: e.name,
                                value: e.object_id.toString(),
                            })
                            if (e.is_have) {
                                defaultValue.push(e.object_id.toString())
                            }
                        })
                        setAppEmpowerList(defaultValue)
                        setInnerComponent(<AppEmpower options={options} defaultValue={defaultValue}
                                                      setAppEmpowerList={setAppEmpowerList}/>);
                    }
                } else {
                    showError(res.message);
                }
            })
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createOrUpdateRole(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                createOrUpdateRole(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            const formData = getFormData(filterData({object_id: selectedItem.object_id}))
            deleteRole(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('删除成功！');
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.EMPOWER) {
            let temp = filterData({
                object_id: selectedItem.object_id,
                authorizations: selectEmpowerList
            })
            const formData = new FormData();
            Object.entries(temp).map(e => {
                if (e[0] === 'authorizations') {
                    formData.append(e[0], (e[1] as any).join(','))
                } else {
                    formData.append(e[0], e[1] as any)
                }
            })
            authorizationRole(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('授权成功！');
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.APPEMPOWER) {
            let temp = filterData({
                object_id: selectedItem.object_id,
                app_authorizations: appEmpowerList
            })
            const formData = new FormData();
            Object.entries(temp).map(e => {
                if (e[0] === 'app_authorizations') {
                    formData.append(e[0], (e[1] as any).join(','))
                } else {
                    formData.append(e[0], e[1] as any)
                }
            })
            authorizationRoleApp(formData).then(res => {
                if (res.code === 200) {
                    afterOnOK('授权成功！');
                } else {
                    showError(res.message);
                }
            })
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        } else if (title === ModalTitle.EMPOWER || title === ModalTitle.APPEMPOWER) {
            setSelectEmpowerList([]);
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);
        ;
        setOpen(false);
    }

    const getDataAsync = (queryCondition: any) => {
        return new Promise((resolve) => {
            getRoleList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} getDataAsync={getDataAsync}
                               ExtraComponent={<ExtraComponent/>} ref={gRef}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}
export default Role
