import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/lib/table";
import {Table, TableColumnsType} from "antd";
import SelectFormOrderView from "../components/selectFormOrderView";
import {getCheckOrderListRequest} from "../../../service";
import moment from "moment";
import {toastErrorMessage} from "../../../utils";

/**
 * 订单查看
 */
const OrderView = (): React.ReactElement => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState<number>(0);
    const [dataSource, setDataSource] = useState<API.orderItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customName, setCustomName] = useState('');
    const [sellNum, setSellNum] = useState('');

    const columns: ColumnsType<API.orderItem> = [
        {
            title: 'No', dataIndex: 'no', align: 'center', ellipsis: true, width: '5%',
            render: (text, record, index) => <div>{index + 1}</div>
        },
        Table.EXPAND_COLUMN,
        {title: '销售单号', dataIndex: 'code', ellipsis: true,},
        {title: '客户', dataIndex: 'trader_name', ellipsis: true,},
        {
            title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true,
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD')}</div>
        },
        {title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true,},
        {
            title: '状态', dataIndex: 'status', align: 'center', ellipsis: true,
            render: (operate: number) => <div
                style={{color: '#3E7BFA'}}>
                {
                    operate === 10 ? '未下发' : operate === 20 ? '已下发' : ''
                }
            </div>
        },
        {title: '创建者', dataIndex: 'user_name', align: 'center', ellipsis: true,},
        {
            title: '创建时间', dataIndex: 'create_time', align: 'center', ellipsis: true,
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD HH:mm')}</div>
        },
        {
            title: '下发时间', dataIndex: 'issue_time', align: 'center', ellipsis: true,
            render: (text) => <div>{text === null || undefined ? '' : moment(text).format('YYYY/MM/DD HH:mm')}</div>
        },
    ]

    useEffect(() => {
        onFinish(1, 10);
    }, [])

    const onFinish = (page: number, size: number, data?: {
        customName: string,
        sellNum: string
    }) => {
        setCurrentPage(page);
        setPageSize(size)
        setIsLoading(true)
        let param: any = {page_num: page, page_size: size}
        if (data) {
            if (data.customName !== '') {
                param = {...param, trader_name: data.customName}
            }
            if (data.sellNum !== '') {
                param = {...param, code: data.sellNum}
            }
        } else {
            if (customName !== '') {
                param = {...param, trader_name: customName}
            }
            if (sellNum !== '') {
                param = {...param, code: sellNum}
            }
        }
        getCheckOrderListRequest(param).then((res) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setDataSource(res.data.data)
            } else {
                toastErrorMessage(res.message)
            }
        }).catch((e: any) => {
            toastErrorMessage(e.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleShowTotal = (total: number, range: number[]) => {
        const totalPage = Math.ceil(total / pageSize);
        return (
            <div style={{flex: 1, display: "flex", color: '#999999'}}>
                {`共${total}条记录，第${currentPage}/${totalPage}页`}
            </div>
        )
    }

    const rowRender = (record: API.orderItem) => {
        const childColumns: TableColumnsType<API.childOrderItem> = [
            {key: 0, title: 'No', dataIndex: 'serial_num', align: 'center', ellipsis: true},
            {key: 1, title: '产品', dataIndex: 'productinfo_name', align: 'center', ellipsis: true,},
            {
                key: 2, title: '数量', dataIndex: 'num', align: 'center', ellipsis: true,
                render: (num) => <div>{num === null || undefined ? '' : num.toLocaleString()}</div>
            },
            {key: 3, title: '单位', dataIndex: 'unit_name', align: 'center', ellipsis: true,},
            {
                key: 4, title: '交期', dataIndex: 'finish_date', align: 'center', ellipsis: true,
                render: (date) => <div>{date === null || undefined ? '' : moment(date).format('YYYY/MM/DD')}</div>
            },
            {key: 5, title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true,},
            {key: 6, title: '生产任务单号', dataIndex: 'task_number', align: 'center', ellipsis: true},
            {
                key: 7, title: '生产任务状态', dataIndex: 'task_status', align: 'center', ellipsis: true,
                render: (operate: number) => <div style={{color: '#3E7BFA'}}>
                    {operate === 10 ? '未下达' : operate === 20 ? '已下达' : operate === 30 ? '处理中' : operate === 40 ? '已完成' : ''}
                </div>
            },
        ];
        return (
            <Table
                className={'childTable'}
                style={{marginTop: 20, marginBottom: 20, marginLeft: 40, marginRight: 120}}
                columns={childColumns}
                dataSource={record.orderproduct_info}
                pagination={false}
            />
        )
    };

    return (
        <div>
            <SelectFormOrderView
                queryClick={(customName: string, sellNum: string) => {
                    setCustomName(customName)
                    setSellNum(sellNum)
                    onFinish(1, pageSize, {customName: customName, sellNum: sellNum})
                }}
            />
            <Table
                className={'commonTable'}
                dataSource={dataSource}
                rowKey={'object_id'}
                columns={columns}
                size={'small'}
                loading={isLoading}
                pagination={{
                    position: ['bottomRight'],
                    defaultCurrent: 1,
                    defaultPageSize: 10,
                    current: currentPage,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    total: total,
                    showQuickJumper: true,
                    onChange: onFinish,
                    showTotal: handleShowTotal
                }}
                expandable={{
                    expandedRowRender: record => rowRender(record),
                    expandIcon: ({expanded, onExpand, record}) => record.orderproduct_info.length > 0 ? (expanded ?
                        <img style={{width: 30, height: 30, cursor: "pointer"}}
                             src={require('../../../assets/icon_expanded.png')}
                             alt=""
                             onClick={e => onExpand(record, e)}/> :
                        <img style={{width: 30, height: 30, cursor: "pointer"}}
                             src={require('../../../assets/icon_unexpanded.png')}
                             alt=""
                             onClick={e => onExpand(record, e)}/>) : null,
                    rowExpandable: record => record.orderproduct_info.length > 0,
                }}
            />
        </div>
    )
}
export default OrderView
