import GeneralManagement, {GRef} from '../../../components/GeneralManagement';
import React, {useCallback, useRef, useState} from "react";
import {Button, Form, Input, message, Space} from "antd";
import {
    createOrUpdateExceptionType,
    deleteExceptionType,
    getExceptionTypeById,
    getExceptionTypeList,
} from "../../../service";
import {filterData, getFormData, showError, toastMessage} from "../../../utils";
import DeleteItem from "../../../components/DeleteItem";
import ModalContainer from "../../../components/Modal";
import '../index.less'
import {ColumnsType} from "antd/es/table";

export enum ModalTitle {
    ADD = '新增异常类别',
    EDIT = '编辑异常类别',
    DELETE = '系统确认',
}

/**
 * 异常类别
 */
const EXCCategory = () => {
    const gRef = useRef<GRef>(null);
    const [form] = Form.useForm();
    const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [innerComponent, setInnerComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

    const formList = [
        {
            key: 'exception_type_describe',
            name: '异常类别',
            placeholder: '请输入异常类别',
        }
    ]

    const columns: ColumnsType<any>  = [
        {
            title: '异常类别',
            dataIndex: 'exception_type_describe',
            key: 'exception_type_describe',
            align: 'center',
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            ellipsis: true
        },
        {
            title: '操作',
            dataIndex: 'options',
            key: 'options',
            align: 'center',
            ellipsis: true,
            render: (_: any, item: any) => {
                return (
                    <Space size={'large'}>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.EDIT, item)
                        }}>编辑</span>
                        <span className={'mes-options'} onClick={() => {
                            openModal(ModalTitle.DELETE, item)
                        }}>删除</span>
                    </Space>
                )
            }
        },
    ]

    const AddOrEditCategory = React.memo((): React.ReactElement => {
        const layout = {
            labelCol: {span: 6},
            wrapperCol: {span: 18},
        };
        return (
            <Form {...layout} colon={false} form={form}>
                <Form.Item label={'异常类别'} name={'exception_type_describe'} rules={[
                    {required: true, message: '请输入异常类别!'},
                    {
                        type: "string",
                        max: 32,
                        pattern: /^[\u4E00-\u9FA5]{0,32}$/,
                        message: '异常类别为不大于32位的汉字！'
                    }
                ]}>
                    <Input placeholder={'请输入异常类别'}/>
                </Form.Item>
                <Form.Item label={'备注'} name={'remarks'} rules={[
                    {max: 200, message: '备注不得大于200位！'}
                ]}>
                    <Input placeholder={'请输入备注'}/>
                </Form.Item>
            </Form>
        )
    })

    const openModal = (title: ModalTitle, item: any) => {
        setModalTitle(title);
        setSelectedItem(item);
        if (title === ModalTitle.ADD) {
            form.resetFields();
            setInnerComponent(<AddOrEditCategory/>)
        } else if (title === ModalTitle.EDIT) {
            getExceptionTypeById({object_id: item.object_id}).then(res => {
                if (res.code === 200) {
                    form.setFieldsValue({
                        exception_type_describe: res.data.exception_type_describe,
                        remarks: res.data.remarks
                    })
                    setInnerComponent(<AddOrEditCategory/>);
                } else {
                    showError(res.message);
                }
            })
        } else if (title === ModalTitle.DELETE) {
            setInnerComponent(<DeleteItem text={'确定要删除该数据？数据删除以后将无法恢复。'}/>)
        }
        setOpen(true);
    }

    const onOK = (title: ModalTitle) => {
        if (title === ModalTitle.ADD) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData(values))
                createOrUpdateExceptionType(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('新增成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.EDIT) {
            form.validateFields().then(values => {
                const formData = getFormData(filterData({...values, object_id: selectedItem.object_id}))
                createOrUpdateExceptionType(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('编辑成功！');
                    } else {
                        showError(res.message);
                    }
                })
            })
        } else if (title === ModalTitle.DELETE) {
            if (selectedRowKeys.length) {
                //批量删除
                const formData = getFormData(filterData({object_ids: selectedRowKeys.toString()}))
                deleteExceptionType(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                        setSelectedRowKeys([])
                    } else {
                        showError(res.message);
                    }
                })
            } else {
                //单个删除
                const formData = getFormData(filterData({object_ids: selectedItem.object_id}))
                deleteExceptionType(formData).then(res => {
                    if (res.code === 200) {
                        afterOnOK('删除成功！');
                    } else {
                        showError(res.message);
                    }
                })
            }
        }
    }

    const onCancel = (title: ModalTitle) => {
        if (title === ModalTitle.ADD || title === ModalTitle.EDIT) {
            form.resetFields();
        }
        //去除勾选的数据
        if (title === ModalTitle.DELETE && selectedRowKeys.length > 0) {
            setSelectedRowKeys([])
        }
        setSelectedItem(null);
        setModalTitle(ModalTitle.ADD);
        setInnerComponent(undefined);;
        setOpen(false);
    }

    const afterOnOK = (text: string) => {
        message.success(text);
        setOpen(false);
        setSelectedItem(null);
        refreshData();
    }

    const refreshData = () => {
        if (gRef.current) {
            gRef.current.onFinish();
        }
    }

    const ExtraComponent = React.memo((): React.ReactElement => (
        <div>
            <Button className={'mes-add'} style={{marginRight: 20}} onClick={() => {
                openModal(ModalTitle.ADD, null)
            }}>新增</Button>
            <Button className={'mes-batch-deletion'} onClick={() => {
                if (selectedRowKeys.length) {
                    openModal(ModalTitle.DELETE, null)
                } else {
                    toastMessage('请选择要删除的异常类别')
                }
            }}>批量删除</Button>
        </div>
    ))

    const getDataAsync = useCallback((queryCondition: any): Promise<any> => {
        return new Promise(resolve => {
            getExceptionTypeList(queryCondition).then(res => {
                if (res.code === 200) {
                    resolve(res.data);
                } else {
                    showError(res.message);
                }
            })
        })
    }, [])

    return (
        <div>
            <GeneralManagement formList={formList} columns={columns} ExtraComponent={<ExtraComponent/>}
                               getDataAsync={getDataAsync} ref={gRef} showSelection={true}
                               setSelectedRowKeys={setSelectedRowKeys} selectedRowKeys={selectedRowKeys}/>
            {
                modalTitle &&
                <ModalContainer title={modalTitle} open={open}
                                onOk={() => onOK(modalTitle)} onCancel={() => onCancel(modalTitle)}
                                okText={(modalTitle === ModalTitle.DELETE) ? '确认' : '保存'}
                                innerComponent={innerComponent}/>
            }
        </div>
    )
}

export default EXCCategory
